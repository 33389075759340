import React, { useEffect, useRef, useState } from "react";
import {
  Loading,
  useAuthState,
  useNotify,
  useTranslate,
  useVersion,
} from "react-admin";
import { Prompt, useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
  Typography,
  Box,
  Checkbox,
  Paper,
  makeStyles,
} from "@material-ui/core";
import currency from "currency.js"
import OfferEditAction from "./OfferEditAction";
import dataProviderForOffers from "../../../providers/DataProviderForOffers";
import { wrapArrayToMap } from "../../../utils/Wrapper";
import { cleanSession } from "../../../authProvider";
import { Delivery, OfferDetailHeader, ProductListInOffer, TotalBoxPrices, FooterOfferEditDetails } from "./OfferEditDetails";
import { deliveryRequiredFields, STATUS  } from "../enums";
import { pad } from "../../../utils/commonUtils";

const useStyles = makeStyles(() => ({
  offerFilling: {
    width: "100%",
    height: "36px",
    background: "#E7E8EA",
    borderRadius: "5px",
    lineHeight: "36px",
    padding: "0 17px"
  },
}));

const MAX_DISCOUNT_KB_PRICE = 100;
const MAX_DISCOUNT = 30;

const EditTitle = ({ record }) => {
  return <span>{record?.name?.toUpperCase()}</span>;
};

const OfferEditDetails = ({ record, id, ...props }) => {
  const [products, setProducts] = useState([]);
  const [discount, setDiscount] = useState(record?.discountPercent);
  const [kbPrice, setKbPrice] = useState(record?.kbChargePercent);
  const [isUnloading, setIsUnloading] = useState(!!record?.serviceUnloading);
  const [totals, setTotals] = useState({
    supplierPrice: currency(record?.supplierPrice),
    totalPriceForCustomer: currency(record?.total),
    priceWithDiscount: currency(record?.supplierPriceWithDiscount),
    kbPriceCalc: currency(record?.kbPrice),
    grossProfit: currency(record?.profit),
    kbPriceWithDiscountCalc: currency(record?.kbPriceWithDiscount),
    deliveryCost: currency(record?.deliveryOffer?.deliveryCost),
    subtotalWithoutVat: currency(record?.kbPriceWithDiscount),
    vat: currency(record?.vat),
  })

  const [checkAll, setCheckAll] = useState(true);
  const [errors, setErrors] = useState({});
  const [indeterminate, setIndeterminate] = useState(false);
  const [isBlocking, setIsBlocking] = useState(false);
  const checkedProducts = useRef(record?.products?.length);
  const timeError = useRef(false)
  const classes = useStyles();
  const translate = useTranslate();
  const history = useHistory();
  const notify = useNotify();

  const deliveryInfo = useRef({
    appropriateVehicleWeight: record?.deliveryOffer?.appropriateVehicleWeight,
    contactName: record?.deliveryOffer?.contactName,
    contactPhone: record?.deliveryOffer?.contactPhone,
    deliveryCost: record?.deliveryOffer?.deliveryCost ? record?.deliveryOffer?.deliveryCost : 0,
    deliveryMode: record?.deliveryOffer?.deliveryMode,
    time: record?.deliveryOffer?.deliveryTimeRange,
    date: record?.deliveryOffer?.deliveryDate,
    deliveryDate: record?.deliveryOffer?.deliveryDate,
    pickupApt: record?.deliveryOffer?.pickupApt,
    pickupCity: record?.deliveryOffer?.pickupCity,
    pickupStreet: record?.deliveryOffer?.pickupStreet,
  });

  useEffect(() => {
    const newProducts = record.products?.map((product) => {
      const newProduct = { ...product };
      newProduct.initialQuantity = product.originalQuantity;
      newProduct.isChecked = product.inStock || !!product.alternativeProduct;
      newProduct.quantity = currency(product.quantity, { precision: 3 });
      newProduct.supplierPricePerItem = currency(product.supplierPricePerItem);
      newProduct.supplierPriceTotal = currency(product.supplierPriceTotal);
      return newProduct;
    });
    const checkedValues = newProducts?.filter((val) => val.isChecked).length;
    checkedProducts.current = checkedValues;
    setIndeterminate(checkedValues > 0 && checkedValues < newProducts?.length);
    setCheckAll(checkedValues === newProducts?.length);
    setProducts(newProducts);
  }, []);

  const recalculateTotals = (products, discount, kbPrice) => {
    const supplierPrice = !products ? totals.supplierPrice : products.filter((val) => val.isChecked).reduce((a, product) => {
      return a.add(product.supplierPriceTotal);
    }, currency(0));

    const discountPrice = currency(supplierPrice).multiply(discount / 100);
    const priceWithDiscount = supplierPrice.subtract(discountPrice);
    const grossProfit = currency(priceWithDiscount).multiply(kbPrice / 100);
    const kbPriceCalc = currency(supplierPrice).multiply(1 + kbPrice / 100);
    const kbPriceWithDiscountCalc = priceWithDiscount.add(grossProfit);
    const subtotalWithoutVat = kbPriceWithDiscountCalc.add(deliveryInfo.current.deliveryCost);
    const vat = subtotalWithoutVat.multiply(17/100)

    const newTotals = ({
      supplierPrice: supplierPrice,
      totalPriceForCustomer: subtotalWithoutVat.add(vat),
      priceWithDiscount,
      kbPriceCalc,
      deliveryCost: currency(deliveryInfo.current.deliveryCost),
      kbPriceWithDiscountCalc,
      grossProfit,
      subtotalWithoutVat,
      vat,
    });
    setTotals(newTotals);
  }

  const changesDeliveryInfo = (values) => {
      setIsBlocking(true);
      deliveryInfo.current = { ...deliveryInfo.current, ...values };
      if (values?.deliveryCost) {
        recalculateTotals(null, discount, kbPrice);
      }
  }

  const changesUnloadingInfo = (values) => {
    setIsBlocking(true);
    setIsUnloading(values)
  }

  const changesProducts = (id, key, value) => {
    setIsBlocking(true);
    const newProducts = [...products];
    newProducts[id][key] = currency(value);
    newProducts[id].supplierPriceTotal = currency(newProducts[id]?.quantity)?.multiply(newProducts[id]?.supplierPricePerItem);
    recalculateTotals(newProducts, discount, kbPrice);
    setProducts(newProducts);
  }

  const changeKbPrice = (e) => {
    setIsBlocking(true);
    const { value } = e.target;
    let regex = /^\d+\.?\d{0,1}$/;
    if (!regex.test(value) && value !== "") {
      return;
    }
    if (value > MAX_DISCOUNT_KB_PRICE) {
      setKbPrice(MAX_DISCOUNT_KB_PRICE);
      recalculateTotals(null, discount, MAX_DISCOUNT_KB_PRICE);
      return;
    }
    recalculateTotals(null, discount, value);
    setKbPrice(value);
  }

  const changeDiscount = (e) => {
    setIsBlocking(true);
    const { value } = e.target;
    let regex = /^\d+\.?\d{0,1}$/;
    if (!regex.test(value) && value !== "") {
      return;
    }
    if (value > MAX_DISCOUNT) {
      setDiscount(MAX_DISCOUNT);
      recalculateTotals(null, MAX_DISCOUNT, kbPrice);
      return;
    }
    setDiscount(value);
    recalculateTotals(null, value, kbPrice);
  }

  const addProductFromOrder = (product) => {
    // productsArray.push(product);
    const newProducts = [...products];
    newProducts.push(product);
    setProducts(newProducts);
  }

  const handleSave = () => {
    const newErrors = { ...errors };

    if(record.status !== STATUS.IN_PROGRESS && record.status !== STATUS.ACCEPTED) {
      deliveryRequiredFields[deliveryInfo.current.deliveryMode]
      .forEach(field =>  field === "deliveryCost" 
        ? (!deliveryInfo.current["deliveryCost"] ?? (newErrors[field] = !Boolean(deliveryInfo.current[field])))
        : newErrors[field] = !Boolean(deliveryInfo.current[field]));
    }
 

    const isHaveError = Object.values(newErrors).some((e) => e);
    if (isHaveError || timeError.current) {
      setErrors(newErrors);
      return;
    }

    const productsArray = [];
    products.forEach((product) => {
        const newProduct = {};
        const { alternativeProduct } = product;
  
        if (alternativeProduct) {
          if (alternativeProduct.isNew) {
            newProduct.draftProductId = alternativeProduct.productId;
          } else {
            newProduct.alternativeProductId = alternativeProduct.productId;
          }
        }
        newProduct.price = product?.supplierPricePerItem || 0;
        newProduct.quantity = product.quantity || 1;
        newProduct.productId = product.productId;
       
        if (product.isChecked) {
          newProduct.inStock = true
        }else {
          newProduct.inStock = false;
        }

        productsArray.push(newProduct);
    });


    if (deliveryInfo?.current?.date) {
      let oldDate;
      // let oldTime;

      if (typeof deliveryInfo.current.date !== "object") {
        oldDate = new Date(deliveryInfo.current.date);
      } else {
        oldDate = deliveryInfo.current.date;
      }
      // if (typeof deliveryInfo.current.time !== "object") {
      //   oldTime = new Date(deliveryInfo.current.time);
      // } else {
      //   oldTime = deliveryInfo.current.time;
      // }
      const newDate = oldDate.getFullYear() +
        '-' + pad(oldDate.getMonth() + 1) +
        '-' + pad(oldDate.getDate())
        // 'T' + pad(oldTime.getHours()) +
        // ':' + pad(oldTime.getMinutes()) +
        // ':' + pad(oldTime.getSeconds());

      deliveryInfo.current.deliveryDate = newDate;
      delete deliveryInfo.current.date;
    }

    deliveryInfo.current.deliveryTimeRangeId = deliveryInfo.current?.time?.id ?? deliveryInfo.current?.time;
    deliveryInfo?.current?.toString()

     dataProviderForOffers.update("offers/",
      {
        offerId: id,
        data: {
          products: productsArray || null,
          delivery: deliveryInfo?.current,
          discount: discount || 0,
          kbPrice: kbPrice || 0,
          serviceUnloading: isUnloading,
        }
      }
    )
      .then((item) => {
        notify("ra.notification.updated", "success");
        setIsBlocking(false);
        history.push(`./${id}/show`);
      }).catch((error) => {
        if (!error.response) {
          console.error(error);
          return;
        }
        let { status } = error.response;
        if (status === 401 || status === 403) {
          cleanSession();
          window.location.href = "/login";
        }
        if (status === 500) {
          notify(
            error.response.data?.error,
            "warning"
          )
        }
        notify(
          error.response.data?.message?.message,
          "warning",
          wrapArrayToMap(error.response.data?.message?.parameters)
        );
      });
  }

  const handleCheckAll = (e) => {
    setIsBlocking(true);
    const { checked } = e.target;
    setCheckAll(checked);
    let checkedValues = 0;
    const newProducts = products.map((product) => {
      if (!product?.alternativeProduct) {
        product.isChecked = checked;
      }
      if (product.isChecked) {
        checkedValues += 1;
      }
      return product;
    });
    checkedProducts.current = checkedValues;
    recalculateTotals(newProducts, discount, kbPrice);
    setIndeterminate(false);
    setProducts(newProducts);
  };

  const handleCheckOne = (id, check) => {
    setIsBlocking(true);
    const newProducts = [...products];
    newProducts[id].isChecked = check;
    const checkedValues = newProducts.filter((val) => val.isChecked).length;
    checkedProducts.current = checkedValues;
    recalculateTotals(products, discount, kbPrice);
    setProducts(newProducts);
    setIndeterminate(checkedValues > 0 && checkedValues < newProducts.length);
    setCheckAll(checkedValues === newProducts.length);
  }

  const handleRemoveAlternative = (id) => { 
    setIsBlocking(true);
    const newProducts = [...products];
    if (!newProducts[id].inStock) {
      newProducts[id].isChecked = false;
      const checkedValues = newProducts.filter((val) => val.isChecked).length;
      checkedProducts.current = checkedValues;
      setIndeterminate(checkedValues > 0 && checkedValues < newProducts.length);
      setCheckAll(checkedValues === newProducts.length);
    }

    newProducts[id].alternativeProduct = null;
    newProducts[id].supplierPricePerItem = currency(products[id].originalSupplierPricePerItem || 0);
    newProducts[id].supplierPriceTotal = newProducts[id].quantity.multiply(products[id].originalSupplierPricePerItem || 0);
    newProducts[id].quantity = products[id].originalQuantity;
    recalculateTotals(newProducts, discount, kbPrice);
    setProducts(newProducts);
  }

  const removeProductFromOrder = (id) => {  
    const newProducts = [...products];

    newProducts.filter(({productId}, index) => {
      if(productId === id) {
        newProducts.splice(index, 1);
      }

      recalculateTotals(newProducts, discount, kbPrice);
      setProducts(newProducts);
    });
  }

  const handleAddAlternative = (id, newProduct) => {
    setIsBlocking(true);
    const newProducts = [...products];
    newProducts[id].alternativeProduct = newProduct;
    //newProducts[id].originalSupplierPricePerItem = newProducts[id].originalSupplierPricePerItem || newProducts[id].supplierPricePerItem;
    newProducts[id].supplierPricePerItem = currency(newProduct.supplierPrice);
    newProducts[id].supplierPriceTotal = newProducts[id].quantity.multiply(newProducts[id].supplierPricePerItem);
    recalculateTotals(newProducts, discount, kbPrice);
    setProducts(newProducts);
  }

  return (
    <>
      <Prompt
        when={isBlocking}
        message={translate("confirm_messages.products.back")}
      />
      <OfferEditAction basePath="/offers" resource="offers" data={record} handleSave={handleSave} />
      <Paper style={{ padding: "1em", marginTop: "1em" }}>
        <OfferDetailHeader record={record} />
        <Delivery {...props} record={record} changesDeliveryInfo={changesDeliveryInfo} changesUnloadingInfo={changesUnloadingInfo} errors={errors} setErrors={setErrors} timeError={timeError}/>
          <Box p="1em" className="table_style">
            <Typography variant="subtitle2" className={classes.offerFilling} gutterBottom>
              <Checkbox
                size="small"
                className="checkbox_products_list"
                indeterminate={indeterminate}
                checked={checkAll}
                onChange={handleCheckAll}
              />
              {translate("konebone.offer.products_in_stock")} ({checkedProducts.current}/{products?.length})
            </Typography>
              <ProductListInOffer
                offerId = {id}
                products={products}
                handleCheckOne={handleCheckOne}
                changesProducts={changesProducts}
                supplierId={record?.supplier?.supplierId}
                supplierName={record?.supplier?.name}
                handleRemoveAlternative={handleRemoveAlternative}
                handleAddAlternative={handleAddAlternative} 
                removeProductFromOrder={removeProductFromOrder}
                status={record.status}
                />
            <Box display="flex" justifyContent="space-between">
              <FooterOfferEditDetails  
                record={record}
                // minTotalKbPrice={totals.kbPriceCalc}
                // maxTotalKbPrice={totals.kbPriceCalc}
                addProductFromOrder={addProductFromOrder} 
              />      
              <TotalBoxPrices
                record={record}
                discount={discount} kbPrice={kbPrice}
                changeDiscount={changeDiscount}
                changeKbPrice={changeKbPrice}
                totals={totals}
                totalPriceForCustomer={totals.totalPriceForCustomer}
              />
            </Box>
          </Box >
      </Paper >
    </>
  )
}

const OfferEdit = (props) => {
  const [record, setRecord] = useState({});
  const [load, setLoad] = useState(false);
  const { id } = useParams();
  const notify = useNotify();
  const version = useVersion();

  const { loading, authenticated } = useAuthState();

  const mounted = useRef(false);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      setLoad(true);
      dataProviderForOffers.getOne("offers", { id: id })
        .then((item) => {
          if (mounted.current) {
            if (item?.data) {
              setRecord(item.data);
            }
            setLoad(false);
          }
        }).catch((error) => {
          setLoad(false);
          if (!error.response) {
            console.error(error);
            return;
          }
          let { status } = error.response;
          if (status === 401 || status === 403) {
            cleanSession();
            window.location.href = "/login";
          }
          if (status === 500) {
            notify(
              error.response.data?.error,
              "warning"
            )
          }
          notify(
            error.response.data?.message?.message,
            "warning",
            wrapArrayToMap(error.response.data?.message?.parameters)
          );
        });
    }
    return () => {
      mounted.current = false;
    };
  }, [version]);

  if (load) {
    return <Loading />
  }

  if (!authenticated || loading) {
    return null;
  }
  return (
    <div key={version}>
      <h1><EditTitle /></h1>

      <OfferEditDetails
        basePath="/offers"
        resource="offers"
        record={record}
        redirect="show"
        id={id}
      />
    </div>
  );
};

export { OfferEdit };
