import React, { useState, useRef, useEffect } from "react";
import {
  useTranslate,
  FormWithRedirect,
  TextField,
  useNotify,
  useRefresh,
} from "react-admin";
import {
  Typography,
  Box,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  makeStyles,
  TextField as TextInput,
  InputAdornment,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  Button,
  Paper,
  FormControl,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  List,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { Form } from 'react-final-form';
import currency from "currency.js";

import { STATUS, statusColorMap } from "./enums";
import dataProviderForProducts from "../../providers/DataProviderForProducts";

import ProjectDialog from "../../components/ProjectDialog";

import { ReactComponent as OrderIcon } from "../../svg/order.svg";
import { ReactComponent as DateIcon } from "../../svg/date-icon.svg";
import { ReactComponent as EditIcon } from "../../svg/edit_pen.svg";
import NoImage from '../../images/no_image.png';
import AutoreNewIcon from '@material-ui/icons/Autorenew';
import RemoveIcon from '@material-ui/icons/Remove';
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import { pad } from "../../utils/commonUtils";
import { wrapArrayToMap } from "../../utils/Wrapper";
import OrderEditAction from "./OrderEditAction";
import dataProviderForProjects from "../../providers/DataProviderForProjects";
import { cleanSession } from "../../authProvider";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import classNames from "classnames";
import dataProviderForOrders from "../../providers/DataProviderForOrders";
import {ReactComponent as InfoIconPack} from "../../svg/info.svg";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    width: '80%',
  },
  select: {
    boxShadow: "6px 0px 5px -5px rgb(0 0 0 /20%),-6px 0px 5px -5px rgb(0 0 0 /20%)",
    "& ul": {
      padding: "0",
      maxHeight: "302px",
      borderBottom: "none",
    },
    "& li": {
      height: "84px",
      border: "1px solid #D8DDDF",
      borderBottom: "none",
      "&:hover": {
        background: "#fff9f9",
      },
    },
  },
  statusField: {
    minWidth: "89px",
    height: "26px",
    borderRadius: "3px",
    display: "table-cell",
    textAlign: "center",
    verticalAlign: "middle",
    textTransform: "capitalize",
    fontSize: "1em",
    lineHeight: "26px",
  },
  subtitle: {
    textTransform: "uppercase",
    fontWeight: "600",
    lineHeight: "26px",
  },
  quantity: {
    display: "inline-block",
    lineHeight: "42px",
    textAlign: "center",
    height: "42px",
    width: "100px",
    background: "#E7E8EA",
    borderRadius: "2px"
  },
  avatar: {
    marginTop: 0,
    marginRight: "20px",
    "& > div": {
      padding: "5px 10px",
      height: "80px",
      width: "80px",
      borderRadius: 0,
      border: "1px solid #E5E9EB"
    }
  },
  refreshButton: {
    height: "26px",
    width: "26px",
    background: "#F7F7F8",
    border: "1px solid #DDDBDA",
    borderRadius: "4px",
    marginLeft: "8px",
    "&:hover": {
      borderRadius: "4px",
    },
  },
  searchResult: {
    position: "absolute",
    background: "#FFFFFF",
    border: "1px solid #EFEFEF",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.16)",
    overflow: 'auto',
    maxHeight: 320,
  },
  customerRequestInfo: {
    border: "1px solid #D62C2C;",
    borderRadius: "3px",
    padding: "16px",
    height: "72px",
    marginTop: "19px"
  }
}));
const OPERATION_ADD = "ADD";
const OPERATION_SUBTRACT = "SUBTRACT";
const PROJECT_OPTION_ADD = "ADD_NEW_PROJECT";
const PROJECT_OPTION_DELETE = "DELETE_PROJECT";
const PROJECT_OPTION_EDIT = "EDIT_PROJECT";
const DEFAULT_QUANTITY = 1;

const ILS = value => currency(value, { symbol: '₪ ', decimal: '.', separator: ',' });

const requiredFieldsSpecificDeliveryTime = ["date", "time"];

const OrderDetailHeader = ({ record }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const {
    id,
    customer,
    manager,
  } = record;

  return (
    <Box
      display="flex"
      mb="2em"
      flexDirection='column'
      className="box_size"
    >
      <Box mb="1em">
        <Typography variant="h6">
          {translate("konebone.offer.edit")} {translate("konebone.orders.order")} {"#" + id}<OrderIcon />
        </Typography>
        <Typography variant="body2" gutterBottom>
          {translate("konebone.orders.placed_on")} {record.created}
        </Typography>
        <TextField style={{ background: statusColorMap[record.status] }}
          className={classes.statusField} source="statusLower" />
      </Box>
      <Box >
        <Typography variant="subtitle2" gutterBottom className={classes.subtitle}>
          {translate("konebone.orders.company_name")}
        </Typography>
        <Typography variant="body2">{customer?.name}</Typography>
        <Typography variant="body2">{customer?.firstName} {customer?.lastName}, {manager?.phone}</Typography>
      </Box>
    </Box>
  )
}

const TimeConstraints = ({ deliveryTimeRange, deliveryDate, changeDeliveryInfo, errors, setErrors, timeError }) => {
  const [accordionOpen, setAccordionOpen] = useState(true);
  const [date, setDate] = useState(deliveryDate ? deliveryDate : "");
  const [time, setTime] = useState(deliveryTimeRange ? {
    label : `${deliveryTimeRange?.start?.slice(0, 5)}-${deliveryTimeRange?.end?.slice(0, 5)}`,
    id: deliveryTimeRange?.id
  } : "");
  const [timeRanges, setTimeRanges] = useState([])
  const translate = useTranslate();


  useEffect(()=> {
      dataProviderForOrders.getTimeRange()
          .then((item)=> {
            setTimeRanges(item.data)
          })
  }, []);

  const handleChange = (event) => {
    const timeValue = timeRanges.find(item => item.label === event.target.value);
    setTime(timeValue);
    changeDeliveryInfo({ time: timeValue })
  };

  const handleChangeDate = (date) => {
    if (errors.date && date) {
      setErrors({ ...errors, date: false });
    }
    setDate(date);
    changeDeliveryInfo({ date: date })
  }

  const handleChangeTime = (e) => {
    const valueDate = new Date(deliveryTimeRange.split('at'));
    const valueTime = new Date(deliveryTdeliveryTimeRangeime.split('at'))
    const newDate = new Date(e);
    const newTime = new Date(e);

    if (valueDate === newDate && valueTime < newTime) {
      timeError.current = true
    } else {
      timeError.current = false
    }

    if (errors.time && e) {
      setErrors({ ...errors, time: false });
    }
    setTime(e);
    changeDeliveryInfo({ time: e })
  }

  const handleChangeAccordion = () => {
    const newErrors = { ...errors };
    if (newErrors.date || newErrors.time) {
      return;
    }
    setAccordionOpen(!accordionOpen);
  }

  const checkRequiredField = (e) => {
    if (requiredFieldsSpecificDeliveryTime.includes(e.target.id) && !e.target.value) {
      setErrors({ ...errors, [e.target.id]: true })
    }
  }

  return (
    <FormWithRedirect
      render={() => (
        <form >
          <Accordion className="accordion" expanded={accordionOpen} onChange={handleChangeAccordion}>
            <AccordionSummary
              expandIcon={<KeyboardArrowDown />}
              aria-controls="panel1a-content"
            >
              <Typography variant="subtitle2" gutterBottom>
                {translate("konebone.orders.time_constraints")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <RadioGroup
                aria-label="gender"
                name="radio-buttons-group">
                <div className="time_constraints">
                  <Box display="flex" className="box_size">
                    <Box flex={1} mr="1em" p="1em">
                      <Typography variant="body2">{translate("konebone.orders.date")}</Typography>
                      <KeyboardDatePicker
                        id="date"
                        format={"MMM dd, yyyy"}
                        clearable
                        inputVariant="outlined"
                        value={date}
                        required
                        placeholder={translate("konebone.offer.pick_a_date")}
                        onChange={date => handleChangeDate(date)}
                        style={{ width: "100%" }}
                        autoOk="false"
                        disablePast={true}
                        keyboardIcon={<DateIcon />}
                        onBlur={checkRequiredField}
                        error={errors?.date}
                        helperText={errors?.date && translate("ra.validation.required")}
                        InputProps={{ readOnly: true }}
                        minDate={new Date(deliveryDate?.split('at'))}
                      />
                    </Box>
                    <Box flex={1} p="1em">
                      <Typography variant="body2">{translate("konebone.orders.time")}</Typography>
                      <Select
                          className={classNames('time-range', !time?.label && 'placeholder-time')}
                          variant='standard'
                          IconComponent = {AccessTimeIcon}
                          labelId="demo-controlled-open-select-label"
                          id="time"
                          value={time?.label ?? ''}
                          label="Age"
                          disableUnderline
                          required
                          onChange={handleChange}
                          displayEmpty
                          renderValue={(value) => (value ? value : translate("konebone.offer.pick_a_time"))}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left"
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left"
                            },
                            getContentAnchorEl: null,
                            classes: {
                              paper: "time-range-item",
                            },
                          }}
                      >
                        {timeRanges.map((item) => (
                            <MenuItem value={item.label} key={item.id}>{item.label}</MenuItem>
                        ))}
                      </Select>
                    </Box>
                  </Box>
                </div>
              </RadioGroup>
            </AccordionDetails>
          </Accordion>
        </form >
      )}
    />
  )
}


const Projects = ({ record, addProjectToOrder, updateProjectFromOrder, selectedProject, projects, removeProjectFromOrder }) => {
  const [accordionOpen, setAccordionOpen] = useState(true);
  const [projectSelect, setProjectSelect] = useState(record.project[0].project);
  const [openDropdownList, setOpenDropdownList] = useState(false);
  const [isProjectDialogOpen, setProjectDialogOpen] = useState(false);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [projectOption, setProjectOption] = useState("");
  const projectData = useRef({});
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const translate = useTranslate();

  const changeSelectedItem = (value, id) => {
    selectedProject(value, id)
    setProjectSelect(value);
    setOpenDropdownList(!openDropdownList);
  }

  const handleDropdownList = () => {
    setOpenDropdownList(!openDropdownList);
  }

  const openDeleteDialog = (e, projectOption, id) => {
    e.stopPropagation();
    projectData.current = projects[id]
    setProjectOption(projectOption);
    setIsOpenDeleteDialog(!isOpenDeleteDialog)
  }

  const openProjectDialog = (e,projectOption, id) => {
    if (projectOption === PROJECT_OPTION_EDIT) {
      const editProject = projects[id];
      editProject.id = id;
      projectData.current = editProject
    } else if (projectOption === PROJECT_OPTION_ADD) {
      projectData.current = {}
    }
    e.stopPropagation();
    setProjectOption(projectOption);
    setProjectDialogOpen(!isProjectDialogOpen);
  }

  const handleDelete = async (e) => {
    e.stopPropagation();
    setIsOpenDeleteDialog(!isOpenDeleteDialog);

    try {
      await dataProviderForProjects.delete("/project", {
        projectId: projectData.current.project,
      });
      refresh();
      removeProjectFromOrder(projectData.current.project);
    } catch (error) {
      if (!error.response) {
        console.error(error);
        return;
      }
      let { status } = error.response;
      if (status === 401 || status === 403) {
        cleanSession();
        window.location.href = "/login";
      }
      if (status === 500) {
        notify(
          error.response.data?.error,
          "warning"
        )
        return;
      }
      notify(
        error.response.data?.message?.message,
        "warning",
        wrapArrayToMap(error.response.data?.message?.parameters)
      );
    }
  }

  const handleCloseDialog = (e) => {
    e.stopPropagation();
    setIsOpenDeleteDialog(!isOpenDeleteDialog)
  }

  const handleSaveData = (projectOption, project) => {
    if (projectOption === PROJECT_OPTION_ADD) {
      dataProviderForProjects.create('/project', {
        apt: project?.apt || null,
        city: project.city,
        contactFullName: project.contactFullName,
        contactPhone: project.contactPhone,
        customerId: record.customer.customerId,
        district: 'null',
        name: project.name,
        street: project.street
      })
        .then((res) => {
          addProjectToOrder(res?.data)
        })
        .catch((error) => {
          if (!error.response) {
            console.error(error);
            return;
          }
          let { status } = error.response;
          if (status === 401 || status === 403) {
            cleanSession();
            window.location.href = "/login";
          }
          if (status === 500) {
            notify(
              error.response.data?.error,
              "warning"
            )
            return;
          }
        });
    }

    if (projectOption === PROJECT_OPTION_EDIT) {
      dataProviderForProjects.update('/project',
        {
          projectId: projectData.current.project,
          data: {
            apt: project?.apt || null,
            city: project.city,
            contactFullName: project.contactFullName,
            contactPhone: project.contactPhone,
            name: project.name,
            street: project.street
          }
        }
      )
        .then((res) => {
          updateProjectFromOrder(res?.data?.data, projectData.current.id)
        })
        .catch((error) => {
          if (!error.response) {
            console.error(error);
            return;
          }
          let { status } = error.response;
          if (status === 401 || status === 403) {
            cleanSession();
            window.location.href = "/login";
          }
          if (status === 500) {
            notify(
              error.response.data?.error,
              "warning"
            )
            return;
          }
        });
    }
  }

  const menuItemData = projects?.map((p, i) => {
    return (
      <>
        <MenuItem
          key={i}
          value={i}
          id={i}
          className={p.project === projectSelect ? "selected-item" : ""}
          onClick={(e) => changeSelectedItem(p.project, i)}
        >
          <ListItemText className="item-text">
            <Typography variant="subtitle1" style={{textWrap: 'wrap'}}>
              {`${p?.street} ${p?.apt ? `‘רח ${p?.apt}` : ""}, ${p?.city}, ${p?.name}`}
            </Typography>
            <Typography variant="body2">
              {`${p?.contactFullName}, ${p?.contactPhone}`}
            </Typography>
          </ListItemText>
          <Button
            className="edit-btn"
            variant="text"
            endIcon={<EditIcon />}
            onClick={(e) => openProjectDialog(e, PROJECT_OPTION_EDIT, i)}
          >{translate("konebone.orders.edit")}</Button>
          <Button
            startIcon={<CloseIcon />}
            onClick={(e) => openDeleteDialog(e, PROJECT_OPTION_DELETE, i)}>
          </Button>
          {isOpenDeleteDialog && (
            <div className="project-dialog">
              <Dialog
                open={isOpenDeleteDialog}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                classes={{ paper: classes.dialogPaper }}
              >
                <DialogTitle id="form-dialog-title" className="dialog-title">
                  {translate("konebone.orders.delete_project")}
                </DialogTitle>
                <DialogContent >
                  <div><b>{translate("konebone.orders.sure_text")}</b></div>
                  <div>{translate("konebone.orders.confirmation_text")}</div>
                </DialogContent>
                <DialogActions className={classes.root}>
                  <Button onClick={(e) => handleCloseDialog(e)} variant="outlined">
                    {translate("ra.action.cancel")}
                  </Button>
                  <Button onClick={(e) => handleDelete(e)} variant="contained" color="secondary">
                    {translate("konebone.orders.delete")}
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          )}
        </MenuItem>
      </>
    )
  })

  const SelectedMenuItem = () => {
    let project;
    projects.map((item) => {
      if (item.project === projectSelect) {
        project = item
      }
    })

    return (
      <>
        {!project ?
          <MenuItem>
            <Typography variant="subtitle1" >
              {translate("konebone.orders.no_project")}
            </Typography>
          </MenuItem>
          :
          <MenuItem onClick={handleDropdownList}>
            <ListItemText className="item-text">
              <Typography variant="subtitle1" style={{textWrap: 'wrap'}}>
                {`"${project?.name}", ${project?.street} ${project?.apt ? `‘רח ${project?.apt}` : ''} ${project?.city}`}
              </Typography>
              <Typography variant="body2">
                {`${project?.contactFullName}, ${project?.contactPhone}`}
              </Typography>
            </ListItemText>
            <Button
              className="change-btn"
              variant="text"
            >{translate("konebone.orders.change")}</Button>
          </MenuItem>
        }
      </>
    )
  }


  const handleClose = (e) => {
    e.stopPropagation();
    setIsOpenDeleteDialog(false);
  };

  const handleChange = () => {
    setAccordionOpen(!accordionOpen);
  }

  return (
    <FormWithRedirect
      render={() => (
        <form>
          <Accordion className="accordion" style={{ position: "relative" }} expanded={accordionOpen} onChange={handleChange}>
            <AccordionSummary
              expandIcon={<KeyboardArrowDown />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="subtitle2" gutterBottom> {translate("konebone.orders.project_id")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box className="box_size" mr="1em" p="1em">
                <FormControl>
                  <div className="dropdown">
                    <div className="dropdown__selected-item">
                      <SelectedMenuItem />
                    </div>
                    <div className={`wrapper ${!openDropdownList ? "closeList" : ""}`}>
                      <div className="dropdown__list">
                        {menuItemData}
                      </div>
                      <div className="add-new-project-btn">
                        <Button
                          startIcon={<AddIcon />}
                          onClick={(e) => openProjectDialog(e, PROJECT_OPTION_ADD)}>
                          {translate("konebone.orders.add_new_project")}
                        </Button>
                      </div>
                    </div>
                  </div>
                </FormControl>
              </Box>
            </AccordionDetails>
          </Accordion>
          {isProjectDialogOpen &&
            <ProjectDialog
              projectOption={projectOption}
              openDialog={isProjectDialogOpen}
              setProjectDialogOpen={setProjectDialogOpen}
              project={projectData.current}
              handleSaveData={handleSaveData}
            />
          }
        </form >
      )}
    />
  )
}

const AdditionalServices = ({ serviceUnloading, customerComment, changeDeliveryInfo, errors, setErrors }) => {
  const [unloading, setUnloading] = useState(serviceUnloading);
  const [comment, setComment] = useState(customerComment);
  const [customerCommentError, setCustomerCommentError] = useState("");
  const [accordionOpen, setAccordionOpen] = useState(true);
  const translate = useTranslate();

  const handleChangeUnloading = (e) => {
    setUnloading(e.target.checked);
    changeDeliveryInfo({ serviceUnloading: e.target.checked })
  }

  const handleChangeComment = (e) => {
    const { value } = e.target;
    setComment(value);
    changeDeliveryInfo({ customerComment: value })
  }

  const handleChange = () => {
    if (customerCommentError) {
      return;
    }
    setAccordionOpen(!accordionOpen);
  }
  return (
    <Accordion className="accordion" expanded={accordionOpen} onChange={handleChange}>
      <AccordionSummary
        expandIcon={<KeyboardArrowDown />}
        aria-controls="panel1a-content"
      >
        <Typography variant="subtitle2">{translate("konebone.orders.additional_services")}</Typography>
      </AccordionSummary>
      <Box className="box_size" p="1em">
        <AccordionDetails style={{ flexDirection: "column" }}>
          <FormControlLabel
            checked={unloading}
            onChange={handleChangeUnloading}
            control={<Checkbox />}
            label={translate("konebone.orders.unloading_with_crane")} />
          <TextInput
            id="customerComment"
            value={comment || ""}
            onChange={handleChangeComment}
            multiline
            placeholder={translate("konebone.orders.comment_for_supplier")}
            error={!!customerCommentError}
            helperText={translate(customerCommentError, { max: "100" })}
            className="comment"
          />
        </AccordionDetails>
      </Box>
    </Accordion>
  )
}

const ProductQuantityClicker = ({ id, mainQuantity, changeQuantity }) => {
  const handleClick = (operation) => {
    if (OPERATION_ADD === operation) {
      changeQuantity(id, mainQuantity + 1)
    } else {
      mainQuantity > 1
        ? changeQuantity(id, mainQuantity - 1)
        : null
    }
  }

  return (
    <div className="quantity-box__quantity-element">
      <IconButton onClick={() => handleClick(OPERATION_SUBTRACT)}><RemoveIcon /></IconButton>
      <span>{mainQuantity}</span>
      <IconButton onClick={() => handleClick(OPERATION_ADD)}><AddIcon /></IconButton>
    </div>
  )
}

const ProductAvatarInfo = ({ catalogNumber, pictureFileName, koneboneProductName, isReadyForAlternatives }) => {
  const classes = useStyles();
  return (
    <ListItem>
      <ListItemAvatar className={classes.avatar}>
        <Avatar alt="product picture" src={pictureFileName || NoImage} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <p className="code">
            <span className="product_number">{catalogNumber}</span>
            {isReadyForAlternatives && <IconButton
              variant="outlined"
              className={classes.refreshButton}
              size="small"
            >
              <AutoreNewIcon />
            </IconButton>}</p>
        }
        secondary={koneboneProductName}
      />
    </ListItem >
  )
}

const ProductListRow = ({ id, handleCheckOne, row, changeQuantity, removeProductFromOrder }) => {
  const { catalogNumber,
    isReadyForAlternatives,
    minKbPrice, maxKbPrice,
    minKpPriceWithQuantity, maxKpPriceWithQuantity,
    pictureFileName,
    koneboneProductName,
    quantity } = row;

  const handleRemoveProduct = (id, quantity) => {
    removeProductFromOrder(id, quantity);
  }

  return (
    <div className="product-row">
      <div className="product-row__checkbox">
        <Checkbox size="small" checked={row.isChecked} onChange={e => handleCheckOne(id, e.target.checked)} />
      </div>
      <div className="product-row__avatar-info">
        <ProductAvatarInfo
          catalogNumber={catalogNumber}
          pictureFileName={pictureFileName}
          koneboneProductName={koneboneProductName}
          isReadyForAlternatives={isReadyForAlternatives}
        />
      </div>
      <div className="product-row__clicker">
        <ProductQuantityClicker id={id} mainQuantity={quantity || DEFAULT_QUANTITY} changeQuantity={changeQuantity} />
      </div>
      <div className="product-row__price">
        {/* empty div */}
        <div></div>
        <div>
          <Typography variant="button" display="block" style={{ fontWeight: "600" }}>{ILS(minKpPriceWithQuantity ? minKpPriceWithQuantity : minKbPrice).format()} - {ILS(maxKpPriceWithQuantity ? maxKpPriceWithQuantity : maxKbPrice).format()}</Typography>
          <Typography variant="overline" display="block" style={{ textTransform: "lowercase" }}>{ILS(minKbPrice).format()} - {`${ILS(maxKbPrice).format()}/item`}</Typography>
        </div>
      </div>
      <div className="product-row__btn-delete">
        <IconButton onClick={() => handleRemoveProduct(id, quantity)}>
          <CloseIcon />
        </IconButton>
      </div>
    </div>
  )
}

const ProductsList = ({
  products,
  handleCheckOne,
  indeterminate,
  checkAll,
  handleCheckAll,
  changeQuantity,
  removeProductFromOrder,
  customerRequest,
  status}) => {
  const t = useTranslate();
  const classes = useStyles();

  if (!products?.length) {
    return <div></div>;
  }

  return (
    <>
      <div className="list-header">
        <Typography variant="subtitle2">
          <Checkbox
            size="small"
            className="checkbox_products_list"
            indeterminate={indeterminate}
            checked={checkAll}
            onChange={handleCheckAll}
          />
          {t("konebone.orders.ready_to_get_alternative_product")}
        </Typography>
      </div>
      {(customerRequest && (status === STATUS.NEW || status === STATUS.IN_PROGRESS || status === STATUS.IN_REVIEW)) && (
          <div className={classes.customerRequestInfo}>
            <InfoIconPack />
            <span style={{marginLeft: '15px', color: '#E02443'}}> New product request: </span>
            <br/>
            <span style={{marginLeft: '35px'}}> {customerRequest} </span>
          </div>
      )}
      {products.map((row, index) => {
        return <ProductListRow
          key={index}
          id={index}
          handleCheckOne={handleCheckOne}
          row={row}
          changeQuantity={changeQuantity}
          removeProductFromOrder={removeProductFromOrder}
        />
      })}
    </>
  )
}

const FooterOrderEditDetails = ({ record, minTotalKbPrice, maxTotalKbPrice, addProductFromOrder }) => {
  const [filterData, setFilterData] = useState('');
  const [open, setOpen] = useState(false);
  const [product, setProduct] = useState([]);
  const [allProducts, setAllProducts] = useState(record.orderProductResponseDtos);
  const translate = useTranslate();
  const notify = useNotify();
  const classes = useStyles();

  const onSubmit = () => {
    setFilterData("");
    setOpen(true);
    handleSearch();
  };

  const handleSearch = () => {
    if (filterData.length < 10) {
      setOpen(false);
      return;
    }

    if (filterData.length > 10) {
      const isExist = allProducts.some(item =>
        item.catalogNumber === filterData
      )

      if (isExist) {
        notify('This catalog number exist', 'warning');
        setOpen(false);
        return;
      }

      dataProviderForProducts.search("product", {
        search: filterData
      })
        .then((item) => {
          if (!item.data) {
            setProduct("");
          }
          setProduct(item.data);
        })
        .catch((error) => {
          if (!error.response) {
            console.error(error);
            return;
          }
          let { status } = error.response;
          if (status === 401 || status === 403) {
            cleanSession();
            window.location.href = "/login";
          }
          if (status === 500) {
            notify(
              error.response.data?.error,
              "warning"
            );
          }
          notify(
            error.response.data?.message?.message,
            "warning",
            wrapArrayToMap(error.response.data?.message?.parameters)
          );
        });
    }
  };

  const handleFilterValue = (e) => {
    if (e.target?.value) {
      setFilterData(e.target.value);
      !product && setProduct(e.target.value);
    } else {
      onSubmit();
    }
  }

  const handleAddProduct = (product) => {
    const index = allProducts.find(item =>
      item.catalogNumber === product.catalogNumber
    )

    if (!index) {
      setAllProducts([...allProducts, product])
      addProductFromOrder(product)
    } else {
      notify('This catalog number exist', 'warning')
    }

    setProduct("");
    setFilterData("");
  }

  const SearchProduct = ({ product }) => {
    const { catalogNumber, pictureFileName, koneboneProductName, minKbPrice, maxKbPrice } = product;
    return (
      <ListItem>
        <ListItemAvatar className={classes.avatar}>
          <Avatar alt="product picture" src={pictureFileName || NoImage} />
        </ListItemAvatar>
        <ListItemText
          primary={
            <p className="code">
              <span className="product_number">{catalogNumber}</span>
            </p>
          }
          secondary={
            <div className="search-product">
              <p className="search-product__p-name">{koneboneProductName}</p>
              <div className="search-product__btn">
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => handleAddProduct(product)}
                  label={translate("ra.action.add")}
                >
                  {translate("ra.action.add")}
                </Button>
              </div>
              <div className="search-product__price">{ILS(minKbPrice).format()} - {ILS(maxKbPrice).format()}</div>
            </div>
          }
        />
      </ListItem >
    )
  }

  return (
    <div className="footer-order-edit">
      <Form onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <TextInput
              helperText={false}
              source="q"
              label={translate("konebone.orders.search_by_catalog_number")}
              color="secondary"
              variant="outlined"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <SearchIcon color="disabled" />
                  </InputAdornment>
                )
              }}
              value={filterData}
              onChange={(event) => handleFilterValue(event)}
            />
            {open && product && product?.catalogNumber ? (
              <List>
                <SearchProduct product={product} />
              </List>
            ) : open && filterData.length ? (
              <div className={classes.searchResult}>
                <div className="product_not_found">
                  <div className="container">
                    <div className="product_not_found_box">
                      <div className="banner">
                        <span />
                      </div>
                      <p className="header">
                        {translate('konebone.offer.no_search_results_for')} &quot;<span className="red">{filterData}</span>&quot;
                      </p>
                      <p>{translate('konebone.offer.no_search_text_alternative')}</p>
                    </div>
                  </div>
                </div>
              </div>
            )
              : null
            }
          </form>)}
      </Form>
      <div className="footer-order-edit__total-price">
        <div>
          {translate("konebone.orders.estimated_total")}
        </div>
        <div className="footer-order-edit__price">
          <Typography variant="button" display="block" style={{ fontWeight: "600" }}>{ILS(minTotalKbPrice).format()} - {ILS(maxTotalKbPrice).format()}</Typography>
        </div>
      </div>
    </div>
  )
}

const OrderEditDetail = ({
  record,
  products,
  handleCheckOne,
  indeterminate,
  checkAll, handleCheckAll,
  totalsPrices,
  changeQuantity,
  removeProductFromOrder,
  removeProjectFromOrder,
  addProductFromOrder,
  deliveryInfo, changeDeliveryInfo,
  addProjectToOrder, updateProjectFromOrder, projects,
  errors, setErrors, timeError, basePath }) => {

  const [selectedProjectId, setSelectedProjectId] = useState(record?.project[0]?.project);

  const productArray = products.map((item) => {
    return (
      {
        isReadyForAlternatives: !!item?.isChecked,
        productId: item.productId,
        quantity: item.quantity || 1,
      }
    )
  });

  const selectedProject = (value, id) => {
    setSelectedProjectId(value)
  }

  const getDetails = () => {

    if (timeError.current) {
      return
    }

    const data = {
      products: productArray,
      unloading: deliveryInfo?.serviceUnloading
    }

    if (deliveryInfo.customerComment) {
      data.comment = deliveryInfo.customerComment
    } else {
      data.comment = ""
    }

    if (!projects.length) {
      data.projectId = null
    } else if (!selectedProjectId) {
      data.projectId = projects[0].project
    } else {
      data.projectId = selectedProjectId
    }

    if (deliveryInfo.date && deliveryInfo.time &&
        (record.status !== STATUS.AWAITING_DELIVERY && record.status !== STATUS.DELIVERED)) {
      let oldDate;

        if (typeof deliveryInfo.date !== "object") {
          oldDate = new Date(deliveryInfo.date.split('at'));
        } else {
          oldDate = deliveryInfo.date;
        }

      const newDate = oldDate?.getFullYear() +
        '-' + pad(oldDate?.getMonth() + 1) +
        '-' + pad(oldDate?.getDate())

      data.deliveryDate = newDate.toString();
      data.deliveryTimeRangeId = deliveryInfo.time?.id;
    }

    if ((!data.deliveryTimeRangeId || !data.projectId || !data.products || !data.deliveryDate)
        && (record.status !== STATUS.AWAITING_DELIVERY && record.status !== STATUS.DELIVERED)) {
      return setErrors(true)
    } else {
      setErrors(false)
      return data
    }
  }


  return (
    <>
      <OrderEditAction record={record} basePath={basePath} getDetails={getDetails} errors={errors} />
      <Paper style={{ padding: "1em", marginTop: "1em", minHeight: '700px' }} className="order_edit">

        <OrderDetailHeader record={record} />

        {(record.status !== STATUS.AWAITING_DELIVERY && record.status !== STATUS.DELIVERED) &&
          <TimeConstraints
            urgentStatus={record.urgentStatus}
            deliveryTimeRange={record.deliveryTimeRange}
            deliveryDate={record.deliveryDate}
            changeDeliveryInfo={changeDeliveryInfo}
            errors={errors}
            setErrors={setErrors}
            timeError={timeError}
          />
        }
        <>
          <Projects
            selectedProject={selectedProject}
            record={record}
            projects={projects}
            addProjectToOrder={addProjectToOrder}
            updateProjectFromOrder={updateProjectFromOrder}
            project={deliveryInfo.project}
            changeDeliveryInfo={changeDeliveryInfo}
            removeProjectFromOrder={removeProjectFromOrder}
          />
          <AdditionalServices
            serviceUnloading={record.serviceUnloading}
            customerComment={record.customerComment}
            changeDeliveryInfo={changeDeliveryInfo}
            errors={errors}
            setErrors={setErrors}
          />
        </>
          <>
            <ProductsList
              products={products}
              handleCheckOne={handleCheckOne}
              indeterminate={indeterminate}
              checkAll={checkAll}
              handleCheckAll={handleCheckAll}
              changeQuantity={changeQuantity}
              removeProductFromOrder={removeProductFromOrder}
              status={record.status}
              customerRequest={record.customerRequest}
            />
            <FooterOrderEditDetails
              record={record}
              minTotalKbPrice={totalsPrices.minTotalKbPrice}
              maxTotalKbPrice={totalsPrices.maxTotalKbPrice}
              addProductFromOrder={addProductFromOrder}
            />
          </>
      </Paper>
    </>
  )
}

export default OrderEditDetail;
