import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  IconButton,
  Typography,
  Button,
  makeStyles,
} from "@material-ui/core";
import { EditButton, Loading, TextField } from "ra-ui-materialui";
import DatagridResponsive from "../../components/DataGridResponsive";
import dataProviderForOffers from "../../providers/DataProviderForOffers";
import ClearIcon from '@material-ui/icons/Clear';
import { useEffect, useState } from "react";
import keyBy from "lodash/keyBy";
import { ListContextProvider, useNotify, useQuery, useRefresh, useTranslate } from "ra-core";
import { deliveryColorStatus, deliveryStatus, offerStatusColorMap, offerStatuses, STATUS } from "./enums";
import { ReactComponent as Repeat }  from '../../svg/repeat.svg';
import { handleFailure } from "../../utils/handleFailure";

const useStyles = makeStyles((theme) => ({
  statusField: {
    minWidth: "89px",
    height: "26px",
    borderRadius: "3px",
    display: "table-cell",
    textAlign: "center",
    verticalAlign: "middle",
    textTransform: "capitalize",
  },
  countField: {
    width: "contained",
    padding: "0 5px",
    height: "26px",
    borderRadius: "3px",
    display: "table-cell",
    textAlign: "center",
    verticalAlign: "middle",
    textTransform: "capitalize",
  },
  small_btn: {
    width: "30px",
    minWidth: "30px",
    maxWidth: "30px",
    padding: "3px 0",
    border: "1px solid #DDDBDA",
    borderRadius: "5px"
  },
}));

export const OfferCancelButton = ({ record, disabledGroupButton, checkedOffers, setSelectedSuppliers }) => {
  const [dialogOpened, setDialogOpened] = useState(false);
  const [excludeDialogOpened, setExcludeDialogOpened] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const classes = useStyles();
  const translate = useTranslate();

  const handleClick = (e) => {
    e.stopPropagation();
    setDialogOpened(true)
  };

  const handleExclude = (e) => {
    e.stopPropagation();
    setDialogOpened(true)
    setExcludeDialogOpened(true)
  }

  const handleClose = (e) => {
    e.stopPropagation();
    setDialogOpened(false);
  };


  const cancel = async () => {
    if(excludeDialogOpened) {
      try {
        await dataProviderForOffers.ungroup("offers", {
          id: record.offerId,
        });
        disabledGroupButton.current = true
        checkedOffers.current = 0;
        setSelectedSuppliers({});
        setDialogOpened(false);
        refresh();
      } catch (error) {
        handleFailure(error, notify)
      }
    } else {
      try {
        await dataProviderForOffers.cancel("offers", {
          id: record.offerId,
        });
        checkedOffers.current = 0;
        setSelectedSuppliers({});
        setDialogOpened(false);
        refresh();
      } catch (error) {
        handleFailure(error, notify)
      }
    }
  };

  if (record.status !== STATUS.COMPLETED && record.status !== STATUS.IN_PROGRESS && record.status !== STATUS.IN_REVIEW) {
    return <></>;
  }

  return (
    <>
     {!record.isPartOfGroup ?
        <div className="listButton">
          <IconButton
            variant="outlined"
            className={classes.small_btn}
            onClick={handleClick}
          >
            <ClearIcon />
          </IconButton>
        </div> :
        record.status === "IN_REVIEW" ?
          <div className="listButton" style={{opacity: "0.6", cursor: "not-allowed"}}>
            <IconButton
              variant="outlined"
              className={classes.small_btn}
              disabled={true}
            >
              <Repeat />
            </IconButton>
          </div> :
          <div className="listButton">
            <IconButton
              variant="outlined"
              className={classes.small_btn}
              onClick={handleExclude}
            >
              <Repeat />
            </IconButton>
          </div>}
      {dialogOpened && (
        <Dialog
          open={dialogOpened}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
         {excludeDialogOpened ? 
            <>
            <DialogTitle id="form-dialog-title" className={classes.capitalize}>
              {translate("konebone.orders.offers.exclude_offer")}
            </DialogTitle>
            <DialogContent>
              <Typography variant="subtitle1" style={{ fontWeight: 600 }} gutterBottom>{translate(
                "konebone.orders.offers.exclude_offer_text_start"
              )} <span style={{ color: "red" }}>{record.name}</span> {translate(
                "konebone.orders.offers.exclude_offer_text_end"
              )}</Typography>
            </DialogContent>
            </>
          :
          <>
            <DialogTitle id="form-dialog-title" className={classes.capitalize}>
              {translate("konebone.orders.offers.cancel_offer")}
            </DialogTitle>
            <DialogContent>
              <Typography variant="subtitle1" style={{ fontWeight: 600 }} gutterBottom>{translate(
                "konebone.orders.offers.sure_cancel_offer"
              )} <span style={{ color: "red" }}>{record.name}</span>?</Typography>
              <Typography variant="subtitle2" paragraph>{translate(
                "konebone.orders.offers.cancel_offer_message1"
              )}<br />{translate(
                "konebone.orders.offers.cancel_offer_message2"
              )}
              </Typography>
            </DialogContent>
          </>
          }
          <DialogActions className={classes.root}>
            <Button onClick={handleClose} variant="outlined">
              {translate("ra.action.cancel")}
            </Button>
            <Button onClick={cancel} variant="contained" color="secondary">
              {translate("konebone.orders.offers.confirm")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

const OfferEditButton = ({ status, record, id }) => {
  if (record.status !== STATUS.COMPLETED && record.status !== STATUS.IN_REVIEW 
    && record.status !== STATUS.IN_PROGRESS && (record.status !== STATUS.ACCEPTED || status === STATUS.PACKING_SLIP_VERIFIED)) {
    return <></>;
  }

  return (
    <div className="listButton">
      <EditButton label={""} record={record} basePath={`/orders/${id}/show/offers`} resource="offers" />
    </div>
  );
};

const OfferStatusField = (props) => {
  const classes = useStyles();
  const color = offerStatusColorMap[props.record?.status];
  return (
    <span
      style={{ background: color }}
      className={classes.statusField}
    >{offerStatuses[props.record?.status]}</span>
  )
};

const OfferNewProductsField = (props) => {
  const classes = useStyles();
  const color = props.record?.newProducts > 0 ? "#FBE3E7" : "transparent";

  return (
    <span
      style={{ background: color }}
      className={classes.countField}
    >{props.record?.newProducts}</span>
  )
};

const OfferNameField = (props) => {
  const { offerId, status, name, isPartOfGroup} = props.record;
  let showId = false;
  if (![STATUS.DEFAULT, STATUS.COMPLETED, STATUS.CANCELED, STATUS.IN_PROGRESS].includes(status)) {
    showId = true
  }

  return (
    <div style={{color: `${isPartOfGroup && "#E02443"}`}}><span>{name} </span>
      {showId && <div style={{ color: "#484848", opacity: "0.5", display: "inline-block" }}> (#{offerId})</div>}
    </div>
  )
};

const OfferDeliveryField = (props) => {
  const { delivery, deliveryTimeRange, deliveryDate } = props.record;
  const translate = useTranslate();
  const classes = useStyles();
  const bgrColor = !deliveryTimeRange ? deliveryColorStatus[delivery] : "";
  const color = props.record.status === STATUS.ACCEPTED && deliveryTimeRange.end && new Date() > new Date(deliveryTimeRange.end) ? 'red' : "";

  const date = deliveryDate?.split("-");
  const deliveryDateData = date ? `${date?.[2]}/${date?.[1]}/${date?.[0]}` : '';
  const deliveryTime = !deliveryTimeRange?.full ?
      `${deliveryTimeRange?.start?.slice(0, 5)}-${deliveryTimeRange?.end?.slice(0, 5)}` :
      'during the working day';

  const showTime = deliveryTimeRange.full || deliveryTimeRange.start;
  return (
    <div style={{ color: color }}>
     <div>
        <span 
            style={{ background: bgrColor }}
            className={!deliveryTimeRange ? classes.countField : ""}>
          {deliveryStatus[delivery] ? translate(`konebone.offer.${deliveryStatus[delivery].toLowerCase()}`) : "-"}
        </span>
      </div>
      <div>
        {showTime && <span>{`${deliveryDateData}; ${deliveryTime}`}</span>}
       </div>
    </div>
  )
};

const OrderOffersList = (props) => {
  const [groupId, setGroupId] = useState([]);
  const [disableSelectAll, setDisableSelectAll] = useState(false);
  const [disableByStatus, setDisableByStatus] = useState(false);
  const translate = useTranslate();
  const notify = useNotify();
  const { 
    changeSendButtonsState, 
    sendButtonsState, 
    groupOffer, 
    setPackingSlipButton, 
    getGroupPartOffers, 
    record, 
    disableCompleted,
    disabledGroupButton,
    checkedOffers,
    setSelectedSuppliers} = props;
  const { data, total, loading, error } = useQuery({
    type: 'getList',
    resource: 'offers',
    payload: {
      pagination: { page: 0, perPage: 1000 },
      sort: { field: "id", order: "ASC" },
      filter: { id: props.id },
    }
  });


  useEffect(() => {
    if (record.status === STATUS.DELIVERED || record.status === STATUS.PACKING_SLIP_VERIFIED || record.status === STATUS.AWAITING_DELIVERY) {
      setPackingSlipButton(false);
    } 
    if (record.status === STATUS.PACKING_SLIP_VERIFIED || record.status === STATUS.CANCELED 
      || record.status === STATUS.DELIVERED || record.status === STATUS.AWAITING_DELIVERY 
      || record.status === STATUS.DECLINED) {
      setDisableSelectAll(true);
    }
    if (record.status === STATUS.PACKING_SLIP_VERIFIED || record.status === STATUS.CANCELED) {
      setDisableByStatus(true)
    }

  }, []);


  useEffect(() => {
    if (!data) {
      return;
    }
    
    if(groupOffer) {
      data?.map(item => {item.isPartOfGroup ? setGroupId([...groupId,item.id]) : ""})
    }

    getGroupPartOffers(data?.filter(({isPartOfGroup}) => isPartOfGroup));

    const result = { ...sendButtonsState };
    let enabledField = false;

    data.forEach((offer) => {
      if (offer.status === STATUS.COMPLETED) {
        if (!offer.newProducts ) {
          result.customer.show = true;
        }
      } else {
        result.suppliers.show = true;
      }

      if (props.record?.status === STATUS.CANCELED || !offer.selectable) {
        enabledField = false;
      } else {
        enabledField = true;
      }
    });

    if (result.customer.show && data.length > 3) {
      setDisableSelectAll(true);
    }

    data.sort((a, b) => {
      return (Number(b.isPartOfGroup) - Number(a.isPartOfGroup))
    })

    changeSendButtonsState(result);
  }, [data, groupOffer])

  if (loading) {
    return <Loading />
  }

  if (error) {
    handleFailure(error, notify)
  }
   
  return (
    <ListContextProvider
      value={{
        onToggleItem: props.selectSupplier,
        selectedIds: props.selectedSuppliers,
        onSelect: props.onSelectAll,
        onSelectGroup: props.onSelectGroup,
        sort: { field: "name", order: "ASC" },
        currentSort: { field: "name", order: "ASC" },
        resource: "offers",
        basePath: "./offers",
        total,
      }}
    >
      <DatagridResponsive
        data={keyBy(data, "id")}
        ids={data?.map(({ id }) => String(id))}
        hasBulkActions={true}
        isRowSelectable={record => 
          disableByStatus || !record.productAvailability
            ? false : record.selectable
        }
        rowClick="show"
        disableSelectAll={disableSelectAll}
      >
        <OfferNameField
          source="name"
          label={translate("konebone.orders.offers.supplier_name")}
          sortable={false}
        />
        <OfferStatusField
          source="status"
          label={translate("konebone.orders.offers.offer_status")}
          sortable={false}
        />
        <TextField
          source="rating"
          label={translate("konebone.orders.offers.rating")}
          sortable={false}
        />
        <OfferDeliveryField
          source="delivery"
          label={translate("konebone.orders.offers.delivery")}
          sortable={false}
        />
        <OfferNewProductsField
          source="newProducts"
          label={translate("konebone.orders.offers.new_products")}
          sortable={false}
        />
        <TextField
          source="supplierPrice"
          label={translate("konebone.orders.offers.supplier_price")}
          sortable={false}
        />
        <TextField
          source="kbPrice"
          label={translate("konebone.orders.offers.kb_price")}
          sortable={false}
        />

        <OfferEditButton id={props.id} status={record.status}/>
        <OfferCancelButton disabledGroupButton={disabledGroupButton} checkedOffers={checkedOffers} setSelectedSuppliers={setSelectedSuppliers} />
      </DatagridResponsive>
    </ListContextProvider>
  )
};

export default OrderOffersList;
