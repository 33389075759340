import { Paper } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import {
  Loading, useAuthState, useNotify, useVersion,
} from "react-admin";
import { useParams } from "react-router-dom";
import { handleFailure } from "../../../utils/handleFailure";
import { STATUS } from "../enums";
import OfferShowAction from "./OfferShowAction";
import { OfferDetailHeader, ProductListInOffer, TotalBoxPrices } from "./OfferShowDetails"
import dataProviderForOffers from "../../../providers/DataProviderForOffers";
import dataProviderForOrders from '../../../providers/DataProviderForOrders';

const ShowTitle = ({ record }) => {
  return <span>{record?.name?.toUpperCase()}</span>;
};

const OfferShow = (props) => {
  const [record, setRecord] = useState({});
  const [load, setLoad] = useState(false);
  const { id, orderId } = useParams();
  const notify = useNotify();
  const version = useVersion();
  const orderStatusRef = useRef('');
  const { loading, authenticated } = useAuthState();

  const mounted = useRef(false);

  const separatorIdx = id.indexOf("_");

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      setLoad(true);

      dataProviderForOffers.getOne("offer", separatorIdx !== -1 ? { supplierId: id.slice(0, separatorIdx), orderId: id.slice(separatorIdx + 1) } : { id: id })
        .then((item) => {
          if (mounted.current) {
            if (item?.data) {
              setRecord(item.data);
            }
            setLoad(false);
          }
        }).catch((error) => {
          setLoad(false);
          handleFailure(error, notify)
        });
    }
    return () => {
      mounted.current = false;
    };
  }, [version]);

  useEffect(()=> {
    if(record?.status === STATUS.ACCEPTED){
      dataProviderForOrders.getOne('/order', {id: orderId})
      .then((item)=> {
        if(item.data.status === STATUS.PACKING_SLIP_VERIFIED){
          orderStatusRef.current = item.data.status;
        }
      })
    }
  }, [record])

  if (load) {
    return <Loading />
  }

  if (!authenticated || loading) {
    return null;
  }

  return (
    <div key={version}>
      <h1><ShowTitle /></h1>
      <OfferShowAction basePath="/offers" resource="offers" data={record} status={orderStatusRef}/>
      <Paper style={{ padding: "1em", marginTop: "1em" }}>
        <OfferDetailHeader record={record} />
        <ProductListInOffer status={record.status} products={record.productInStock} typeStock="inStock" baseHeader={Boolean(record.productInStock?.length)} />
        <ProductListInOffer status={record.status} products={record.productAlternative} typeStock="alternative" baseHeader={Boolean(record.productAlternative?.length) && !Boolean(record.productInStock?.length)} />
        <ProductListInOffer status={record.status} products={record.productOutStock} typeStock="outStock" baseHeader={Boolean(record.productOutStock?.length) && !Boolean(record.productAlternative?.length) && !Boolean(record.productInStock?.length)} />
        <TotalBoxPrices record={record} />
      </Paper >
    </div>
  );
};

export { OfferShow };
