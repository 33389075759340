import { ROOT_NODE_ID } from './constants';
/**
 * Get the identifiers of the root nodes which are stored under the ROOT_NODE_ID
 * in the nodes reducer map
 * @returns An array of identifiers
 */
export var getTreeRootNodes = function (state, resource) {
    return state.tree[resource] ? state.tree[resource].nodes[ROOT_NODE_ID] : [];
};
/**
 * Get the identifiers of the children of the node specified by its identifier
 * @returns Either an array of identifier of `false` to indicate we fetched them but found none
 */
export var getChildrenNodes = function (state, resource, id) {
    return state.tree[resource] && state.tree[resource].nodes[id] != undefined // eslint-disable-line
        ? state.tree[resource].nodes[id]
        : [];
};
/**
 * Get the nodes specified by their identifiers
 * @returns An array of records
 */
export var getTreeNodes = function (state, resource, ids) {
    return state.tree[resource] && ids
        ? ids.map(function (id) { return state.tree[resource].data[id]; })
        : [];
};
export var getIsExpanded = function (state, resource, id) {
    return state.tree[resource] ? state.tree[resource].expanded[id] === true : false;
};
export var getIsLoading = function (state, resource, id) {
    return state.tree[resource] ? state.tree[resource].loading[id] === true : false;
};
export var getHash = function (state, resource, type) {
    return state.tree[resource] ? state.tree[resource].hash[type] : 0;
};