import Wrapper from "../utils/Wrapper";

import axios from "axios";
import { stringify } from "query-string";

const dataProviderForPriceLines = {
  getOne: async (resource, { id }) => {
    const url = process.env.REACT_APP_API_URL + `/product_supplier/${id}`;
    const response = await axios.get(url, {
      withCredentials: true,
      headers: { accept: "application/json" },
    });
    let resultData = Wrapper.wrapProductSupplier(response.data.data);
    return {
      data: resultData,
    };
  },

  getList: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { order } = params.sort;
    const { productPackageId } = params.filter;
    const query = {
      field: "",
      order: order,
      page: page,
      perPage: perPage,
    };
    
    const url =
      process.env.REACT_APP_API_URL +
      `/product_supplier/${productPackageId}/?` +
      stringify(query);

    const response = await axios.get(url, {
      withCredentials: true,
      headers: { accept: "application/json" },
    });

    if (!response.data.data.productSuppliers) {
      return {
        data: [],
        total: 0,
      };
    }

    let resultData = response.data.data.productSuppliers.map((product) =>
      Wrapper.wrapPriceLines(product)
    );
    const totalElements = response.data.data.totalElements;
    return {
      data: resultData,
      total: totalElements,
    };
  },

  create: async (resource, { data }) => {
    let response = await axios.post(
      process.env.REACT_APP_API_URL + "/product_supplier",
      data,
      {
        withCredentials: true,
        headers: { accept: "application/json" },
      }
    );

    let resultData = Wrapper.wrapPriceLines(response.data.data);

    return {
      data: resultData,
    };
  },

  update: async (resource, { id, data }) => {
    let response = await axios.put(
      process.env.REACT_APP_API_URL + `/product_supplier/${id}`,
      data,
      {
        withCredentials: true,
        headers: { accept: "application/json" },
      }
    );

    let resultData = Wrapper.wrapPriceLines(response.data.data);

    return {
      data: resultData,
    };
  },

  delete: async (resource, { id }) => {
    await axios.delete(
      process.env.REACT_APP_API_URL + `/product_supplier/${id}`,
      {
        withCredentials: true,
        headers: { accept: "application/json" },
      }
    );

    return {
      data: true,
    };
  },
};

export default dataProviderForPriceLines;
