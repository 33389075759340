import Wrapper from "../utils/Wrapper";
import axios from 'axios';

const dataProviderForOffers = {

  getTimeRange: async () => {
    const url = process.env.REACT_APP_API_URL + "/time-range/" + "offer";
    const response = await axios.get(url,
        {
          withCredentials: true,
          headers: { 'accept': 'application/json' }
        });


    let resultData = response.data.data;

    let data = resultData.map((item) => {
      if (!item.full) {
        return { label: `${item.start.slice(0, 5)}-${item.end.slice(0, 5)}`, id: item.id };
      } else {
        return { label: 'during the working day', id: item.id };
      }
    });

    return ({
      data: data,
    });
  },

  delete: async (resource, params) => {
    const url = process.env.REACT_APP_API_URL + "/offer/" + params.offerId + '/product/' + params.productId;
    const response = await axios.delete(url,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      });

      if (!response || !response.data) {
        return { data: false };
      }
  
      return { data: true }
  },

  getList: async (resource, params) => {
    const { id } = params.filter;
    const url = process.env.REACT_APP_API_URL + `/order/${id}/suppliers`;

    const response = await axios.get(url,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      });

    if (!response.data || !response.data.data) {
      return ({
        data: [],
        total: 0
      });
    }

    const notSelectable = response.data.data.some((offer) => offer.status === "IN_REVIEW" || offer.status === "DECLINED" );

    let resultData = response.data.data.map((offer, i) => {
      const newOffer = Wrapper.wrapOffer(offer);
      newOffer.selectable = !notSelectable && offer.status !== "IN_PROGRESS" && offer.status !== "CANCELED" && offer.status !== "ACCEPTED"; //cancel karogha petq lini jnjel
      
      if (offer.status === "COMPLETED" && (offer.newProducts > 0 ||
        (offer.delivery === "BY_KONEBONE" && (!!offer.deliveryCost || !!offer.deliveryTime)))) {
        newOffer.selectable = true;
      } 

      if(offer.status === "DEFAULT") {
        response.data.data.some((offer) => offer.status === "IN_REVIEW" ?
          newOffer.selectable = true : false
        )
      }

      if(newOffer.productAvailability < 1) {
        newOffer.selectable = false 
      }

      newOffer.id = offer.offerId ? offer.offerId : `${offer.supplierId}_${id}`
      return newOffer;
    });


    const responseTotalCount = response.data.data.length;

    return ({
      data: resultData,
      total: responseTotalCount
    });
  },

  create: async (resource, params) => {
    let data = params.data;
    data.name = data.name.trim();
    let response = await axios.post(process.env.REACT_APP_API_URL + "/order", data,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      }
    );

    if (response && response.data) {
      let dataRes = response.data;
      dataRes.data.id = dataRes.data.manufacturerId;
      return ({
        data: dataRes.data
      });
    } else {
      return ({
        data: []
      });
    }
  },

  
  compareOffer: async (resource, params) => {
    let response = await axios.post(process.env.REACT_APP_API_URL + "/offer/compare", params,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      }
    );

    if (response && response.data && response.data.data) {
      return ({
        data: response.data.data
      });
    } else {
      return false
    }
  },

  groupOffers: async (resource, params) => {
    let response = await axios.post(process.env.REACT_APP_API_URL + "/offer/group", params,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      }
    );

    if (!response || !response.data) {
      return { data: false };
    }
    return { data: true }
  },

  ungroup: async (resource, params) => {
    let response = await axios.put(process.env.REACT_APP_API_URL + "/offer/ungroup/" + params.id, {},
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      }
    );

    if (response && response.data) {
      let dataRes = response.data;
      return ({
        data: dataRes.data
      });
    } else {
      return ({
        data: []
      });
    }
  },

  getOne: async (resource, params) => {
    const url = process.env.REACT_APP_API_URL + (params?.orderId ? `/order/${params.orderId}/supplier/${params.supplierId}` : "/offer/" + params.id);

    const response = await axios.get(url,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      });

    if (response.data.data) {
      const { products } = response.data.data
      response.data.data.productInStock = products?.filter(p => p?.inStock && !p?.alternativeProduct);
      response.data.data.productOutStock = products?.filter(p => !p?.inStock && !p?.alternativeProduct);
      response.data.data.productAlternative = products?.filter(p => p?.alternativeProduct);
    }

    let resultData = Wrapper.wrapOffer(response.data.data);

    return ({
      data: resultData,
    })
  },

  getNewProduct: async (resource, params) => {
    const url = process.env.REACT_APP_API_URL + `/offer/${params.offerId}/product/${params.productId}/new`;

    const response = await axios.get(url,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      });

    if (!response.data.data) {
      return { data: {} };
    }

    const { supplier, draftProduct } = response.data.data;

    const resultData = {};

    resultData.supplier = {
      supplierInfo: supplier?.street && supplier?.unit && supplier?.city ? `${supplier?.street} st, ${supplier?.unit} ${supplier?.city}` : "-",
      supplierContact: `${supplier?.firstName} ${supplier?.lastName}, ${supplier?.phone}`,
    }

    resultData.alternativeProduct = {
      supplierPrice: draftProduct?.pricePerUnit,
      kbPrice: draftProduct?.pricePerUnit,
      name: draftProduct?.name,
      supplierProductName: draftProduct?.name,
      inStock: true,
      supplierPn: draftProduct?.catalogNumber,
      supplierId: supplier?.supplierId,
      measurementTitle: draftProduct?.measurementTitle,
      manufacturerName: draftProduct?.manufacturerName,
      packageTypeTitle: draftProduct?.packageTypeTitle,
    }

    return ({
      data: resultData,
    })
  },

  setAlternativeProduct: async (resource, params) => {
    let response = await axios.put(process.env.REACT_APP_API_URL + `/offer/${params.offerId}/product/${params.productId}/add/${params.alternativeProductId}`, {},
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      }
    );

    if (response && response.data) {
      let dataRes = response.data;
      return ({
        data: dataRes.data
      });
    } else {
      return ({
        data: []
      });
    }
  },

  update: async (resource, params) => {
    let data = params.data;
    let response = await axios.put(process.env.REACT_APP_API_URL + "/offer/" + params.offerId, data,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      }
    );

    if (response && response.data) {
      let dataRes = response.data;
      return ({
        data: dataRes.data
      });
    } else {
      return ({
        data: []
      });
    }
  },

  cancel: async (resource, {id}) => {
    let response = await axios.put(process.env.REACT_APP_API_URL + `/offer/${id}/cancel`, {},
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      }
    );

    if (!response || !response.data) {
      return { data: false };
    }

    return { data: true }
  },

  send: async (resource, data) => {
    let response = await axios.post(process.env.REACT_APP_API_URL + `/offer/send`, data,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      }
    );

    if (!response || !response.data) {
      return { data: false };
    }

    return { data: true }
  },

  sendToCustomer: async (resource, data) => {
    let response = await axios.post(process.env.REACT_APP_API_URL + `/offer/send-to-customer`, data,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      }
    );

    if (!response || !response.data) {
      return { data: false };
    }
    return { data: true }
  },


  getTimeList: async (recource) => {
    const url = process.env.REACT_APP_API_URL + `/offer/time_for_review`;

    const response = await axios.get(url,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      });

    if (!response.data || !response.data.data) {
      return ({
        data: [],
      });
    }
    
    return ({
      data: response.data.data
    });
  },

  getPackingSlipsList: async (resource, params) => {
    const { id } = params;
    const url = process.env.REACT_APP_API_URL + `/upload/packing_slip/${id}`;

    const response = await axios.get(url, {
      withCredentials: true,
      headers: { accept: "application/json" },
    });
    if (!response.data || !response.data.data) {
      return {
        data: [],
      };
    }

    return {
      data: response.data.data,
    };
  },


  postPackingSlipsItem: async (resource, formData, index) => {
    let response = await axios.post(
      process.env.REACT_APP_API_URL + `/upload/packing_slip?offerId=${index}`,
      formData,
      {
        withCredentials: true,
        headers: {
          accept: "application/json",
        },
      }
    );
    if (!response || !response.data) {
      return { data: false };
    }
    return { data: true };
  },

  
  deletePackingSlipsItem: async (resource, params) => {
    const { id } = params;
    const url = process.env.REACT_APP_API_URL + `/upload/packing_slip/${id}`;
    const response = await axios.delete(url, {
      withCredentials: true,
      headers: { accept: "application/json" },
    });
    if (response.status === 200) {
      return { data: { id: params.id } };
    }
  },

};

export default dataProviderForOffers;
