import {
  useNotify,
  useTranslate,
  FormWithRedirect,
  DataProviderContext,
} from "react-admin";
import {
  Typography,
  Box,
  makeStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
} from "@material-ui/core";
import { useState, useContext } from "react";
import { wrapArrayToMap } from "../../utils/Wrapper";
import { cleanSession } from "../../authProvider";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  capitalize: {
    textTransform: "capitalize",
  },
}));

const validateFields = ["kbPrice", "supplierPrice", "minOrderQuantity"];
const fieldsMaxValues = {
  kbPrice: 100000,
  supplierPrice: 100000,
  minOrderQuantity: 999999
}

const PriceDialog = ({
  open,
  handleClose,
  title,
  record,
  productSupplierId,
  initData = {},
}) => {
  const [supplierPrice, setSupplierPrice] = useState(
    record?.supplierPrice || +initData?.supplierPrice || ""
  );
  const [minOrderQuantity, setMinOrderQuantity] = useState(
    record?.minOrderQuantity || 1
  );
  const [kbPrice, setKbPrice] = useState(record?.kbPrice || +initData?.kbPrice || "");
  const [errorsRequired, setErrorsRequired] = useState({});
  const [errorsBetween, setErrorsBetween] = useState({});

  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useContext(DataProviderContext);

  const handleSave = async (e) => {
    try {
      const data = {
        validFromDate: null,
        supplierPrice: +supplierPrice,
        minOrderQuantity: +minOrderQuantity,
        validToDate: null,
        kbPrice: +kbPrice,
        pricePerUnit: true,
        productSupplierId: productSupplierId,
      };
      const newErrorsRequired = { ...errorsRequired };

      const newErrorsBetween = { ...errorsBetween };

      validateFields.forEach((field) => {
        if (!data[field] && data[field] !== 0) {
          newErrorsRequired[field] = true;
        }
      });

      validateFields.forEach((field) => {
        if (data[field] <= 0 || data[field] > fieldsMaxValues[field]) {
          newErrorsBetween[field] = true;
        }
      });

      let isWrong = Object.values(newErrorsRequired).some((error) => error);

      if (isWrong) {
        setErrorsRequired(newErrorsRequired);
        return;
      }

      isWrong = Object.values(newErrorsBetween).some((error) => error);

      if (isWrong) {
        setErrorsBetween(newErrorsBetween);
        return;
      }

      if (!record) {
        await dataProvider.create("prices", { data: data });
      } else {
        await dataProvider.update("prices", { data: data, id: record.id });
      }

      handleClose(true);
    } catch (error) {
      if (!error.response) {
        console.error(e);
        return;
      }
      let { status } = error.response;
      if (status === 401 || status === 403) {
        cleanSession();
        window.location.href = "/login";
      }
      if (status === 500) {
        notify(
          error.response.data?.error,
          "warning"
        )
        return;
      }
      notify(
        error.response.data?.message?.message,
        "warning",
        wrapArrayToMap(error.response.data?.message?.parameters)
      );
    }
    handleClose(true);
  };

  const validate = (e) => {
    if (validateFields.includes(e.target.id) && !e.target.value) {
      setErrorsRequired({ ...errorsRequired, [e.target.id]: true });
    }
    if (
      validateFields.includes(e.target.id) &&
      (e.target.value <= 0 || (e.target.max && e.target.value > +e.target.max))
    ) {
      setErrorsBetween({ ...errorsBetween, [e.target.id]: true });
    }
    if (e.target.id === "minOrderQuantity" && e.target.value % 1 !== 0) {
      setErrorsBetween({ ...errorsBetween, [e.target.id]: true });
    }
  };

  const changeSupplierPrice = (e) => {
    if (errorsRequired.supplierPrice && e.target.value) {
      setErrorsRequired({ ...errorsRequired, supplierPrice: false });
    }
    if (
      errorsBetween.supplierPrice &&
      e.target.value >= 0 &&
      e.target.value < fieldsMaxValues.supplierPrice
    ) {
      setErrorsBetween({ ...errorsBetween, supplierPrice: false });
    }
    setSupplierPrice(e.target.value);
  };

  const changeKbPrice = (e) => {
    if (errorsRequired.kbPrice && e.target.value) {
      setErrorsRequired({ ...errorsRequired, kbPrice: false });
    }
    if (
      errorsBetween.kbPrice &&
      e.target.value >= 0 &&
      e.target.value < fieldsMaxValues.kbPrice
    ) {
      setErrorsBetween({ ...errorsBetween, kbPrice: false });
    }
    setKbPrice(e.target.value);
  };

  const changeMinOrderQuantity = (e) => {
    if (errorsRequired.minOrderQuantity && e.target.value) {
      setErrorsRequired({ ...errorsRequired, minOrderQuantity: false });
    }
    if (
      errorsBetween.minOrderQuantity &&
      e.target.value >= 1 && e.target.value % 1 === 0
    ) {
      setErrorsBetween({ ...errorsBetween, minOrderQuantity: false });
    }
    setMinOrderQuantity(e.target.value);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" className={classes.capitalize}>
        {title}
      </DialogTitle>
      <DialogContent>
        <FormWithRedirect
          record={{ ...record, ...initData }}
          render={() => (
            <form>
              <Box p="1em">
                <Typography variant="h6" gutterBottom margin="dense">
                  {translate(
                    "konebone.products.price_lines_list.delivery_price"
                  ).toUpperCase()}
                </Typography>

                <Box display="flex">
                  <Box flex={1} mr="0.5em">
                    <TextField
                      error={
                        errorsRequired.supplierPrice ||
                        errorsBetween.supplierPrice
                      }
                      id="supplierPrice"
                      type="number"
                      label={translate(
                        "konebone.products.price_lines_list.supplier_price"
                      )}
                      fullWidth
                      variant="outlined"
                      value={supplierPrice}
                      onChange={changeSupplierPrice}
                      onBlur={validate}
                      InputProps={{
                        inputProps: {
                          min: 0,
                          max: 100000
                        }
                      }}
                      helperText={
                        errorsRequired.supplierPrice
                          ? translate("ra.validation.required")
                          : errorsBetween.supplierPrice &&
                          translate("ra.validation.between", {
                            min: 0,
                            max: 100000,
                          })
                      }
                      required
                    />
                    <TextField
                      error={errorsRequired.minOrderQuantity ||
                        errorsBetween.minOrderQuantity}
                      id="minOrderQuantity"
                      type="number"
                      label={translate(
                        "konebone.products.price_lines_list.min_order_quantity"
                      )}
                      fullWidth
                      variant="outlined"
                      value={minOrderQuantity}
                      onChange={changeMinOrderQuantity}
                      onBlur={validate}
                      InputProps={{
                        inputProps: {
                          min: 1,
                        }
                      }}
                      helperText={errorsRequired.minOrderQuantity ? translate("ra.validation.required")
                        : errorsBetween.minOrderQuantity &&
                        translate("ra.validation.integer")}
                      required
                    />
                  </Box>
                  <Box flex={1} mr="0.5em">
                    <TextField
                      error={errorsRequired.kbPrice || errorsBetween.kbPrice}
                      id="kbPrice"
                      type="number"
                      label={translate(
                        "konebone.products.price_lines_list.kb_price"
                      )}
                      fullWidth
                      variant="outlined"
                      value={kbPrice}
                      onChange={changeKbPrice}
                      onBlur={validate}
                      InputProps={{
                        inputProps: {
                          min: 0,
                          max: 100000
                        }
                      }}
                      helperText={
                        errorsRequired.kbPrice
                          ? translate("ra.validation.required")
                          : errorsBetween.kbPrice &&
                          translate("ra.validation.between", {
                            min: 0,
                            max: 100000,
                          })
                      }
                      required
                    />
                  </Box>
                </Box>
              </Box>
            </form>
          )}
        />
      </DialogContent>
      <DialogActions className={classes.root}>
        <Button onClick={handleClose} variant="outlined">
          {translate("ra.action.cancel")}
        </Button>
        <Button onClick={handleSave} variant="contained" color="secondary">
          {translate("ra.action.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PriceDialog;
