import { EXPAND_NODE, CLOSE_NODE, TOGGLE_NODE, CRUD_GET_TREE_ROOT_NODES_SUCCESS, CRUD_GET_TREE_CHILDREN_NODES_SUCCESS } from '../actions';

const expandedReducer = (previousState = {}, { payload, type }) => {
    switch (type) {
        case EXPAND_NODE:
            return {
                ...previousState,
                [payload]: true,
            };
        case CLOSE_NODE:
            return {
                ...previousState,
                [payload]: false,
            };
        case TOGGLE_NODE:
            return {
                ...previousState,
                [payload]: previousState[payload]
                    ? !previousState[payload]
                    : true,
            };
        case CRUD_GET_TREE_ROOT_NODES_SUCCESS: {
            if (previousState && Object.keys(previousState).length > 0) {
                return previousState;
            }
            const newState = setupExpandedChildren(previousState, payload.data);
            return newState;
        }
        // case CRUD_GET_TREE_CHILDREN_NODES_SUCCESS: {
        //     if (previousState && payload.data.length && previousState[payload.data[0].id]) {
        //         return previousState;
        //     }
        //     const newState = setupExpandedChildren(previousState, payload.data);
        //     return newState;
        // }
        default:
            return previousState;
    }
};


const setupExpandedChildren = (previousState, nodes) => {
    const result = previousState;

    nodes.forEach(({ id }) => {
        result[id] = previousState[id] != undefined // eslint-disable-line eqeqeq
            ? previousState[id]
            : true;
    });

    return result;
};

export default expandedReducer;