import React from "react";
import {
  Show, Tab, TabbedShowLayout, TabbedShowLayoutTabs, useAuthState, useTranslate,
} from "react-admin";
import { makeStyles,  Paper } from "@material-ui/core";
import SupplierCompanyShow from "./SupplierCompanyShow";
import { ManagersList } from "../../components/ManagerTable";
import SupplierShowAction from "./SupplierActionComponent";
import AccessLink from "../AccessLink";

const useStyles = makeStyles((theme) => ({
  toolbar_root: {
    flexDirection: "column",
    justifyContent: "flex-start",
    "& button": {
      margin: theme.spacing(1),
    },
  },
  stepper_root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: "inline-block",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

}));

const useShowStyles = makeStyles((theme) => ({
  card: {
    background: "none",
    border: "none",
    boxShadow: "none",
    paddingBottom: "3px",
  }
}));

const ShowCustomeTitle = ({ record }) => {
  return <span>{record?.name?.toUpperCase()}</span>;
};

const ShowContent = ({ record, resource, basePath }) => {
  return (
    <>
      <Paper><SupplierCompanyShow record={record} resource={resource} basePath={basePath} /></Paper>
      <ManagersList entityId={record.id} entityType="SUPPLIER" entityStatus={record.status} canAddManager={record.status === "ACTIVE"} />
    </>
  );
};

const SupplierShow = (props) => {
  const classes = useShowStyles();
  const { loading, authenticated } = useAuthState();
  const translate = useTranslate();

  if (!authenticated || loading) {
    return null;
  }

  return (
    <>
      <Show
        title={<ShowCustomeTitle />}
        actions={<SupplierShowAction />}
        classes={classes}
        {...props}
      >
        <TabbedShowLayout tabs={<TabbedShowLayoutTabs className="sticky-tab" indicatorColor="secondary" textColor="secondary" /> }>
          <Tab label={translate("konebone.customer.info")}> 
            <ShowContent />
          </Tab>
          <Tab label={translate("konebone.customer.access_link")} >
             <AccessLink props={props}/>
          </Tab> 
        </TabbedShowLayout>
      </Show>
    </>
  );
};

export { SupplierShow };