const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June",
  "July", "Aug", "Sept", "Oct", "Nov", "Dec"
];

export function getDateByFormatShort(date) {
  const dateObj = new Date(date);
  return `${dateObj.getDate().toString()} ${monthNames[dateObj.getMonth().toString()]} ${dateObj.getFullYear().toString()}`
}

export function parseData(dataString) {
  if (!dataString) {
    return ""
  }

  const dateObj = new Date(dataString);
  const hour = dateObj.getHours();
  const minute = dateObj.getMinutes() <= 9 ? "0" + dateObj.getMinutes() : dateObj.getMinutes();
  return `${dateObj.getDate().toString()} ${monthNames[dateObj.getMonth().toString()]}, ${dateObj.getFullYear().toString()} at ${hour}:${minute}`;
}

export function getDateByFormatFull(date) {
  return new Date(date).toLocaleString('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  }).replace(',', '');
}