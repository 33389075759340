import React from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@material-ui/core";
import { MenuItemLink, getResources } from "react-admin";
import DefaultIcon from "@material-ui/icons/ViewList";
import CloudUpload from "@material-ui/icons/CloudUpload";
import { useTranslate } from "ra-core";
import VersionComponent from "./VersionComponent";

const MyMenu = ({ onMenuClick, logout }) => {
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);
  const translate = useTranslate();

  return (
    <div>
      {resources.map((resource) => {
        return(
        <MenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={open ? translate(resource.options.label) : ""}
          leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      )})}
      <MenuItemLink
        to="/upload"
        primaryText={open ? translate('konebone.upload.menu_item') : ""}
        leftIcon={<CloudUpload />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      {isXSmall && logout}
      {open && <VersionComponent />}
    </div>
  );
};

export default MyMenu;
