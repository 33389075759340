import { useTranslate } from "ra-core";
import React, { useCallback } from "react";
import {
  TextInput,
  useNotify,
  SaveButton,
  FormWithRedirect,
  useUpdate,
  useRedirect,
} from "react-admin";
import {
  Typography,
  Box,
  makeStyles,
  Button,
  Toolbar,
} from "@material-ui/core";
import { cleanSession } from "../../authProvider";
import Validator from "../../utils/Validator";
import { wrapArrayToMap } from "../../utils/Wrapper";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));


const CustomerCompanyEdit = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const notify = useNotify();
  const [update, { error }] = useUpdate();
  const redirectTo = useRedirect();
  const { basePath, resource, redirect, record } = props;

  const handleSave = useCallback(
    (values, redirect) => {
      update(resource, values.id, {
        data: values,
        onSuccess: () => {
          notify('ra.notification.updated', 'success');
          redirectTo(redirect, basePath, record.id);
        },
      });
    },
    [update, notify, redirectTo, basePath, record]
  );

  const handleCancel = () => redirectTo(redirect, basePath);

  if (error) {
    if (!error.response) {
      console.error(error);
      return;
    }
    const { status } = error.response;
    if (status === 401 || status === 403) {
      cleanSession();
      window.location.href = "/login";
    }
    if (status === 500) {
      notify(
        error.response?.data?.error,
        "warning"
      )
      return;
    }
    notify(
      error.response?.data?.message?.message,
      "warning",
      wrapArrayToMap(error.response?.data?.message?.parameters)
    );
  }

  return (
    <FormWithRedirect
      {...props}
      warnWhenUnsavedChanges
      render={formProps => (
        // here starts the custom form layout
        <form>
          <Toolbar>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <Typography variant="h6" gutterBottom>
                {translate("konebone.customer.company").toUpperCase()}
              </Typography>
              <Box className={classes.root}>
                <Button
                  variant="outlined"
                  onClick={handleCancel}
                >
                  {translate("ra.action.cancel")}
                </Button>
                <SaveButton
                  color="secondary"
                  redirect="show"
                  submitOnEnter={true}
                  saving={formProps.saving}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  onSave={handleSave}
                />
              </Box>
            </Box>
          </Toolbar>
          <Box p="1em">
            <Box display="flex" width="100%">
              <Box flex={1} mr="1em">
                <TextInput
                  source="name"
                  resource="customers"
                  label={translate(
                    "konebone.customer.company_name"
                  )}
                  fullWidth
                  variant="outlined"
                  validate={Validator.validateCompanyName}
                />
                <TextInput
                  source="companyPhone"
                  resource="customers"
                  type="tel"
                  label={translate(
                    "konebone.customer.company_phone_number"
                  )}
                  fullWidth
                  variant="outlined"
                  validate={Validator.validateCompanyPhoneNumber}
                />
                <TextInput
                  source="city"
                  resource="customers"
                  label={translate(
                    "konebone.customer.city"
                  )}
                  fullWidth
                  variant="outlined"
                  validate={Validator.validateCity}
                />
              </Box>
              <Box flex={1}>
                <Box display="flex">
                  <Box flex={1} mr="1em">
                    <TextInput
                      source="taxId"
                      resource="customers"
                      label={translate(
                        "konebone.customer.taxId"
                      )}
                      fullWidth
                      variant="outlined"
                      validate={Validator.validateTaxId}
                    />
                  </Box>
                  <Box flex={1}>
                    <TextInput
                      source="contractorNumber"
                      type="tel"
                      resource="customers"
                      label={translate(
                        "konebone.customer.contractor_number"
                      )}
                      fullWidth
                      variant="outlined"
                      validate={Validator.validateContractorNumber}
                    />
                  </Box>
                </Box>
                <TextInput
                  source="companyEmail"
                  type="email"
                  resource="customers"
                  label={translate(
                    "konebone.customer.email"
                  )}
                  fullWidth
                  variant="outlined"
                  validate={Validator.validateEmailSignIn}
                />
                <Box display="flex">
                  <Box flex={1} mr="1em">
                    <TextInput
                      source="street"
                      resource="customers"
                      label={translate(
                        "konebone.customer.street"
                      )}
                      fullWidth
                      variant="outlined"
                      validate={Validator.validateStreet}
                    />
                  </Box>
                  <Box flex={1}>
                    <TextInput
                      source="unit"
                      resource="customers"
                      label={translate(
                        "konebone.customer.unit"
                      )}
                      fullWidth
                      variant="outlined"
                      validate={Validator.validateUnit}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <TextInput
              source="description"
              resource="customers"
              label={translate(
                "konebone.customer.company_description"
              )}
              fullWidth
              multiline
              rows="4"
              variant="outlined"
              validate={Validator.validateCompanyDescription}
            />
            {record.status === "BLOCKED" && (<TextInput
              source="reasonBlock"
              resource="customers"
              label={translate(
                "konebone.customer.reason_for_block"
              )}
              fullWidth
              multiline
              rows="4"
              variant="outlined"
              validate={Validator.validateReasonBlockCompany}
            />)}
            {record.status === "DECLINED" && (<TextInput
              source="reasonDecline"
              resource="customers"
              label={translate(
                "konebone.customer.reason_for_decline"
              )}
              fullWidth
              multiline
              rows="4"
              variant="outlined"
              validate={Validator.validateReasonBlockCompany}
            />)}
          </Box>
        </form>
      )
      }
    />
  );
};

export default CustomerCompanyEdit;
