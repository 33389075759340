import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useNotify, useTranslate } from 'ra-core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Notification } from "react-admin";
import Validator from "../utils/Validator";
import { useHistory, useLocation, useParams } from 'react-router-dom';
import authProvider from '../authProvider';


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: "center"
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  forgotLink: {
    justifyContent:"center" 
  }
}));

const ResetPassword = ({ theme }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const history = useHistory();
  const notify = useNotify();
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [submitDone, setSubmitDone] = useState(false);
  const location = useLocation();
  const token = location.search;

  const submit = e => {
    e.preventDefault();
    setSubmitDone(true);

    if (passwordError.length > 0 || confirmPasswordError.length > 0) {
      return;
    }
  };
  
  const routeChange = (props) => { 
    if(password !== confirmPassword) {
      return
    }

    if(passwordError || confirmPasswordError) {
      return
    }

    authProvider.newPassword({'password': password, "token": token.slice(7)})
    .then((e)=> {
      let path = `/login`; 
      history.push(path);
    })
    .catch((e) => {
      notify('konebone.sign_in.reset_error')});   
  }

  const updatePassword = (password) => {
    setPassword(password);
    if (!Validator.validatePasswordSignIn(password)) {
      setPasswordError(translate('konebone.sign_in.password_size'));
    } else {
      setPasswordError("");
    }
  }

  const updateConfirmPassword = (confirmPassword) => {
    setConfirmPassword(confirmPassword);
    if (!Validator.validatePasswordSignIn(confirmPassword)) {
      setConfirmPasswordError(translate('konebone.sign_in.password_size'));
    } else {
      setConfirmPasswordError("");
    }
  }

  return (
    <MuiThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {translate('ra.auth.set_new_password')}
          </Typography>
          <form className={classes.form} noValidate onSubmit={submit}>
          <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              // label="Password"
              label={translate('ra.auth.new_password')}
              type="password"
              id="password"
              // autoComplete="current-password"
              value={password}
              onChange={e => updatePassword(e.target.value)}
              error={submitDone && passwordError.length > 0}
              helperText={submitDone ? passwordError : ""}
            />
             <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              // label="Password"
              label={translate('ra.auth.confirm_password')}
              type="password"
              id="confirmPassword"
              // autoComplete="current-password"
              value={confirmPassword}
              onChange={e => updateConfirmPassword(e.target.value)}
              error={submitDone && confirmPasswordError.length > 0}
              helperText={submitDone ? confirmPasswordError : ""}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={routeChange}
            >
              {translate('ra.auth.reset_password')}
            </Button>
          </form>
        </div>
      </Container>
      <Notification />
    </MuiThemeProvider>
  );
}

export default ResetPassword;
