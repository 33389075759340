import Wrapper from "../utils/Wrapper";
import axios from 'axios';
import { stringify } from "query-string";

const dataProviderForPackages = {

  getOne: async (resource, params) => {
    const url = process.env.REACT_APP_API_URL + "/package/" + params.id;
    const response = await axios.get(url,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      });
    let resultData = Wrapper.wrapPackage(response.data.data);
    return ({
      data: resultData,
    });
  },

  delete: async (resource, params) => {
    const url = process.env.REACT_APP_API_URL + "/package/" + params.id;
    const response = await axios.delete(url,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      });

    if (response.status === 200) {
      return { data: { id: params.id } }
    }
  },

  getList: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      'field': field === 'id' ? 'packageId' : field,
      'order': order,
      'page': page,
      'perPage': perPage,
    };
    const url = process.env.REACT_APP_API_URL + '/package?' + stringify(query);
    const response = await axios.get(url,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      });

    if (!response || !response.data || !response.data.data) {
      return ({
        data: [],
        total: 0
      });
    }

    let resultData = response.data.data.packages.map(the_package => Wrapper.wrapPackage(the_package));
    let count = response.data.data.totalElements;

    return ({
      data: resultData,
      total: count
    });
  },

  create: async (resource, params) => {
    let data = params.data;
    data.deviceId = 'deviceId_' + Math.random() * 123;
    data.clientType = 'WEB'

    let response = await axios.post(process.env.REACT_APP_API_URL + "/package", data,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      }
    );

    if (response && response.data) {
      let dataRes = response.data;
      dataRes.data.id = dataRes.data.adminId;
      return ({
        data: dataRes.data
      });
    } else {
      return ({
        data: []
      });
    }
  },

  update: async (resource, params) => {
    let data = params.data;
    data.deviceId = 'deviceId_' + Math.random() * 123;
    data.clientType = 'WEB';
    data.measurementId = params.data.measurement.measurementId;
    data.packageTypeId = params.data.packageType.packageTypeId;

    let response = await axios.put(process.env.REACT_APP_API_URL + "/package/" + params.data.packageId, data,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      }
    );

    if (response && response.data) {
      let dataRes = response.data;
      dataRes.data.id = dataRes.data.adminId;
      return ({
        data: dataRes.data
      });
    } else {
      return ({
        data: []
      });
    }
  },
};

export default dataProviderForPackages;
