import React, { useState, useEffect, useRef, useContext } from "react";
import {
  TextField,
  TopToolbar,
  useNotify,
  EmailField,
  ListContextProvider,
  Pagination,
  Loading,
  BooleanInput,
  DataProviderContext,
  FormWithRedirect,
  Confirm,
} from "react-admin";
import {
  makeStyles,
  Button,
  Typography,
  Paper,
  TextField as TextInput,
  DialogActions,
  DialogTitle,
  Dialog,
  DialogContent,
  Box,
  IconButton,
} from "@material-ui/core";
import classnames from "classnames";
import keyBy from "lodash/keyBy";
import { useTranslate } from "ra-core";
import EditIcon from "@material-ui/icons/Edit";
import { wrapArrayToMap } from "../utils/Wrapper";
import DatagridResponsive from "./DataGridResponsive";
import { cleanSession } from "../authProvider";
import { ReactComponent as ButtonLock } from "../svg/button-lock.svg";
import { ReactComponent as ButtonOpen } from "../svg/button-open.svg";
import dataProviderForManagers from "../providers/DataProviderForManagers";
import Validator from "../utils/Validator";

const ACCESS = {
  block: 'block',
  unblock: 'unblock'
}
const STATUS = {
  BLOCKED: 'BLOCKED',
  ACTIVE: 'ACTIVE',
  WAITING_FOR_REVIEW: 'WAITING_FOR_REVIEW',
  DECLINED: "DECLINED"
}

const requredFields = ["firstName", "phone", "jobTitle", "email", "lastName"];

const useStyles = makeStyles((theme) => ({
  tolbar_root: {
    justifyContent: "space-between",
    "& > *": {
      margin: theme.spacing(1.5),
    },
  },
  blocked: {
    color: "red",
  },
  active: {
    color: "green",
  },
  waiting_for_review: {
    color: "#2F80ED",
  },
  unfinished: {
    color: "#686868",
  },
  paper_mr: {
    marginTop: "10px",
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  checkbox: {
    width: "fit-content",
  },
  capitalize: {
    textTransform: "capitalize",
  },
  editButton: {
    height: "28px",
    width: "28px",
    "&:hover": {
      borderRadius: "3px",
      backgroundColor: "rgba(63, 81, 181, 0.04)",
    },
  },
}));

const ListActions = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter, // you can hide ExportButton if exporter = (null || false)
  filterValues,
  permanentFilter,
  hasCreate, // you can hide CreateButton if hasCreate = false
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  openAddManagerDialog,
  canAddManager,
  ...rest
}) => {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <TopToolbar className={classnames(className, classes.tolbar_root)}>
      <Typography variant="h6" gutterBottom margin="dense">
        {translate(
          "konebone.manager.title"
        ).toUpperCase()}
      </Typography>
      <Button
        variant="outlined"
        color="secondary"
        onClick={(e) => openAddManagerDialog()}
        disabled={!canAddManager}
      >
        {translate("konebone.manager.add_manager")}
      </Button>
    </TopToolbar>
  )
}

ListActions.defaultProps = {
  selectedIds: [],
  onUnselectItems: () => null,
};

const ManagerDialog = ({ open,
  handleClose,
  title,
  record,
  entityId,
  entityType,
  total }) => {
  const [firstName, setFirstName] = useState(
    record?.firstName || ""
  );
  const [lastName, setLastName] = useState(
    record?.lastName || ""
  );
  const [phone, setPhone] = useState(
    record?.phone || ""
  );
  const [email, setEmail] = useState(
    record?.email || ""
  );

  const [jobTitle, setJobTitle] = useState(
    record?.jobTitle || ""
  );

  const [main, setMain] = useState(
    record?.main || ((record && total === 1) || total === 0) || false
  );

  const [reason, setReason] = useState(
    record?.reasonBlock || ""
  );

  const [errors, setErrors] = useState({});

  const [phoneError, setPhoneError] = useState("");

  const [emailError, setEmailError] = useState("");

  const [firstNameError, setFirstNameError] = useState("");

  const [lastNameError, setLastNameError] = useState("");

  const [jobTitleError, setJobTitleError] = useState("");

  const [reasonForBlockError, setReasonForBlockError] = useState("");

  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useContext(DataProviderContext);

  const handleSave = async (e) => {
    try {
      const data = {
        firstName: firstName?.trim(),
        lastName: lastName?.trim(),
        email: email?.trim(),
        jobTitle: jobTitle?.trim(),
        phone: phone,
        entityId: entityId,
        main: main,
        entityType: entityType,
      };

      let isError = false;

      if (Validator.validateCustomerPhone(phone)) {
        setPhoneError(Validator.validateCustomerPhone(phone));
        isError = true;
      }

      if (Validator.validateEmailSignIn(email)) {
        setEmailError(Validator.validateEmailSignIn(email));
        isError = true;
      }

      if (Validator.validateFirstName(firstName)) {
        setFirstNameError(Validator.validateFirstName(firstName));
        isError = true;
      }

      if (Validator.validateLastName(lastName)) {
        setLastNameError(Validator.validateLastName(lastName));
        isError = true;
      }

      if (Validator.validateJobTitle(jobTitle)) {
        setJobTitleError(Validator.validateJobTitle(jobTitle));
        isError = true;
      }

      if (Validator.validateReasonBlock(reason)) {
        setReasonForBlockError(Validator.validateReasonBlock(reason));
        isError = true;
      }

      const newErrors = { ...errors };

      requredFields.forEach((field) => {
        if (!data[field]) {
          newErrors[field] = true;
        }
      });

      const isWrong = Object.values(newErrors).some((error) => error);

      if (isWrong) {
        setErrors(newErrors);
        return;
      }

      if (isError) {
        return;
      }

      if (!record) {
        await dataProvider.create("manager", { data: data });
      } else {
        await dataProvider.update("manager", { data: data, id: record.id, reasonBlock: reason });
      }

      handleClose(true);
    } catch (error) {
      if (!error.response) {
        console.error(error);
        return;
      }
      let { status } = error.response;
      if (status === 401 || status === 403) {
        cleanSession();
        window.location.href = "/login";
      }
      if (status === 500) {
        notify(
          error.response.data?.error,
          "warning"
        )
        return;
      }
      notify(
        error.response.data?.message?.message,
        "warning",
        wrapArrayToMap(error.response.data?.message?.parameters)
      );

    }
  };

  const validate = (e) => {
    if (requredFields.includes(e.target.id) && !e.target.value) {
      setErrors({ ...errors, [e.target.id]: true });
    }
  };

  const changeFirstName = (e) => {
    if (errors.firstName && e.target.value) {
      setErrors({ ...errors, firstName: false });
    }
    setFirstNameError("");
    setFirstName(e.target.value);
  };

  const changeLastName = (e) => {
    if (errors.lastName && e.target.value) {
      setErrors({ ...errors, lastName: false });
    }
    setLastNameError("");
    setLastName(e.target.value);
  };

  const changeEmail = (e) => {
    if (errors.email && e.target.value) {
      setErrors({ ...errors, email: false });
    }
    setEmailError("");
    setEmail(e.target.value);
  };

  const changeJobTitle = (e) => {
    if (errors.jobTitle && e.target.value) {
      setErrors({ ...errors, jobTitle: false });
    }
    setJobTitleError("");
    setJobTitle(e.target.value);
  };

  const changePhone = (e) => {
    if (errors.phone && e.target.value) {
      setErrors({ ...errors, phone: false });
    }
    setPhoneError("");
    setPhone(e.target.value);
  };

  const changeReason = (e) => {
    setReasonForBlockError("");
    setReason(e.target.value);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" className={classes.capitalize}>
        {title}
      </DialogTitle>
      <DialogContent>
        <FormWithRedirect
          record={record}
          render={() => (
            <form>
              <Box p="1em">
                <Typography variant="h6" gutterBottom margin="dense">
                  {translate(
                    "konebone.manager.user_information"
                  ).toUpperCase()}
                </Typography>

                <Box display="flex">
                  <Box flex={1} mr="0.5em">
                    <TextInput
                      error={errors.firstName || !!firstNameError}
                      id="firstName"
                      label={translate(
                        "konebone.manager.first_name"
                      )}
                      fullWidth
                      variant="outlined"
                      value={firstName}
                      onChange={changeFirstName}
                      onBlur={validate}
                      helperText={errors.firstName ? translate("ra.validation.required") : translate(firstNameError, { max: "100" })}
                      required
                    />
                    <TextInput
                      error={errors.phone || !!phoneError}
                      id="phone"
                      type="tel"
                      label={translate(
                        "konebone.manager.phone"
                      )}
                      fullWidth
                      variant="outlined"
                      value={phone}
                      onChange={changePhone}
                      onBlur={validate}
                      helperText={errors.phone ? translate("ra.validation.required") : translate(phoneError)}
                      required
                    />
                    <TextInput
                      error={errors.email || !!emailError}
                      id="email"
                      type="email"
                      label={translate(
                        "konebone.manager.email"
                      )}
                      fullWidth
                      variant="outlined"
                      value={email}
                      onChange={changeEmail}
                      onBlur={validate}
                      helperText={errors.email ? translate("ra.validation.required") : translate(emailError)}
                      required
                    />
                  </Box>
                  <Box flex={1}>
                    <TextInput
                      error={errors.lastName || !!lastNameError}
                      id="lastName"
                      label={translate(
                        "konebone.manager.last_name"
                      )}
                      fullWidth
                      variant="outlined"
                      value={lastName}
                      onChange={changeLastName}
                      onBlur={validate}
                      helperText={errors.lastName ? translate("ra.validation.required") : translate(lastNameError, { max: "100" })}
                      required
                    />
                    <TextInput
                      error={errors.jobTitle || !!jobTitleError}
                      id="jobTitle"
                      label={translate(
                        "konebone.manager.job_title"
                      )}
                      fullWidth
                      variant="outlined"
                      value={jobTitle}
                      onChange={changeJobTitle}
                      onBlur={validate}
                      helperText={errors.jobTitle ? translate("ra.validation.required") : translate(jobTitleError, { max: "100" })}
                      required
                    />
                    <BooleanInput
                      source="main"
                      resource="customers"
                      label={translate(
                        "konebone.manager.user_main_contact"
                      ).toUpperCase()}
                      className={classes.checkbox}
                      checked={main}
                      onChange={(value) => setMain(value)}
                      // disabled={(record && total === 1) || total === 0 || record?.status === STATUS.BLOCKED || record?.main}
                    />
                  </Box>
                </Box>
                {record && record?.status === STATUS.BLOCKED && <TextInput
                  error={!!reasonForBlockError}
                  id="reason"
                  label={translate(
                    "konebone.manager.reason_for_block"
                  )}
                  fullWidth
                  variant="outlined"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  helperText={translate(reasonForBlockError, { max: "500" })}
                />}
              </Box>
            </form>
          )}
        />
      </DialogContent>
      <DialogActions className={classes.root}>
        <Button onClick={handleClose} variant="outlined">
          {translate("ra.action.cancel")}
        </Button>
        <Button onClick={handleSave} variant="contained" color="secondary">
          {translate("ra.action.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ManagerBlockedButton = ({ record, refreshAfterClose, entityStatus }) => {
  const [dialogOpened, setDialogOpened] = useState(false);
  const [open, setOpen] = useState(false);
  const [reason, setReason] = useState("");
  const [reasonError, setReasonError] = useState("");
  const notify = useNotify();
  const classes = useStyles();
  const translate = useTranslate();

  const unblocked = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const handleClick = (e) => {
    e.stopPropagation();
    setDialogOpened(true)
  };

  const handleClose = () => {
    setDialogOpened(false);
  };

  const blocked = () => {
    let isError = false;

    if (Validator.validateReasonBlock(reason)) {
      setReasonError(Validator.validateReasonBlock(reason));
      isError = true;
    }

    if (isError) {
      return;
    }
    dataProviderForManagers.updateStatus("managers", {
      data: {
        status: ACCESS.block,
        managerId: record.id,
        reason: reason,
      },
    }).then(() => {
      setDialogOpened(false);
      refreshAfterClose(true);
    }).catch((error) => {
      if (!error.response) {
        console.error(error);
        return;
      }
      let { status } = error.response;
      if (status === 401 || status === 403) {
        cleanSession();
        window.location.href = "/login";
      }
      if (status === 500) {
        notify(
          error.response.data?.error,
          "warning"
        )
        return;
      }
      notify(
        error.response.data?.message?.message,
        "warning",
        wrapArrayToMap(error.response.data?.message?.parameters)
      );
    })
  };

  const handleConfirm = () => {
    dataProviderForManagers.updateStatus("managers", {
      data: {
        status: ACCESS.unblock,
        managerId: record.id,
      },
    }).then(() => {
      setOpen(false)
      refreshAfterClose(true);
    }).catch((error) => {
      if (!error.response) {
        console.error(error);
        return;
      }
      let { status } = error.response;
      if (status === 401 || status === 403) {
        cleanSession();
        window.location.href = "/login";
      }
      if (status === 500) {
        notify(
          error.response.data?.error,
          "warning"
        )
        return;
      }
      notify(
        error.response.data?.message?.message,
        "warning",
        wrapArrayToMap(error.response.data?.message?.parameters)
      );
    })
  };

  if (record?.main || record.status === STATUS.WAITING_FOR_REVIEW || (entityStatus === STATUS.DECLINED || entityStatus === STATUS.BLOCKED)) {
    return (<></>);
  }

  const changeReason = (e) => {
    const { value } = e.target
    setReasonError("");
    setReason(value);
  }

  return (
    <>
      <div className="listButton">
        {record.status === STATUS.ACTIVE ?
          (<button style={{ border: "none", background: "none" }} onClick={handleClick}>
            <ButtonLock />
          </button>) : (
            (<button style={{ border: "none", background: "none" }} onClick={unblocked}>
              <ButtonOpen />
            </button>)
          )
        }
      </div>
      {dialogOpened && (
        <Dialog
          open={dialogOpened}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" className={classes.capitalize}>
            {translate("konebone.manager.block_manager")}
          </DialogTitle>
          <DialogContent>
            <TextInput
              error={!!reasonError}
              id="name"
              label={translate(
                "konebone.manager.reason_for_block"
              )}
              fullWidth
              variant="outlined"
              value={reason}
              onChange={changeReason}
              multiline
              rows="4"
              helperText={reasonError && translate(reasonError, { max: "500" })}
            />
          </DialogContent>
          <DialogActions className={classes.root}>
            <Button onClick={handleClose} variant="outlined">
              {translate("ra.action.cancel")}
            </Button>
            <Button onClick={blocked} variant="contained" color="secondary">
              {translate("konebone.customer.block")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Confirm
        isOpen={open}
        title=""
        content={translate("confirm_messages.manager.unblock", { arg_0: record.fullName })}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

const ManagersEditButton = ({ record, openAddManagerDialog }) => {
  const classes = useStyles();

  return (
    <div className="listButton">
      <IconButton
        record={record}
        color="primary"
        onClick={(e) => openAddManagerDialog(record)}
        size="small"
        className={classes.editButton}
      >
        <EditIcon />
      </IconButton>
    </div>
  );
};

const ManagerNameField = ({ record }) => {
  const translate = useTranslate();

  if (!record?.main) {
    return (<span>{record.fullName}</span>)
  }

  return (
    <>
      <span>{record?.fullName}</span>
      <br />
      <b>{translate("konebone.manager.main_contact")}</b>
    </>
  );
}

const ManagerStatusField = ({ record, entityStatus }) => {
  const classes = useStyles();

  const value = (entityStatus === STATUS.DECLINED || entityStatus === STATUS.BLOCKED) ? "Blocked" : record?.active;
  const status = (entityStatus === STATUS.DECLINED || entityStatus === STATUS.BLOCKED) ? "blocked" : record?.status.toLowerCase();

  return (
    (<span className={classnames(classes.capitalize, classes[status])}>{value}</span>)
  )
}

export const ManagersList = ({ entityId, entityType, canAddManager, entityStatus }) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [sort, setSort] = useState({ field: 'id', order: 'ASC' })
  const [openDialog, setOpenDialog] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);

  const dataProvider = useContext(DataProviderContext);

  const classes = useStyles();
  const translate = useTranslate();
  const notify = useNotify();

  const dialogRecord = useRef(null);

  useEffect(() => {
    if (!entityId || !entityType) {
      return;
    }

    dataProvider
      .getList("manager", {
        sort: sort,
        pagination: {
          page: page,
          perPage: perPage,
        },
        filter: { entityId: entityId, entityType: entityType },
      })
      .then(({ data, total }) => {

        setData(data);
        setTotal(total);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (!error.response) {
          console.error(error);
          return;
        }
        let { status } = error.response;
        if (status === 401 || status === 403) {
          cleanSession();
          window.location.href = "/login";
        }
        if (status === 500) {
          notify(
            error.response.data?.error,
            "warning"
          )
          return;
        }
        notify(
          error.response.data?.message?.message,
          "warning",
          wrapArrayToMap(error.response.data?.message?.parameters)
        );
      });
  }, [entityStatus]);

  const openAddManagerDialog = (record) => {
    setOpenDialog(true);
    dialogRecord.current = record;
  }

  const closeAddManagerDialog = (refresh) => {
    if (refresh) {
      dataProvider
        .getList("manager", {
          sort: sort,
          pagination: {
            page: page,
            perPage: perPage,
          },
          filter: { entityId: entityId, entityType: entityType },
        })
        .then(({ data }) => {
          setData(data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          if (!error.response) {
            console.error(error);
            return;
          }
          let { status } = error.response;
          if (status === 401 || status === 403) {
            cleanSession();
            window.location.href = "/login";
          }
          if (status === 500) {
            notify(
              error.response.data?.error,
              "warning"
            )
            return;
          }
          notify(
            error.response.data?.message?.message,
            "warning",
            wrapArrayToMap(error.response.data?.message?.parameters)
          );
        });
    }

    setOpenDialog(false);
    dialogRecord.current = null;
  };

  if (loading) {
    return <Loading />
  }

  return (
    <Paper className={classes.paper_mr}>
      <ListContextProvider
        value={{
          total,
          page,
          perPage,
          setPage,
          setSort
        }}>
        <ListActions openAddManagerDialog={openAddManagerDialog} canAddManager={canAddManager} />
        <DatagridResponsive
          data={keyBy(data, 'id')}
          ids={data.map(({ id }) => id)}
          currentSort={sort}
          setSort={(field, order) => setSort({ field, order })}>
          <ManagerNameField
            source="fullName"
            label={translate("konebone.manager.full_name")}
            sortable={false}
          />
          <TextField
            source="phone"
            label={translate("konebone.manager.phone")}
            sortable={false}
          />
          <ManagerStatusField
            source="active"
            label={translate("konebone.manager.active")}
            entityStatus={entityStatus}
            sortable={false}
          />
          <EmailField
            source="email"
            label={translate("konebone.manager.email")}
            sortable={false}
          />
          <TextField
            source="jobTitle"
            label={translate("konebone.manager.job_title")}
            sortable={false}
          />
          <ManagersEditButton openAddManagerDialog={openAddManagerDialog} />
          <ManagerBlockedButton refreshAfterClose={closeAddManagerDialog} entityStatus={entityStatus} />
        </DatagridResponsive>
        {data.length ? <Pagination
          page={page}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
        /> : <></>}
      </ListContextProvider >
      {openDialog && <ManagerDialog
        title={
          dialogRecord.current
            ? translate("konebone.manager.edit_manager")
            : translate("konebone.manager.add_manager")
        }
        open={openDialog}
        handleClose={closeAddManagerDialog}
        record={dialogRecord.current}
        total={total}
        entityId={entityId} entityType={entityType} />}
    </Paper>
  );
};
