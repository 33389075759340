import React, { Children, cloneElement, memo, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";

import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import { getTreeNodes } from "../raTreeCore/selectors";

const TreeNodeListView = ({
  basePath,
  children,
  classes,
  data,
  hasCreate,
  hasEdit,
  hasList,
  hasShow,
  loading,
  nodes,
  parentSource,
  positionSource,
  resource,
  crudGetTreeRootNodes,
  ...props
}) => (
  <List
    classes={{
      root: classes.root,
    }}
    dense
    disablePadding
    {...props}
  >
    {data.map((record) =>
      // Necessary because of a possible race condition leading to a null record
      // when user expand/close multiple times very quickly
      record
        ? cloneElement(Children.only(children), {
            basePath,
            hasCreate,
            hasEdit,
            hasList,
            hasShow,
            key: record.id,
            nodeChildren: children,
            nodes,
            parentSource,
            positionSource,
            record,
            resource,
          })
        : null
    )}
  </List>
);

export const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
  },
};

const mapStateToProps = (state, { resource, nodes }) => ({
  data: getTreeNodes(state, resource, nodes),
});

const TreeNodeList = compose(
  withStyles(styles),
  connect(mapStateToProps, {})
)(TreeNodeListView);

export default memo(TreeNodeList);
