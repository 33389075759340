

import * as React from 'react';
import { forwardRef } from 'react';
import { useLogout, useTranslate } from 'react-admin';
import MenuItem from '@material-ui/core/MenuItem';
import ExitIcon from '@material-ui/icons/PowerSettingsNew';
import ProductService from '../services/ProductService';

const MyLogoutButton = forwardRef((props, ref) => {
  const logout = useLogout();
  const translate = useTranslate();

  const handleClick = () => {
    let result = true;

    if (ProductService.isBlocking()) {
      result = confirm(translate("confirm_messages.products.back"));
    }

    if (result) {
      logout();
    }
  }
  return (
    <MenuItem
      onClick={handleClick}
      ref={ref}
    >
      <ExitIcon /> Logout
    </MenuItem>
  );
});

export default MyLogoutButton;