import React from "react";
import {
  useNotify,
  useTranslate,
  ListContextProvider,
  TextField,
  // DateField,
  DeleteButton,
  TopToolbar,
} from "react-admin";
import { makeStyles, IconButton, Button, Typography } from "@material-ui/core";
import keyBy from "lodash/keyBy";
import classnames from "classnames";
import DatagridResponsive from "../../components/DataGridResponsive";
import EditIcon from "@material-ui/icons/Edit";
import { wrapArrayToMap } from "../../utils/Wrapper";

const useStyles = makeStyles((theme) => ({
  tolbar_root: {
    justifyContent: "space-between",
    "& > *": {
      margin: theme.spacing(1.5),
    },
  },
  editButton: {
    height: "28px",
    width: "28px",
    "&:hover": {
      borderRadius: "3px",
      backgroundColor: "rgba(63, 81, 181, 0.04)",
    },
  },
  deleteButton: {
    height: "28px",
    width: "28px",
    "&:hover": {
      borderRadius: "3px",
      backgroundColor: "rgba(181, 63, 63, 0.04)",
    },
  },
}));

const ListActions = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter, // you can hide ExportButton if exporter = (null || false)
  filterValues,
  permanentFilter,
  hasCreate, // you can hide CreateButton if hasCreate = false
  basePath,
  showFilter,
  maxResults,
  total,
  openAddPriceDialog,
  productSupplierId,
  ...rest
}) => {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <TopToolbar className={classnames(className, classes.tolbar_root)}>
      <Typography variant="h6" gutterBottom margin="dense">
        {translate(
          "konebone.products.price_lines_list.price_details"
        ).toUpperCase()}
      </Typography>
      <Button
        label={translate("konebone.products.price_lines_list.add_price")}
        variant="contained"
        color="secondary"
        onClick={(e) => openAddPriceDialog()}
        disabled={!productSupplierId || total > 0}
      >
        {translate("konebone.products.price_lines_list.add_price")}
      </Button>
    </TopToolbar>
  );
};

const PriceEditButton = ({ record, openAddPriceDialog }) => {
  const classes = useStyles();

  return (
    <div className="listButton">
      <IconButton
        record={record}
        color="primary"
        onClick={(e) => openAddPriceDialog(record)}
        size="small"
        className={classes.editButton}
      >
        <EditIcon />
      </IconButton>
    </div>
  );
};

const PriceDeleteButton = ({ record, closeAddPriceDialog }) => {
  const notify = useNotify();

  const handleFailure = (error) => {
    if (!error.response) {
      console.error(error);
      return;
    }
    let { status } = error.response;
    if (status === 401 || status === 403) {
      cleanSession();
      window.location.href = "/login";
    }
    if (status === 500) {
      notify(
        error.response.data?.error,
        "warning"
      )
      return;
    }
    notify(
      error.response.data?.message?.message,
      "warning",
      wrapArrayToMap(error.response.data?.message?.parameters)
    );
  };

  const onSuccess = () => {
    notify("ra.notification.deleted", "success");
    closeAddPriceDialog(true);
  };

  return (
    <div className="listButton">
      <DeleteButton
        record={record}
        mutationMode="pessimistic"
        resource="prices"
        onFailure={handleFailure}
        label={""}
        onSuccess={onSuccess}
      />
    </div>
  );
};

const PriceList = ({
  prices,
  openAddPriceDialog,
  closeAddPriceDialog,
  productSupplierId,
}) => {
  const translate = useTranslate();

  return (
    <div style={{ display: "block", width: "100%", maxWidth: "100%" }}>
      {prices && (
        <ListContextProvider
          value={{
            // data: keyBy(data, "id"),
            // ids: data.map(({ id }) => id),
            total: prices.length,
            currentSort: { field: "id", order: "ASC" },
            resource: "price_lines",
            selectedIds: [],
          }}
        >
          <ListActions
            openAddPriceDialog={openAddPriceDialog}
            productSupplierId={productSupplierId}
            total={prices.length}
          />
          <DatagridResponsive
            data={keyBy(prices, "id")}
            ids={prices.map(({ id }) => id)}
          >
            <TextField
              source="supplierPrice"
              label={translate(
                "konebone.products.price_lines_list.supplier_price"
              )}
              sortable={false}
            />
            <TextField
              source="kbPrice"
              label={translate("konebone.products.price_lines_list.kb_price")}
              sortable={false}
            />
            {/* <DateField
              source="validFromDate"
              label={translate(
                "konebone.products.price_lines_list.valid_from"
              )}
              sortable={false}
            />
            <DateField
              source="validToDate"
              label={translate(
                "konebone.products.price_lines_list.valid_to"
              )}
              sortable={false}
            /> */}
            <TextField
              source="minOrderQuantity"
              label={translate(
                "konebone.products.price_lines_list.min_order_quantity"
              )}
              sortable={false}
            />
            <PriceEditButton openAddPriceDialog={openAddPriceDialog} />
            <PriceDeleteButton closeAddPriceDialog={closeAddPriceDialog} />
          </DatagridResponsive>
        </ListContextProvider>
      )}
    </div>
  );
};

export default PriceList;
