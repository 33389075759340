import { useRef, useState } from 'react';
import {
  Show,
  TabbedShowLayout,
  useAuthState,
  Tab,
  useTranslate,
  TabbedShowLayoutTabs,
  useRefresh,
  useNotify,
} from "react-admin";
import {
  Button,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Typography,
  Box,
} from "@material-ui/core";
import OrderInfo from "./OrderInfo";
import OrderActions from "./OrderActions";
import dataProviderForOrders from '../../providers/DataProviderForOrders';
import OrderOffersList from './OrderOffersList';
import Validator from "../../utils/Validator";
import SendToSuppliersDialog from './SendToSuppliersDialog';
import SendToCustomersDialog from './SendToCustomersDialog';
import { STATUS } from './enums';
import { ReactComponent as InfoIcon } from "../../svg/info-ico.svg";
import { OfferGroupDialog } from './offers/OfferGroupDialog';
import PackingSlips from './PackingSlips';
import { handleFailure } from '../../utils/handleFailure';

const MAX_COMPLETED_IDS = 3;

const useStyles = makeStyles((theme) => ({
  inputField: {
    border: "1px solid #28253D",
    borderRadius: "6px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "12px 12px 12px 15px",
    wordBreak: "break-all",
    gap: '0px 10px',
    background: "#FCE9EC"
  },
  icon: {
    "& > path": {
      fill: 'red'
    },
  },
  tabs: {
    "& > div": {
      padding: "0",
    },
    "& > .sticky-tab": {
      position: "sticky",
      backgroundColor: "white",
      zIndex: "2",
      top: "110px"
    },
  },
  root: {
    "& * > button": {
      textTransform: "capitalize",
    }
  },
}));

const useShowStyles = makeStyles((theme) => ({
  card: {
    overflow: "initial",
  }
}));

const CancelOrderDialog = ({ id, closeCancelDialog, open, status, mounted }) => {
  const [reason, setReason] = useState("");
  const [error, setError] = useState(false);
  const [reasonError, setReasonError] = useState("");
  const notify = useNotify();
  const classes = useStyles();
  const translate = useTranslate();

  const handleClose = (e) => {
    e.stopPropagation();
    closeCancelDialog();
  };

  const cancel = async () => {
    if (!reason) {
      setError(true);
      return;
    }

    let isError = false;

    if (Validator.validateReasonBlock(reason)) {
      setReasonError(Validator.validateReasonBlock(reason));
      isError = true;
    }

    if (isError) {
      return;
    }

    try {
      await dataProviderForOrders.cancel("orders", {
        id: id,
        reason: reason
      });
      mounted.current = false
      closeCancelDialog();
    } catch (error) {
      handleFailure(error, notify)
    }
  };

  const checkRequired = (e) => {
    if (!e.target.value) {
      setError(true);
    }
  }

  const changeReason = (e) => {
    const { value } = e.target
    if (value) {
      setError(false);
    }
    setReasonError("");
    setReason(value);
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" className={classes.capitalize}>
        {translate("konebone.orders.cancel_order")}
      </DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1" gutterBottom>{translate(
          "konebone.orders.indicate_reason"
        )}</Typography>
        <TextField
          error={error || !!reasonError}
          id="name"
          label=""
          placeholder={`${translate(
            "konebone.orders.type_reason_placeholder"
          )} *`}
          fullWidth
          variant="outlined"
          value={reason}
          onChange={changeReason}
          onBlur={(e) => checkRequired(e)}
          helperText={error ? translate("ra.validation.required") : translate(reasonError, { max: "500" })}
          multiline
          rows="4"
          required
        />
        {status === STATUS.AWAITING_DELIVERY || status === STATUS.DELIVERED ? 
          <Box width="100%" mb="2em" className={classes.inputField}>
            <InfoIcon className={classes.icon}/>
            <Typography>{translate("konebone.orders.paid_order_cancel_message_start")}
            <br/>{translate("konebone.orders.paid_order_cancel_message_end")}
            </Typography>
          </Box>
        : <div></div>
        }
      </DialogContent>
      <DialogActions className={classes.root}>
        <Button onClick={handleClose} variant="outlined">
          {translate("ra.action.cancel")}
        </Button>
        <Button onClick={cancel} variant="contained" color="secondary">
          {translate("konebone.orders.cancel_order")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ShowOrderTitle = ({ record }) => {
  const translate = useTranslate();

  return <span>{translate("konebone.orders.order")} #{record.id}</span>;
};

const OrdersShow = (props) => {
  const classes = useStyles();
  const showClasses = useShowStyles();
  const translate = useTranslate();
  const refresh = useRefresh();
  const { loading, authenticated } = useAuthState();

  const [status, setStatus] = useState('');
  const [groupDialogOpened, setGroupDialogOpened] = useState(false);
  const [groupOffer, setGroupOffer] = useState(false);
  const [groupedOfferId, setGroupedOfferId] = useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState({});
  const [cancelDialogOpened, setCancelDialogOpened] = useState(false);
  const [sendOffersToSuppliersDialogOpened, setSendOffersToSuppliersDialogOpened] = useState(false);
  const [sendOffersToCustomerDialogOpened, setSendOffersToCustomerDialogOpened] = useState(false);
  const [sendButtonsState, setSendButtonsState] = useState({
    suppliers: {
      show: false,
      active: false
    },
    customer: {
      show: false,
      active: false
    }
  });
  const [packingSlipButton, setPackingSlipButton] = useState(true);
  const [checkGroupInclude, setCheckGroupInclude] = useState([]);
  const [paidData, setPaidData] = useState('');
  
  const disabledGroupButton = useRef(false)
  const disableElse = useRef(false);
  const disableCompleted = useRef(false);
  const singleOffers = useRef([])
  const mounted = useRef(true)
  const checkedOffers = useRef(0)

  const openGroupDialog = (e) => {
    e.stopPropagation()
    setGroupDialogOpened(true);

  };

  const openCancelDialog = (e) => {
    setCancelDialogOpened(true);
    singleOffers.current = []
    const buttons = { ...sendButtonsState };
    buttons.customer.active = false;
    setSendButtonsState(buttons);
    checkedOffers.current = 0;
    setSelectedSuppliers({}); 
    setStatus(e)
  };

  const openSendOffersToSuppliersDialog = () => {
    setSendOffersToSuppliersDialogOpened(true);
  };

  const openSendOffersToCustomerDialog = () => {
    setSendOffersToCustomerDialogOpened(true);
  };

  const closeCancelDialog = () => {
    setCancelDialogOpened(false);
    refresh();
  };

  const closeGroupDialog = (e) => {
    setGroupDialogOpened(false);
    singleOffers.current = []
    const buttons = { ...sendButtonsState };
    buttons.customer.active = false;
    setSendButtonsState(buttons);
    checkedOffers.current = 0;
    setSelectedSuppliers({});    // setGroupedOfferId(e)
    refresh();
  };

  const closeSendOffersToSuppliersDialog = () => {
    setSendOffersToSuppliersDialogOpened(false);
    const buttons = { ...sendButtonsState };
    buttons.suppliers.active = false;
    setSendButtonsState(buttons);
    setSelectedSuppliers({});
    refresh();
  }

  const closeSendOffersToCustomerDialog = () => {
    setSendOffersToCustomerDialogOpened(false);
    const buttons = { ...sendButtonsState };
    buttons.customer.active = false;
    setSendButtonsState(buttons);
    checkedOffers.current = 0;
    setSelectedSuppliers({});
    refresh();
  }

 const manageCustomerButton = (buttons) => {
  if(checkedOffers.current > MAX_COMPLETED_IDS) {
    buttons.customer.active = false
  } else {
    buttons.customer.active = true
  }
 }

  const selectSupplier = (id, record, event) => {
    const newObj = { ...selectedSuppliers };
    const buttons = { ...sendButtonsState };
    
    if(record?.productAvailability >= 1) {

      if(checkGroupInclude.length > 1) {
        if(Array.isArray(id) && singleOffers.current.length < 1) {
          setGroupOffer(true);
          setGroupedOfferId(id);
          disabledGroupButton.current = false
        } else if (Array.isArray(id) && singleOffers.current.length >= 1){
          disabledGroupButton.current = true
        } else if (Array.isArray(id) && groupedOfferId.length < 1){
          singleOffers.current.push(+id)
          disabledGroupButton.current = true
        } else {
          singleOffers.current.push(+id)
          disabledGroupButton.current = true
        }
      } else {
        if(singleOffers.current.length >= 1){
          disabledGroupButton.current = true
        } else {
          singleOffers.current.push(+id)
        }
      }
    }

    if (newObj[id] && !Array.isArray(id)) {
      checkedOffers.current--
      manageCustomerButton(buttons)
      delete newObj[id];
      singleOffers.current = singleOffers?.current?.filter((filteredId)=> +filteredId !== +id);
      const newObjArr = Object.values(newObj);
      const includeComplete = newObjArr.filter(({ status }) => status === STATUS.COMPLETED).length;
      

      if(singleOffers.current.length < 1 && checkGroupInclude.length > 1){
        disabledGroupButton.current = false
      }


      if (!includeComplete && buttons.customer.active) {
        buttons.customer.active = false;
        if (newObjArr.length > 0) {
          buttons.suppliers.active = true;
        }
      }

      if (!newObjArr.length) {
        buttons.suppliers.active = false;
      }

      if (newObjArr.length < MAX_COMPLETED_IDS) {
        disableCompleted.current = false;
        disableElse.current = false;
      }

      setSendButtonsState(buttons);
      setSelectedSuppliers(newObj);
      return;
    } else if (Array.isArray(id) && !event.target.checked) {
      checkedOffers.current--
      manageCustomerButton(buttons)
      setGroupedOfferId([])
      disabledGroupButton.current = false

      id.forEach(({offerId})=> delete newObj[offerId])
      if(groupedOfferId.length < 1 ) {
        disableCompleted.current = false;
      }

      const newObjArr = Object.values(newObj)
      const includeComplete = newObjArr.filter(({ status }) => status === STATUS.COMPLETED).length;

      if (!includeComplete && buttons.customer.active) {
          buttons.customer.active = false
        if (newObjArr.length > 0) {
          buttons.suppliers.active = true;
        }
      }

      if (!newObjArr.length) {
        buttons.suppliers.active = false;
      }

      if (newObjArr.length < MAX_COMPLETED_IDS) {
        disableCompleted.current = false;
        disableElse.current = false;
      }

      setSendButtonsState(buttons);
      setSelectedSuppliers(newObj);
      return
    }

    if (sendButtonsState.customer.show) {
      checkedOffers.current++
      manageCustomerButton(buttons)
      const includeComplete = Object.values(newObj).filter(({ status }) => status === STATUS.COMPLETED).length

      if(Array.isArray(id)) {
        id?.forEach(({offerId, name, status, isPartOfGroup, id}) => newObj[offerId] = { name, status, isPartOfGroup, id });
        buttons.suppliers.active = false;

        setSendButtonsState(buttons);
        setSelectedSuppliers(newObj);
        return
      } else {
      if (record.status === STATUS.COMPLETED && !includeComplete) {
        if (Object.keys(newObj).length > MAX_COMPLETED_IDS) {
          return;
        }

        newObj[id] = { name: record.name, status: record.status, isPartOfGroup: record.isPartOfGroup, id: record.id };
        buttons.suppliers.active = false;


        if (Object.keys(newObj).length === MAX_COMPLETED_IDS) {
          disableElse.current = true;
        }
      
        setSendButtonsState(buttons);
        setSelectedSuppliers(newObj);
        return;
      }

      newObj[id] = { name: record.name, status: record.status, isPartOfGroup: record.isPartOfGroup, id: record.id };
      }
    }
    const includeComplete = Object.values(newObj).filter(({ status }) => status === STATUS.COMPLETED).length
    newObj[id] = { name: record.name, status: record.status,  isPartOfGroup: record.isPartOfGroup, id: record.id };

    if (!includeComplete) {
      buttons.suppliers.active = true;
      buttons.customer.active = false;

      if (Object.keys(newObj).length === MAX_COMPLETED_IDS) {
        disableCompleted.current = true;
      }
    } else {
      if (
        Object.keys(newObj).length === MAX_COMPLETED_IDS) {
        disableElse.current = true;
      }
    }
    setSendButtonsState(buttons);
    setSelectedSuppliers(newObj);
    return;
  };

  const onSelectAll = (ids, data) => {
    const buttons = { ...sendButtonsState };
    
    if (!ids.length) {
      singleOffers.current = [];
      checkedOffers.current = 0;
      setSelectedSuppliers({});
      buttons.suppliers.active = false;
      buttons.customer.active = false;
      setSendButtonsState(buttons);
      return;
    }

    const includeOtherStatus = Object.values(data).filter(
      (offer) => offer.status !== STATUS.COMPLETED && offer.selectable
    );

    const includeComplete = Object.values(data).filter(
      (offer) => offer.status === STATUS.COMPLETED && offer.selectable
    );

    if(includeComplete.length > 1 && !includeOtherStatus.length) {
      includeComplete.map(({offerId})=> {
        singleOffers.current.push(offerId)
      })
      disabledGroupButton.current = true
    };

    if (includeComplete.length && includeComplete.length < data.length) {
      return;
    }

    const newObj = {}

    ids.forEach((id) => {
      newObj[id] = { name: data[id].name, status: data[id].status, isPartOfGroup: data[id].isPartOfGroup, id: data[id].id };
    });

    setSelectedSuppliers(newObj);

    if (includeComplete.length) {
      buttons.customer.active = true;
      buttons.suppliers.active = false;
    } else {
      buttons.suppliers.active = true;
      buttons.customer.active = false;
    }
    setSendButtonsState(buttons);
  }

  const changeSendButtonsState = (newValue) => {
    setSendButtonsState(newValue);
  }

  const getPaidData = (e) => {
    setPaidData(e)
  }

  const getGroupPartOffers = (e) => {
   setCheckGroupInclude(e)
  }

  if (!authenticated || loading) {
    return null;
  }

  return (
    <>
      <Show
        title={<ShowOrderTitle />}
        actions={<OrderActions
          setAnchorEl
          selectedSuppliers={selectedSuppliers}
          openSendToSupplierDialog={openSendOffersToSuppliersDialog}
          openSendToCustomerDialog={openSendOffersToCustomerDialog}
          sendButtonsState={sendButtonsState}
          openCancelDialog={openCancelDialog}
          openGroupDialog={openGroupDialog}
          disabledGroupButton={disabledGroupButton}
          paidData={paidData}
        />}
        {...props}
        classes={showClasses}
      >
        <TabbedShowLayout className={classes.tabs} tabs={<TabbedShowLayoutTabs className="sticky-tab" indicatorColor="secondary" textColor="secondary" />}>
          <Tab label={translate("konebone.orders.info")}>
            <OrderInfo getPaidData={getPaidData} mounted={mounted} setPackingSlipButton={setPackingSlipButton}/>
          </Tab>
          <Tab label={translate("konebone.orders.suppliers")} path="suppliers" onClick={() => refresh()}>
            <OrderOffersList
              id={props.id}
              selectSupplier={selectSupplier}
              selectedSuppliers={Object.keys(selectedSuppliers)}
              onSelectAll={onSelectAll}
              sendButtonsState={sendButtonsState}
              changeSendButtonsState={changeSendButtonsState}
              disableElse={disableElse.current}
              disableCompleted={disableCompleted.current}
              groupOffer={groupOffer}
              setPackingSlipButton={setPackingSlipButton}
              getGroupPartOffers={getGroupPartOffers}
              disabledGroupButton={disabledGroupButton}
              checkedOffers={checkedOffers}
              setSelectedSuppliers={setSelectedSuppliers}
            />
          </Tab>
          <Tab
            label={translate("konebone.orders.packing_slips")}
            path="packing-slips"
            disabled={packingSlipButton}
          >
             <PackingSlips
              id={props.id}
              setPackingSlipButton={setPackingSlipButton}
            />
          </Tab>
        </TabbedShowLayout>
      </Show>
      {cancelDialogOpened && <CancelOrderDialog mounted={mounted} status={status} open={cancelDialogOpened} id={props.id} closeCancelDialog={closeCancelDialog} />}
      {groupDialogOpened && <OfferGroupDialog disabledGroupButton={disabledGroupButton} openGroupDialog={openGroupDialog} open={groupDialogOpened} suppliers={selectedSuppliers} id={props.id} closeGroupDialog={closeGroupDialog} />}
      {sendOffersToSuppliersDialogOpened && <SendToSuppliersDialog open={sendOffersToSuppliersDialogOpened} id={props.id} suppliers={selectedSuppliers} closeSendOffersDialog={closeSendOffersToSuppliersDialog} />}
      {sendOffersToCustomerDialogOpened && <SendToCustomersDialog  groupedOfferId={groupedOfferId} open={sendOffersToCustomerDialogOpened} id={props.id} suppliers={selectedSuppliers} closeSendOffersDialog={closeSendOffersToCustomerDialog} />}
    </>
  );
};

export { OrdersShow };
