import { STATUS } from "../pages/orders/enums";
import { getDateByFormatShort, parseData } from "./DateFormat";
export default {
  wrapAdmin: (item) => {
    return {
      ...item,
      id: item.adminId,
      createdAtUnix: parseData(item.createdAtUnix),
      editedAtUnix: parseData(item.editedAtUnix),
      rolesString: wrapArrayToString(item.roles),
    };
  },

  wrapProduct: ({
    maxQuantityOnPallet,
    minQuantityOnPallet,
    maxKbPrice,
    minKbPrice,
    productNumber,
    category,
    manufacturer,
    productId,
    packageId,
    konebonePn,
    manufacturingCountry,
    catalogNumber,
    ...items
  }) => {
    return {
      ...items,
      // unitsInPackage: `${minUnitsInPackage || 0}...${maxUnitsInPackage || 0}`,
      quantityOnPallet: `${minQuantityOnPallet || 0}...${maxQuantityOnPallet || 0
        }`,
      kbPrice: `${minKbPrice || 0}...${maxKbPrice || 0}`,
      id: productId,
      categoryId: category?.categoryId,
      manufacturerId: manufacturer?.manufacturerId,
      manufacturingCountryId: manufacturingCountry?.manufacturingCountryId,
      catalogNumber: catalogNumber,
      productNumber: productNumber || konebonePn,
    };
  },

  wrapProductForCatalog: ({
    maxQuantityOnPallet,
    minQuantityOnPallet,
    maxKbPrice,
    minKbPrice,
    productNumber,
    category,
    manufacturer,
    productId,
    packageId,
    konebonePn,
    manufacturingCountry,
    catalogNumber,
    ...items
  }) => {
    return {
      ...items,
      // unitsInPackage: `${minUnitsInPackage || 0}...${maxUnitsInPackage || 0}`,
      quantityOnPallet: `${minQuantityOnPallet || 0}...${maxQuantityOnPallet || 0
        }`,
      kbPrice: `${minKbPrice || 0}...${maxKbPrice || 0}`,
      id: catalogNumber,
      catalogNumber: catalogNumber,
      productNumber: productNumber || konebonePn,
    };
  },

  wrapCategory: ({
    categoryId,
    name,
    pageText,
    metaDescription,
    metaTitle,
    image,
    parentCategoryId,
    position,
    productsCount,
  }) => {
    return {
      id: categoryId,
      name: name,
      pageText: pageText,
      metaDescription: metaDescription,
      metaTitle: metaTitle,
      image: { src: image },
      parent_id: parentCategoryId,
      position: position || 0,
      productsCount: productsCount
    };
  },

  wrapManufacturer: (item) => {
    return {
      ...item,
      id: item.manufacturerId,
    };
  },

  wrapManufacturingCountries: (item) => {
    return {
      ...item,
      id: item.manufacturingCountryId,
    };
  },

  wrapSupplier: ({ supplierId, id, foundation, ...item }) => {
    let newId = supplierId || id;

    // if (item.entityType === "SUPPLIER_CANDIDATE") {
    //   newId = newId + "c";
    // }

    return {
      ...item,
      id: newId,
      mainContact: `${item.firstName || ""} ${item.lastName || ""}`,
      address: `${item.street ? `${item.street}, ` : ""}${item.unit ? `${item.unit}, ` : ""}${item.city || ""}`,
      statusLower: item.status?.toLowerCase(),
      foundation: foundation ? getDateByFormatShort(foundation) : null,
    };
  },

  wrapMeasurement: (item) => {
    return {
      ...item,
      id: item.measurementId,
    };
  },

  wrapPackage: (item) => {
    return {
      ...item,
      id: item.packageId,
    };
  },

  wrapPackageType: (item) => {
    return {
      ...item,
      id: item.packageTypeId,
    };
  },

  wrapCustomer: ({ id, customerId, ...item }) => {
    return {
      ...item,
      id: id || customerId,
      mainContact: `${item.firstName || ""} ${item.lastName || ""}`,
      address: `${item.street ? `${item.street}, ` : ""}${item.unit ? `${item.unit}, ` : ""}${item.city || ""}`,
      statusLower: item.status?.toLowerCase(),
    };
  },

  wrapHistory: ({ id, adminId, endpoint, payload, managerId, time, ...item }) => {
    const date = new Date(time);
    const getYear = date?.getFullYear();
    const month = `0${date?.getMonth()+1}`.slice(-2);
    const day = date?.getDate() < 10 ? `0${date?.getDate()}` : date?.getDate();
    const minutes = date?.getMinutes() < 10 ? `0${date?.getMinutes()}` : date?.getMinutes();
    const hour = date?.getHours() < 10 ? `0${date?.getHours()}` : date?.getHours();
    const fullDate = date ? `${getYear}-${month}-${day}`.toString() : '';
    const fullTime = date ? `${hour}:${minutes}:00` : '';

    return {
      ...item,
      adminId,
      userId: managerId,
      id,
      requestBody: payload ? typeof payload === 'string' ? payload : JSON.parse(payload)  : '-',
      endpoint: endpoint ? JSON.stringify(endpoint) : "-",
      dateTime: `${fullDate} at ${fullTime}`,
    };
  },

  wrapPrice: (item) => {
    return {
      ...item,
      id: item.productPriceId,
    };
  },

  wrapPriceLines: ({
    kbPrice,
    supplierPrice,
    productSupplierId,
    supplier,
    ...items
  }) => {
    return {
      ...items,
      id: productSupplierId,
      supplierName: supplier.name,
      supplierId: supplier.supplierId,
      kbPrice: kbPrice || "-",
      supplierPrice: supplierPrice || "-",
    };
  },

  wrapManager: ({ managerId, firstName, lastName, status, type, ...item }) => {
    return {
      ...item,
      id: managerId,
      fullName: firstName + " " + lastName,
      active: status.replaceAll('_', ' ').toLowerCase(),
      firstName: firstName,
      lastName: lastName,
      main: type === "PRIMARY_MANAGER",
      status: status,
    };
  },

  wrapProject: ({ project, ...item }) => {
    return {
      ...item,
      id: project,
      address: `${item.street ? `${item.street}, ` : ""}${item.unit ? `${item.unit}, ` : ""}${item.city || ""}`,
    };
  },

  wrapOffer: ({selectable, isPartOfGroup, kbPrice, supplierPrice, productAvailability, productCount, supplier, delivery, deliveryTime, project, productInStock, productOutStock, orderDeliveryTime, ...item }) => {

    return {
      ...item,
      statusLower: item.status?.toLowerCase().replace("_", " "),
      kbPrice: kbPrice ? `₪${kbPrice}` : "-",
      supplierPrice: supplierPrice ? `₪${supplierPrice}` : "-",
      deliveryOffer: delivery,
      deliveryTime: parseData(deliveryTime),
      deliveryTimeOther: delivery?.deliveryTime ? parseData(delivery?.deliveryTime) : "",
      deliveryCost: delivery?.deliveryCost ? delivery?.deliveryCost : "",
      deliveryAddress: delivery?.pickupStreet && delivery?.pickupCity ? `${delivery?.pickupStreet} ${delivery?.pickupApt ? `st. ${delivery?.pickupApt}` : ""}, ${delivery?.pickupCity}` : "",
      deliveryContact: delivery?.contactName && delivery?.contactPhone ? `${delivery?.contactName}, ${delivery?.contactPhone}` : "",
      deliveryVehicleWeight: delivery?.appropriateVehicleWeight,
      delivery: !delivery || item.status === "DEFAULT" || item.status === "IN_PROGRESS" || item.status === "CANCELED" ? "-" : delivery,
      rating: `${productAvailability || 0} of ${productCount || 0}`,
      projectInfo: project?.name && project?.street && project?.city ? `${project?.name}, ${project?.street}  ${project?.apt ? `st. ${project?.apt}` : ""}, ${project?.city}` : "-",
      projectContact: `${project?.contactFullName}, ${project?.contactPhone}`,
      supplierInfo: supplier?.street && supplier?.unit && supplier?.city ? `${supplier?.street} ${supplier?.unit ? `st. ${supplier.unit}` : ""}, ${supplier?.city}` : "-",
      supplierContact: `${supplier?.firstName} ${supplier?.lastName}, ${supplier?.phone}`,
      supplier: supplier,
      selectable,
      productCount,
      isPartOfGroup,
      productInStock,
      productOutStock,
      productAvailability,
      ratingOfferProduct: productInStock?.length && productInStock?.length ? `${productInStock.length} of ${productInStock.length + productOutStock.length}` : "",
      orderDeliveryTime: orderDeliveryTime,
    };
  },

  wrapOrder: ({
    orderId,
    id,
    created,
    deliveryTime,
    creationDate,
    minPrice,
    maxPrice,
    reviewTime,
    reviewStartTime,
    urgentStatus,
    minTotalKbPrice,
    maxTotalKbPrice,
    project,
    totalKbPrice,
    ...item }) => {
    let leftTime = "";
    const minOrderPrice = minPrice || minTotalKbPrice;
    const maxOrderPrice = maxPrice || maxTotalKbPrice;

    if (reviewTime) {
      // let tmpTime = reviewTime * 1000 - (new Date().valueOf() - new Date(reviewStartTime).valueOf());
      // if (tmpTime < 0) {
      //   tmpTime = reviewTime * 1000;
      // }
      let tmp = Math.abs(reviewTime);
      const resHour = Math.floor(tmp / (60 * 60));
      tmp -= resHour * 60 * 60;
      const resMinute = Math.floor(tmp / 60);
      let resSecond = Math.floor(tmp) % 60;
      leftTime = `${`0${resHour}`.slice(-2)}:${`0${resMinute}`.slice(-2)}:${`0${resSecond}`.slice(-2)}`
    }

    return {
      ...item,
      id: orderId || id,
      created: parseData(created || creationDate),
      deliveryTime: parseData(deliveryTime),
      project: project?.name && project?.street && project?.city && project?.contactFullName && project?.contactPhone ? [project] : "-",
      fullProjectInfo: project?.name && project?.street && project?.city && project?.apt ? `${project?.name}, ${project?.street} ${project?.apt ? `‘רח ${project.apt}` : ""}, ${project?.city}` : "-",
      projectInfo: project?.name && project?.street && project?.city ? `${project?.name}, ${project?.street}, ${project?.city}` : "-",
      projectContact: `${project?.contactFullName}, ${project?.contactPhone}`,
      statusLower: item.status?.toLowerCase().replaceAll("_", " "),
      minTotalKbPrice: `₪${minTotalKbPrice}`,
      maxTotalKbPrice: `₪${maxTotalKbPrice}`,
      orderPrice: minOrderPrice !== maxOrderPrice ? `₪${minOrderPrice} - ₪${maxOrderPrice}` : minOrderPrice ? `₪${minOrderPrice}` : (!minOrderPrice && !minOrderPrice) && totalKbPrice ?  `₪${totalKbPrice}` : "-",
      chooseSuppliers: item.status === "NEW"
        ? (leftTime ? leftTime : "grey") : item.status === 'CANCELED' ? "red" : "green",
      inReviewSuppliers: (item.status === "IN_PROGRESS" || item.status === "NEW")
        ? (leftTime && item.status === "IN_PROGRESS" ? leftTime : "grey") : item.status === 'CANCELED' ? "grey" : "green",
      inReviewCustomer: (item.status === "IN_REVIEW" || item.status === "IN_PROGRESS" || item.status === "NEW")
        ? (leftTime && item.status === "IN_REVIEW" ? leftTime : "grey") : item.status === 'DECLINED' ? "red" : item.status === 'CANCELED' ? "grey" : "green",
      urgentStatus: Boolean(urgentStatus),
    };
  },

  wrapPaid: ({ 
    kbPrice, 
    supplierPrice, 
    dueDate, 
    isCreditPaymentUsed,
    offers,
    productInStock, 
    productOutStock,
    productAlternative,
    ...item }) => {
    
    const paidOfferInfo = offers.map(({delivery, project, products, supplier, supplierPrice, offerId})=> {
      return {
        offerId: offerId,
        supplierPrice: supplierPrice ? `₪${supplierPrice}` : "-",
        deliveryOffer: delivery,
        products: products,
        deliveryTime: delivery?.deliveryTime ? parseData(delivery?.deliveryTime) : "",
        deliveryAddress: delivery?.pickupStreet && delivery?.pickupCity ? `${delivery?.pickupStreet} st. ${delivery?.pickupApt}, ${delivery?.pickupCity}` : "",
        deliveryContact: delivery?.contactName && delivery?.contactPhone ? `${delivery?.contactName}, ${delivery?.contactPhone}` : "",
        deliveryVehicleWeight: delivery?.appropriateVehicleWeight,
        projectInfo: project?.name && project?.street && project?.apt && project?.city ? `${project?.name}, ${project?.street} ‘רח ${project?.apt}, ${project?.city}` : "-",
        projectContact: `${project?.contactFullName}, ${project?.contactPhone}`,
        supplierInfo: supplier?.street && supplier?.unit && supplier?.city ? `${supplier?.street} st. ${supplier?.unit}, ${supplier?.city}` : "-",
        supplierContact: `${supplier?.firstName} ${supplier?.lastName}, ${supplier?.phone}`,
        supplier: supplier,
      };
    });

    return {
      paidOfferInfo,
      ...item,
      supplierPrice,
      isCreditPaymentUsed,
      dueDate,
      // deliveryOffer,
      // products,
      // deliveryTime,
      // deliveryAddress,
      // deliveryContact,
      // deliveryVehicleWeight,
      // projectInfo,
      // projectContact,
      // supplierInfo,
      // supplierContact,
      // supplier,
      statusLower: item.status?.toLowerCase().replace("_", " "),
      kbPrice: kbPrice ? `₪${kbPrice}` : "-",
      productInStock,
      productOutStock,
      productAlternative,
      ratingOfferProduct: productInStock?.length && productInStock?.length ? `${productInStock.length} of ${productInStock.length + productOutStock.length}` : "",
      orderDeliveryTime: dueDate,
    }
  },

};

export function wrapArrayToMap(argsArray) {
  let argsMap = {};
  if (!argsArray) {
    return argsMap;
  }
  let count = 0;
  for (let i = 0; i < argsArray.length; i++) {
    if (typeof argsArray[i] === "object") {
      let objValues = Object.values(argsArray[i]);
      for (let j = 0; j < objValues.length; j++) {
        argsMap["arg_" + count] = objValues[j];
        count++;
      }
    } else {
      argsMap["arg_" + count] = argsArray[i];
      count++;
    }
  }
  return argsMap;
}

function wrapArrayToString(array) {
  let result = "";
  if (!array) {
    return result;
  }
  for (let i = 0; i < array.length; i++) {
    result += array[i];
    if (i < array.length - 1) {
      result += ", ";
    }
  }
  return result;
}
