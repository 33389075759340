import * as React from "react";
import {
  List,
  TextField,
  CreateButton,
  TopToolbar,
  sanitizeListRestProps,
  ExportButton,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  EditButton,
  DeleteButton,
  useNotify,
} from "react-admin";
import { wrapArrayToMap } from "../utils/Wrapper";
import { cloneElement } from "react";
import { useAuthState, useRefresh, useTranslate } from "ra-core";
import Validator from "../utils/Validator";
import DatagridResponsive from "../components/DataGridResponsive";

const ListActions = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter, // you can hide ExportButton if exporter = (null || false)
  filterValues,
  permanentFilter,
  hasCreate, // you can hide CreateButton if hasCreate = false
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  ...rest
}) => (
  <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
    {filters &&
      cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button",
      })}
    <CreateButton basePath="/package_type" />
    {exporter && (
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={{ ...filterValues, ...permanentFilter }}
        exporter={exporter}
        maxResults={maxResults}
      />
    )}
  </TopToolbar>
);

ListActions.defaultProps = {
  selectedIds: [],
  onUnselectItems: () => null,
};

export const PackageTypeCreate = (props) => {
  const translate = useTranslate();
  const notify = useNotify();
  const { loading, authenticated } = useAuthState();

  const handleFailure = (error) => {
    notify(
      error.response?.data.message.message,
      "warning",
      wrapArrayToMap(error.response.data.message.parameters)
    );
  };

  if (!authenticated || loading) {
    return null;
  }

  return (
    <Create {...props} onFailure={handleFailure}>
      <SimpleForm redirect="list" submitOnEnter={false}>
        <TextInput
          source="title"
          label={translate("konebone.package_type.name")}
          validate={Validator.validatePackageType}
          variant="outlined"
        />
      </SimpleForm>
    </Create>
  );
};

export const PackageTypeEdit = (props) => {
  const translate = useTranslate();
  const notify = useNotify();
  const { loading, authenticated } = useAuthState();

  const handleFailure = (error) => {
    console.log(JSON.stringify(error.response));
    notify(
      error.response?.data.message.message,
      "warning",
      wrapArrayToMap(error.response.data.message.parameters)
    );
  };

  if (!authenticated || loading) {
    return null;
  }

  return (
    <Edit {...props} mutationMode="pessimistic" onFailure={handleFailure}>
      <SimpleForm redirect="list" submitOnEnter={false}>
        <TextInput
          source="title"
          label={translate("konebone.package_type.name")}
          validate={Validator.validatePackageType}
          variant="outlined"
        />
      </SimpleForm>
    </Edit>
  );
};

const PackageTypeDeleteButton = ({ record }) => {
  const notify = useNotify();
  const refresh = useRefresh();

  const onSuccess = () => {
    notify("ra.notification.deleted", "success");
    refresh();
  };

  const handleFailure = (error) => {
    notify(
      error.response?.data.message.message,
      "warning",
      wrapArrayToMap(error.response.data.message.parameters)
    );
  };

  return (
    <div className="listButton">
      <DeleteButton
        mutationMode="pessimistic"
        onFailure={handleFailure}
        label={""}
        record={record}
        onSuccess={onSuccess}
      />
    </div>
  );
};

const PackageTypeEditButton = ({ record }) => {
  return (
    <div className="listButton">
      <EditButton label={""} record={record} />
    </div>
  );
};

export const PackageTypeList = (props) => {
  const translate = useTranslate();
  const { loading, authenticated } = useAuthState();

  if (!authenticated || loading) {
    return null;
  }

  return (
    <List
      title={translate("konebone.package_type.title").toUpperCase()}
      {...props}
      actions={<ListActions />}
      bulkActionButtons={false}
      exporter={null}
      sort={{ field: "id", order: "DESC" }}
    >
      <DatagridResponsive>
        <TextField source="id"
          label={translate("konebone.package_type.id")}
          sortable={false} />
        <TextField
          source="title"
          label={translate("konebone.package_type.name")}
          sortable={false}
        />
        <PackageTypeEditButton />
        <PackageTypeDeleteButton />
      </DatagridResponsive>
    </List>
  );
};
