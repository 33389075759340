import Wrapper from "../utils/Wrapper";
import axios from 'axios';
import { stringify } from "query-string";

const dataProviderForOrders = {

  delete: async (resource, params) => {
    const url = process.env.REACT_APP_API_URL + "/order/" + params.id;
    const response = await axios.delete(url,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      });
    if (response.status === 200) {
      return { data: { id: params.id } }
    }
  },

  getTimeRange: async () => {
    const url = process.env.REACT_APP_API_URL + "/time-range/" + "order";
    const response = await axios.get(url,
        {
          withCredentials: true,
          headers: { 'accept': 'application/json' }
        });


    let data = response.data.data.map((item) => {
      if (!item.full) {
        return { label: `${item.start.slice(0, 5)}-${item.end.slice(0, 5)}`, id: item.id };
      } else {
        return { label: 'during the working day', id: item.id };
      }
    });

    return ({
      data: data,
    });
  },

  getList: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { q } = params.filter;
    const query = {
      page: page - 1,
      size: perPage,
      search: q ? q : "",
    };
    const filterStatus = params.filter?.status?.toUpperCase();
    let url = ""
    if (filterStatus && filterStatus !== "ALL") {
      url = process.env.REACT_APP_API_URL + `/order/byStatus/${filterStatus}?${stringify(query)}`;
    } else {
      url = process.env.REACT_APP_API_URL + '/order?' + stringify(query);
    }

    const response = await axios.get(url,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      });

    if (!response.data || !response.data.data) {
      return ({
        data: [],
        total: 0
      });
    }

    let resultData = response.data.data.content.map(order => Wrapper.wrapOrder(order));
    const responseTotalCount = response.data.data.totalElements;

    return ({
      data: resultData,
      total: responseTotalCount
    });
  },

  create: async (resource, params) => {
    let data = params.data;
    data.name = data.name.trim();
    let response = await axios.post(process.env.REACT_APP_API_URL + "/order", data,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      }
    );

    if (response && response.data) {
      let dataRes = response.data;
      dataRes.data.id = dataRes.data.manufacturerId;
      return ({
        data: dataRes.data
      });
    } else {
      return ({
        data: []
      });
    }
  },

  verified: async (resource, params) => {
    let response = await axios.post(process.env.REACT_APP_API_URL + "/order/verified/" + params.id, params.invoice,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      }
    );

    if (response && response.data) {
      return ({
        data: response.data
      });
    } else {
      return ({
        data: []
      });
    }
  },


  getOne: async (resource, params) => {
    const url = process.env.REACT_APP_API_URL + "/order/" + params.id;
    const response = await axios.get(url,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      });


    let resultData = Wrapper.wrapOrder(response.data.data);
    
    return ({
      data: resultData,
    });
  },

  update: async (resource, params) => {
    let data = params.data;

    let response = await axios.put(process.env.REACT_APP_API_URL + "/order/" + params.orderId, data,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      }
    );
    
    if (response && response.data) {
      let dataRes = response.data;
      dataRes.data.id = dataRes.data.manufacturerId;
      return ({
        data: dataRes.data
      });
    } else {
      return ({
        data: []
      });
    }
  },

  cancel: async (resource, { id, reason }) => {
    let response = await axios.put(process.env.REACT_APP_API_URL + `/order/${id}/cancel`, { cancelReason: reason },
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      }
    );

    if (!response || !response.data) {
      return { data: false };
    }

    return { data: true }
  },
  

};

export default dataProviderForOrders;
