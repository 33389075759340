import { DELETE, DELETE_MANY, Identifier } from 'ra-core';
import {
    CRUD_GET_TREE_ROOT_NODES_SUCCESS,
    CRUD_GET_TREE_CHILDREN_NODES_SUCCESS,
    MOVE_NODE,
    CRUD_MOVE_NODE_SUCCESS,
} from '../actions';
import { ROOT_NODE_ID } from '../constants';

/**
 * This reducer maintains a map of all nodes (using their id) and their children (only their id too).
 * Root nodes are persisted as the children of a special node which has the identifier ROOT_NODE_ID
 * @example
 * {
 *     ROOT_NODE_ID: [1, 2],
 *     1: [3, 4],
 *     2: [5, 6],
 * }
 */
const nodesReducer = (
    previousState = { [ROOT_NODE_ID]: [] },
    action
) => {
    if (action.meta && action.meta.optimistic) {

        if (action.meta.fetch === DELETE) {
            return removeNodes(previousState, [action.payload.id]);
        }

        if (action.meta.fetch === DELETE_MANY) {
            return removeNodes(previousState, action.payload.ids);
        }
    }

    switch (action.type) {
        case CRUD_GET_TREE_ROOT_NODES_SUCCESS: {
            const newState = {
                ...previousState,
                [ROOT_NODE_ID]: action.payload.data.map(({ id }) => id),
                // Initialize the children for all root nodes if necessary
                ...setupNodesChildren(previousState, action.payload.data),
            };

            return newState;
        }
        // case CRUD_MOVE_NODE_SUCCESS: {
        //     return moveNode(previousState, action);
        // }
        case CRUD_GET_TREE_CHILDREN_NODES_SUCCESS: {
            const newState = {
                ...previousState,
                [action.requestPayload.id]:
                    // The children value for this node may be false to indicate we fetched them but found none
                    action.payload.data.length > 0
                        ? action.payload.data.map(({ id }) => id)
                        : [],
                // Initialize the children for all the children nodes if necessary
                ...setupNodesChildren(previousState, action.payload.data),
            };

            return newState;
        }
        default:
            return previousState;
    }
};

export default nodesReducer;

const setupNodesChildren = (previousState, nodes) =>{
    if (!nodes.length) {
        return {};
    }

    const result = nodes.reduce(
        (acc, { id }) => ({
            ...acc,
            [id]:
                previousState[id] != undefined // eslint-disable-line eqeqeq
                    ? previousState[id]
                    : [],
        }),
        {}
    );

    return result;
};

const moveNode = (previousState, action) => {
    console.log("move node");
    // We need to remove this node from its previous parent as it may have changed
    const previousParentId =
        action.requestPayload.previousData[action.meta.parentSource] || ROOT_NODE_ID;

    const updatedPreviousParent = (previousState[
        previousParentId
    ]).filter(id => id !== action.requestPayload.data.id);

    const newState = {
        ...previousState,
        [previousParentId]: updatedPreviousParent,
    };

    // Then we need to update the new parent (which may be the same)
    const newParentId =
        action.requestPayload.data[action.meta.parentSource] || ROOT_NODE_ID;
    // The new parent may not have any child yet
    const currentParentChildren = newState[newParentId] || [];

    // We may have to update the node position
    if (action.meta.positionSource) {
        const childrenBeforeMovedNode = currentParentChildren.slice(
            0,
            action.requestPayload.data[action.meta.positionSource]
        );

        const childrenAfterMovedNode = currentParentChildren.slice(
            action.requestPayload.data[action.meta.positionSource]
        );

        newState[newParentId] = [
            ...childrenBeforeMovedNode,
            action.requestPayload.data.id,
            ...childrenAfterMovedNode,
        ];
        return newState;
    }

    // Otherwise, we just append the moved node to its new parent
    newState[newParentId] = [...currentParentChildren, action.requestPayload.data.id];

    return newState;
};

const removeNodes = (previousState, idsToRemove) =>
    idsToRemove.reduce(
        (newState, idToRemove) =>
            // Traverse all registered nodes and remove the node from their children
            Object.keys(newState).reduce((acc, id) => {
                // If this is a removed node or this node has no children (false indicates we fetched them but found none)
                if (id === idToRemove || newState[id] === false) {
                    return acc;
                }

                const children = newState[id];

                const index = children.findIndex(
                    nodeId => nodeId === idToRemove
                );

                if (index === -1) {
                    return {
                        ...acc,
                        [id]: children,
                    };
                }

                return {
                    ...acc,
                    [id]: [
                        ...children.slice(0, index),
                        ...children.slice(index + 1),
                    ],
                };
            }, {}),
        previousState
    );
