import React, { useEffect, useRef, useState } from "react";
import {
  Loading,
  useAuthState,
  useNotify,
  useVersion
} from "react-admin";
import { wrapArrayToMap } from "../../utils/Wrapper";
import {
  Paper,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import SupplierCompanyEdit from "./SupplierCompanyEdit";
import { cleanSession } from "../../authProvider";
import { ManagersList } from "../../components/ManagerTable";
import dataProviderForSupplierCandidate from "../../providers/DataProviderForSupplierCandidate";
import SupplierEditAction from "./SupplierActionComponent"

const EditCustomeTitle = ({ record }) => {
  return <span>{record?.name?.toUpperCase()}</span>;
};

const EditContent = ({ record, ...rest }) => {
  return (
    <>
      <Paper><SupplierCompanyEdit {...rest} record={record} /></Paper>
      <ManagersList entityId={record.id} entityType="SUPPLIER_CANDIDATE" entityStatus={record.status} canAddManager={record.status === "ACTIVE"} />
    </>
  );
};

const SupplierCandidateEdit = (props) => {
  const [record, setRecord] = useState({});
  const [load, setLoad] = useState(false);
  const { id } = useParams();
  const notify = useNotify();
  const version = useVersion();
  const { loading, authenticated } = useAuthState();

  const mounted = useRef(false);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      setLoad(true);
      dataProviderForSupplierCandidate.getOne("supplier_candidate", {id: id}) //isFinite(id) ? {id: id} : {id: id.slice(0, -1)}
        .then((item) => {
          if (mounted.current) {
            if (item?.data) {
              setRecord(item.data);
            }
            setLoad(false);
          }
        }).catch((error) => {
          setLoad(false);
          if (!error.response) {
            console.error(error);
            return;
          }
          let { status } = error.response;
          if (status === 401 || status === 403) {
            cleanSession();
            window.location.href = "/login";
          }
          if (status === 500) {
            notify(
              error.response.data?.error,
              "warning"
            )
          }
          notify(
            error.response.data?.message?.message,
            "warning",
            wrapArrayToMap(error.response.data?.message?.parameters)
          );
        });
    }
    return () => {
      mounted.current = false;
    };
  }, [version]);

  if (load) {
    return <Loading />
  }

  if (!authenticated || loading) {
    return null;
  }

  return (
    <div key={version}>
      <h1><EditCustomeTitle /></h1>
      <SupplierEditAction basePath="/supplier" resource="supplier" data={record} />
      <EditContent
        basePath="/supplier_candidate"
        resource="supplier_candidate"
        record={record}
        redirect="show"
      />
    </div>
  );
};

export { SupplierCandidateEdit };
