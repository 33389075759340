import { useTranslate } from "ra-core";
import React, { useEffect, useRef, useState, useContext } from "react";
import {
  ImageInput,
  // SimpleForm,
  TextInput,
  RadioButtonGroupInput,
  ImageField,
  useNotify,
  DataProviderContext,
} from "react-admin";
import {
  Typography,
  Box,
  makeStyles,
  IconButton,
  FormControl,
  Select,
  InputLabel,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormHelperText,
  MenuItem,
  Toolbar,
} from "@material-ui/core";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import {
  Prompt, useLocation,
} from "react-router-dom";
import TreeService from "../../services/TreeService";
import { cleanSession } from "../../authProvider";
import { wrapArrayToMap } from "../../utils/Wrapper";
import ProductService from "../../services/ProductService";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
  },
  plusButton: {
    height: "29px",
    width: "29px",
    marginTop: "12px",
    "&:hover": {
      borderRadius: "1px",
    },
  },
  blackButton: {
    backgroundColor: "#222",
    color: "#fff",
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  dialog: {
    "& label": {
      textTransform: "capitalize",
    },
  },
  capitalize: {
    textTransform: "capitalize",
  },
}));

const requredFields = ["name", "categoryId", "packageTypeId", "measurementId"];

const AddInfoDialog = ({ fields, open, title: dialogTitle, handleClose, initialValue }) => {
  const [description, setDescription] = useState("");
  const [name, setName] = useState(initialValue);
  const [title, setTitle] = useState(initialValue);
  const [requiredError, setRequiredError] = useState(false);
  const translate = useTranslate();
  const classes = useStyles();
  const checkRequired = (e) => {
    if (!e.target.value && e.target.required) {
      setRequiredError(true);
    }
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const renderFields = fields.map(({ source, label, required }, index) => {
    const value =
      source === "name" ? name : source === "title" ? title : description;
    const handleChange =
      source === "name"
        ? handleNameChange
        : source === "title"
          ? handleTitleChange
          : handleDescriptionChange;

    return (
      <TextField
        key={source}
        autoFocus={index === 0}
        margin="dense"
        error={requiredError && required}
        label={label}
        id={source}
        fullWidth
        value={value}
        variant="outlined"
        onChange={handleChange}
        onBlur={(e) => checkRequired(e)}
        helperText={required && requiredError && "Required"}
        required={required}
      />
    );
  });

  const handleSave = (e) => {
    const data = {};

    if (name) {
      data.name = name.trim();
    } else if (title) {
      data.title = title.trim();
    }

    if (description) {
      data.description = description;
    }

    const isWrong = fields.some(
      ({ required, source }) => !data[source] && required
    );

    if (isWrong) {
      setRequiredError(true);
      return;
    }

    handleClose(e, data);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" className={classes.capitalize}>
        {dialogTitle}
      </DialogTitle>
      <DialogContent className={classes.dialog}>{renderFields}</DialogContent>
      <DialogActions className={classes.root}>
        <Button onClick={handleClose} variant="outlined">
          {translate("ra.action.cancel")}
        </Button>
        <Button onClick={handleSave} variant="contained" color="secondary">
          {translate("ra.action.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const createOptionsWthTitle = (data) => {
  const result = [<option aria-label="None" value="" key={0} />];
  data.forEach(({ title, id }) => {
    result.push(
      <option key={id} value={id}>
        {title}
      </option>
    );
  });

  return result;
};

const createOptionsWthName = (data) => {
  const result = [<option aria-label="None" value="" key={0} />];
  data.forEach(({ name, id }) => {
    result.push(
      <option key={id} value={id}>
        {name}
      </option>
    );
  });

  return result;
};

const createCategoriesOptions = (data) => {
  const result = [];

  data.forEach(({ name, id, childCount, extendNum }) => {
    if (childCount > 0) {
      result.push(
        <MenuItem
          key={id}
          name="category"
          style={{ paddingLeft: `${extendNum * 20}px` }}
          disabled
        >
          {name}
        </MenuItem>
      );
      return;
    }
    result.push(
      <MenuItem
        key={id}
        value={id}
        name="category"
        style={{ paddingLeft: `${extendNum * 20}px` }}
      >
        {name}
      </MenuItem>
    );
  });

  return result;
};

const getListsByResource = (dataProvider, resouce, notify, saveCallback) => {

  dataProvider
    .getList(resouce, {
      pagination: { page: 1, perPage: 1000 },
      sort: { order: "ASC" },
    })
    .then((items) => {
      saveCallback(items);
    })
    .catch((error) => {
      if (!error.response) {
        console.error(error);
        return;
      }
      let { status } = error.response;
      if (status === 401 || status === 403) {
        cleanSession();
        window.location.href = "/login";
      }
      if (status === 500) {
        notify(
          error.response.data?.error,
          "warning"
        )
        return;
      }
      notify(
        error.response.data.message?.message,
        "warning",
        wrapArrayToMap(error.response.data.message?.parameters)
      );
    });
};

const ProductGeneralInfo = (props) => {
  const {
    closed,
    handleComplete,
    handleStepError,
    completed,
    record,
    environment,
  } = props;
  const [manufacturerNames, setManufacturerNames] = useState([]);
  const [manufacturerCountries, setManufacturerCountries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [packageTypes, setPackageTypes] = useState([]);
  const [measurementTypes, setMeasurementTypes] = useState([]);
  const [productPhotoAttach, setProductPhotoAttach] = useState("link");
  const [fileUrl, setFileUrl] = useState("");
  const [file, setFile] = useState(null);
  const [openManufactureNameDialog, setOpenManufactureNameDialog] =
    useState(false);
  const [openManufactureCountryDialog, setOpenManufactureCountryDialog] =
    useState(false);
  const [openPackageTypeDialog, setOpenPackageTypeDialog] = useState(false);
  const [openPriceForDialog, setOpenPriceForDialog] = useState(false);
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const [additionalMessages, setAdditionalMessages] = useState({});
  const [urlError, setUrlError] = useState(false);
  const [categoryId, setCategory] = useState("");
  const [name, setName] = useState("");
  const [packageTypeId, setPackageTypeId] = useState("");
  const [measurementId, setMeasurementId] = useState("");
  const [manufacturerId, setManufacturerId] = useState("");
  const [manufacturingCountry, setManufacturingCountry] = useState("");
  const [isBlocking, setIsBlocking] = useState(false);
  const [productId, setProductId] = useState("");
  const dataProvider = useContext(DataProviderContext);

  const classes = useStyles();
  const translate = useTranslate();
  const notify = useNotify();
  const currentLocation = useLocation();

  const mounted = useRef(false);
  const packagesTypesData = useRef([]);
  const measurementTypesData = useRef([]);
  const manufacturerNamesData = useRef([]);

  useEffect(() => {
    if (!record || !record.name) {
      return;
    }
    setData({
      technicalSpecifications: record?.technicalSpecifications,
      description: record?.description,
      comments: record?.comments,
      fileName: record?.fileName,
      productPictureId: record?.productPictureId,
      barCode: record?.barCode,
      videoInstructionAndTips: record?.videoInstructionAndTips,
    });
    setName(record.name);
  }, [record]);

  useEffect(() => {
    if (!record || !categories.length) {
      return;
    }
    setCategory(record.categoryId);
  }, [categories, record]);

  useEffect(() => {
    if (!record) {
      return;
    }

    if (packageTypes?.length <= 1) {
      return;
    }

    if (!record.packageTypeId && record.packageTypeTitle) {
      const packageTypeId = packagesTypesData.current.find((el) => el.title === record.packageTypeTitle)?.id;
      if (!packageTypeId) {
        setAdditionalMessages({ ...additionalMessages, packageType: true });
        return;
      }
      setPackageTypeId(packageTypeId);
      return;
    }

    setPackageTypeId(record.packageTypeId || "");
  }, [packageTypes, record]);

  useEffect(() => {
    if (!record) {
      return;
    }

    if (measurementTypes?.length <= 1 || !packageTypeId) {
      return;
    }

    if (!record.measurementId && record.measurementTitle) {
      const measurementId = measurementTypesData.current.find((el) => el.title === record.measurementTitle)?.id;
      if (!measurementId) {
        setAdditionalMessages({ ...additionalMessages, measurement: true });
        return;
      }
      setMeasurementId(measurementId);
      return;
    }

    setMeasurementId(record.measurementId || "");
  }, [measurementTypes, packageTypeId, record]);

  useEffect(() => {
    if (!record) {
      return;
    }

    if (manufacturerNames?.length <= 1) {
      return;
    }

    if (!record.manufacturerId && record.manufacturerName) {
      const manufacturerId = manufacturerNamesData.current.find((el) => el.name === record.manufacturerName)?.id;
      if (!manufacturerId) {
        setAdditionalMessages({ ...additionalMessages, manufacturerName: true });
        return;
      }
      setManufacturerId(manufacturerId);
      return;
    }

    setManufacturerId(record.manufacturerId || "");
  }, [manufacturerNames, record]);

  useEffect(() => {
    if (!record) {
      return;
    }

    if (manufacturerCountries?.length <= 1) {
      return;
    }

    setManufacturingCountry(record.manufacturingCountryId || "");
  }, [manufacturerCountries, record]);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      getListsByResource(dataProvider, "manufacturer", notify, (items) => {
        if (mounted.current) {
          manufacturerNamesData.current = items?.data;
          setManufacturerNames(createOptionsWthName(items?.data));
        }
      });
      getListsByResource(
        dataProvider,
        "manufacturing_country",
        notify,
        (items) => {
          if (mounted.current) {
            setManufacturerCountries(createOptionsWthName(items?.data));
          }
        }
      );
      dataProvider
        .GET_TREE_ROOT_NODES("categories")
        .then((roots) => {
          if (mounted.current) {
            const categoriesData = TreeService.createFlatTree(roots?.data, 1);
            setCategories(createCategoriesOptions(categoriesData));
          }
        })
        .catch((error) => {
          if (!error.response) {
            console.error(error);
            return;
          }
          let { status } = error.response;
          if (status === 401 || status === 403) {
            cleanSession();
            window.location.href = "/login";
          }
          if (status === 500) {
            notify(
              error.response.data?.error,
              "warning"
            )
            return;
          }
          notify(
            error.response.data?.message?.message,
            "warning",
            wrapArrayToMap(error.response.data?.message?.parameters)
          );
        });
      getListsByResource(dataProvider, "package_type", notify, (items) => {
        if (mounted.current) {
          packagesTypesData.current = items?.data;
          setPackageTypes(createOptionsWthTitle(items?.data));
        }
      });
      getListsByResource(dataProvider, "measurement", notify, (items) => {
        if (mounted.current) {
          measurementTypesData.current = items?.data;
          setMeasurementTypes(createOptionsWthTitle(items?.data));
        }
      });
    }
    return () => {
      mounted.current = false;
      setIsBlocking(false);
    };
  }, []);

  useEffect(() => {
    if (closed) {
      handleSave();
    }
  }, [closed, handleSave]);

  useEffect(() => {
    ProductService.setIsBlocking(isBlocking);
  }, [isBlocking]);

  useEffect(() => {
    if (ProductService.isBlocking() && currentLocation.pathname.slice(-2) === "/1") {
      setIsBlocking(false);
      setData({
        technicalSpecifications: record?.technicalSpecifications,
        description: record?.description,
        comments: record?.comments,
        fileName: record?.fileName,
        productPictureId: record?.productPictureId,
        barCode: record?.barCode,
        videoInstructionAndTips: record?.videoInstructionAndTips,
      });
      setName(record.name);
      setManufacturingCountry(record.manufacturingCountryId);
      setCategory(record.categoryId);
      setPackageTypeId(record.packageTypeId);
      setManufacturerId(record.manufacturerId);
    }
  }, [currentLocation])

  const handleChange = (e) => {
    setIsBlocking(true);
    setData({
      ...data,
      [e.target.id || e.target.name]: e.target.value,
    });
  };

  const handleSave = () => {
    const createData = {
      ...data,
      categoryId: categoryId,
      name: name.trim(),
      measurementId: measurementId || null,
      packageTypeId: packageTypeId || null,
      manufacturerId: manufacturerId || null,
      manufacturingCountry: manufacturingCountry || null,
      file: file || "",
      fileUrl: fileUrl || "",
    };
    const newErrors = { ...errors };

    requredFields.forEach((field) => {
      if (!createData[field]) {
        newErrors[field] = true;
      }
    });

    const isWrong = Object.values(newErrors).some((error) => error);

    if (isWrong) {
      setErrors(newErrors);
      if (handleStepError) {
        handleStepError();
      }
      return;
    }

    if (!completed && !productId && environment === "create") {
      dataProvider
        .create("products", { data: createData })
        .then(({ data }) => {
          if (data.pictureError) {
            notify(
              data.pictureError.message,
              "warning",
              wrapArrayToMap(data.pictureError.parameters)
            );
            setProductId(data.id);
            handleStepError();
            return;
          }
          notify("ra.notification.general_info_saved", "success");
          handleComplete(data);
          setIsBlocking(false);
        })
        .catch((e) => {
          handleStepError();
          notify(
            e.response?.data.message.message,
            "warning",
            wrapArrayToMap(e.response?.data.message.parameters)
          );
        });
      return;
    }

    if (
      ((completed && environment === "create") || environment === "edit" || productId) &&
      isBlocking
    ) {
      dataProvider
        .update("products", { data: createData, id: record.id || productId })
        .then((res) => {
          notify("ra.notification.general_info_updated", "success");
          if (handleComplete) {
            handleComplete(res.data);
          } else {
            setData({
              ...data,
              productPictureId: res.data.productPictureId,
              fileName: res.data.fileName,
            })
          }
          setIsBlocking(false);
        })
        .catch((error) => {
          if (!error.response) {
            console.error(error);
            if (handleStepError) {
              handleStepError();
            }
            return;
          }
          let { status } = error.response;
          if (status === 401 || status === 403) {
            cleanSession();
            window.location.href = "/login";
          }
          if (status === 500) {
            notify(
              error.response.data?.error,
              "warning"
            )
            return;
          }
          if (handleStepError) {
            handleStepError();
          }
          notify(
            error.response.data?.message?.message,
            "warning",
            wrapArrayToMap(error.response.data?.message?.parameters)
          );
        });
    } else if (!isBlocking && environment === "create") {
      handleComplete(record);
    }
  };

  const changeCategory = (e) => {
    setIsBlocking(true);
    if (errors.categoryId && e.target.value) {
      setErrors({ ...errors, categoryId: false });
    }
    setCategory(e.target.value);
  };

  const changeName = (e) => {
    setIsBlocking(true);
    if (errors.name && e.target.value) {
      setErrors({ ...errors, name: false });
    }
    setName(e.target.value);
  };

  const changePackageType = (e) => {
    setIsBlocking(true);
    if (errors.packageTypeId && e.target.value) {
      setErrors({ ...errors, packageTypeId: false });
    }
    setPackageTypeId(e.target.value);
  };

  const changeMeasurement = (e) => {
    setIsBlocking(true);
    if (errors.measurementId && e.target.value) {
      setErrors({ ...errors, measurementId: false });
    }
    setMeasurementId(e.target.value);
  };

  const changeManufacturerId = (e) => {
    setManufacturerId(e.target.value);
    setIsBlocking(true);
  };

  const changeManufacturingCountry = (e) => {
    setManufacturingCountry(e.target.value);
    setIsBlocking(true);
  };

  const checkRequired = (e, key) => {
    if (!e.target.value && requredFields.includes(e.target?.id || key)) {
      setErrors({ ...errors, [e.target?.id || key]: true });
    }
  };

  const selectPhotoAttachedWay = (value) => {
    setProductPhotoAttach(value);
  };

  const attachProductPhotoLink = (e) => {
    if (e.target.value) {
      setUrlError(false);
      setFileUrl(e.target.value);
    }
  };

  const uploadImage = () => {
    if (!fileUrl) {
      setUrlError(true);
      return;
    }
    const newData = {
      ...data,
      fileName: fileUrl,
    };
    setIsBlocking(true);
    setData(newData);
  };

  const deleteImage = async () => {
    try {
      if (data.productPictureId) {
        await dataProvider
          .delete("products", { id: data.productPictureId });
      }
      const newData = {
        ...data,
        fileName: "",
        productPictureId: "",
      };
      setData(newData);
      setFile(null);
      setFileUrl("");
    } catch (error) {
      if (!error.response) {
        console.error(error);
        if (handleStepError) {
          handleStepError();
        }
        return;
      }
      let { status } = error.response;
      if (status === 401 || status === 403) {
        cleanSession();
        window.location.href = "/login";
      }
      if (status === 500) {
        notify(
          error.response.data?.error,
          "warning"
        )
        return;
      }
      if (handleStepError) {
        handleStepError();
      }
      notify(
        error.response.data?.message?.message,
        "warning",
        wrapArrayToMap(error.response.data?.message?.parameters)
      );
    }
  };

  const attachProductPhotoFile = (imgFile) => {
    if (FileReader && imgFile) {
      var fr = new FileReader();
      fr.onload = function () {
        const newData = {
          ...data,
          fileName: fr.result,
        };
        setIsBlocking(true);
        setData(newData);
      };
      fr.readAsDataURL(imgFile);
      setFile(imgFile);
    }
  };

  const saveManufacturerName = async (e, data) => {
    if (!data) {
      setOpenManufactureNameDialog(false);
      return;
    }

    setAdditionalMessages({ ...additionalMessages, manufacturerName: false });

    try {
      await dataProvider.create("manufacturer", { data: data });
      setOpenManufactureNameDialog(false);
      dataProvider;
      getListsByResource(dataProvider, "manufacturer", notify, (items) => {
        if (mounted.current) {
          manufacturerNamesData.current = items?.data;
          setManufacturerNames(createOptionsWthName(items?.data));
        }
      });
    } catch (error) {
      if (!error.response) {
        console.error(error);
        return;
      }
      let { status } = error.response;
      if (status === 401 || status === 403) {
        cleanSession();
        window.location.href = "/login";
      }
      if (status === 500) {
        notify(
          error.response.data?.error,
          "warning"
        )
        return;
      }
      notify(
        error.response.data?.message?.message,
        "warning",
        wrapArrayToMap(error.response.data?.message?.parameters)
      );
    }
  };

  const saveManufacturerCountry = async (e, data) => {
    if (!data) {
      setOpenManufactureCountryDialog(false);
      return;
    }

    try {
      await dataProvider.create("manufacturing_country", { data: data });
      setOpenManufactureCountryDialog(false);
      getListsByResource(
        dataProvider,
        "manufacturing_country",
        notify,
        (items) => {
          if (mounted.current) {
            setManufacturerCountries(createOptionsWthName(items?.data));
          }
        }
      );
    } catch (error) {
      if (!error.response) {
        console.error(error);
        return;
      }
      let { status } = error.response;
      if (status === 401 || status === 403) {
        cleanSession();
        window.location.href = "/login";
      }
      if (status === 500) {
        notify(
          error.response.data?.error,
          "warning"
        )
        return;
      }
      notify(
        error.response.data?.message?.message,
        "warning",
        wrapArrayToMap(error.response.data?.message?.parameters)
      );
    }
  };

  const savePackageType = async (e, data) => {
    if (!data) {
      setOpenPackageTypeDialog(false);
      return;
    }

    setAdditionalMessages({ ...additionalMessages, packageType: false });

    try {
      await dataProvider.create("package_type", { data: data });
      setOpenPackageTypeDialog(false);
      getListsByResource(dataProvider, "package_type", notify, (items) => {
        if (mounted.current) {
          packagesTypesData.current = items?.data;
          setPackageTypes(createOptionsWthTitle(items?.data));
        }
      });
    } catch (error) {
      if (!error.response) {
        console.error(error);
        return;
      }
      let { status } = error.response;
      if (status === 401 || status === 403) {
        cleanSession();
        window.location.href = "/login";
      }
      if (status === 500) {
        notify(
          error.response.data?.error,
          "warning"
        )
        return;
      }
      notify(
        error.response.data?.message?.message,
        "warning",
        wrapArrayToMap(error.response.data?.message?.parameters)
      );
    }
  };

  const savePriceFor = async (e, data) => {
    if (!data) {
      setOpenPriceForDialog(false);
      return;
    }

    setAdditionalMessages({ ...additionalMessages, measurement: false });

    try {
      await dataProvider.create("measurement", { data: data });
      setOpenPriceForDialog(false);
      getListsByResource(dataProvider, "measurement", notify, (items) => {
        if (mounted.current) {
          measurementTypesData.current = items?.data;
          setMeasurementTypes(createOptionsWthTitle(items?.data));
        }
      });
    } catch (error) {
      if (!error.response) {
        console.error(error);
        return;
      }
      let { status } = error.response;
      if (status === 401 || status === 403) {
        cleanSession();
        window.location.href = "/login";
      }
      if (status === 500) {
        notify(
          error.response.data?.error,
          "warning"
        )
        return;
      }
      notify(
        error.response.data?.message?.message,
        "warning",
        wrapArrayToMap(error.response.data?.message?.parameters)
      );
    }
  };

  return (
    // <FormWithRedirect
    //   record={data}
    //   render={(formProps) => (
    //     // here starts the custom form layout
    <>
      <Prompt
        when={isBlocking}
        message={(location) => {
          return location.pathname.startsWith(currentLocation.pathname)
            ? translate("confirm_messages.products.next_step")
            : translate("confirm_messages.products.back")
        }}
      />
      {environment === "edit" && (
        <Toolbar>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            width="100%"
          >
            <Button
              color="secondary"
              variant="contained"
              label={translate("ra.action.save")}
              onClick={handleSave}
              disabled={!isBlocking}
            >
              {translate("ra.action.save")}
            </Button>
          </Box>
        </Toolbar>
      )}
      <Box p="1em">
        <Box display="flex">
          <Box flex={1} mr="1em">
            <Typography variant="h6" gutterBottom>
              {translate("konebone.products.general_info").toUpperCase()}
            </Typography>
            <TextField
              error={errors.name}
              id="name"
              label={translate(
                "konebone.products.catalog.konebone_product_name"
              )}
              fullWidth
              variant="outlined"
              value={name}
              onChange={changeName}
              onBlur={(e) => checkRequired(e)}
              helperText={errors.name && "Required"}
              required
            />
            <FormControl
              className={classes.formControl}
              required
              error={errors.categoryId}
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-category-simple-label">
                {translate("konebone.products.catalog.category")}
              </InputLabel>
              <Select
                labelWidth={75}
                value={categoryId}
                name="category"
                onChange={changeCategory}
                onBlur={(e) => checkRequired(e, "categoryId")}
                labelId="outlined-category-simple-label"
                id="outlined-category-simple"
              >
                {categories}
              </Select>
              {errors.categoryId && <FormHelperText>Required</FormHelperText>}
            </FormControl>
            <Box mt="1em" />
            <TextInput
              source="description"
              resource="customers"
              label={translate(
                "konebone.categories.description"
              )}
              fullWidth
              variant="outlined"
              multiline
              rows="4"
              value={data.description}
              onChange={handleChange}
            />
            <TextInput
              source="technicalSpecifications"
              resource="customers"
              label={translate(
                "konebone.products.catalog.technical_specification"
              )}
              fullWidth
              variant="outlined"
              multiline
              rows="4"
              value={data.technicalSpecifications}
              onChange={handleChange}
            />
            <Box mt="1em" />

            <Typography variant="h6" gutterBottom>
              {translate("konebone.products.manufacturer").toUpperCase()}
            </Typography>

            <Box display="flex">
              <FormControl
                className={classes.formControl}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-manufacturerId-native-simple-label">
                  {translate("konebone.products.catalog.manufacturer_name")}
                </InputLabel>
                <Select
                  native
                  name="manufacturerId"
                  labelWidth={152}
                  value={manufacturerId}
                  onChange={changeManufacturerId}
                  labelId="outlined-manufacturerId-native-simple-label"
                  id="outlined-manufacturerId-native-simple"
                >
                  {manufacturerNames}
                </Select>
                {additionalMessages.manufacturerName && (
                  <FormHelperText error>{translate("konebone.products.catalog.additonal_manufacturer_name")}</FormHelperText>
                )}
              </FormControl>
              <IconButton
                className={classes.plusButton}
                size="medium"
                onClick={() => {
                  setOpenManufactureNameDialog(true);
                }}
              >
                <AddBoxOutlinedIcon />
              </IconButton>
            </Box>
            <Box display="flex">
              <FormControl
                className={classes.formControl}
                variant="outlined">
                <InputLabel htmlFor="outlined-manufacturingCountry-native-simple-label">
                  {translate(
                    "konebone.products.catalog.manufacturer_country"
                  )}
                </InputLabel>
                <Select
                  native
                  labelWidth={158}
                  name="manufacturingCountry"
                  value={manufacturingCountry}
                  onChange={changeManufacturingCountry}
                  labelId="outlined-manufacturingCountry-native-simple-label"
                  id="outlined-manufacturingCountry-native-simple"
                >
                  {manufacturerCountries}
                </Select>
              </FormControl>

              <IconButton
                variant="outlined"
                className={classes.plusButton}
                size="medium"
                onClick={() => {
                  setOpenManufactureCountryDialog(true);
                }}
              >
                <AddBoxOutlinedIcon />
              </IconButton>
            </Box>
            <TextInput
              source="comments"
              label={translate("konebone.products.catalog.comments")}
              fullWidth
              variant="outlined"
              multiline
              rows="4"
              value={data.comments}
              onChange={handleChange}
            />
          </Box>

          <Box flex={1} ml="1em" color="text.primary" variant="outlined">
            <Box display="flex">
              <Box flex={2} mr="0.4em">
                {data.fileName && <img
                  src={data.fileName}
                  title={translate("konebone.products.catalog.product_photo")}
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                />}
              </Box>
              <Box flex={3} ml="0.6em">
                <TextInput
                  source="barCode"
                  resource="customers"
                  label={translate("konebone.products.catalog.bar_code")}
                  fullWidth
                  variant="outlined"
                  value={data.barCode}
                  onChange={handleChange}
                />
                {environment === "edit" && (
                  <Box display="flex">
                    <Box flex={1} mr="0.5em">
                      <TextInput
                        source="id"
                        resource="customers"
                        label={translate(
                          "konebone.products.catalog.product_number"
                        )}
                        fullWidth
                        variant="outlined"
                        disabled
                      />
                    </Box>
                    <Box flex={1} mr="0.5em">
                      <TextInput
                        source="catalogNumber"
                        resource="customers"
                        label={translate(
                          "konebone.products.catalog.catalog_number"
                        )}
                        fullWidth
                        variant="outlined"
                        disabled
                      />
                    </Box>
                  </Box>
                )}
                <Typography
                  variant="h6"
                  gutterBottom
                  className={classes.capitalize}
                >
                  {translate("konebone.products.catalog.product_photo")}
                </Typography>
                <RadioButtonGroupInput
                  source="productPhotoAttach"
                  resource="customers"
                  choices={[
                    {
                      id: "file",
                      name: translate(
                        "konebone.products.catalog.attach_file"
                      ),
                    },
                    {
                      id: "link",
                      name: translate("konebone.products.catalog.add_link"),
                    },
                  ]}
                  value={productPhotoAttach}
                  defaultValue="link"
                  onChange={selectPhotoAttachedWay}
                  label={""}
                />
                {productPhotoAttach === "file" ? (
                  <>
                    <ImageInput
                      source="pictureFile"
                      label={""}
                      accept="image/*"
                      value={file}
                      resource="customers"
                      onChange={attachProductPhotoFile}
                    >
                      <ImageField title="title" style={{ display: "none" }} />
                    </ImageInput>
                    <Button variant="outlined" fullWidth onClick={deleteImage}>
                      {translate("ra.action.delete")}
                    </Button>
                  </>
                ) : (
                  <>
                    <TextField
                      error={urlError}
                      id="pictureUrl"
                      label={translate("konebone.products.catalog.url")}
                      type="url"
                      fullWidth
                      variant="outlined"
                      value={fileUrl}
                      onChange={attachProductPhotoLink}
                      InputProps={{
                        inputProps: {
                          min: 1,
                        }
                      }}
                      helperText={urlError && translate("ra.validation.required")}
                      required
                    />
                    <Box display="flex">
                      <Box flex={1} ml="0.5em">
                        <Button
                          variant="contained"
                          className={classes.blackButton}
                          onClick={uploadImage}
                          fullWidth
                        >
                          {translate("konebone.products.upload")}
                        </Button>
                      </Box>
                      <Box flex={1} ml="0.5em">
                        <Button
                          variant="outlined"
                          fullWidth
                          onClick={deleteImage}
                        >
                          {translate("ra.action.delete")}
                        </Button>
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
            </Box>

            <Box mt="1em" style={{ marginTop: "40px" }} />

            <Typography variant="h6" gutterBottom>
              {translate("konebone.products.specification").toUpperCase()}
            </Typography>

            <Box display="flex">
              <FormControl
                className={classes.formControl}
                required
                error={errors.packageTypeId}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-packageTypeId-native-simple-label">
                  {translate("konebone.products.catalog.package_type")}
                </InputLabel>
                <Select
                  native
                  labelWidth={115}
                  name="packageTypeId"
                  value={packageTypeId}
                  onChange={changePackageType}
                  onBlur={(e) => checkRequired(e, "packageTypeId")}
                  labelId="outlined-packageTypeId-native-simple-label"
                  id="outlined-packageTypeId-native-simple"
                >
                  {packageTypes}
                </Select>
                {errors.packageTypeId && (
                  <FormHelperText>{translate("ra.validation.required")}</FormHelperText>
                )}
                {additionalMessages.packageType && (
                  <FormHelperText error>{translate("konebone.products.catalog.additonal_package_type")}</FormHelperText>
                )}
              </FormControl>

              <IconButton
                variant="outlined"
                className={classes.plusButton}
                size="medium"
                onClick={() => {
                  setOpenPackageTypeDialog(true);
                }}
              >
                <AddBoxOutlinedIcon />
              </IconButton>
            </Box>
            <Box display="flex">
              <FormControl
                className={classes.formControl}
                required
                error={errors.measurementId}
                variant="outlined"
                disabled={!packageTypeId && !record.packageTypeId}
              >
                <InputLabel htmlFor="outlined-measurementId-native-simple-label">
                  {translate("konebone.products.catalog.price_for")}
                </InputLabel>
                <Select
                  native
                  labelWidth={80}
                  name="measurementId"
                  value={measurementId}
                  onChange={changeMeasurement}
                  onBlur={(e) => checkRequired(e, "measurementId")}
                  labelId="outlined-measurementId-native-simple-label"
                  id="outlined-measurementId-native-simple"
                >
                  {measurementTypes}
                </Select>
                {errors.measurementId && (
                  <FormHelperText>{translate("ra.validation.required")}</FormHelperText>
                )}
                {additionalMessages.measurement && (
                  <FormHelperText error>{translate("konebone.products.catalog.additonal_measurement")}</FormHelperText>
                )}
              </FormControl>
              <IconButton
                variant="outlined"
                className={classes.plusButton}
                size="medium"
                onClick={() => {
                  setOpenPriceForDialog(true);
                }}
              >
                <AddBoxOutlinedIcon />
              </IconButton>
            </Box>
            <TextInput
              source="videoInstructionAndTips"
              resource="customers"
              label={translate("konebone.products.catalog.link_video")}
              fullWidth
              type="url"
              variant="outlined"
              value={data.videoInstructionAndTips}
              onChange={handleChange}
            />
          </Box>
        </Box>
      </Box>
      {openManufactureNameDialog && (
        <AddInfoDialog
          title={translate("konebone.products.add_manufacturer")}
          fields={[
            {
              source: "name",
              label: translate("konebone.products.catalog.manufacturer_name"),
              required: true,
            },
            {
              source: "description",
              label: translate(
                "konebone.products.catalog.manufacturer_description"
              ),
            },
          ]}
          initialValue={record.manufacturerName}
          open={openManufactureNameDialog}
          handleClose={saveManufacturerName}
        />
      )}
      {openManufactureCountryDialog && (
        <AddInfoDialog
          title={translate("konebone.products.add_manufacturer_country")}
          fields={[
            {
              source: "name",
              label: translate(
                "konebone.products.catalog.manufacturer_country"
              ),
              required: true,
            },
          ]}
          open={openManufactureCountryDialog}
          handleClose={saveManufacturerCountry}
        />
      )}
      {openPackageTypeDialog && (
        <AddInfoDialog
          title={translate("konebone.products.add_package_type")}
          fields={[
            {
              source: "title",
              label: translate("konebone.products.catalog.package_type"),
              required: true,
            },
          ]}
          initialValue={record.packageTypeTitle}
          open={openPackageTypeDialog}
          handleClose={savePackageType}
        />
      )}
      {openPriceForDialog && (
        <AddInfoDialog
          title={translate("konebone.products.add_measurement_type")}
          fields={[
            {
              source: "title",
              label: translate("konebone.products.catalog.measurement_type"),
              required: true,
            },
          ]}
          initialValue={record.measurementTitle}
          open={openPriceForDialog}
          handleClose={savePriceFor}
        />
      )}
    </>
    //   )}
    // />
  );
};

export default ProductGeneralInfo;
