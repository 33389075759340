import { Box, makeStyles } from "@material-ui/core";
import currency from "currency.js";
import { useTranslate } from "ra-core";

const useStyles = makeStyles(() => ({
  heading: {
    color: "#333333"
  },
  creditLimit: {
    padding: "14px 36px",
  },
  availableCredit: {
    borderInline: '1px solid #E9E9E9',
    padding: '0 73px'
  },
  outstanding: {
    padding: '0 73px'
  },
  total: {
    display: 'grid'
  },

}))

const ILS = value => currency(value, { symbol: '₪ ', decimal: '.', separator: ',' });

const CreditInfo = ({data}) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <>
        <Box display='flex' alignItems='center'>
          <Box className={classes.creditLimit}>
            <div className={classes.total}>
              <span className={classes.heading}>{translate("konebone.customer.credit_limit")}</span>
              <span><b>{ILS(data.limit || 0).format()}</b></span>
            </div>
          </Box>
          <Box className={classes.availableCredit}>
            <div className={classes.total}>
              <span className={classes.heading}>{translate("konebone.customer.available_credit")}</span>
              <span><b>{ILS(data.available || 0).format()}</b></span>
            </div>
          </Box>
          <Box className={classes.outstanding}> 
            <div className={classes.total}>
              <span className={classes.heading}>{translate("konebone.customer.outstanding_receivables")}</span>
              <span><b>{ILS(data.used || 0).format()}</b></span>
            </div>
          </Box>
        </Box> 
    </>
  )
}

export default CreditInfo;
