import Wrapper from "../utils/Wrapper";
import axios from 'axios';
import { stringify } from "query-string";

const fieldMap = {
  id: "project",
  address: "street"
}

const dataProviderForProjects = {

  delete: async (resource, params) => {
    const url = process.env.REACT_APP_API_URL + "/project/" + params.projectId;
    const response = await axios.delete(url,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      });

    if (response.status === 200) {
      return { data: { projectId: params.projectId } }
    }
  },

  getList: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const { customerId } = params.filter;
    const query = {
      page: page - 1,
      perPage: perPage,
      order: order,
      field: fieldMap[field] ? fieldMap[field] : field,
    };
    const url = process.env.REACT_APP_API_URL + `/project/byCustomer/${customerId}?${stringify(query)}`;
    const response = await axios.get(url,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      });

    if (!response.data || !response.data.data) {
      return ({
        data: [],
        total: 0
      });
    }

    let resultData = response.data.data.projects.map(project => Wrapper.wrapProject(project));
    const responseTotalCount = response.data.data.totalElements;

    return ({
      data: resultData,
      total: responseTotalCount
    });
  },

  getProjectList: async (resource, params) => {
    const url = process.env.REACT_APP_API_URL + "/project/by-customer/" + params.customerId;
    const response = await axios.get(url,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      });

      if (response && response.data) {
        let dataRes = response.data
        return ({
          data: dataRes.data
        });
      } else {
        return ({
          data: []
        });
      }
  },

  create: async (resource, params) => {
    let response = await axios.post(process.env.REACT_APP_API_URL + "/project", params,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      }
    );

    if (response && response.data) {
      let dataRes = response.data;
      dataRes.data.id = dataRes.data.project;
      return ({
        data: dataRes.data
      });
    } else {
      return ({
        data: []
      });
    }
  },

  getOne: async (resource, params) => {
    const url = process.env.REACT_APP_API_URL + "/project/" + params.id;
    const response = await axios.get(url,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      });
    let resultData = Wrapper.wrapPr(response.data.data);
    return ({
      data: resultData,
    });
  },

  update: async (resource, params) => {
      let response = await axios.put(process.env.REACT_APP_API_URL + "/project/" + params.projectId, params.data,
        {
          withCredentials: true,
          headers: { 'accept': 'application/json' }
        }
      );

      if (response && response.data) {
        return ({
          data: response.data
        });
      } else {
        return ({
          data: []
        });
      }
  },

};

export default dataProviderForProjects;
