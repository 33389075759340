const hebrewMessages = {
  ra: {
    action: {
      add_filter: "הוסף סינון",
      add: "הוסף",
      back: "חזור",
      bulk_actions: "פריט אחד נבחר |||| %{smart_count} פריטים נבחרו",
      cancel: "ביטול",
      clear_input_value: "נקה ערך",
      clone: "שכפל",
      confirm: "אשר",
      create: "צור",
      create_item: "Create %{item}",
      delete: "מחק",
      edit: "לַעֲרוֹך",
      export: "יְצוּא",
      list: "רשימה",
      refresh: "רענן",
      remove_filter: "הסר סינון",
      remove: "מחק",
      save: "שמור",
      search: "חפש",
      show: "הצג",
      sort: "מיין",
      undo: "בטל",
      unselect: "בטל את הבחירה",
      expand: "לְהַרְחִיב",
      close: "סגור",
      open_menu: "פתח תפריט",
      close_menu: "סגור תפריט",
    },
    boolean: {
      true: "כן",
      false: "לא",
      null: " ",
    },
    page: {
      create: "צור %{name}",
      dashboard: "לוח המחוונים",
      edit: "%{name} #%{id}",
      error: "שגיאה התרחשה",
      list: "רשימת %{name}",
      loading: "טוען",
      not_found: "לא קיים",
      show: "%{name} #%{id}",
      empty: "עדיין אין %{name}.",
      invite: "האם אתה רוצה להוסיף אחד?",
    },
    input: {
      file: {
        upload_several: "גרור מספר קבצים להעלאה או לחץ לבחור אחד.",
        upload_single: "גרור קובץ להעלאה או לחץ לבחור אחד.",
      },
      image: {
        upload_several: "גרור מספר תמונות להעלאה או לחץ לבחור אחת.",
        upload_single: "גרור תמונה להעלאה או לחץ לבחור אחת.",
      },
      references: {
        all_missing: "ישויות הקשר לא נמצאו.",
        many_missing: "לפחות אחת מישויות הקשר אינה זמינה יותר.",
        single_missing: "ישות הקשר אינה זמינה יותר.",
      },
      password: {
        toggle_visible: "הסתר סיסמא",
        toggle_hidden: "הראה סיסמה",
      },
    },
    message: {
      about: "אודות",
      are_you_sure: "האם את/ה בטוח/ה ?",
      bulk_delete_content:
        "האם אתה בטוח שברצונך למחוק %{name}? |||| האם אתה בטוח שברצונך למחוק %{smart_count} פריטים?",
      bulk_delete_title: "מחק %{name} |||| מחק %{smart_count} %{name} פריטים",
      delete_content: "האם אתה בטוח שברצונך למחוק את הפריט הזה?",
      delete_title: "מחק %{name} #%{id}",
      details: "פרטים",
      error: "קרתה שגיאת תקשורת והבקשה שלך לא הסתיימה.",
      invalid_form: "הטופס אינו תקין. אנא תקן את שגיאותיך",
      loading: "העמוד טוען, אנא המתן",
      no: "לא",
      not_found: "הכנסת כתובת שגויה או לחצת על קישור לא תקין",
      yes: "כן",
      unsaved_changes:
        "חלק מהשינויים שלך לא נשמרו. האם אתה בטוח שאתה רוצה להתעלם מהם?",
    },
    navigation: {
      no_results: "לא נמצאו תוצאות",
      no_more_results: "עמוד %{page} מחוץ לתחום. אנא נסה את העמוד הקודם.",
      page_out_of_boundaries: "עמוד %{page} מחוץ לתחום",
      page_out_from_end: "לא ניתן להמשיך מעבר לעמוד האחרון",
      page_out_from_begin: "לא ניתן לגשת לפני העמוד הראשון",
      page_range_info: "%{offsetBegin}-%{offsetEnd} מתוך %{total}",
      page_rows_per_page: "מספר שורות לעמוד:",
      next: "הבא",
      prev: "הקודם",
      skip_nav: "דלג לתוכן",
    },
    sort: {
      sort_by: "מיין לפי %{field} %{order}",
      ASC: "עולה",
      DESC: "יורד",
    },
    auth: {
      auth_check_error: "אנא התחבר על מנת להמשיך",
      user_menu: "פרופיל",
      username: "שם משתמש",
      password: "סיסמא",
      sign_in: "כניסה",
      can_not_login: "לא מצליח להיכנס?",
      forgot_password: "שכחת את הסיסמא?",
      forgot_text: 'הזן את כתובת הדוא"ל שלך. תקבל אימייל עם קישור לאיפוס הסיסמה שלך.',
      back_sign_in: "חזרה לכניסה",
      reset_password: "לאפס את הסיסמה",
      link_sent: "קישור השחזור נשלח",
      recover_text_half: "מייל נשלח אל",
      recover_text: ". אנא בצע את הקישור במכתב כדי ליצור סיסמה חדשה.",
      got_it: "בסדר קיבלתי את זה",
      sign_in_error: "הכניסה נכשלה, אנא נסו שנית",
      logout: "התנתקות",
      set_new_password: "הגדר סיסמה חדשה",
      confirm_password: "תאשר סיסמא חדשה",
      new_password: "סיסמה חדשה",
    },
    notification: {
      updated: "פריט עודכן בהצלחה",
      created: "פריט נוצר בהצלחה",
      deleted: "פריט נמחק בהצלחה",
      bad_item: "פריט לא תקין",
      item_doesnt_exist: "פריט לא קיים",
      http_error: "שגיאה בהתקשרות מול השרת",
      data_provider_error: "שגיאת dataProvider. בדוק את הקונסול לפרטים נוספים.",
      canceled: "הפעולה בוטלה",
      logged_out: "הנך מנותק, אנא התחבר מחדש.",
      i18n_error: "לא ניתן לטעון את התרגומים עבור השפה שצוינה",
      general_info_saved: "המוצר נוצר",
      general_info_updated: "המוצר נוצר",
    },
    validation: {
      numberAmount: 'שדה זה תומך רק בפורמט ספרות',
      link: "חייב להיות קישור",
      required: "חובה",
      minLength: "חייב להכיל לפחות %{min} תווים",
      maxLength: "יכול להכיל לכל היותר %{max} תווים",
      minValue: "חייב להיות לפחות %{min}",
      maxValue: "חייב להיות %{max} או פחות",
      number: "חייב להיות מספר",
      email: 'כתובת דוא"ל לא תקנית',
      phone: "חייב להיות טלפון חוקי (בפורמט 05 xxxx xxxx)",
      oneOf: "חייב להיות מאחת האפשרויות הבאות: %{options}",
      regex: "חייב להתאים לתבנית ספציפית (ביטוי רגולרי): %{pattern}",
      password_mismatch: "הסיסמה אינה תואמת את סיסמת האישור",
      between: "חייב להיות בין%{min} ל-%{max}",
      integer: "ערך זה חייב להיות מספר שלם ולהיות גדול מ- 0",
      tax_id: "מזהה המס חייב להכיל ערך מספרי בן 9 ספרות",
      cost: "חייב להיות מספר",
      amount_error_text: "הכמות צריכה להיות בין 0"
    },
    tree: {
      add_child_node: "הוסף צומת ילד",
      add_node_after: "הכנס צומת אחרי",
      add_node_before: "הכנס צומת לפני",
      close: "סגור",
      expand: "לְהַרְחִיב",
    },
  },
  konebone: {
    sign_in: {
      email: 'כתובת דוא"ל',
      reset_error: "אימייל שגוי",
      login_error: 'הדוא"ל או הסיסמה שגויים',
      password_size: "גודל הסיסמה חייב להיות בין 6 ל -64",
    },
    upload: {
      menu_item: "קטלוג ייבוא",
      title: "קטלוג ייבוא",
      dropzone_text: "גרור את הקובץ לכאן או לחץ כדי לבחור",
      success: "הַצלָחָה",
      errors: "שגיאות בקובץ שהועלה!",
      rejected_with_errors: "נדחה עם שגיאות:",
      rejected_with_defects: "נדחה עם פגמים:",
      total_record_count: "מספר שיא כולל: ",
      uploaded_record_count: "ספירת רשומות שהועלתה: ",
      rejected_record_count: "ספירת שיאים שנדחתה: ",
      read_csv_error: "קריאת שגיאת CSV עם הודעת יוצא מן הכלל %{arg_0}",
      read: {
        csv: {
          not_null_value_error:
            "הערכים של %{arg_1} ברשומה %{arg_0} חייבים להיות נוכחים",
          not_parsable_value_error:
            "הערכים של %{arg_1} ברשומה %{arg_0} חייבים להכיל %{arg_2} פורמטים תקפים",
          required_header_name_error:
            "כותרות קבצי scv אינן מכילות כותרות נדרשות %{arg_1}",
          input_valid_price_date_error:
            "ערך אחד או יותר של %{arg_1} חייבים להיות ערכים תקפים",
          product_package_relation_error: "למוצר בשם %{arg_1} יש כבר חבילה מסוג %{arg_0}",
        },
      },
      product: {
        image: {
          connection_timeout: "הזמן הקצוב לחיבור %{arg_2} בעת העלאה של %{arg_1}",
          response_status_is_not_ok: "סטטוס תגובה %{arg_2} בעת העלאה של %{arg_1}",
          unknown_error: "שגיאה לא ידועה בעת העלאה של %{arg_1}",
          unsupported_image_format: "תמונה לא נתמכת בפורמט %{arg_1} %{arg_2}. חייב להיות %{arg_3}",
          unknown_content_type: "סוג תוכן לא ידוע בעת העלאה של %{arg_1}",
          bigger_than_max_size: "התמונה %{arg_1} גודל %{arg_3} גדולה מהגודל המקסימלי %{arg_2}",
          content_length_unknown: "אורך תוכן לא ידוע %{arg_2} בעת העלאה של %{arg_1}",
        },
      },
      image: {
        get_image_from_url_failed: "שגיאה בעת קבלת התמונה מכתובת האתר"
      },
    },
    admins: {
      menu_item: "מנהלים",
      title: "כל המנהלים",
      id: "Id",
      login: "התחברות",
      first_name: "שם פרטי",
      last_name: "שם משפחה",
      email: "אימייל",
      phone: "מכשיר טלפון",
      confirmation_code: "קוד לאישור",
      password: "סיסמה",
      confirm_password: "אשר סיסמה",
      roles: "תפקידים",
      logo_url: "כתובת לוגו",
      logo_url_size: "חייב להיות בין 1 ל -512",
      created_by: "נוצר על ידי",
      created_at: "נוצר ב",
      edited_by: "נערך על ידי",
      edited_at: "נערך ב",
    },
    manufacturer: {
      menu_item: "יצרנים",
      title: "כל היצרנים",
      id: "Id",
      name: "שֵׁם",
      description: "תיאור",
    },
    manufacturing_country: {
      menu_item: "ארץ ייצור",
      title: "כל מדינות הייצור",
      id: "Id",
      name: "שֵׁם",
    },
    measurement: {
      menu_item: "כל המדידות",
      title: "כל המדידות",
      id: "Id",
      name: "שֵׁם",
    },
    products: {
      export: 'יְצוּא',
      menu_item: "מוצרים",
      title: "מוצרים",
      add_product: "הוסף מוצר",
      import: "יְבוּא ",
      search: "חפש לפי שם המוצר, מספר המוצר או הברקוד של Konebone",
      next: "הַבָּא",
      previous: "קודם",
      general_info: "מידע כללי",
      alternative_product: "מוצרים אלטרנטיביים",
      manufacturer: "יַצרָן",
      specification: "מִפרָט",
      upload: "העלה",
      add_manufacturer: "להוסיף יצרן",
      add_manufacturer_country: "הוסף מדינה יצרן",
      add_package_type: "הוסף סוג חבילה",
      add_measurement_type: "הוסף סוג מדידה",
      show_deactivated: "סוג מדידה",
      add_new_price_line: "הוסף שורת מחירים חדשה",
      edit_price_line: "מינימום כמות הזמנה",
      price_lines: "שורות מחיר",
      deactivate_product: "השבת את המוצר",
      activate_product: "הפעל מוצר",
      go_back: "חזרה לקטלוג",
      status: "סטָטוּס",
      create_on: "נוצר ב-%{arg_0} על ידי%{arg_1}",
      last_update_on: "עדכון אחרון בתאריך",
      catalog: {
        category: "קטגוריה",
        technical_specification: "מפרט טכני",
        product_number: "מספר מוצמספר קטלוגי",
        product_name: "שם מוצר",
        catalog_number: "מספר קטלוגי",
        konebone_product_name: "שם מוצר konebone",
        bar_code: "ברקוד",
        product_model: "דגם מוצר",
        type_of_measurement: "סוג המדידה",
        package_type: "סוג האריזה",
        units_in_package: "יחידות באריזה",
        suppliers: "ספקים",
        manufacturer_name: "שם היצרן",
        manufacturer_description: "תיאור היצרן",
        manufacturer_country: "מדינה יצרנית",
        comments: "הערות",
        last_update_date: "תאריך עדכון אחרון",
        consumption_quantity: "כמות צריכה",
        consumption_units: "יחידות צריכה",
        shelf_life: "חיי מדף, חודשים",
        link_video: "קישור להדרכת וידאו וטיפים",
        price_for: "מחיר בשביל",
        product_photo: "תמונת מוצר",
        attach_file: "לצרף קובץ",
        add_link: "הוסף קישור",
        url: "כתובת אתר",
        measurement_type: "סוג מדידה",
        quantity_on_pallet: "כמות על מזרן",
        kb_price: "מחיר Kb",
        additonal_package_type: `The supplier suggested a package type that was not previously in the system.
        Click on the "+" icon to add a new package type.`,
        additonal_manufacturer_name: `The supplier suggested a manufacturer name that was not previously in the system.
        Click on the "+" icon to add a new manufacturer name.`,
        additonal_measurenment: `The supplier suggested a type of measurement that was not previously in the system.
        Click on the "+" icon to add a new type of measurement.`,
      },
      price_lines_list: {
        supplier_name: "שם ספק",
        supplier_part_number: "מספר חלק של הספק",
        supplier_product_name: "שם מוצר הספק",
        supplier_price: "מחיר ספק",
        kb_price: "מחיר Kb",
        valid_from: "בתוקף מ",
        valid_to: "תקף ל",
        price_per_unit: "מחיר ליחידה",
        quantity_on_pallet: "כמות על מזרן",
        min_order_quantity: "מינימום כמות הזמנה",
        supplier: "ספק",
        supplier_product_details: "פרטי מוצר הספק",
        part_number: "מספר קטלוגי",
        product_name: "שם מוצר",
        in_stock: "במלאי",
        price_details: "פרטי מחיר",
        add_price: "הוסף מחיר",
        edit_price: "ערוך מחיר",
        add_supplier: "הוסף ספק",
        supplier_description: "תיאור ספק",
        delivery_price: "מחיר משלוח",
      },
    },
    supplier: {
      error_foundation: "בלתי אפשרי לשמור תאריך מהעתיד",
      date_time: 'תאריך ושעה',
      user_id: 'תעודת זהות של המשתמש',
      admin_id: "זיהוי מנהל",
      request: "בַּקָשָׁה",
      endpoint: "נקודת קצה",
      request_body: "גוף הבקשה",
      manager: "מנהל",
      add_supplier: 'הוסף ספק',
      imported: "מְיוֹבָּא",
      menu_item: "ספקים",
      title: "ספקים",
      id: "תְעוּדַת זֶהוּת",
      name: "ספק",
      description: "תיאור",
      tax_id: "מספר זהות לצורך מס",
      foundation: "קרן",
      activity: "פעילות",
      city: "עִיר",
      street: "רְחוֹב",
      unit: "יחידה",
      firstName: "שם פרטי",
      lastName: "שם משפחה",
      jobTitle: "הגדרת תפקיד",
      company_email: "חברה דואל",
      candidates: "מועמדים",
      active: "פָּעִיל",
      inactive: "לֹא פָּעִיל",
      search: "חיפוש לפי שם החברה",
      decline_candidate: "ירידה מועמד",
      reason_for_decline: "סיבה לירידה",
      decline: "יְרִידָה",
      block: "לַחסוֹם",
      status: "סטָטוּס",
      reason_for_block: "סיבה לחסום",
      block_supplier: "בלוק הספק",
      company: "חֶברָה",
      go_back: "חזרה לספקים",
      create_on: "נוצר ב",
      company_description: "תיאור חברה",
      approve: "לְאַשֵׁר",
      business_company_name: "שם עסקים / חברה",
      has_been_imported: "הספק כבר מיובא לפני",
      field_of_activity: "תחום פעילות",
      address: "כתובת",
      main_contact: "קשר עיקרי",
      phone_number: "מספר טלפון",
      company_foundation: "קרן החברה",
      company_name: "שם חברה/עסק",
      company_phone_number: "מספר טלפון של החברה",
      approve_candidate: "לאשר המועמד",
    },
    supplier_candidates: {
      menu_item: "מועמדי ספקים",
      title: "מועמדי ספקים",
      id: "תְעוּדַת זֶהוּת",
      name: "מועמדי ספקים",
      description: "תיאור",
      taxId: "מספר זהות לצורך מס",
      foundation: "קרן",
      activity: "פעילות",
      city: "עִיר",
      street: "רְחוֹב",
      unit: "יחידה",
      firstName: "שם פרטי",
      lastName: "שם משפחה",
      jobTitle: "הגדרת תפקיד",
      email: "אימייל",
      approve: "לְאַשֵׁר"
    },
    categories: {
      menu_item: "קטגוריות",
      title: "קטגוריות",
      create_button: "צור קטגוריית שורש",
      name: "שֵׁם",
      description: "תיאור",
      image: "תמונה",
    },
    package: {
      menu_item: "חֲבִילָה",
      title: "כל החבילות",
      id: "Id",
      name: "שֵׁם",
      package_type: "סוג האריזה",
      measurement: "מדידה",
      quantity: "כַּמוּת",
      size: "גודל",
      weight: "מִשׁקָל",
    },
    package_type: {
      menu_item: "סוג האריזה",
      title: "כל סוגי החבילות",
      name: "שֵׁם",
      id: "Id",
    },
    customer: {
      access_link: "קישור גישה",
      link: "קישור",
      time: "זְמַן",
      second: "שניות",
      generate_access_link: "צור קישור גישה",
      menu_item: "לקוחות",
      title: "לקוחות",
      id: "תְעוּדַת זֶהוּת",
      company_name: "שם חברה/עסק",
      taxId: "מספר זהות לצורך מס",
      contractor_number: "מספר קבלן",
      adress: "כתובת ברשת",
      city: "עִיר",
      email: "אימייל",
      firstName: "שם פרטי",
      lastName: "שם משפחה",
      main_contact: "קשר עיקרי",
      phone_number: "מספר טלפון",
      contactor_number: "מספר רשום",
      jobTitle: "הגדרת תפקיד",
      disable: "מופעל",
      candidates: "מועמדים",
      active: "פָּעִיל",
      inactive: "לֹא פָּעִיל",
      search: "חיפוש לפי שם החברה או מספר הטלפון",
      decline_candidate: "דחה מועמד",
      reason_for_decline: "סיבה לירידה",
      decline: "יְרִידָה",
      block: "חָסוּם",
      status: "סטָטוּס",
      info: "מידע",
      cerdit_payment: "תשלומי אשראי",
      available_credit: "אשראי זמין",
      credit_limit: "מסגרת אשראי",
      expiration: "תאריך פקיעת תקרת האשראי",
      extendid_payment: "תקופת תשלום מורחבת",
      outstanding_receivables: "יתרות חובות",
      pay_later: "קנה עכשיו שלם מאוחר יותר",
      date_placed: "תאריך מיקום",
      due_date: "תאריך להגשה",
      payment_term: "טווח תשלום",
      order_id: "מספר הזמנה",
      order_total_amount: "סכום כולל של הזמנה",
      amount: "כמות",
      comment: "תגובה",
      restore: "לשחזר",
      credit_limit_restore: "שחזור מסגרת אשראי",
      enable_credit_line: "הפעל קו אשראי",
      connect: "לְחַבֵּר",
      extended_payment_term: "תקופת תשלום מורחבת",
      expiration_date: "תאריך פקיעת תקרת האשראי",
      reason_for_block: "סיבה לחסימה",
      block_customer: "חסום לקוח",
      company: "חֶברָה",
      go_back: "חזרה ללקוחות",
      create_on: "נוצר ב%",
      company_description: "תיאור חברה",
      company_phone_number: "מספר טלפון של החברה",
    },
    customer_type: {
      menu_item: "סוג לקוח",
      title: "כל סוגי הלקוחות",
      name: "שֵׁם",
      id: "תְעוּדַת זֶהוּת",
    },
    manager: {
      firstName: "שם פרטי",
      lastName: "שם משפחה",
      jobTitle: "הגדרת תפקיד",
      contact_email: "אימייל ליצירת קשר",
      phone_number: "מספר טלפון",
      action: 'פעולה',
      title: "מנהלים",
      name: "שֵׁם",
      full_name: "שם מלא",
      email: "צור קשר עם דוא",
      job_title: "הגדרת תפקיד",
      phone: "מספר טלפון",
      phone_confirmed: "הטלפון אישר",
      created_at: "נוצר ב",
      active: "סטָטוּס",
      add_manager: "הוסף מנהל",
      actions: "פעולות",
      user_information: "מידע משתמש",
      first_name: "שם פרטי",
      last_name: "שם משפחה",
      reason_for_block: "סיבה לחסום",
      main_contact: "קשר עיקרי",
      user_main_contact: "המשתמש הוא איש הקשר העיקרי",
      edit_manager: "ערוך מנהל",
      block_manager: "מנהל בלוק",
    },
    project: {
      packing_slips: "תלושי אריזה",
      mark_packing_slip_verified: "סמן את תלוש האריזה מאומת",
      eldorado_packing_slip: 'תלוש אריזה של אלדורדו',
      confirmation_message: "משלוח דורש אישור. נא להעלות את תלוש האריזה להזמנה זו.",
      menu_item: "פרויקטים",
      title: "פרויקטים",
      project_name: "שם הפרוייקט",
      contact_phone: "טלפון ליצירת קשר",
      contact_full_name: "צור קשר בשם המלא",
      address: "כתובת",
      city: "עִיר",
      street: "רְחוֹב",
      district: "מָחוֹז",
      apt: "מַתְאִים",
      customer_id: "מספר לקוח"
    },
    orders: {
      packing_slip_verified: "שובר אריזה מאומת",
      total_with_difference: 'סך הכל עם הבדל',
      verify: "תאשר",
      invoice: '#חשבונית',
      invoice_link: "קישור לחשבונית",
      invoice_amount: 'סכום החשבונית',
      order_total: "סך ההזמנה (שולם על ידי הלקוח)",
      packing_slips_message:"משלוח דורש אישור. נא להעלות את תלוש האריזה להזמנה זו.",
      paid_order_cancel_message_start: "שימו לב שהזמנה זו כבר שולמה.",
      paid_order_cancel_message_end: "אל תשכח לבצע החזר עבור הזמנה זו.",
      due_date: "תאריך להגשה",
      bnpl: "קנה עכשיו שלם מאוחר יותר",
      card: "כרטיס חיוב או אשראי",
      mark_packing_slip_verified: "סמן את תלוש האריזה מאומת",
      eldorado_packing_slip: 'תלוש אריזה של אלדורדו',
      packing_slip: 'תלוש אריזה של',
      packing_slip_error_maxSize: 'Please use a max file size of 10 MB for uploads',
      no_project: "אין פרויקט שמור",
      menu_item: "הזמנות",
      title: "הזמנות",
      id: "מספר הזמנה",
      date_placed: "תאריך הניח",
      customer_manager: "מנהל לקוחות",
      status: "סטָטוּס",
      all_orders: "כל ההזמנות",
      new: "חָדָשׁ",
      in_progress: "בתהליך",
      in_review: "בסקירה",
      accepted: "מְקוּבָּל",
      declined: "סָרוּחַ",
      completed: "הוחזקה",
      delivered: "נמסר",
      canceled: "מבוטל",
      choose_suppliers: "בחר את הספקים",
      in_review_suppliers: "בסקירה של ספקים",
      in_review_customer: "בסקירה של הלקוח",
      order_price: "מחיר ההזמנה",
      project_city: "פרויקט סיטי",
      search: "חיפוש לפי מזהה הזמין או שם החברה",
      company_name: "שם החברה",
      info: "מידע",
      suppliers: "ספקים",
      invoices: "חשבוניות",
      packing_slips: "תלושי אריזה",
      order: "להזמין",
      placed_on: "נוצר ב",
      time_constraints: "מגבלות זמן",
      edit_notify: "שדות חובה של מגבלות זמן, פרויקט ומוצר",
      estimated_total: "סך הכל מוערך",
      delivery_time: "זמן אספקה ספציפי",
      project: "פּרוֹיֶקט",
      additional_services: "שירותים נוספים",
      comments: "הערות",
      unloading: "פְּרִיקָה",
      unloading_with_crane: "פריקה עם מנוף",
      order_filling: "מילוי סדר",
      go_back: "חזרה לרשימת הזמנות",
      more: "יותר",
      cancel_order: "בטל הזמנה",
      edit_order: "עריכת ההזמנה",
      delete_project: "מחק את הפרויקט",
      delete: 'לִמְחוֹק',
      sure_text: 'האם אתה בטוח שברצונך למחוק את הפרויקט הזה?',
      confirmation_text: "לאחר האישור לא ניתן לבטל פעולה זו",
      send_supplier: "שלח לספק (ים)",
      type_reason_placeholder: "הקלד את הסיבה לכאן",
      indicate_reason: "ציין את הסיבה לסגירת ההזמנה",
      delivery: "מְסִירָה",
      supplier_info: "מידע ספק",
      payment: "תַשְׁלוּם",
      search_by_catalog_number: "חפש לפי מספר קטלוגי",
      projects: {
        apt_unit: "דירה / יחידה",
        menu_item: "פרוייקטים",
        title: "פרוייקטים",
        project_name: "שם הפרוייקט",
        contact_phone: "צור קשר עם הטלפון",
        contact_full_name: "צור קשר עם שם מלא",
        address: "כתובת",
        city: "עִיר",
        street: "רְחוֹב",
        district: "מָחוֹז",
        apt: "מַתְאִים",
        customer_id: "מספר לקוח"
      },
      offers: {
        remove_offer_group: "הסר את המוצר מקבוצת המבצעים",
        group: 'קְבוּצָה',
        supplier_name: "שם ספק",
        offer_status: "הצעה מצב",
        rating: "דֵרוּג",
        delivery: "מְסִירָה",
        error_time: "נא להזין זמן מעבר לפעם הקודמת",
        new_projects: "פרויקטים חדשים",
        supplier_price: "מחיר הספק",
        kb_price: "מחיר Konebone",
        cancel_offer: "בטל הצעה",
        exclude_offer: "אל תכלול הצעה מהקבוצה",
        exclude_offer_text_start: "האם אתה בטוח שאתה רוצה לא לכלול",
        exclude_offer_text_end: "הצעה מהקבוצה?",
        confirm: "לְאַשֵׁר",
        sure_cancel_offer: "האם אתה בטוח שאתה רוצה לבטל את ההצעה הזאת עבור",
        sure_cancel_offer_group_start: "אתה בטוח שאתה רוצה להסיר",
        sure_cancel_offer_group_mid: "מ ה",
        sure_cancel_offer_group_end: "הַצָעָה?",
        sure_group_offer_start: "האם אתה בטוח שאתה רוצה לקבץ",
        sure_group_offer_end: " הצעות?",
        no_matches: "לא נמצאו התאמות בין המוצרים של ספקים אלו.",
        matches_products: "נמצאו ההתאמות בין מוצרי הספקים. הסר התאמות כדי להמשיך:",
        cancel_offer_message1: "ספק זה לא יוכל עוד להמשיך לעבוד על צו זה.",
        cancel_offer_message2: "זה יבוטל בשבילו.",
        group_offers: "הצעות קבוצתיות",
      },
    },
    offer: {
      delivery_time_not_specified: "אילוץ זמן",
      offer: "הַצָעָה",
      customer_project: "פרויקט לקוחות",
      additional_services: "שירותים נוספים",
      delivery: "מְסִירָה",
      supplier_info: "מידע על הספק",
      comments: "הערות",
      in_stock: "במלאי",
      out_stock: "החוצה במלאי",
      supplier_price: "מחיר הספק",
      discount: "הנחה",
      kb_price: "מחיר KB",
      kb_price_discount: "מחיר KB עם הנחה",
      gross_profit: "רווח ברוטו",
      delivery_price: "מחיר משלוח",
      total: "סה",
      time_constraints: "מגבלות זמן",
      available_at_supplier: "זמין בספק",
      go_back: "חזרה להצעות רשימה",
      go_back_edit: "חזרה לעריכת ההצעה",
      cancel_offer: "ביטול הצעה",
      edit_offer: "ערוך הצעה",
      new_product: "מוצר חדש",
      replacement: "תַחֲלִיף",
      customer_delivery_time: "זמן המסירה הצפוי של הלקוח",
      without_delivery: "בלי לשנות משלוח",
      specific_delivery_time: "זמן אספקה ספציפי",
      delivery_by_konebone: "משלוח על ידי Konebone.",
      select_date_time: "צריך לבחור תאריך איפוק ושעה",
      delivery_will_be_handled_by_konebone: "משלוח יטופל על ידי Konebone",
      by_konebone: "על ידי konebone.",
      by_supplier_with_delay: "על ידי ספק עם עיכוב",
      by_supplier_as_customer_expect: "על ידי הספק כצפוי",
      vehicle: "רכב",
      with_crane: "עם קריין",
      unloading: "פְּרִיקָה",
      alternative: "חֲלוּפָה",
      edit: "לַעֲרוֹך",
      delivery_contact_name: "משלוח שם איש קשר",
      delivery_contact_phone: "משלוח צור קשר",
      delivery_cost: "דמי משלוח",
      date: "תַאֲרִיך",
      time: "זְמַן",
      date_time: "המבצעים בתוקף עד התאריך והשעה",
      edit_type_of_vehicle: "ערוך את סוג הרכב",
      edit_suppliers_pickup_address: "ערוך את כתובת האיסוף של הספק",
      save: "להציל",
      pick_a_date: "תבחר תאריך",
      pick_a_time: "בחר זמן",
      choose_vehicle: "בחר רכב",
      need_to_specify_address: "צריך לציין כתובת",
      pickup_address: "כתובת איסוף",
      add_alternative_product: "הוסף מוצר חלופי",
      alternative_dialog_message: `השתמש בשורת החיפוש לפי מספר הקטלוג של Eldorado
      להוסיף מוצר חלופי במקום`,
      search_alternative: "חפש לפי מספר קטלוג הספק",
      product_not_in_catalog: "המוצר אינו מופיע בקטלוג?",
      city: "עִיר",
      apt: "מַתְאִים",
      street: "רְחוֹב",
      products_in_stock: "מוצרים במלאי",
      cancel: "לְבַטֵל",
      products_out_stock: "מוצרים במלאי",
      info_btn: "הלקוח אינו מוכן לשקול חלופה עבור הזמנה זו.",
      subtotal:"סכום משנה",
      vat: "מס ערך מוסף",
      without_vat: 'ללא מע"מ',
      sent_on: "sent on",
    },
    button: {
      submit: "לשלוח",
    },
  },
  error: {
    admin: {
      email_exists: "אלמנט עם אותו שם כבר קיים",
    },
    manufacturer: {
      name: {
        exists: "אלמנט עם אותו שם כבר קיים",
      },
      not_found_by_id: "היצרן לא נמצא על ידי %{arg_0} %{arg_1}",
      name_exists: "יצרן עם השם %{arg_0} כבר קיים!",
    },
    manufacturing_country: {
      name: {
        exists: "אלמנט עם אותו שם כבר קיים",
      },
      not_found_by_id: "מדינה ייצור לא נמצאה על ידי %{arg_0} %{arg_1}",
    },
    supplier: {
      with_the_same_name_exists: "אלמנט עם אותו שם כבר קיים",
      not_found_by_id: "הספק לא נמצא על ידי %{arg_0} %{arg_1}",
    },
    measurement: {
      with_the_same_title_exists: "אלמנט עם אותו שם כבר קיים",
      not_found_by_id: "המדידה לא נמצאה על ידי %{arg_1} %{arg_0}.",
    },
    package: {
      with_the_same_fields_exists: "אלמנט עם אותו שם כבר קיים",
      not_found_by_id: "החבילה לא נמצאה על ידי %{arg_1} %{arg_0}.",
    },
    package_type: {
      with_the_same_title_exists: "אלמנט עם אותו שם כבר קיים",
      not_found_by_id: "סוג החבילה לא נמצא על ידי %{arg_0} %{arg_1}",
    },
    email: {
      send: 'שגיאת שליחת דוא"ל לכתובת %{arg_0} עם ההודעה: %{arg_1}',
    },
    pageable: {
      page_should_be_more_then_zero: "הדף צריך להיות יותר מ- 0",
    },
    product: {
      not_found_by_id: "המוצר לא נמצא על ידי %{arg_0} %{arg_1}",
      name_exists: "אלמנט עם אותו שם כבר קיים",
      picture: {
        not_found_by_id: "המוצר לא נמצא על ידי %{arg_0} %{arg_1}",
      },
      picture_name_exists: "תמונה עם אותו שם כבר קיימת",
    },
    manufacturing: {
      country_name_exists:
        "מדינה ייצור עם אותו שם כבר קיים!",
    },
    product_price: {
      with_the_same_product_supplier_id_exists: "מחיר מוצר אצל אותו ספק קיים",
    },
    product_supplier: {
      with_the_same: {
        supplier_pn_and_product_package_id_and_supplier_id_exists:
        "מספר קטלוג הספק או שם הספק חייבים להיות ייחודיים עבור שורת מחיר מוצר זו.",
      }
    },
    while_file_resizing: "שגיאה בעת שינוי גודל הקובץ",
    parameter: {
      should_be_more_then_zero: "הפרמטר צריך להיות יותר אז אפס"
    },
    category: {
      has_child_categories: "יש קטגוריות ילדים",
      has_products: "יש מוצרים",
      count_limit: "חרגת מכמות מגבלת הקטגוריה. המספר המרבי של קטגוריות שווה ל-1000",
    },
    order: {
      not_found_by_id: "ההזמנה לא נמצאה על ידי% {arg_0}.",
      already_cancel: "ההזמנה כבר בוטלו",
      cancel_not_allowed: "ביטול לא מותר",
    },
    offer: {
      incorrect_count: "מספר ספקים נבחר גדול מ-3. שנה את הבחירה כדי להמשיך.",
      not_found_by_id: "ההצעה לא נמצאה על ידי %{arg_0}.",
      cannot_send_order_already_in_progress: "לא ניתן לשלוח הזמנה כי היא כבר בעיצומה",
      cancel_not_allowed: "ביטול לא מותר",
      does_not_contains_products: "The offer must consist of one or more in-stock products",
    },
  },
  confirm_messages: {
    products: {
      back: "האם אתה באמת רוצה לצאת? כל המידע שהוזן לא יישמר",
      save: "המוצר יוצג בקטלוג מיד לאחר השמירה. לְהַמשִׁיך?",
      next_step: "יש לך שינויים שלא נשמרו. האם אתה רוצה לעזוב את הדף מבלי לשמור אותם?",
      activate:
        "האם אתה באמת רוצה להפעיל את המוצר%{arg}? המוצר יוצג בקטלוג באופן מיידי.",
      deactivate:
        "האם אתה באמת רוצה להשבית את המוצר% {arg}? לקוחות לא יוכלו להזמין את המוצר.",
    },
    price_lines: "האם אתה באמת רוצה לצאת? כל המידע שהוזן לא יישמר",
    customer: {
      delete: "האם אתה באמת רוצה למחוק את המועמד?",
      unblock: "האם אתה באמת רוצה לבטל חסימה% {arg_0} הלקוח?",
      approval: "האם אתה באמת רוצה לאשר את המועמד?",
    },
    supplier: {
      delete: "האם אתה באמת רוצה למחוק את המועמד?",
      unblock: "האם אתה באמת רוצה לבטל חסימה% {arg_0} הספק?",
      approval: "האם אתה באמת רוצה לאשר את המועמד?",
    },
    manager: {
      unblock: "האם אתה באמת רוצה לבטל חסימה% {arg_0} מנהל?",
    },
  },
  package: {
    was_deleted_successfully: "אתה מוחק בהצלחה את החבילה.",
    type: {
      was_deleted_successfully: "מחקת בהצלחה את סוג החבילה.",
    },
  },
  log: {
    google: {
      drive: {
        error_init: "לא ניתן לאתחל שירות לעבודה עם כונן Google.",
        error: {
          resource_not_found: "המשאב לא נמצא.",
          not_folder: "קובץ עם id = %{arg_0} אינו תיקייה.",
          create: "לא ניתן ליצור באמצעות API, סוג לא נתמך %{arg_0}",
          folder_content:
            "לא יכול לקרוא את תוכן התיקיה, מזהה התיקיה = %{arg_0}",
          file_read: "לא יכול לקרוא קבצים.",
          file_create: "לא ניתן ליצור קובץ %{arg_0}",
          file_incorrect_type: "סוג קובץ שגוי %{arg_0}",
          file_incorrect_name: "אנא, ספק שם קובץ לא ריק.",
          file_delete: "לא ניתן למחוק קובץ עם id = %{arg_0}",
          file_update: "לא ניתן לעדכן את שם הקובץ עם id = %{arg_0}",
          file_upload: "לא ניתן להעלות את %{arg_0}",
          file_move: "לא ניתן להעביר את שם הקובץ עם id = %{arg_0}",
          file_download: "לא ניתן להוריד קובץ עם id = %{arg_0}",
          file_download_not_found:
            "לא ניתן להוריד קובץ עם id = %{arg_0}, לא נמצא.",
          folder_download: "לא ניתן להוריד תיקיה עם id = %{arg_0}",
          file_get: "לא ניתן לקבל קובץ עם id = %{arg_0}",
          tmp: {
            io: "אירעה שגיאת IO בעת ניסיון ליצור עותק מקומי זמני ב- %{arg_0}",
          },
          tmp_copy_create: "לא ניתן ליצור עותק זמני של %{arg_0}",
          tmp_copy_delete: "לא ניתן למחוק עותק זמני של %{arg_0}",
          error_tmp_create: "לא ניתן ליצור קובץ זמני.",
        },
      },
    },
  },
  auth: {
    service: {
      logout: "אתה מתנתק בהצלחה.",
      change_password: "שינית את הסיסמה שלך בהצלחה.",
      send_new_password: 'סיסמה חדשה נשלחה לדוא"ל שלך.',
      send_confirmation_code: "קוד אישור נשלח.",
      check_confirmation_code: "קוד אישור אומת.",
      login_exists: "התחברות %{arg_0} קיימת.",
      login_does_not_exist: "הכניסה %{arg_0} אינה קיימת.",
    },
  },
  bean: {
    validation: {
      fail: "האימות נכשל.",
    },
  },
  exception: {
    admin: {
      not_found_by_id: "מנהל מערכת עם מזהה %{arg_0} לא נמצא",
    },
    category: {
      not_found_by_id: "קטגוריה עם מזהה %{arg_0} לא נמצאה",
      wrong_position: "בקטגוריה עם מזהה האב יש מיקום שגוי",
      root_must_be_unique: "יכולה להיות רק קטגוריית שורש אחת.",
      not_found_by_parent_id: "קטגוריית אב לקטגוריה עם מזהה %{arg_0} לא נמצאה",
      already_exist: "קטגוריה עם שם כבר קיימת",
      not_deletable: "קטגוריה %{arg_1}",
      parent_relationship: "קטגוריה עם מזהה לא יכול להיות ההורה עצמו"
    },
    user: {
      not_found_by_id: "מנהל מערכת עם מזהה %{arg_0} לא נמצא.",
      not_found_by_email: 'מנהל המערכת עם הדוא"ל %{arg_0} לא נמצא.',
      not_found_by_login: "מנהל מערכת עם כניסה %{arg_0} לא נמצא.",
    },
    order: {
      not_found_by_id: "ההזמנה עם מזהה %{arg_0} לא נמצאה.",
    },
    api_not_found: "פקודת Api לא נמצאה",
    manager_not_found: "מנהל לא נמצא",
    supplier_candidate_not_found: "מועמד הספק לא נמצא",
    customer_not_found: "הלקוח לא נמצא",
    role_was_not_found: "התפקיד לא נמצא עם %{arg_0} = %{arg_1}!",
    credit_not_enough: 'Insufficent funds on the balance of the available credit limit',
    repository: {
      empty_result_data_access: "מספר קטלוגי לא חוקי",
      integrity_violation:
        "רכיב מוגדר עבור מוצרים מסוימים ולא ניתן למחוק אותו",
      invalid_data_access_api_usage:
        "שימוש לא חוקי ב- API לגישה לנתונים",
        duplicate_key: "ודא שלכל מוצר יש רק שורת מחיר אחת.",

    },
    empty_required_fields: "אנא מלא את השדה שם עסק/חברה לפני אישור המועמד"
  },
  s3: {
    uploaded: "קובץ %{arg_0} מועלה עם מפתח %{arg_1}.",
    upload_failed: "העלאה נכשלה. קובץ %{arg_0}, מפתח %{arg_1}.",
    downloaded: "קובץ עם מפתח %{arg_0} הורד בהצלחה.",
    download_failed: "הורדה נכשלה. מפתח %{arg_0}.",
    file_deleted: "הקובץ %{arg_0} נמחק.",
    delete_failed: "המחיקה נכשלה. מפתח %{arg_0}.",
  },
  product: {
    was_deleted_successfully: "מחקת בהצלחה את המוצר.",
    picture: {
      was_deleted_successfully: "אתה מוחק את המוצר בהצלחה.",
    },
  },
  manufacturer: {
    was_deleted_successfully: "מחקת את היצרן בהצלחה.",
    country: {
      was_deleted_successfully: "מחקת בהצלחה את ארץ הייצור.",
    },
  },
  measurement: {
    was_deleted_successfully: "אתה מוחק בהצלחה מדידה.",
  },
  supplier: {
    was_deleted_successfully: "אתה מוחק בהצלחה את הספק.",
  },
  repository: {
    general: "exception.repository.general",
    cannot_acquire_lock: "exception.repository.cannot_acquire_lock",
    cannot_serialize_transaction:
      "exception.repository.cannot_serialize_transaction",
    deadlock_loser_data_access:
      "exception.repository.deadlock_loser_data_access",
    cleanup_failure_data_access:
      "exception.repository.cleanup_failure_data_access",
    data_access_resource_failure:
      "exception.repository.data_access_resource_failure",
    duplicate_key: "exception.repository.duplicate_key",
    integrity_violation: "exception.repository.integrity_violation",
    empty_result_data_access: "exception.repository.empty_result_data_access",
    incorrect_result_size_data_access:
      "exception.repository.incorrect_result_size_data_access",
    incorrect_update_semantics_data_access:
      "exception.repository.incorrect_update_semantics_data_access",
    invalid_data_access_api_usage:
      "exception.repository.invalid_data_access_api_usage",
    non_transient_data_access_resource:
      "exception.repository.non_transient_data_access_resource",
    optimistic_locking_failure:
      "exception.repository.optimistic_locking_failure",
    permission_denied_data_access:
      "exception.repository.permission_denied_data_access",
    pessimistic_locking_failure:
      "exception.repository.pessimistic_locking_failure",
    query_timeout: "exception.repository.query_timeout",
    recoverable_data_access: "exception.repository.recoverable_data_access",
    transient_data_access_resource:
      "exception.repository.transient_data_access_resource",
    type_mismatch_data_access: "exception.repository.type_mismatch_data_access",
    uncategorized_data_access: "exception.repository.uncategorized_data_access",
    invalid_data_access_resource_usage:
      "exception.repository.invalid_data_access_resource_usage",
    data_retrieval_failure: "exception.repository.data_retrieval_failure",
    concurrency_failure: "exception.repository.concurrency_failure",
    non_transient_data_access: "exception.repository.non_transient_data_access",
    transient_data_access: "exception.repository.transient_data_access",
  },
  customer: {
    not: {
      found: "לקוח עם מזהה %{arg_0} לא נמצא.",
    },
  },
  customer_type: {
    not: {
      found: "סוג הלקוח עם מזהה %{arg_0} לא נמצא.",
    },
  },
  upload: {
    read_csv_error: "קריאת שגיאת CSV עם הודעת יוצא מן הכלל %{arg_0}",
    read: {
      csv: {
        not_null_value_error:
          "הערכים של %{arg_1} ברשומה %{arg_0} חייבים להיות נוכחים",
        not_parsable_value_error:
          "הערכים של %{arg_1} ברשומה %{arg_0} חייבים להכיל %{arg_2} פורמטים תקפים",
        required_header_name_error:
          "כותרות קבצי scv אינן מכילות כותרות נדרשות %{arg_0}",
        input_valid_price_date_error:
          "ערך אחד או יותר של %{arg_0} חייבים להיות ערכים תקפים",
        product_package_relation_error: "למוצר בשם %{arg_1} יש כבר חבילה מסוג %{arg_0}",
      },
    },
    image: {
      get_image_from_url_failed: "שגיאה בעת קבלת התמונה מכתובת האתר"
    },
  },
};

export default hebrewMessages;
