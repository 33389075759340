import React, { useState, useEffect } from "react";
import {
  List,
  TextField,
  useTranslate,
  TopToolbar,
  CreateButton,
  sanitizeListRestProps,
  useNotify,
  DateField,
  Pagination as RaPagination,
  PaginationActions as RaPaginationActions,
  Confirm,
  useListContext,
  downloadCSV,
  ExportButton,
  Loading,
} from "react-admin";
import { Form } from 'react-final-form';
import { useAuthState, useRefresh, linkToRecord } from "ra-core";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import ReplayIcon from "@material-ui/icons/Replay";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Button, Box, InputAdornment, TextField as SearchField, Switch, FormGroup, FormControlLabel } from "@material-ui/core";
import DatagridResponsive from "../../components/DataGridResponsive";
import { Link, useLocation } from "react-router-dom";
import dataProviderForProducts from "../../providers/DataProviderForProducts";
import { wrapArrayToMap } from "../../utils/Wrapper";
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import axios from "axios";
import { handleFailure } from "../../utils/handleFailure";
import EditButtonSaveSearch from "../../components/EditButonSaveSearch";


const useStyles = makeStyles((theme) => ({
  toolbar: {
    alignItems: "flex-start",
    justifyContent: "space-between",
    width: "100%",
    height: "80px",
  },
  root: {
    alignItems: "stretch",
    "& > *": {
      marginLeft: theme.spacing(1),
      height: "40px",
    },
  },
  listRoot: {
    "& > div:first-child": {
      display: "flex",
      justifyContent: "space-between",
    },
    "& > div.empty_box": {
      display: "block",
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    "& form": {
      width: "100%",
      flexWrap: "nowrap",
      justifyContent: "space-between",
    },
    "& form > div": {
      margin: "0",
    },
    "& form > div:first-child": {
      alignItems: "flex-start",
      width: "100%",
    },
    "& form > div:nth-child(2)": {
      width: "30%",
    },
  },
  filterButton: {
    "& > button": {
      color: theme.palette.secondary.main,
    },
  },
  textField: {
    margin: 0,
    marginRight: "30px",
    width: "100%",
    "& > div": {
      maxHeight: "40px",
      width: "100%",
    },
    "& > label": {
      fontSize: "14px",
    },
  },
  stickyColumn: {
    "& > th:first-child": {
      position: "sticky",
      width: "20%",
      minWidth: "100px",
    },
    "& > td:first-child": {
      position: "sticky",
      width: "20%",
      minWidth: "100px",
    },
  },
}));

const ListActions = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter, // you can hide ExportButton if exporter = (null || false)
  filterValues,
  permanentFilter,
  hasCreate, // you can hide CreateButton if hasCreate = false
  basePath,
  showFilter,
  maxResults,
  total,
  ...rest
}) => {
  const translate = useTranslate();
  const classes = useStyles();
  const { loading, authenticated } = useAuthState();

  if (!authenticated || loading) {
    return null;
  }

  return (
    <TopToolbar
      className={classnames(className, classes.toolbar)}
      {...sanitizeListRestProps(rest)}
    >
      <ProductsFilter />
      <Box display="flex" className={classes.root}>
        <CreateButton
          icon={<></>}
          variant="contained"
          color="secondary"
          basePath="/products"
          label="konebone.products.add_product"
        />
        <Button
          to="/upload"
          component={Link}
          label={translate("konebone.products.import")}
          endIcon={<ImportExportIcon />}
          variant="outlined"
          color="secondary"
        >
          {translate("konebone.products.import")}
        </Button>
        <ExportButton variant="outlined" color="secondary">
          {translate("konebone.products.export")}
        </ExportButton>

      </Box>
    </TopToolbar>
  );
};

const ProductsFilter = (props) => {
  const translate = useTranslate();
  const classes = useStyles();
  const { search } = useLocation();
  const {
    // setSort,
    setFilters,
    filterValues,
  } = useListContext();
  const [searchValue, setSearchValue] = useState(filterValues.q || '');
  const [deactivate, setDeactivate] = useState(filterValues.deactivate || false);

  useEffect(() => {
    if (!search) {
      setSearchValue('');
      setDeactivate(false);
      setFilters({ q: '', deactivate: '' });
    }
  }, [search])

  const handleChange = (event) => {
    if (!event || !event.target.value) {
      setSearchValue('');
      setFilters({ q: '', deactivate });
      return;
    }

    setSearchValue(event.target.value);
  };

  const handleToggle = (value) => {
    setDeactivate(!deactivate)
    setFilters({ q: filterValues.q, deactivate: !deactivate });
  };

  const onSubmit = () => {
    setFilters({ q: searchValue, deactivate: filterValues.deactivate });
  };

  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Box display="flex" alignItems="flex-end" mb={1}>
            <SearchField
              id="name"
              label={translate("konebone.products.search")}
              color="secondary"
              variant="outlined"
              className={classes.textField}
              value={searchValue}
              onChange={handleChange}
              helperText={false}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    {/* <SearchIcon color="disabled" /> */}
                    {!searchValue ?
                      <SearchIcon color="disabled" /> : (
                        <IconButton
                          size="small"
                          onClick={() => handleChange()}
                        >
                          <ClearIcon />
                        </IconButton>)
                    }
                  </InputAdornment>
                )
              }}
            />
            <FormGroup row>
              <FormControlLabel
                label={translate("konebone.products.show_deactivated")}
                control={
                  <Switch
                    color="secondary"
                    variant="standard"
                    checked={deactivate}
                    onChange={handleToggle}
                    alwaysOn />}
              />
            </FormGroup>
          </Box>
        </form>
      )}
    </Form>

  );
};

const ProductsListTitle = () => {
  const translate = useTranslate();
  return <span>{translate("konebone.products.title").toUpperCase()}</span>;
};

const ProductDeactivateButton = ({ record }) => {
  const [open, setOpen] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const translate = useTranslate();

  const handleClick = (e) => {
    e.stopPropagation();
    setOpen(true);
  }
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = () => {
    setOpen(false);
    updateStatus();
  };

  const updateStatus = async (e) => {
    try {
      await dataProviderForProducts.updateStatus("products", {
        data: {
          status: !record?.active,
          productName: `${record.koneboneProductName}`,
        },
      });
      refresh();
    } catch (error) {
      notify(
        translate(
          error.response?.data.message.message,
          "warning",
          wrapArrayToMap(error.response.data.message.parameters)
        )
      );
    }
  };

  if (!record) {
    return <></>;
  }

  return (
    <div className="listButton">
      {record.active ? (
        <IconButton
          variant="outline"
          size="small"
          icon={<ReplayIcon />}
          onClick={handleClick}
        >
          <HighlightOffIcon />
        </IconButton>
      ) : (
        <IconButton
          variant="outline"
          size="small"
          icon={<ReplayIcon />}
          onClick={handleClick}
        >
          <ReplayIcon />
        </IconButton>
      )}
      <Confirm
        isOpen={open}
        title=""
        content={
          record.active
            ? translate("confirm_messages.products.deactivate", {
              arg: record.koneboneProductName,
            })
            : translate("confirm_messages.products.activate", {
              arg: record.koneboneProductName,
            })
        }
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </div>
  );
};

const ProductEditButton = ({ record }) => {
  return (
    <div className="listButton">
      <EditButtonSaveSearch basePath="/products" label={""} record={record} />
    </div>
  );
};

const PaginationActions = (props) => (
  <RaPaginationActions {...props} color="secondary" />
);

const ProductsPagination = (props) => (
  <RaPagination ActionsComponent={PaginationActions} {...props} />
);

// const Empty = () => {
//   const translate = useTranslate();

//   return (
//     <Box textAlign="center" m={1} className="empty_box">
//       <Typography variant="h4" paragraph>
//         No products available
//       </Typography>
//       <Typography variant="body1">Create one or import from a file</Typography>
//       <CreateButton
//         icon={<></>}
//         variant="contained"
//         color="secondary"
//         basePath="/products/create"
//         label="konebone.products.add_product"
//       />
//       <Button
//         to="/upload"
//         component={Link}
//         label={translate("konebone.products.import")}
//         endIcon={<ImportExportIcon />}
//         variant="outlined"
//         color="secondary"
//       >
//         {translate("konebone.products.import")}
//       </Button>
//     </Box>
//   );
// };

export const ProductsList = (props) => {
  const [load, setLoad] = useState(false);
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();
  const { search } = useLocation();

  const { loading, authenticated } = useAuthState();

  if (!authenticated || loading) {
    return null;
  }

  const postRowClick = (id, resource, record) => {
    localStorage.setItem('listSearch', search);
    return linkToRecord(id, resource);
  };

  const exporter = async () => {
    setLoad(true);
    axios
      .get(process.env.REACT_APP_API_URL + '/export/catalog', {
        withCredentials: true,
        headers: { accept: "application/octet-stream" },
      })
      .then((response) => {
        let products = response.data
        downloadCSV(products, 'catalog'); // download as 'posts.csv` file
        setLoad(false);
      })
      .catch((error) => {
        setLoad(false);
        handleFailure(error, notify)
      })
  };

  if (load) {
    return <Loading />;
  }


  return (
    <List
      {...props}
      title={<ProductsListTitle />}
      actions={<ListActions />}
      pagination={<ProductsPagination />}
      bulkActionButtons={false}
      empty={<ListActions />}
      variant="scrollable"
      className={classes.listRoot}
      exporter={exporter}
    >
      <DatagridResponsive rowClick={postRowClick} className={"sticky-first-column"}>
        <TextField
          source="catalogNumber"
          label={translate("konebone.products.catalog.catalog_number")}
        />
        <TextField
          source="koneboneProductName"
          label={translate("konebone.products.catalog.konebone_product_name")}
        />
        <TextField
          source="typeOfMeasurement"
          label={translate("konebone.products.catalog.price_for")}
          sortable={false}
        />
        <TextField
          source="quantityOnPallet"
          label={translate("konebone.products.catalog.quantity_on_pallet")}
          sortable={false}
        />
        <TextField
          source="supplierName"
          label={translate("konebone.products.catalog.suppliers")}
          sortable={false}
        />
        <TextField
          source="kbPrice"
          label={translate("konebone.products.catalog.kb_price")}
          sortable={false}
        />
        <TextField
          source="manufacturerName"
          label={translate("konebone.products.catalog.manufacturer_name")}
        />
        <DateField
          source="lastUpdateDate"
          label={translate("konebone.products.catalog.last_update_date")}
        />
        <ProductEditButton />
        <ProductDeactivateButton />
      </DatagridResponsive>
    </List>
  );
};
