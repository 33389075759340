import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useAuthProvider, useNotify, useTranslate } from 'ra-core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Notification } from "react-admin";
import Validator from "../utils/Validator";
import { Link, useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: "center"
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  forgotLink: {
    justifyContent:"center" 
  }
}));

const ForgotPassword = ({ theme }) => {
  const authProvider = useAuthProvider();
  const classes = useStyles();
  const translate = useTranslate();
  const history = useHistory();
  const notify = useNotify();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [submitDone, setSubmitDone] = useState(false);

  const submit = e => {
    e.preventDefault();
    setSubmitDone(true);

    if (emailError.length > 0) {
      return;
    }
  };

  const routeChange = () => {
    if(emailError){
      return
    }
    authProvider.resetPassword({ 'login': email})
    .then((e)=> {
      history.push({
        pathname: `/recover`,
        state: {email}
    });
    })
    .catch((e) => {
      notify('konebone.sign_in.reset_error')});
  }

  const updateEmail = (email) => {
    setEmail(email);
    if (Validator.validateEmailSignIn(email)) {
      setEmailError(translate('ra.validation.email'));
    } else {
      setEmailError("");
    }
  }

  return (
    <MuiThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {translate('ra.auth.can_not_login')}
          </Typography>
          {" "}
          <Typography component="h4" className={classes.text}>
            {translate('ra.auth.forgot_text')}
          </Typography>
          <form className={classes.form} noValidate onSubmit={submit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="login"
              label={translate('konebone.sign_in.email')}
              name="login"
              value={email}
              onChange={e => updateEmail(e.target.value)}
              error={submitDone && emailError.length > 0}
              helperText={submitDone ? emailError : ""}
              type="email"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={routeChange}
            >
              {translate('ra.auth.reset_password')}
            </Button>
            <Grid container className={classes.forgotLink}>
              <Grid item >
              <Link variant="body2" to="/login">
               {translate("ra.auth.back_sign_in")} 
              </Link>
            </Grid>
          </Grid>
          </form>
        </div>
      </Container>
      <Notification />
    </MuiThemeProvider>
  );
}

export default ForgotPassword;
