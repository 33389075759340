import React, { useState, useEffect, useRef, useContext } from "react";
import keyBy from "lodash/keyBy";
import {
  useTranslate,
  TopToolbar,
  BooleanField,
  Pagination,
  useNotify,
  Loading,
  TextField,
  ListContextProvider,
  DataProviderContext,
  DeleteButton,
} from "react-admin";
import classnames from "classnames";
import {
  Button,
  TableRow,
  TableCell,
  IconButton,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DatagridResponsive from "../../components/DataGridResponsive";
import CreatePriceLineDialog from "./CreatePriceLineDialog";
import EditIcon from "@material-ui/icons/Edit";
import { wrapArrayToMap } from "../../utils/Wrapper";

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "flex-start",
    "& > *": {
      margin: theme.spacing(1.5),
    },
  },
  tableRoot: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  editButton: {
    height: "28px",
    width: "28px",
    "&:hover": {
      borderRadius: "3px",
      backgroundColor: "rgba(63, 81, 181, 0.04)",
    },
  },
  deleteButton: {
    height: "28px",
    width: "28px",
    "&:hover": {
      borderRadius: "3px",
      backgroundColor: "rgba(181, 63, 63, 0.04)",
    },
  },
  capitalize: {
    textTransform: "capitalize",
  },
}));

const ListActions = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter, // you can hide ExportButton if exporter = (null || false)
  filterValues,
  permanentFilter,
  hasCreate, // you can hide CreateButton if hasCreate = false
  basePath,
  showFilter,
  maxResults,
  total,
  openAddPriceLineDialog,
  ...rest
}) => {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <TopToolbar className={classnames(className, classes.root)}>
      <Typography variant="h6">
        {`${translate("konebone.products.price_lines").toUpperCase()} `}
      </Typography>
      <Button
        label={translate("konebone.products.add_new_price_line")}
        variant="outlined"
        color="secondary"
        onClick={(e) => {
          openAddPriceLineDialog();
        }}
      >
        {translate("konebone.products.add_new_price_line")}
      </Button>
    </TopToolbar>
  );
};

const PriceLineEditButton = ({ record, openAddPriceLineDialog }) => {
  const classes = useStyles();

  return (
    <div className="listButton">
      <IconButton
        record={record}
        color="primary"
        onClick={(e) => openAddPriceLineDialog(record)}
        size="small"
        className={classes.editButton}
      >
        <EditIcon />
      </IconButton>
    </div>
  );
};

const PriceLineDeleteButton = ({ record, closeAddPriceLineDialog }) => {
  const notify = useNotify();

  const handleFailure = (error) => {
    if (!error.response) {
      console.error(error);
      return;
    }
    let { status } = error.response;
    if (status === 401 || status === 403) {
      cleanSession();
      window.location.href = "/login";
    }
    if (status === 500) {
      notify(
        error.response.data?.error,
        "warning"
      )
      return;
    }
    notify(
      error.response.data?.message?.message,
      "warning",
      wrapArrayToMap(error.response.data?.message?.parameters)
    );
  };

  const onSuccess = () => {
    notify("ra.notification.deleted", "success");
    closeAddPriceLineDialog(true);
  };

  return (
    <div className="listButton">
      <DeleteButton
        record={record}
        mutationMode="pessimistic"
        resource="price_lines"
        onFailure={handleFailure}
        label={""}
        record={record}
        onSuccess={onSuccess}
      />
    </div>
  );
};

const PostShow = ({ record, children, basePath, resource }) => {
  const newChildren = children.slice(0, -2);

  return record.nonActualPriceLines.map((row, i) => (
    <TableRow key={`${record.id}-${i}`}>
      <TableCell>{ }</TableCell>
      {React.Children.map(newChildren, (field) => (
        <TableCell key={`${record.id}-${field.props.source}`}>
          {React.cloneElement(field, {
            record: row,
            basePath,
            resource,
          })}
        </TableCell>
      ))}
      <TableCell>{ }</TableCell>
      <TableCell>{ }</TableCell>
    </TableRow>
  ));
};

const ProductsPriceLinesList = ({ record: { productPackageId }, initData }) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [sort, setSort] = useState({ field: "id", order: "ASC" });
  const [openDialog, setOpenDialog] = useState(!!initData);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);

  const dataProvider = useContext(DataProviderContext);

  const translate = useTranslate();
  const notify = useNotify();

  const dialogRecord = useRef(null);

  useEffect(() => {
    if (!productPackageId) {
      return;
    }
    dataProvider
      .getList("price_lines", {
        pagination: { page, perPage },
        sort,
        filter: { productPackageId: productPackageId },
      })
      .then(({ data, total }) => {
        setData(data);
        setTotal(total);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (!error.response) {
          console.error(error);
          return;
        }
        let { status } = error.response;
        if (status === 401 || status === 403) {
          cleanSession();
          window.location.href = "/login";
        }
        if (status === 500) {
          notify(
            error.response.data?.error,
            "warning"
          )
          return;
        }
        notify(
          error.response.data?.message?.message,
          "warning",
          wrapArrayToMap(error.response.data?.message?.parameters)
        );
      });
  }, []);

  const openAddPriceLineDialog = (record) => {
    setOpenDialog(true);
    dialogRecord.current = record;
  };

  const closeAddPriceLineDialog = (refresh) => {
    if (refresh) {
      dataProvider
        .getList("price_lines", {
          pagination: { page, perPage },
          sort,
          filter: { productPackageId: productPackageId },
        })
        .then(({ data }) => {
          setData(data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          if (!error.response) {
            console.error(error);
            return;
          }
          let { status } = error.response;
          if (status === 401 || status === 403) {
            cleanSession();
            window.location.href = "/login";
          }
          if (status === 500) {
            notify(
              error.response.data?.error,
              "warning"
            )
            return;
          }
          notify(
            error.response.data?.message?.message,
            "warning",
            wrapArrayToMap(error.response.data?.message?.parameters)
          );
        });
    }

    setOpenDialog(false);
    dialogRecord.current = null;
  };

  if (!data || !productPackageId) return null;

  if (loading) {
    return <Loading />;
  }

  return (
    <div style={{ display: "block", width: "100%", maxWidth: "100%" }}>
      <ListContextProvider
        value={{
          total,
          page,
          perPage,
          setPage,
          setSort,
          currentSort: { field: "id", order: "ASC" },
          resource: "price_lines",
          selectedIds: [],
        }}
      >
        <ListActions openAddPriceLineDialog={openAddPriceLineDialog} />
        <DatagridResponsive
          data={keyBy(data, "id")}
          ids={data.map(({ id }) => id)}
          expand={<PostShow />}
        >
          <BooleanField
            source="inStock"
            label={translate("konebone.products.price_lines_list.in_stock")}
            sortable={false}
          />
          <TextField
            source="supplierName"
            label={translate(
              "konebone.products.price_lines_list.supplier_name"
            )}
          />
          <TextField
            source="supplierPn"
            label={translate(
              "konebone.products.price_lines_list.supplier_part_number"
            )}
            sortable={false}
          />
          <TextField
            source="supplierProductName"
            label={translate(
              "konebone.products.price_lines_list.supplier_product_name"
            )}
            sortable={false}
          />
          <TextField
            source="supplierPrice"
            label={translate(
              "konebone.products.price_lines_list.supplier_price"
            )}
            sortable={false}
          />
          <TextField
            source="kbPrice"
            label={translate("konebone.products.price_lines_list.kb_price")}
            sortable={false}
          />
          {/* <DateField
            source="validFromDate"
            label={translate(
              "konebone.products.price_lines_list.valid_from"
            ).toUpperCase()}
            sortable={false}
          />
          <DateField
            source="validToDate"
            label={translate(
              "konebone.products.price_lines_list.valid_to"
            ).toUpperCase()}
            sortable={false}
          /> */}
          <TextField
            source="quantityOnPallet"
            label={translate(
              "konebone.products.price_lines_list.quantity_on_pallet"
            )}
            sortable={false}
          />
          <TextField
            source="minOrderQuantity"
            label={translate(
              "konebone.products.price_lines_list.min_order_quantity"
            )}
            sortable={false}
          />
          <PriceLineEditButton
            openAddPriceLineDialog={openAddPriceLineDialog}
          />
          <PriceLineDeleteButton closeAddPriceLineDialog={closeAddPriceLineDialog} />
        </DatagridResponsive>
        <Pagination
          page={page}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
          total={total}
        />
      </ListContextProvider>
      {openDialog && (
        <CreatePriceLineDialog
          title={
            dialogRecord.current
              ? translate("konebone.products.edit_price_line")
              : translate("konebone.products.add_new_price_line")
          }
          open={openDialog}
          handleClose={closeAddPriceLineDialog}
          record={dialogRecord.current}
          productPackageId={productPackageId}
          initData={!dialogRecord.current ? initData : {}}
        />
      )}
    </div>
  );
};

export default ProductsPriceLinesList;
