
import * as React from 'react';
import { Children, isValidElement, useCallback } from 'react';
import {
  useListContext,
  useResourceContext,
} from 'ra-core';
import { Checkbox, TableCell, TableHead, TableRow } from '@material-ui/core';
import classnames from 'classnames';
import { DatagridHeaderCell, useDatagridStyles } from 'react-admin';

/**
 * The default Datagrid Header component.
 *
 * Renders select all checkbox as well as column header buttons used for sorting.
 */
export const DatagridHeader = (props) => {
  const {
    children,
    className,
    hasExpand = false,
    hasBulkActions = false,
    isRowSelectable,
    disableSelectAll,
  } = props;
  const classes = useDatagridStyles(props);
  const resource = useResourceContext(props);
  const {
    currentSort,
    data,
    ids,
    setSort,
    onSelect,
    selectedIds,
  } = useListContext(props);

  const updateSortCallback = useCallback(
    event => {
      event?.stopPropagation();
      const newField = event?.currentTarget?.dataset?.field;
      const newOrder =
       currentSort && currentSort?.field === newField
          ? currentSort?.order === 'ASC'
            ? 'DESC'
            : 'ASC'
          : event?.currentTarget?.dataset?.order;

      setSort(newField, newOrder);
    },
    [currentSort?.field, currentSort?.order, setSort]
  );

  const updateSort = setSort ? updateSortCallback : null;

  const handleSelectAll = useCallback(
    event => {
      if (event.target.checked) {
        const all = ids.concat(
          selectedIds.filter(id => !ids.includes(id))
        );
        onSelect(
          isRowSelectable
            ? all.filter(id => isRowSelectable(data[id]))
            : all, data
        );
      } else {
        onSelect([]);
      }
    },
    [data, ids, onSelect, isRowSelectable, selectedIds]
  );

  const selectableIds = isRowSelectable
    ? ids?.filter(id => isRowSelectable(data[id]))
    : ids;

  return (
    <TableHead className={classnames(className, classes.thead)}>
      <TableRow className={classnames(classes.row, classes.headerRow)}>
        {hasExpand && (
          <TableCell
            className={classnames(
              classes.headerCell,
              classes.expandHeader
            )}
          />
        )}
        {hasBulkActions && selectedIds && (
          <TableCell
            padding="checkbox"
            className={classes.headerCell}
            style={{ textAlign: "center" }}
            align="center"
          >
            <Checkbox
              className="select-all"
              color="primary"
              disabled={disableSelectAll}
              checked={
                selectedIds?.length > 0 &&
                selectableIds?.length > 0 &&
                selectableIds?.every(id =>
                  selectedIds.includes(id)
                )
              }
              onChange={handleSelectAll}
            />
          </TableCell>
        )}
        {Children.map(children, (field, index) =>
          isValidElement(field) ? (
            <DatagridHeaderCell
              className={classes.headerCell}
              currentSort={currentSort}
              field={field}
              isSorting={
                currentSort?.field ===
                (field.props.sortBy ||
                  field.props.source)
              }
              key={field.props.source || index}
              resource={resource}
              updateSort={updateSort}
            />
          ) : null
        )}
      </TableRow>
    </TableHead>
  );
};