import Wrapper from "../utils/Wrapper";
import axios from 'axios';

const dataProviderForPaid = {
  getOne: async (resource, params) => {
    const url = process.env.REACT_APP_API_URL + "/paid-order/" + params.orderId;
    const response = await axios.get(url,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      });

    if (response.data.data) {
      const { offers } = response.data.data
      response.data.data.productInStock = [];
      response.data.data.productOutStock = [];
      response.data.data.productAlternative = [];

      offers.map(({products})=> {
        response.data.data.productInStock.push(products?.filter(p => p?.inStock && !p.alternativeProduct));
        response.data.data.productOutStock.push(products?.filter(p => !p?.inStock && !p?.alternativeProduct));
        response.data.data.productAlternative.push( products?.filter(p => p?.alternativeProduct));
      });
    }

    let resultData = Wrapper.wrapPaid(response.data.data);
   
    return ({
      data: resultData,
    });
  },

  editOrder: async (resource, params) => {
    let data = params.data;
    let response = await axios.put(process.env.REACT_APP_API_URL + "/paid-order/" + params.orderId, data,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      }
    );

    if (response && response.data) {
      let dataRes = response.data;
      return ({
        data: dataRes.data
      });
    } else {
      return ({
        data: []
      });
    }
  },
};

export default dataProviderForPaid;
