let queue = [];
let resolveFn;

const createPromise = async (elem) => {
  if (resolveFn) {
    return;
  }

  const promise = new Promise((res, rej) => {
    resolveFn = res;
  });

  elem.fn(elem.params);
  
  return promise;
}

const resolvePromise = () => {
  if (!resolveFn) {
    return;
  }

  resolveFn();
  resolveFn = undefined;
}

const runQueue = async () => {
  if (queue.length && !resolveFn) {
    await createPromise(queue.shift());
    queue.forEach((elem) => {
      elem.fn(elem.params);
    });
    queue = [];
  }
}

const pushToQueue = (element) => {
  queue.push(element);
  runQueue();
}

export default {
  pushToQueue,
  resolvePromise,
}