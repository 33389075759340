import Wrapper from "../utils/Wrapper";
import axios from 'axios';
import { stringify } from "query-string";

const dataProviderForManagers = {

  getOne: async (resource, params) => {
    const url = process.env.REACT_APP_API_URL + "/manager/" + params.id;
    const response = await axios.get(url,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      });
    let resultData = Wrapper.wrapManager(response.data.data);
    return ({
      data: resultData,
    });
  },

  delete: async (resource, params) => {
    const url = process.env.REACT_APP_API_URL + "/manager/" + params.id;
    const response = await axios.delete(url,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      });

    if (response.status === 200) {
      return { data: { id: params.id } }
    }
  },

  getList: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const { entityId, entityType } = params.filter;
    const query = {
      entityId: entityId,
      entityType: entityType,
      page: page - 1,
      size: perPage,
      sort: field === 'id' ? 'managerId' : field
    };
    const url = process.env.REACT_APP_API_URL + `/manager?${stringify(query)},${order}`;
    const response = await axios.get(url,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      });

    if (!response || !response.data || !response.data.data) {
      return ({
        data: [],
        total: 0
      });
    }
    let resultData = response.data.data.content.map(manager => Wrapper.wrapManager(manager));
    let count = response.data.data.totalElements;

    return ({
      data: resultData,
      total: count
    });
  },

  create: async (resource, params) => {
    let data = params.data;
    let response = await axios.post(process.env.REACT_APP_API_URL + "/manager", data,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      }
    );

    if (response && response.data) {
      let dataRes = response.data;
      dataRes.data.id = dataRes.data.managerId;
      return ({
        data: dataRes.data
      });
    } else {
      return ({
        data: []
      });
    }
  },

  update: async (resource, params) => {
    let data = { ...params.data, reasonBlock: params.reasonBlock };

    let response = await axios.put(process.env.REACT_APP_API_URL + "/manager/" + params.id, data,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      }
    );

    if (response && response.data) {
      let dataRes = response.data;
      dataRes.data.id = dataRes.data.managerId;
      return ({
        data: dataRes.data
      });
    } else {
      return ({
        data: []
      });
    }
  },

  updateStatus: async (resource, params) => {
    let {managerId, status, reason } = params.data;

    let response = await axios.put(process.env.REACT_APP_API_URL + `/manager/${managerId}/${status}${reason ? `?reason=${reason}` : ""}`, null,
      {
        withCredentials: true,
        headers: { "Content-Type": "text/plain;charset=utf-8" },
      }
    )
    if (response && response.data) {
      let dataRes = response.data;
      dataRes.data.id = dataRes.data.managerId;

      return ({
        data: dataRes.data
      });
    } else {
      return ({
        data: []
      });
    }
  },
}
export default dataProviderForManagers;
