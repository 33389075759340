import React, { useEffect, useMemo, useState } from "react";
import { TopToolbar } from "react-admin";
import { useTranslate, useRefresh } from "ra-core";
import {Link, useHistory} from "react-router-dom";

import {
  Button,
  Box,
  makeStyles,
  MenuItem,
  Menu
} from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import classNames from "classnames";
import { STATUS } from "./enums";
import PackingSlipsVerify from "./PackingSlipsVerify";

const useStyles = makeStyles((theme) => ({
  toolbar_root: {
    position: "sticky",
    top: "40px",
    zIndex: "2",
    backgroundColor: "#fafafa",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    "& *": {
      textTransform: "inherit",
    },
    "& > a": {
      width: "100%",
      justifyContent: "start"
    }
  },
  root: {
    "& div": {
      marginLeft: theme.spacing(1),
      "& button": {
        height: "40px",
      },
    },
  },
  formControl: {
    minWidth: "82px",
    marginTop: "5px",
  }
}));

export default ({ paidData, disabledGroupButton, openSendToSupplierDialog, 
  sendButtonsState, openCancelDialog, openGroupDialog, 
  openSendToCustomerDialog, data, selectedSuppliers }) => {

  const [dialogOpened, setDialogOpened] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const translate = useTranslate();
  const classes = useStyles();
  const history = useHistory();
  const refresh = useRefresh();

  const completedSuppliers = useMemo(() => {
    return Object.entries(selectedSuppliers).filter(([key, { status }]) => status === STATUS.COMPLETED);
  }, [selectedSuppliers]);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = () => {
    setDialogOpened(true)
  }

  const closeSendDialog = () => {
    setDialogOpened(false);
    refresh();
  }

  const handleChange = (value) => {
    if(data.status !== STATUS.PACKING_SLIP_VERIFIED) {
      if (value === 1) {
        openCancelDialog(data.status);
        setAnchorEl(null);
      } else {
        if(data?.status !== STATUS.CANCELED && data?.status !== STATUS.DECLINED) {
          setAnchorEl(null);
          history.push(`/orders/${data.id}/order`)
        }
      }
    }
  }

  useEffect(()=> {
    setDisabledButton(!data?.offerPackingSlipDtos || 
    !data?.offerPackingSlipDtos?.every((item) => item.offerPackingSlipId > 0))
  }, [data])

  return (
    <TopToolbar className={classNames(classes.toolbar_root, "sticky-toolbar")}>
      <Button
        label={translate("konebone.orders.go_back")}
        startIcon={<KeyboardBackspaceIcon />}
        component={Link}
        to="/orders"
      >
        {translate("konebone.orders.go_back")}
      </Button>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        width="100%"
        className={classes.root}
      >
      {data?.status === STATUS.DELIVERED ?
        <>
          <Button
            disabled={disabledButton}
            label={translate("konebone.orders.mark_packing_slip_verified")}
            variant="contained"
            color="secondary"
            onClick={handleOpen}
            >
            {translate("konebone.orders.mark_packing_slip_verified")}
          </Button>
          {dialogOpened && 
            <PackingSlipsVerify paidData={paidData} open={dialogOpened} closeSendDialog={closeSendDialog} data={data} />
          }
        </>
          :
        <>
        <div style={{display: sendButtonsState.suppliers.show ? "block" : "none"}}>
          <Button
            variant="contained"
            color="secondary"
            onClick={(e) => openSendToSupplierDialog()}
            disabled={!sendButtonsState.suppliers.active}
          >
            {translate("konebone.orders.offers.send_to_suppliers")}
          </Button>
        </div>
        <div style={{display: sendButtonsState.customer.show ? "block" : "none"}}>
          <Button
            variant="contained"
            color="secondary"
            onClick={(e) => openSendToCustomerDialog()}
            disabled={!sendButtonsState.customer.active || Object.values(selectedSuppliers).length <= 0 }
          >
            {translate("konebone.orders.offers.send_to_customer")}
          </Button>
        </div>
        <div style={{display: sendButtonsState.customer.show ? "block" : "none"}}>
          <Button
            onClick={openGroupDialog}
            label={translate("konebone.orders.offers.group_offers")}
            variant="outlined"
            color="secondary"
            disabled={
              completedSuppliers?.length <= 1 
              || Object.values(selectedSuppliers).length !== completedSuppliers.length 
              || !disabledGroupButton.current}
              // || !sendButtonsState.customer.active
          >
            {translate("konebone.orders.offers.group_offers")}
          </Button>
        </div>
      </> 
     } 
      <>
      <Box>
        <div>
          { data?.status !== STATUS.PACKING_SLIP_VERIFIED && data?.status !== STATUS.CANCELED && data?.status !== STATUS.DECLINED &&
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
            variant="outlined"
            endIcon={<ArrowDropDownIcon />}
          >
            {translate("konebone.orders.more")}
          </Button>
           }
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => handleChange(1)}>{translate("konebone.orders.cancel_order")}</MenuItem>
            <MenuItem onClick={() => handleChange(2)}>{translate("konebone.orders.edit_order")}</MenuItem>
          </Menu>
        </div>
      </Box>
      </>
      </Box>
    </TopToolbar>
  );
};
