import React, { useEffect, useRef, useState } from "react";
import { Edit, useNotify} from "react-admin";
import { useRedirect } from "ra-core";
import { makeStyles } from "@material-ui/core";
import currency from "currency.js"
import OrderEditDetail from "./OrderEditDetail";
import { cleanSession } from "../../authProvider";
import dataProviderForProjects from "../../providers/DataProviderForProjects";

const useShowStyles = makeStyles((theme) => ({
  card: {
    background: "none",
    border: "none",
    boxShadow: "none",
    paddingBottom: "3px",
    overflow: "unset"
  }
}));


const EditTitle = ({ record }) => {
  return <span>{record?.name?.toUpperCase()}</span>;
};


const OrderEditContent = ({ record, resource, basePath, ...rest }) => {
  const [products, setProducts] = useState([]);
  const [projects, setProjects] = useState(record.project)
  const [totalsPrices, setTotalsPrices] = useState({
    minTotalKbPrice: record.minTotalKbPrice,
    maxTotalKbPrice: record.maxTotalKbPrice,
  });
  const [checkAll, setCheckAll] = useState(true);
  const [indeterminate, setIndeterminate] = useState(false);
  const [errors, setErrors] = useState({});

  const [deliveryInfo, setDeliveryInfo] = useState({
    time: record.deliveryTimeRange,
    date: record.deliveryDate,
    customerComment: record.customerComment,
    serviceUnloading: !!record.serviceUnloading
  });
  const productsDeleteRef = useRef([]);
  const productsAddRef = useRef([]);
  const projectsAddRef = useRef([]);
  const projectDeleteRef = useRef([]);
  const projectUpdateRef = useRef([]);
  const checkedProductRef = useRef(0);
  const timeError = useRef(false)


  useEffect(() => {
    const newProductList = record.orderProductResponseDtos.map(product => {
      product.isChecked = product.isReadyForAlternatives;
      product.isReadyForAlternatives && (checkedProductRef.current += 1);
      product.maxKpPriceWithQuantity = product.maxKpPriceWithQuantity;
      product.minKpPriceWithQuantity = product.minKpPriceWithQuantity;
      product.maxKbPrice = product.maxKbPrice;
      product.minKbPrice = product.minKbPrice;
      return product;
    })
    setProducts(newProductList);
    setIndeterminate(checkedProductRef.current > 0 && checkedProductRef.current < products.length);
  }, []);


  useEffect (() => {
    dataProviderForProjects.getProjectList('/project', {
      customerId: record.customer.customerId
    })
    .then ((res)=> {
      setProjects(res.data)
    })
    .catch((error) => {
      if (!error.response) {
        console.error(error);
        return;
      }
      let { status } = error.response;
      if (status === 401 || status === 403) {
        cleanSession();
        window.location.href = "/login";
      }
      if (status === 500) {
        notify(
          error.response.data?.error,
          "warning"
        )
        return;
      }
    });
  }, []);


  const changeDeliveryInfo = (values) => {
    setDeliveryInfo({...deliveryInfo, ...values});
  }

  const handleCheckAll = (e) => {
    const { checked } = e.target;
    setCheckAll(checked);
    let currentCheckedValues = 0;
    const newProducts = products.map((product) => {
      product.isChecked = checked;
      product.isChecked && (currentCheckedValues += 1);
      return product;
    });
    checkedProductRef.current = currentCheckedValues;
    setIndeterminate(false);
    setProducts(newProducts);
  };

  const handleCheckOne = (id, check) => {
    const newProducts = [...products];
    newProducts[id].isChecked = check;
    const currentCheckedValues = newProducts.filter(p => p.isChecked).length;
    checkedProductRef.current = currentCheckedValues;
    setProducts(newProducts);
    setIndeterminate(currentCheckedValues > 0 && currentCheckedValues < products.length);
    setCheckAll(currentCheckedValues === products.length);
  };


  const changeQuantity = (id, quantity) => {
    if (quantity < 1) {
      return;
    }
    const newProducts = [...products];
    newProducts[id].quantity = quantity;
    recalculateKbPriceForProduct(newProducts[id], quantity);
    recalculateTotalKbPrices(newProducts);
  };

  const recalculateKbPriceForProduct = (product, quantity) => {
    product.maxKpPriceWithQuantity = product.maxKbPrice * (quantity);
    product.minKpPriceWithQuantity = product.minKbPrice * (quantity);
  };

  const totalPriceCalculate = (products) => {
    return { 
      maxTotalKbPrice: products.reduce((sum, product) => sum.add(product.maxKpPriceWithQuantity), currency(0)),
      minTotalKbPrice: products.reduce((sum, product) => sum.add(product.minKpPriceWithQuantity), currency(0)),
    }
  };

  const recalculateTotalKbPrices = (products) => {
    const newTotalsPrices = totalPriceCalculate(products)
    setTotalsPrices(newTotalsPrices);
  };

  const removeProductFromOrder = (id) => {
    productsDeleteRef.current.push(products[id]);

    const newProducts = [...products];
    newProducts.splice(id, 1);

    const newTotalsPrices = totalPriceCalculate(newProducts);

    setProducts(newProducts);
    setTotalsPrices(newTotalsPrices);
  }

  const addProductFromOrder = (product) => {
    productsAddRef.current.push(product);
    const newProducts = [...products];
    newProducts.push(product);
    setProducts(newProducts);
  }

  const removeProjectFromOrder = (id) => {
    projectDeleteRef.current.push(projects[id]);

    const newProjects = [...projects];
    setProjects(newProjects.filter(element => element.project != id));
  }


  const addProjectToOrder = (project) => {
    projectsAddRef.current.push(project)
    const newProjects = [...projects];
    newProjects.push(project);
    setProjects(newProjects);
  }


  const updateProjectFromOrder = (project, id) => {
    projectUpdateRef.current.push(project);
    const newProjects = [...projects];
    newProjects[id] = project;
    setProjects(newProjects);
  }


  return (
    <OrderEditDetail
      basePath={basePath}
      record={record}
      products={products}
      deliveryInfo={deliveryInfo}
      handleCheckOne={handleCheckOne}
      indeterminate={indeterminate}
      checkAll={checkAll}
      handleCheckAll={handleCheckAll}
      addProductFromOrder={addProductFromOrder}
      removeProductFromOrder={removeProductFromOrder}
      removeProjectFromOrder={removeProjectFromOrder}
      changeQuantity={changeQuantity}
      changeDeliveryInfo={changeDeliveryInfo}
      totalsPrices={totalsPrices}
      projects={projects}
      addProjectToOrder={addProjectToOrder}
      updateProjectFromOrder={updateProjectFromOrder}
      errors={errors}
      setErrors={setErrors}
      timeError={timeError}
    />
  )
};

export const OrderEdit = (props) => {
  const redirect = useRedirect();
  const classes = useShowStyles();
  const notify = useNotify();

  const onSuccess = () => {
    notify("ra.notification.updated", "success");
    redirect("list", props.basePath);
  };

  // const handleFailure = (error) => {
  //   if (!error.response) {
  //     console.error(error);
  //     return;
  //   }
  //   let { status } = error.response;
  //   if (status === 401 || status === 403) {
  //     cleanSession();
  //     window.location.href = "/login";
  //   }
  //   if (status === 500) {
  //     notify(
  //       error.response.data?.error,
  //       "warning"
  //     )
  //     return;
  //   }
  //   notify(
  //     error.response.data?.message?.message,
  //     "warning",
  //     wrapArrayToMap(error.response.data?.message?.parameters)
  //   );
  // };


  return (
    <Edit
      title={<EditTitle />}
      onFailure={(error)=> handleFailure(error, notify)}
      onSuccess={onSuccess}
      classes={classes}
      {...props}
    >
      <OrderEditContent/>
    </Edit>
  );
};
