import React from "react";
import {
  Edit,
  useNotify,
} from "react-admin";
import { wrapArrayToMap } from "../../utils/Wrapper";
import { useAuthState, useRedirect } from "ra-core";
import {
  makeStyles,
  Paper,
} from "@material-ui/core";
import SupplierCompanyEdit from "./SupplierCompanyEdit";
import { cleanSession } from "../../authProvider";
import { ManagersList } from "../../components/ManagerTable";
import SupplierEditAction from "./SupplierActionComponent"


const useShowStyles = makeStyles((theme) => ({
  card: {
    background: "none",
    border: "none",
    boxShadow: "none",
    paddingBottom: "3px",
  }
}));

const EditCustomeTitle = ({ record }) => {
  return <span>{record?.name?.toUpperCase()}</span>;
};

const EditContent = ({ record, ...rest }) => {
  return (
    <>
      <Paper><SupplierCompanyEdit {...rest} record={record} /></Paper>
      <ManagersList entityId={record.id} entityType="SUPPLIER" entityStatus={record.status} canAddManager={record.status === "ACTIVE"} />
    </>
  );
};

const SupplierEdit = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const classes = useShowStyles();
  const { loading, authenticated } = useAuthState();

  const onSuccess = () => {
    notify("ra.notification.updated", "success");
    redirect("list", props.basePath);
  };

  const handleFailure = (error) => {
    if (!error.response) {
      console.error(error);
      return;
    }
    let { status } = error.response;
    if (status === 401 || status === 403) {
      cleanSession();
      window.location.href = "/login";
    }
    if (status === 500) {
      notify(
        error.response.data?.error,
        "warning"
      )
      return;
    }
    notify(
      error.response.data?.message?.message,
      "warning",
      wrapArrayToMap(error.response.data?.message?.parameters)
    );
  };

  if (!authenticated || loading) {
    return null;
  }

  return (
    <Edit
      title={<EditCustomeTitle />}
      onFailure={handleFailure}
      actions={<SupplierEditAction />}
      onSuccess={onSuccess}
      classes={classes}
      {...props}
    >
      <EditContent />
    </Edit>
  );
};

export { SupplierEdit };