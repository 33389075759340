import React from "react";
import {Loading, TextField} from "react-admin";
import { useTranslate } from "ra-core";
import keyBy from "lodash/keyBy";
import DatagridResponsive from "../../components/DataGridResponsive";


export const CreditHistory = ({currentSort, data, loading} ) => {
  const translate = useTranslate();

  if (loading) {
    return <Loading />
  }

  return (
        <DatagridResponsive 
          data={keyBy(data?.payments?.content, 'paymentId')}
          ids={data?.payments?.content.map(({ paymentId }) => paymentId)}
          currentSort={currentSort}
          setSort={(field, order) => setSort({ field, order })}>
          <TextField
            source="placed"
            label={translate("konebone.customer.date_placed")}
            sortable={false}
          />
          <TextField
            source="dueDate"
            label={translate("konebone.customer.due_date")}
            sortable={false}
          />
          <TextField
            source="term"
            label={translate("konebone.customer.payment_term")}
            sortable={false}
          />
          <TextField
            source="order"
            label={translate("konebone.customer.order_id")}
            sortable={false}
          />
          <TextField
            source="amount"
            label={translate("konebone.customer.order_total_amount")}
            sortable={false}
          />
          <TextField
            source="comment"
            label={translate("konebone.customer.comment")}
            sortable={false}
          />
          
         </DatagridResponsive>
  );
};

