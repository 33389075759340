import React from "react";
import {ReactComponent as ManufacturerSvg} from '../svg/manufacturer.svg';
import {ReactComponent as MeasuringSvg} from '../svg/measuring.svg';

import {ReactComponent as PackageSvg} from '../svg/package.svg';
import {ReactComponent as PackageTypeSvg} from '../svg/package-type.svg';

export const Manufacturer = () => <ManufacturerSvg />;
export const Measurement = () => <MeasuringSvg />;
export const Package = () => <PackageSvg />;
export const PackageType = () => <PackageTypeSvg />;
