import { useState, useRef, useEffect, useContext } from "react";
import {
  useTranslate,
  BooleanInput,
  FormWithRedirect,
  DataProviderContext,
  useNotify,
  Confirm,
} from "react-admin";
import {
  Typography,
  Box,
  makeStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@material-ui/core";
import { cleanSession } from "../../authProvider";
import PriceDialog from "./PriceDialog";
// import SupplierDialog from "./SupplierDialog";
import { wrapArrayToMap } from "../../utils/Wrapper";
import PriceList from "./PriceList";
import Validator from "../../utils/Validator";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
  },
  plusButton: {
    height: "29px",
    width: "29px",
    marginTop: "12px",
    "&:hover": {
      borderRadius: "1px",
    },
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  capitalize: {
    textTransform: "capitalize",
  },
  fitContent: {
    width: "fit-content"
  }
}));

const requredFields = ["supplierId", "supplierPn", "supplierProductName"];

const createSupplierNamesOptions = (data) => {
  const result = [<option aria-label="None" value="" key={0} />];
  data.forEach(({ name, id }) => {
    result.push(
      <option key={id} value={id}>
        {name}
      </option>
    );
  });

  return result;
};

const CreatePriceLineDialog = ({
  open,
  handleClose,
  title,
  record,
  productPackageId,
  initData,
}) => {
  const [openedAddPriceDialog, setOpenedAddPriceDialog] = useState(false);
  // const [openedAddSupplierDialog, setOpenedAddSupplierDialog] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [supplierId, setSupplierId] = useState("");
  const [partNumber, setPartNumber] = useState(record?.supplierPn || initData?.supplierPn || "");
  const [productName, setProductName] = useState(
    record?.supplierProductName || initData?.supplierProductName || ""
  );
  const [quantityOnPallet, setQuantityOnPallet] = useState(
    record?.quantityOnPallet || initData?.quantityOnPallet || ""
  );
  const [productSupplierId, setProductSupplierId] = useState(record?.id || "");
  const [inStock, setInStock] = useState(record?.inStock || true);
  const [suppliers, setSuppliers] = useState([]);
  const [prices, setPrices] = useState([]);
  const [errors, setErrors] = useState({});
  const [wasChanges, setWasChanges] = useState(!!initData || false);
  const [saved, setSaved] = useState(false);
  const [quantityValidMsg, setQuantityValidMsg] = useState("");

  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();

  const mounted = useRef(false);
  const dialogRecord = useRef(null);
  const dataProvider = useContext(DataProviderContext);

  useEffect(() => {
    if (!(record?.id || initData) || !suppliers.length) {
      return;
    }

    setSupplierId(record?.supplierId || initData?.supplierId);
  }, [suppliers]);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      dataProvider
        .getList("supplier", {
          pagination: { page: 1, perPage: 1000 },
          sort: { order: "ASC" },
          filter: { status: "active-all" }
        })
        .then((items) => {
          if (mounted.current) {
            setSuppliers(createSupplierNamesOptions(items?.data));
          }
        })
        .catch((error) => {
          if (!error.response) {
            console.error(error);
            return;
          }
          let { status } = error.response;
          if (status === 401 || status === 403) {
            cleanSession();
            window.location.href = "/login";
          }
          if (status === 500) {
            notify(
              error.response.data?.error,
              "warning"
            )
            return;
          }
          notify(
            error.response.data?.message?.message,
            "warning",
            wrapArrayToMap(error.response.data?.message?.parameters)
          );
        });
    }
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!productSupplierId) {
      return;
    }

    dataProvider
      .getList("prices", {
        filter: { productSupplierId: productSupplierId },
      })
      .then((items) => {
        if (mounted.current) {
          setPrices(items?.data);
        }
      })
      .catch((error) => {
        if (!error.response) {
          console.error(error);
          return;
        }
        let { status } = error.response;
        if (status === 401 || status === 403) {
          cleanSession();
          window.location.href = "/login";
        }
        if (status === 500) {
          notify(
            error.response.data?.error,
            "warning"
          )
          return;
        }
        notify(
          error.response.data?.message?.message,
          "warning",
          wrapArrayToMap(error.response.data?.message?.parameters)
        );
      });
  }, [productSupplierId]);

  const handleSave = async (e) => {
    try {
      const validateResult = Validator.validateQuantityOnPallet(quantityOnPallet)
      
      if (validateResult) {
        setQuantityValidMsg(validateResult);
        return;
      }

      const data = {
        productPackageId: productPackageId,
        quantityOnPallet: +quantityOnPallet,
        supplierProductName: productName.trim(),
        inStock: inStock,
        supplierPn: partNumber,
        supplierId: +supplierId,
      };

      const newErrors = { ...errors };

      requredFields.forEach((field) => {
        if (!data[field]) {
          newErrors[field] = true;
        }
      });

      const isWrong = Object.values(newErrors).some((error) => error);

      if (isWrong) {
        setErrors(newErrors);
        return;
      }

      if (!record) {
        const record = await dataProvider.create("price_lines", { data: data });
        setProductSupplierId(record.data.id);
        setSaved(true);
        setWasChanges(false);
      } else {
        await dataProvider.update("price_lines", {
          data: data,
          id: productSupplierId,
        });
        setWasChanges(false);
        handleClose(true);
      }
    } catch (error) {
      if (!error.response) {
        console.error(error);
        return;
      }
      let { status } = error.response;
      if (status === 401 || status === 403) {
        cleanSession();
        window.location.href = "/login";
      }
      if (status === 500) {
        notify(
          error.response.data?.error,
          "warning"
        )
        return;
      }
      notify(
        error.response.data?.message?.message,
        "warning",
        wrapArrayToMap(error.response.data?.message?.parameters)
      );
    }
  };

  const handleOpenConfirm = () => {
    if (wasChanges) {
      setOpenConfirm(true);
      return;
    }

    handleClose(saved);
  }

  const handleDialogClose = () => setOpenConfirm(false);

  const handleConfirm = () => {
    setOpenConfirm(false);
    handleClose();
  };

  const handleCloseAfterSave = () => {
    handleClose(true);
  };

  const openAddPriceDialog = (record) => {
    setOpenedAddPriceDialog(true);
    dialogRecord.current = record;
  };

  const closeAddPriceDialog = (refresh) => {
    if (refresh) {
      dataProvider
        .getList("prices", {
          filter: { productSupplierId: productSupplierId },
        })
        .then((items) => {
          if (mounted.current) {
            setPrices(items?.data);
          }
          setWasChanges(true);
        })
        .catch((error) => {
          if (!error.response) {
            console.error(error);
            return;
          }
          let { status } = error.response;
          if (status === 401 || status === 403) {
            cleanSession();
            window.location.href = "/login";
          }
          if (status === 500) {
            notify(
              error.response.data?.error,
              "warning"
            )
            return;
          }
          notify(
            error.response.data?.message?.message,
            "warning",
            wrapArrayToMap(error.response.data?.message?.parameters)
          );
        });
    }

    setOpenedAddPriceDialog(false);
    dialogRecord.current = null;
  };

  // const openAddSupplierDialog = () => {
  //   setOpenedAddSupplierDialog(true);
  // };

  // const closeAddSupplierDialog = (refresh) => {
  //   if (refresh) {
  //     dataProvider
  //       .getList("supplier", {
  //         pagination: { page: 1, perPage: 1000 },
  //         sort: { order: "ASC" },
  //         filter: { status: "active-all" },
  //       })
  //       .then((items) => {
  //         if (mounted.current) {
  //           setSuppliers(createSupplierNamesOptions(items?.data));
  //         }
  //       })
  //       .catch((error) => {
  //         if (!error.response) {
  //           console.error(error);
  //           return;
  //         }
  //         let { status } = error.response;
  //         if (status === 401 || status === 403) {
  //           cleanSession();
  //           window.location.href = "/login";
  //         }
  //         if (status === 500) {
  //           notify(
  //             error.response.data?.error,
  //             "warning"
  //           )
  //           return;
  //         }
  //         notify(
  //           error.response.data?.message?.message,
  //           "warning",
  //           wrapArrayToMap(error.response.data?.message?.parameters)
  //         );

  //       });
  //   }

  //   setOpenedAddSupplierDialog(false);
  // };

  const checkRequired = (e, key) => {
    if (!e.target.value && requredFields.includes(e.target?.id || key)) {
      setErrors({ ...errors, [e.target?.id || key]: true });
    }
  };

  const changeSupplier = (e) => {
    if (errors.supplierId) {
      setErrors({ ...errors, supplierId: false });
    }
    setSupplierId(e.target.value);
    setWasChanges(true);
  };

  const changePartNumber = (e) => {
    setPartNumber(e.target.value);
    if (errors.supplierPn) {
      setErrors({ ...errors, supplierPn: false });
    }
    setWasChanges(true);
  };

  const changeProductName = (e) => {
    if (errors.supplierProductName) {
      setErrors({ ...errors, supplierProductName: false });
    }
    setProductName(e.target.value);
    setWasChanges(true);
  };

  const changeQuantityOnPallet = (e) => {
    const { value } = e.target;
    if (quantityValidMsg && (!value || !isNaN(value))) {
      setQuantityValidMsg("");
    }
    setQuantityOnPallet(value);
    setWasChanges(true);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" className={classes.capitalize}>
        {title}
      </DialogTitle>
      <DialogContent>
        <FormWithRedirect
          record={record || initData || { inStock: true }}
          render={(formProps) => (
            <form>
              <Box p="1em">
                <Typography variant="h6" gutterBottom margin="dense">
                  {translate(
                    "konebone.products.price_lines_list.supplier"
                  ).toUpperCase()}
                </Typography>
                <Box display="flex">
                  <FormControl
                    className={classes.formControl}
                    required
                    error={errors.supplierId}
                    variant="outlined"
                  >
                    <InputLabel htmlFor="supplierId-label">
                      {translate(
                        "konebone.products.price_lines_list.supplier_name"
                      )}
                    </InputLabel>
                    <Select
                      native
                      labelWidth={115}
                      name="supplierId"
                      id="supplierId"
                      labelId="supplierId-label"
                      value={supplierId}
                      onChange={changeSupplier}
                      onBlur={(e) => checkRequired(e, "id")}
                      disabled={!!initData?.supplierId}
                    >
                      {suppliers}
                    </Select>
                    {errors.supplierId && (
                      <FormHelperText>{translate("ra.validation.required")}</FormHelperText>
                    )}
                  </FormControl>
                  {/* <IconButton
                    variant="outlined"
                    className={classes.plusButton}
                    size="medium"
                    onClick={openAddSupplierDialog}
                  >
                    <AddBoxOutlinedIcon />
                  </IconButton> */}
                </Box>
                <Box mt="1em" />

                <Typography variant="h6" gutterBottom>
                  {translate(
                    "konebone.products.price_lines_list.supplier_product_details"
                  ).toUpperCase()}
                </Typography>

                <Box display="flex">
                  <Box flex={1} mr="0.5em">
                    <TextField
                      error={errors.supplierPn}
                      id="supplierPn"
                      label={translate(
                        "konebone.products.price_lines_list.part_number"
                      )}
                      fullWidth
                      variant="outlined"
                      value={partNumber}
                      onChange={changePartNumber}
                      onBlur={checkRequired}
                      helperText={errors.supplierPn && translate("ra.validation.required")}
                      required
                    />
                    <TextField
                      error={errors.supplierProductName}
                      source="supplierProductName"
                      resource="customers"
                      label={translate(
                        "konebone.products.price_lines_list.product_name"
                      )}
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={productName}
                      onChange={changeProductName}
                      onBlur={checkRequired}
                      helperText={errors.supplierProductName && translate("ra.validation.required")}
                      required
                    />
                  </Box>
                  <Box flex={1} mr="0.5em">
                    <TextField
                      error={quantityValidMsg !== ""}
                      source="quantityOnPallet"
                      resource="customers"
                      label={translate(
                        "konebone.products.price_lines_list.quantity_on_pallet"
                      )}
                      fullWidth
                      variant="outlined"
                      value={quantityOnPallet}
                      onChange={changeQuantityOnPallet}
                      helperText={translate(quantityValidMsg, {max: 99999999, pattern: "/^\d{0,8}\.?\d{0,2}$/"})}
                    />
                    <BooleanInput
                      source="inStock"
                      resource="customers"
                      label={translate(
                        "konebone.products.price_lines_list.in_stock"
                      ).toUpperCase()}
                      className={classes.fitContent}
                      checked={inStock}
                      onChange={(value) => {
                        setInStock(value);
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <PriceList
                prices={prices}
                openAddPriceDialog={openAddPriceDialog}
                closeAddPriceDialog={closeAddPriceDialog}
                productSupplierId={productSupplierId}
              />
              {openedAddPriceDialog && (
                <PriceDialog
                  title={
                    dialogRecord.current
                      ? translate(
                        "konebone.products.price_lines_list.edit_price"
                      )
                      : translate(
                        "konebone.products.price_lines_list.add_price"
                      )
                  }
                  open={openedAddPriceDialog}
                  handleClose={closeAddPriceDialog}
                  record={dialogRecord.current}
                  productSupplierId={productSupplierId}
                  initData={initData}
                />
              )}
              {/* {openedAddSupplierDialog && (
                <SupplierDialog
                  open={openedAddSupplierDialog}
                  handleClose={closeAddSupplierDialog}
                />
              )} */}
            </form>
          )}
        />
      </DialogContent>
      <DialogActions className={classes.root}>
        <Button onClick={handleOpenConfirm} variant="outlined">
          {translate("ra.action.cancel")}
        </Button>

        <Button
          onClick={saved ? handleCloseAfterSave : handleSave}
          variant="contained"
          color="secondary"
          disabled={!wasChanges}
        >
          {translate("ra.action.save")}
        </Button>
      </DialogActions>
      <Confirm
        isOpen={openConfirm}
        title=""
        content={translate("confirm_messages.price_lines")}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </Dialog>
  );
};

export default CreatePriceLineDialog;
