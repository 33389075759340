import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
// import Link from '@material-ui/core/Link';
// import Grid from '@material-ui/core/Grid';
// import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useTranslate } from 'ra-core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { useNotify, Notification } from "react-admin";
import Validator from "../utils/Validator";
import useLogin from '../hooks/useLogin';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  forgotLink: {
    justifyContent:"center" 
  }
}));

const Login = ({ theme }) => {
  const classes = useStyles();
  const translate = useTranslate();

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [submitDone, setSubmitDone] = useState(false);
  const login = useLogin();
  const notify = useNotify();

  const submit = e => {
    e.preventDefault();
    setSubmitDone(true);

    if (emailError.length > 0 || passwordError.length > 0) {
      return;
    }

    login({ 'login': email, 'password': password })
      .catch((e) => {
        console.error(e);
        notify('konebone.sign_in.login_error')});
    setSubmitDone(false);
  };

  const updateEmail = (email) => {
    setEmail(email);
    if (Validator.validateEmailSignIn(email)) {
      setEmailError(translate('ra.validation.email'));
    } else {
      setEmailError("");
    }
  }

  const updatePassword = (password) => {
    setPassword(password);
    if (!Validator.validatePasswordSignIn(password)) {
      setPasswordError(translate('konebone.sign_in.password_size'));
    } else {
      setPasswordError("");
    }
  }

  return (
    <MuiThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {translate('ra.auth.sign_in')}
          </Typography>
          <form className={classes.form} noValidate onSubmit={submit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="login"
              // label="Email Address"
              label={translate('konebone.sign_in.email')}
              name="login"
              // autoComplete="email"
              // autoFocus
              value={email}
              onChange={e => updateEmail(e.target.value)}
              error={submitDone && emailError.length > 0}
              helperText={submitDone ? emailError : ""}
              type="email"
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              // label="Password"
              label={translate('ra.auth.password')}
              type="password"
              id="password"
              // autoComplete="current-password"
              value={password}
              onChange={e => updatePassword(e.target.value)}
              error={submitDone && passwordError.length > 0}
              helperText={submitDone ? passwordError : ""}
            />
            {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={submitDone && (emailError.length > 0 || passwordError.length > 0)}
            >
              {/* Sign In */}
              {translate('ra.auth.sign_in')}
            </Button>
            <Grid container className={classes.forgotLink}>
              <Grid item >
              <Link  variant="body2" to="/forgot">
              {translate('ra.auth.forgot_password')}
              </Link>
            </Grid>
            {/* <Grid item>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid> 
            to="forgot_password"*/}
          </Grid>
          </form>
        </div>
      </Container>
      <Notification />
    </MuiThemeProvider>
  );
}

export default Login;