import React, { useState } from "react";
import { TopToolbar } from "react-admin";
import { useTranslate } from "ra-core";
import { useParams } from "react-router-dom";
import {
  Button,
  Box,
  makeStyles,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import classNames from "classnames";
import { STATUS } from "../enums";
import { OfferCancelDialog } from "./OfferCancelDialog";

const useStyles = makeStyles((theme) => ({
  toolbar_root: {
    position: "sticky",
    top: "0",
    zIndex: "200",
    backgroundColor: "#fafafa",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "60px",
    padding: "0px",
    "& *": {
      textTransform: "inherit",
    },
    "& > a": {
      width: "100%",
      justifyContent: "start"
    }
  },
  root: {
    "& > button": {
      marginLeft: theme.spacing(1),
      height: "42px",
    },
  },
  formControl: {
    minWidth: "42px",
    marginTop: "5px",
  }
}));

export default ({ status, data, ...rest }) => {
  const [dialogOpened, setDialogOpened] = useState(false);
  const { id, orderId } = useParams();
  const translate = useTranslate();
  const classes = useStyles();

  const handleClick = (e) => {
    e.stopPropagation();
    setDialogOpened(true)
  };

  const handleClose = () => {
    // e.stopPropagation();
    setDialogOpened(false);
  };

  return (
    <TopToolbar className={classNames(classes.toolbar_root, "sticky-toolbar")}>
      <Button
        label={translate("konebone.offer.go_back")}
        startIcon={<KeyboardBackspaceIcon />}
        component={Link}
        to={`/orders/${orderId}/show/suppliers`}
      >
        {translate("konebone.offer.go_back")}
      </Button>
      {data.status !== STATUS.DEFAULT && data.status !== STATUS.CANCELED && data.status !== STATUS.DECLINED && data.status !== STATUS.ACCEPTED ?
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          width="100%"
          className={classes.root}
        >
          <Button
            variant="outlined"
            component={Link}
            to={`../${id}`}
            style={{ height: "42px" }}
          >{translate("konebone.offer.edit_offer")}</Button>
          <Button
            variant="outlined"
            onClick={handleClick}
          >{translate("konebone.offer.cancel_offer")}</Button>
        </Box>
        : data.status === STATUS.IN_PROGRESS || data.status === STATUS.IN_REVIEW ?
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            width="100%"
            className={classes.root}
          >
            <Button
              variant="outlined"
              onClick={handleClick}
            >{translate("konebone.offer.cancel_offer")}</Button>
          </Box>
          : data.status === STATUS.ACCEPTED && status?.current !== STATUS.PACKING_SLIP_VERIFIED ?
              <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              width="100%"
              className={classes.root}
              >
              <Button
                variant="outlined"
                component={Link}
                to={`../${id}`}
                style={{ height: "42px" }}
              >{translate("konebone.offer.edit_offer")}</Button>
            </Box>
          : <div></div>
      }
      <OfferCancelDialog record={data} onClose={handleClose} open={dialogOpened} />
    </TopToolbar >
  );
};
