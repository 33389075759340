import { Show, Tab, TabbedShowLayout, TabbedShowLayoutTabs, useAuthState, useTranslate } from "react-admin";
import { Box, makeStyles } from "@material-ui/core";
import CustomerCompanyShow from "./CustomerCompanyShow";
import { ManagersList } from "../../components/ManagerTable";
import { ProjectsList } from "../../components/ProjectsTable";
import CustomerShowAction from "./CustomerActionComponent";
import CreditPaymentShow from "./CreditPaymentShow";
import AccessLink from "../AccessLink";

const useStyles = makeStyles((theme) => ({
  "& > .RaTabbedShowLayout-content-336": {
    backgroundColor: "green",
  },
  tabs: {
    "& > div": {
      padding: "0",
    },
    "& > .sticky-tab": {
      position: "sticky",
      backgroundColor: "white",
      zIndex: "2",
    },
  },
  root: {
    "& * > button": {
      textTransform: "capitalize",
    }
  },
}));


const ShowCustomeTitle = ({ record }) => {
  return <span>{record?.name?.toUpperCase()}</span>;
};

const ShowContent = ({ record, resource, basePath }) => {
  return (
    <>
      <Box><CustomerCompanyShow record={record} resource={resource} basePath={basePath} /></Box>
      <ManagersList entityId={record.id} entityType="CUSTOMER" entityStatus={record.status} canAddManager={record.status === "ACTIVE"} />
      {record.status && (record.status === "ACTIVE" || record.status === "BLOCKED")
        && <ProjectsList entityId={record.id} entityType="CUSTOMER" />}
    </>
  );
};

const CustomerShow = (props) => {
  const translate = useTranslate();
  const { loading, authenticated } = useAuthState();

  if (!authenticated || loading) {
    return null;
  }

  return (
    <>
      <Show
        title={<ShowCustomeTitle />}
        actions={<CustomerShowAction />}
        {...props}
      >
         <TabbedShowLayout tabs={<TabbedShowLayoutTabs className="sticky-tab" indicatorColor="secondary" textColor="secondary" /> }>
          <Tab label={translate("konebone.customer.info")}> 
            <ShowContent />
           </Tab>
          <Tab label={translate("konebone.customer.credit_payment")} >
             <CreditPaymentShow />
          </Tab> 
          <Tab label={translate("konebone.customer.access_link")} >
             <AccessLink props={props}/>
          </Tab> 
        </TabbedShowLayout>
      </Show>
    </>
  );
};

export { CustomerShow };
