import { useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { useAuthProvider, resetNotification } from 'ra-core';

export const defaultAuthParams = {
  loginUrl: '/login',
  afterLoginUrl: '/admin',
};
/**
 * Get a callback for calling the authProvider.login() method
 * and redirect to the previous authenticated page (or the home page) on success.
 *
 * @see useAuthProvider
 *
 * @returns {Function} login callback
 *
 * @example
 *
 * import { useLogin } from 'react-admin';
 *
 * const LoginButton = () => {
 *     const [loading, setLoading] = useState(false);
 *     const login = useLogin();
 *     const handleClick = {
 *         setLoading(true);
 *         login({ username: 'john', password: 'p@ssw0rd' }, '/posts')
 *             .then(() => setLoading(false));
 *     }
 *     return <button onClick={handleClick}>Login</button>;
 * }
 */
const useLogin = () => {
    const authProvider = useAuthProvider();
    const location = useLocation();
    const locationState = location.state;
    const history = useHistory();
    const dispatch = useDispatch();
    let nextPathName = locationState && locationState.nextPathname;
    if (nextPathName?.pathname) {
        nextPathName = nextPathName.pathname;
    }
    const nextSearch = locationState && (locationState.nextSearch || "");

    const login = useCallback(
        (params = {}, pathName) =>
            authProvider.login(params).then(ret => {
                dispatch(resetNotification());
                
                const redirectUrl = pathName
                    ? pathName
                    : nextPathName ? nextPathName + nextSearch :
                      defaultAuthParams.afterLoginUrl;
                history.push(redirectUrl);
                return ret;
            }),
        [authProvider, history, nextPathName, nextSearch, dispatch]
    );

    const loginWithoutProvider = useCallback(
        (_, __) => {
            dispatch(resetNotification());
            history.push(defaultAuthParams.afterLoginUrl);
            return Promise.resolve();
        },
        [history, dispatch]
    );

    return authProvider ? login : loginWithoutProvider;
};


export default useLogin;
