import Wrapper from "../utils/Wrapper";
import axios from 'axios';
import { stringify } from "query-string";

const dataProviderForAdmins = {

  getOne: async (resource, params) => {
    const url = process.env.REACT_APP_API_URL + "/admin/" + params.id;
    const response = await axios.get(url,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      });
    let resultData = Wrapper.wrapAdmin(response.data.data);
    return ({
      data: resultData,
    });
  },

  delete: async (resource, params) => {
    const url = process.env.REACT_APP_API_URL + "/admin/" + params.id;
    const response = await axios.delete(url,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      });

    if (response.status === 200) {
      return { data: { id: params.id } }
    }
  },

  getList: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      'field': field === 'id' ? 'adminId' : field,
      'order': order,
      'page': page,
      'perPage': perPage,
    };
    const url = process.env.REACT_APP_API_URL + '/admin?' + stringify(query);
    const response = await axios.get(url,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      });

    if (!response || !response.data || !response.data.data) {
      return ({
        data: [],
        total: 0
      });
    }

    let resultData = response.data.data.admins.map(admin => Wrapper.wrapAdmin(admin));
    let count = response.data.data.totalElements;

    return ({
      data: resultData,
      total: count
    });
  },

  create: async (resource, params) => {
    let data = params.data;
    data.deviceId = 'deviceId_' + Math.random() * 123;
    data.clientType = 'WEB';
    data.firstName = data.firstName.trim();
    data.lastName = data.lastName.trim();
    data.roles = [data.roles];
    if (!data.roles || !data.roles.length) {
      data.roles = ["ADMIN"];
    }
    let response = await axios.post(process.env.REACT_APP_API_URL + "/admin", data,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      }
    );

    if (response && response.data) {
      let dataRes = response.data;
      dataRes.data.id = dataRes.data.adminId;
      return ({
        data: dataRes.data
      });
    } else {
      return ({
        data: []
      });
    }
  },

  update: async (resource, params) => {
    let data = params.data;
    data.deviceId = 'deviceId_' + Math.random() * 123;
    data.clientType = 'WEB'
    data.editedBy = localStorage.getItem('login');
    data.newPassword = params.data.password;
    data.firstName = data.firstName.trim();
    data.lastName = data.lastName.trim();
    if (!Array.isArray(data.roles)) {
      data.roles = [data.roles];
    }
    let response = await axios.put(process.env.REACT_APP_API_URL + "/admin/" + params.data.adminId, data,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      }
    );

    if (response && response.data) {
      let dataRes = response.data;
      dataRes.data.id = dataRes.data.adminId;
      return ({
        data: dataRes.data
      });
    } else {
      return ({
        data: []
      });
    }
  },
};

export default dataProviderForAdmins;
