import { email, maxLength, minLength, required } from "react-admin";

export default {
  validateName: [
    required(),
    minLength(1),
    maxLength(32),
    (value, allValues) => {
      if (!value.trim()) {
        return "ra.validation.required";
      }

      return undefined;
    },
  ],
  
  validateEmail: [
    required(),
    email(),
    minLength(5),
    maxLength(128),
    (value, allValues) => {
      if (!value.trim()) {
        return "ra.validation.required";
      }

      return undefined;
    },
  ],
  validateCompanyEmail: [email(), minLength(5), maxLength(255)],
  validatePassword: [
    required(),
    minLength(6),
    maxLength(64),
    (value, allValues) => {
      if (!value.trim()) {
        return "ra.validation.required";
      }

      return undefined;
    },
  ],
  validateConfirmPassword: [
    required(),
    minLength(6),
    maxLength(64),
    (value, values) => {
      if (value !== values.password) {
        return "ra.validation.password_mismatch";
      }
      return undefined;
    },
  ],

  validatePhone: [
    required(),
    (value, allValues) => {
      if (!value) {
        return "ra.validation.required";
      }
      let re = /^05\d{8}$/;
      if (re.test(value) === false) {
        return "ra.validation.phone";
      }

      return undefined;
    },
  ],

  validateCompanyPhone: (value) => {
    if (!value) {
      return undefined;
    }

    let re = /^05\d{8}$/;
    if (re.test(value) === false) {
      return "ra.validation.phone";
    }

    return undefined;
  },

  validateNumber: [
    required(),
    (value, allValues) => {
      if (!value) {
        return "ra.validation.required";
      }

      let re = /^[0-9.,]+$/;
      if (re.test(value) === false) {
        return "ra.validation.numberAmount";
      }

      return undefined;
    },
  ],


  
  validateLink: (value, allValues) => {
      if (!value) {
        return "ra.validation.required";
      }

      let re = /^(ftp|http|https):\/\/[^ "]+$/;
      if(re.test(value) === false) {
        return "ra.validation.link";
      }
      return undefined;
  },


  validatePasswordNotRequired: (value, allValues) => {
    if (!value) {
      return undefined;
    }
    if (value.length > 64 || value.length < 6) {
      return "konebone.sign_in.password_size";
    }
    if (value !== allValues.password) {
      return "ra.validation.password_mismatch";
    }
    return undefined;
  },

  validateEmailSignIn: (email) => {
    if (!email) {
      return undefined;
    }
    if (email.length > 225) {
      return "ra.validation.email";
    }

    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (re.test(email)) {
      let idxStart = email.indexOf("@");
      if (idxStart > 64) {
        return "ra.validation.email";
      }

      let idx = email.indexOf(".", idxStart);
      while (idx !== -1) {
        if (idx - idxStart > 64) {
          return "ra.validation.email";
        }
        idxStart = idx + 1;
        idx = email.indexOf(".", idxStart);
      }
      if (email.length - idxStart + 1 > 64) {
        return "ra.validation.email";
      }
    } else {
      return "ra.validation.email";
    }
    return "";
  },

  validatePasswordSignIn: (password) => {
    return password.length >= 6 && password.length <= 64;
  },


  validateRoles: required(),

  validateNameManufacturer: [
    required(),
    minLength(1),
    maxLength(500),
    (value, allValues) => {
      if (!value.trim()) {
        return "ra.validation.required";
      }

      return undefined;
    },
  ],
  validateDescriptionManufacturer: [maxLength(10000)],
  validateNameManufacturingCountry: [
    required(),
    minLength(1),
    maxLength(100),
    (value, allValues) => {
      if (!value.trim()) {
        return "ra.validation.required";
      }

      return undefined;
    },
  ],

  validateName: [
    required(),
    minLength(1),
    maxLength(100),
    (value, allValues) => {
      if (!value.trim()) {
        return "ra.validation.required";
      }
      return undefined;
    },
  ],

  validateContactPhone: [
    minLength(1),
    maxLength(100),
    (value, allValues) => {
      if (!value.trim()) {
        return "ra.validation.required";
      }
      return undefined;
    },
  ],

  validateContactFullName: [
    minLength(1),
    maxLength(100),
    (value, allValues) => {
      if (!value.trim()) {
        return "ra.validation.required";
      }
      return undefined;
    },
  ],

  validateNameSupplier: [
    required(),
    minLength(1),
    maxLength(500),
    (value, allValues) => {
      if (!value.trim()) {
        return "ra.validation.required";
      }

      return undefined;
    },
  ],
  validateNameCategory: [
    required(),
    minLength(1),
    maxLength(500),
    (value, allValues) => {
      if (!value.trim()) {
        return "ra.validation.required";
      }

      return undefined;
    },
  ],
  validateCompanyDescription: [maxLength(500)],

  validateNameMeasurement: [
    required(),
    minLength(1),
    maxLength(100),
    (value, allValues) => {
      if (!value.trim()) {
        return "ra.validation.required";
      }

      return undefined;
    },
  ],

  validatePackageType: [
    required(),
    minLength(1),
    maxLength(100),
    (value, allValues) => {
      if (!value.trim()) {
        return "ra.validation.required";
      }

      return undefined;
    },
  ],

  validateCustomerTypeTitle: [
    required(),
    minLength(1),
    maxLength(150),
    (value, allValues) => {
      if (!value.trim()) {
        return "ra.validation.required";
      }

      return undefined;
    },
  ],

  validateCustomerComment: [
    required(),
    minLength(1),
    maxLength(150),
    (value, allValues) => {
      if (!value.trim()) {
        return "ra.validation.required";
      }

      return undefined;
    },
  ],

  validateFoundation: [  
    required(), 
    minLength(1), 
    maxLength(100),
    (value, allValues) => {
      if (!value.trim()) {
        return "ra.validation.required";
      }
      if (value.length > 100) {
        return "ra.validation.maxValue";
      }

      return undefined;
    },
  ],

  validateActivity: [minLength(1), maxLength(100)],

  validateCity: [
    required(),
    minLength(1),
    maxLength(100),
    (value, allValues) => {
      if (!value.trim()) {
        return "ra.validation.required";
      }
      if (value.length > 100) {
        return "ra.validation.maxValue";
      }

      return undefined;
    },
  ],

  validateStreet: [
    required(),
    minLength(1),
    maxLength(100),
    (value, allValues) => {
      if (!value.trim()) {
        return "ra.validation.required";
      }
      if (value.length > 100) {
        return "ra.validation.maxValue";
      }

      return undefined;
    },
  ],

    validateStreet: (value) => {
    if (!value?.trim()) {
      return "ra.validation.required";
    }
    if (value.length > 100) {
      return "ra.validation.maxValue";
    }
    return undefined;
  },

  validateCity: (value) => {
    if (!value?.trim()) {
      return "ra.validation.required";
    }
    if (value.length > 100) {
      return "ra.validation.maxValue";
    }
    return undefined;
  },


  validateFirstName: (value) => {
    if (!value) {
      return undefined;
    }
    return value.length <= 100 ? "" : "ra.validation.maxLength";
  },

  validateLastName: (value) => {
    if (!value) {
      return undefined;
    }
    return value.length <= 100 ? "" : "ra.validation.maxLength";
  },

  validateManager: [
    required(),
    minLength(1),
    maxLength(10),
    (value, allValues) => {
      if (!value.trim()) {
        return "ra.validation.required";
      }

      return undefined;
    },
  ],


  validateJobTitle: (value) => {
    if (!value) {
      return undefined;
    }
    return value.length <= 100 ? "" : "ra.validation.maxLength";
  },

  validateTaxId: [
    required(),
    minLength(1),
    maxLength(10),
    (value, allValues) => {
      if (!value?.trim()) {
        return "ra.validation.required";
      }
      let regex = /^\d{9}$/;
      if (regex.test(value) === false) {
        return "ra.validation.tax_id";
      }

      return undefined;
    }
  ],

  validateDistrict: [minLength(1), maxLength(200)],

  validateUnit: [
    required(),
    minLength(1),
    maxLength(10),
    (value, allValues) => {
      if (!value.trim()) {
        return "ra.validation.required";
      }

      return undefined;
    },
  ],

  validateCompanyName: [
    required(),
    minLength(1),
    maxLength(100),
    (value, allValues) => {
      if (!value.trim()) {
        return "ra.validation.required";
      }
      var reg = /^\d+$/;
      return undefined;
    },
  ],

  validateRequired: required(),

  validateCustomerPhone: (value) => {
    if (!value) {
      return undefined;
    }

    let re = /^05\d{8}$/;
    if (re.test(value) === false) {
      return "ra.validation.phone";
    }

    return undefined;
  },
  validateContractorNumber: [minLength(1), maxLength(100)],
  validateCompanyPhoneNumber: [minLength(1), maxLength(25)],

  validateReasonBlockCompany: [required(), maxLength(500)],

  validateReasonBlock: (value) => {
    if (!value) {
      return undefined;
    }
    if (value.length > 500) {
      return "ra.validation.maxValue";
    }
    return undefined;
  },

  validateDeliveryContactName: (value) => {
    if (!value?.trim()) {
      return "ra.validation.required";
    }
    if (value.length > 100) {
      return "ra.validation.maxValue";
    }
    return undefined;
  },

  validateDeliveryContactPhone: (value) => {
    if (!value?.trim()) {
      return "ra.validation.required";
    }

    let re = /^05\d{8}$/;
    if (re.test(value) === false) {
      return "ra.validation.phone";
    }

    return undefined;
  },

  validateDeliveryCost: (value) => {
    if (!value?.trim()) {
      return "ra.validation.required";
    }
    return undefined;
  },

  validateApt: (value) => {
    if (!value?.trim()) {
      return "ra.validation.required";
    }
    if (value.length > 10) {
      return "ra.validation.maxValue";
    }
    return undefined;
  },

  validateQuantityOnPallet: (value) => {
    if (!value) {
      return undefined;
    }

    if (isNaN(value) || +value < 0) {
      return "ra.validation.number";
    }

    if (value >= 100000000) {
      return "ra.validation.maxValue";
    }

    let regex = /^\d{0,8}\.?\d{0,2}$/;

    if (!regex.test(value)) {
      return "ra.validation.regex";
    }

    return value.length <= 100 ? "" : "ra.validation.maxLength";
  },
};
