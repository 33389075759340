import Wrapper from "../utils/Wrapper";
import axios from 'axios';
import { stringify } from "query-string";

const dataProviderForMeasurement = {

    delete: async (resource, params) => {
        const url = process.env.REACT_APP_API_URL + "/measurement/" + params.id;
        const response = await axios.delete(url,
            {
                withCredentials: true,
                headers: { 'accept': 'application/json' }
            });
        if (response.status === 200) {
            return { data: { id: params.id } }
        }
    },

    getList: async (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            'field': field === 'id' ? 'measurementId' : field,
            'order': order,
            'page': page,
            'perPage': perPage,
        };
        const url = process.env.REACT_APP_API_URL + '/measurement/pageable?' + stringify(query);
        const response = await axios.get(url,
            {
                withCredentials: true,
                headers: { 'accept': 'application/json' }
            });
        if (!response.data || !response.data.data) {
            return ({
                data: [],
                total: 0
            });
        }

        let resultData = response.data.data.measurements.map(measurement => Wrapper.wrapMeasurement(measurement));
        const responseTotalCount = response.data.data.totalElements;

        return ({
            data: resultData,
            total: responseTotalCount
        });
    },

    create: async (resource, params) => {
        let data = params.data;
        data.title = data.title.trim();
        let response = await axios.post(process.env.REACT_APP_API_URL + "/measurement", data,
            {
                withCredentials: true,
                headers: { 'accept': 'application/json' }
            }
        );

        if (response && response.data) {
            let dataRes = response.data;
            dataRes.data.id = dataRes.data.measurementId;
            return ({
                data: dataRes.data
            });
        } else {
            return ({
                data: []
            });
        }
    },

    getOne: async (resource, params) => {
        const url = process.env.REACT_APP_API_URL + "/measurement/" + params.id;
        const response = await axios.get(url,
            {
                withCredentials: true,
                headers: { 'accept': 'application/json' }
            });
        let resultData = Wrapper.wrapMeasurement(response.data.data);
        return ({
            data: resultData,
        });
    },

    update: async (resource, params) => {
        let data = params.data;
        data.title = data.title.trim();
        let response = await axios.put(process.env.REACT_APP_API_URL + "/measurement/" + params.data.measurementId, data,
            {
                withCredentials: true,
                headers: { 'accept': 'application/json' }
            }
        );

        if (response && response.data) {
            let dataRes = response.data;
            dataRes.data.id = dataRes.data.measurementId;
            return ({
                data: dataRes.data
            });
        } else {
            return ({
                data: []
            });
        }
    },

};

export default dataProviderForMeasurement;
