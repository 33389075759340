import { 
  Button,
  Dialog, 
  DialogActions, 
  DialogContent,
  DialogTitle, 
  makeStyles, 
  Typography
} from "@material-ui/core";
import currency from "currency.js";
import { FormWithRedirect, useNotify, useTranslate } from "ra-core";
import { useState } from "react";
import { TextInput } from "react-admin";
import dataProviderForOrders from "../../providers/DataProviderForOrders";
import { handleFailure } from "../../utils/handleFailure";
import Validator from "../../utils/Validator";


const useStyles = makeStyles((theme) => ({
  capitalize: {
    textTransform: "capitalize"
  },
  root: {
    "& * > button": {
      textTransform: "capitalize",
    }
  },
  validate: {
    '& fieldset' : {
      borderColor: 'red',
    },
    '& p' : {
      color: 'red',
    }
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  }
}));

const requiredFields = ["invoice", 'invoiceLink', 'amount'];
const ILS = (value) =>
  currency(value, { symbol: "₪ ", decimal: ".", separator: "," });

const PackingSlipsVerify = ({open, closeSendDialog, data, paidData}) => {

  const [invoice, setInvoice] = useState('');
  const [invoiceLink, setInvoiceLink] = useState('');
  const [amount, setAmount] = useState('');
  const [linkError, setLinkError] = useState(false);
  const [error, setError] = useState(false);
  const classes = useStyles();
  const notify = useNotify();
  const translate = useTranslate();

  const handleInvoice = (e) => {
    if(e.target.value || e.target.value > 0){
      setError({ ...error, [e.target.id]: false });
     }
     setInvoice(e.target.value)
  }

  const handleInvoiceLink = (e) => {
    if(e.target.value || e.target.value > 0){
      setError({ ...error, [e.target.id]: false });
     }
     setInvoiceLink(e.target.value)
  }

  const handleAmount = (e) => {
    if(e.target.value || e.target.value > 0){
      setError({ ...error, [e.target.id]: false });
     }
     setAmount(e.target.value)
  }


  const validator = (e) => {
    if (requiredFields.includes(e.target.id) && !e.target.value.length || e.target.value <= 0) {
      setError({ ...error, [e.target.id]: true });
    }
  };

  const handleClose = (e) => {
    e.stopPropagation();
    closeSendDialog(true);
  };

  const verify = () => {
    let isError = false;

    if(!amount || !invoice || !invoiceLink) {
      setError({...error, invoice: !invoice, invoiceLink: !invoiceLink,  amount: !amount,})
      return
    }

    if(Validator.validateLink(invoiceLink)) {
      setLinkError(Validator.validateLink(invoiceLink));
      isError = true;
     }

     if (isError) {
      return;
    }
    
    dataProviderForOrders.verified('/orders', {
      id: data.id,
      invoice: {
          invoice: invoice ,
          invoiceAmount: amount,
          invoiceLink: invoiceLink
      }
    })
    .then((item)=> {
      closeSendDialog();
      // getInvoideData(item)
    })
    .catch((error) => {
      handleFailure(error, notify);
    });
  }

  return (
    <>
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" className={classes.capitalize}>
        {translate("konebone.orders.mark_packing_slip_verified")} 
      </DialogTitle>
      <DialogContent>
        <Typography className={classes.header}>
          <span>{translate("konebone.orders.order_total")}</span>  
          <span>{ILS(paidData?.total).format()}</span>
        </Typography>
      <FormWithRedirect
        record={data}
        render={(formProps) => (
          <form>
        <TextInput
          fullWidth
          required
          source="invoice"
          label={translate("konebone.orders.invoice")}
          variant="outlined"
          helperText={error?.invoice && translate("ra.validation.required")}
          onBlur={error?.invoice || validator}
          value={invoice}
          className={`${error?.invoice && classes.validate}`}
          onChange={(event) => handleInvoice(event)}
        >
        </TextInput>
        <TextInput 
          fullWidth
          required
          source="name"
          label={translate("konebone.orders.invoice_link")}
          variant="outlined"
          error={error?.invoiceLink || !!linkError}
          validate={Validator.validateLink}
          onBlur={error?.invoiceLink || validator}
          helperText={error?.invoiceLink && translate("ra.validation.required")}
          value={invoiceLink}
          className={`${error?.invoiceLink && classes.validate}`}
          onChange={(event) => handleInvoiceLink(event)}
          />
        <TextInput 
          type='number'
          fullWidth
          required
          source="amount"
          label={translate("konebone.orders.invoice_amount")}
          variant="outlined"
          onBlur={error?.amount || validator}
          helperText={error?.amount && translate("ra.validation.required")}
          value={amount}
          className={`${error?.amount && classes.validate}`}
          onChange={(event) => handleAmount(event)}
          >
        </TextInput>
        </form>
          )}
        />
      </DialogContent>
      <DialogActions className={classes.root}>
        <Button onClick={closeSendDialog} variant="outlined">
          {translate("ra.action.cancel")}
        </Button>
        <Button onClick={verify} variant="contained" color="secondary">
          {translate("konebone.orders.verify")}
        </Button>
      </DialogActions>
    </Dialog>
    </>
  );
};

export default PackingSlipsVerify;
