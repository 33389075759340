import Wrapper from "../utils/Wrapper";

import axios from "axios";
import { stringify } from "query-string";

const dataProviderForProducts = {
  getOne: async (resource, { id }) => {
    const url = process.env.REACT_APP_API_URL + `/product/${id}`;
    const response = await axios.get(url, {
      withCredentials: true,
      headers: { accept: "application/json" },
    });

    const pictureRes = await axios.get(process.env.REACT_APP_API_URL + `/product_picture/${id}`, {
      withCredentials: true,
      headers: { accept: "application/json" },
    });

    let resultData = Wrapper.wrapProduct({
      fileName: pictureRes.data?.data?.[0]?.fileName,
      productPictureId: pictureRes.data?.data?.[0]?.productPictureId,
      ...response.data.data,
    });;

    return {
      data: resultData,
    };
  },

  getList: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const { q, deactivate } = params.filter;

    const query = {
      page: page,
      perPage: perPage,
      search: q,
      active: !deactivate,
    };

    if (field !== "id") {
      query.field = field === "catalogNumber" ? "productNumber" : field;
      query.order = order;
    }
    const url =
      process.env.REACT_APP_API_URL + "/product_catalog?" + stringify(query);
    const response = await axios.get(url, {
      withCredentials: true,
      headers: { accept: "application/json" },
    });

    if (!response.data.data.products) {
      return {
        data: [],
        total: 0,
      };
    }

    let resultData = response.data.data.products.map((product) =>
      Wrapper.wrapProductForCatalog(product)
    );

    const totalElements = response.data.data.totalElements;
    return {
      data: resultData,
      total: totalElements,
    };
  },

  create: async (resource, { data: { file, fileUrl, ...restData } }) => {
    // const data = {
    //   description: params.data.description,
    //   name: params.data.name,
    //   image: params.data.image?.src,
    //   parentCategoryId: params.data.parent_id || defaultParentId,
    //   position: params.data.position || 0,
    // };

    let productRes = await axios.post(
      process.env.REACT_APP_API_URL + "/product",
      restData,
      {
        withCredentials: true,
        headers: { accept: "application/json" },
      }
    );

    let pictureRes = {};
    let pictureError = ""

    try {
      if (file) {
        const bodyFormData = new FormData();
        bodyFormData.append('file', file, file.name);
        pictureRes = await axios.post(
          process.env.REACT_APP_API_URL + `/product_picture?productId=${productRes.data.data.productId}`,
          bodyFormData,
          {
            withCredentials: true,
            headers: { accept: "application/json", "Content-Type": "multipart/form-data" },
          }
        );
      }

      if (fileUrl) {
        const query = {
          productId: productRes.data.data.productId,
          fileUrl: fileUrl,
        };

        pictureRes = await axios.post(
          process.env.REACT_APP_API_URL + "/product_picture?" + stringify(query), {},
          {
            withCredentials: true,
            headers: { accept: "application/json", "Content-Type": "multipart/form-data" },
          }
        );
      }
    } catch (e) {
      if (e.response?.data) {
        pictureError = e.response?.data.message;
      }
    } finally {
      let resultData = Wrapper.wrapProduct({
        fileName: pictureRes.data?.data.fileName,
        productPictureId: pictureRes.data?.data?.productPictureId,
        pictureError: pictureError,
        ...productRes.data.data,
      });

      return {
        data: resultData,
      };
    }
  },

  update: async (resource, { id, data: { file, fileUrl, productPictureId, ...restData } }) => {
    // const updateData = {
    //   categoryId: id,
    //   description: data.description,
    //   name: data.name,
    //   image: data.image?.src,
    //   parentCategoryId: data.parent_id || 0,
    //   position: data.position || 0,
    // };

    let response = await axios.put(
      process.env.REACT_APP_API_URL + `/product/${id}`,
      restData,
      {
        withCredentials: true,
        headers: { accept: "application/json" },
      }
    );

    let pictureRes = {};

    if (file) {
      const bodyFormData = new FormData();
      bodyFormData.append('file', file, file.name);
      if (productPictureId) {
        pictureRes = await axios.put(
          process.env.REACT_APP_API_URL + `/product_picture/${productPictureId}`,
          bodyFormData,
          {
            withCredentials: true,
            headers: { accept: "application/json", "Content-Type": "multipart/form-data" },
          }
        );
      } else {
        pictureRes = await axios.post(
          process.env.REACT_APP_API_URL + `/product_picture?productId=${response.data.data.productId}`,
          bodyFormData,
          {
            withCredentials: true,
            headers: { accept: "application/json", "Content-Type": "multipart/form-data" },
          }
        );
      }
    }

    if (fileUrl) {
      if (productPictureId) {
        const query = {
          fileUrl: fileUrl,
        };

        pictureRes = await axios.put(
          process.env.REACT_APP_API_URL + `/product_picture/${productPictureId}?` + stringify(query),
          {},
          {
            withCredentials: true,
            headers: { accept: "application/json" },
          }
        );
      } else {
        const query = {
          productId: response.data.data.productId,
          fileUrl: fileUrl,
        };

        pictureRes = await axios.post(
          process.env.REACT_APP_API_URL + "/product_picture?" + stringify(query), {},
          {
            withCredentials: true,
            headers: { accept: "application/json" },
          }
        );
      }
    }

    let resultData = Wrapper.wrapProduct({
      fileName: pictureRes.data?.data.fileName,
      productPictureId: pictureRes.data?.data?.productPictureId,
      ...response.data.data,
    });

    return {
      data: resultData,
    };
  },

  updateStatus: async (resource, { data }) => {
    let response = await axios.put(
      process.env.REACT_APP_API_URL + `/product/product_status`,
      data,
      {
        withCredentials: true,
        headers: { accept: "application/json" },
      }
    );

    return {
      data: response.data?.success,
    };
  },

  delete: async (resource, { id }) => {
    let response = await axios.delete(
      process.env.REACT_APP_API_URL + `/product_picture/${id}`,
      {
        withCredentials: true,
        headers: { accept: "application/json" },
      }
    );

    return {
      data: response.data.success,
    };
  },

  searchAlternative: async (resource, params) => {
    const query = {
      search: params.search,
      supplierId: params.supplierId,
    };
    const url =
      process.env.REACT_APP_API_URL + "/product/searchAlternative?" + stringify(query);
    const response = await axios.get(url, {
      withCredentials: true,
      headers: { accept: "application/json" },
    });

    if (!response.data.data) {
      return {
        data: [],
      };
    }
    return {
      data: response.data.data,
    };
  },

  search: async (resource, params) => {
    const url = process.env.REACT_APP_API_URL + "/product/search?search=" +params.search;
    const response = await axios.get(url, {
      withCredentials: true,
      headers: { accept: "application/json" },
    });

    if (!response.data.data) {
      return {
        data: [],
      };
    }

    return {
      data: response.data.data,
    };
  },

  
  searchOffer: async (resource, params) => {
    const url = process.env.REACT_APP_API_URL + "/product/search/offer/" + params.offerId + "?search=" +params.search;
    const response = await axios.get(url, {
      withCredentials: true,
      headers: { accept: "application/json" },
    });

    if (!response.data.data) {
      return {
        data: [],
      };
    }

    return {
      data: response.data.data,
    };
  },
};

export default dataProviderForProducts;
