import * as React from "react";
import { useState, useEffect, useRef } from "react";
import {
  List,
  TextField,
  TopToolbar,
  ExportButton,
  EditButton,
  useNotify,
  CreateButton,
} from "react-admin";
import {
  Paper,
  Tab,
  Tabs,
  makeStyles,
  Box,
} from "@material-ui/core";
import { useAuthState, useListContext, useTranslate, useVersion } from "ra-core";
import DatagridResponsive from "../../components/DataGridResponsive";
import { cleanSession } from "../../authProvider";
import dataProviderForSupplier from "../../providers/DataProviderForSupplier";
import SupplierActionsButtons from "./SuppliersActionsButtons";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "stretch",
    "& > *": {
      marginLeft: theme.spacing(1),
    },
  },
  rootButton: {
    flexDirection: "row",
    alignItems: "stretch",
    justifyContent: 'end',
    marginBottom: '22px',
  },
  listRoot: {
    "& form": {
      width: "100%",
    },
    "& form > div:first-child": {
      width: "100%",
    },
  },
  textField: {
    marginBottom: 0,
    width: "100%",
    "& > div": {
      maxHeight: "40px",
      width: "100%",
    },
    "& > label": {
      fontSize: "14px",
    },
  },
  approve: {
    border: "1px solid green",
    borderRadius: "3px",
    width: "30px",
    minWidth: "30px",
    maxWidth: "30px",
  },
  decline: {
    border: "1px solid red",
    borderRadius: "3px",
    width: "30px",
    minWidth: "30px",
    maxWidth: "30px",
  },
  capitalize: {
    textTransform: "capitalize",
  },
}));

const statusColorMap = {
  BLOCKED: "#E02443",
  DECLINED: "#F2994A",
  UNFINISHED: "#686868",
}

const statusesMap = ["candidate", "active", "inactive"]; 

const ListActions = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter, // you can hide ExportButton if exporter = (null || false)
  filterValues,
  permanentFilter,
  hasCreate, // you can hide CreateButton if hasCreate = false
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Box width="100%">
      <TopToolbar className={className, classes.root}>
        {exporter && (
          <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={{ ...filterValues, ...permanentFilter }}
            exporter={exporter}
            maxResults={maxResults}
          />
        )}
      </TopToolbar>
      <SuppliersFilter total={total} />
    </Box>
  )
};

ListActions.defaultProps = {
  selectedIds: [],
  onUnselectItems: () => null,
};

const SuppliersFilter = ({ total }) => {
  const [value, setValue] = useState(0);
  const [tabCounts, setTabCounts] = useState({});
  const classes = useStyles();
  const translate = useTranslate();
  const notify = useNotify();
  const {
    setFilters,
    filterValues,
  } = useListContext();

  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    if (filterValues) {
      const index = statusesMap.indexOf(filterValues.status);
      index >= 0 && setValue(index)
    }

    return () => {
      mounted.current = false;
    };
  }, [total])

  useEffect(() => {
    if (!mounted.current) {
      return;
    }

    if ((!tabCounts.candidate && total === null) || (total !== undefined && tabCounts[statusesMap[value]] !== total)) {
      dataProviderForSupplier.getCountByStatus()
      .then((data) => {
        if (mounted.current) {
          setTabCounts(data?.data);
        }
      })
        .catch((error) => {
          if (!error.response) {
            console.error(error);
            return;
          }
          let { status } = error.response;
          if (status === 401 || status === 403) {
            cleanSession();
            window.location.href = "/login";
          }
          if (status === 500) {
            notify(
              error.response.data?.error,
              "warning"
            )
            return;
          }
        });
    }
    return () => {
      mounted.current = false;
    };
  }, [total]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setFilters({ ...filterValues, status: statusesMap[newValue] });
  };

  return (
    <div>
        <Box display="flex" className={classes.rootButton}>
          <CreateButton
            icon={<></>}
            variant="contained"
            color="secondary"
            basePath="/supplier"
            label="konebone.supplier.add_supplier"
          />
        </Box>
      <Paper position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="secondary"
        >
          <Tab className={classes.capitalize} label={`${translate("konebone.supplier.candidates")} (${tabCounts.candidate || 0})`} />
          <Tab className={classes.capitalize} label={`${translate("konebone.supplier.active")} (${tabCounts.active || 0})`} />
          <Tab className={classes.capitalize} label={`${translate("konebone.supplier.inactive")} (${tabCounts.inactive || 0})`} />
        </Tabs>
      </Paper>
    </div>
  );
};

const SupplierStatusField = (props) => {
  const color = statusColorMap[props.record?.status];

  return (
    <TextField
      style={{ color: color, textTransform: "capitalize" }}
      {...props}
    />
  )
}

const SupplierEditButton = ({ record, resource, basePath, ...props }) => {
  return (
    <div className="listButton">
      <EditButton label={""} record={record} resource={resource} basePath={basePath} />
    </div>
  );
};

const SupplierDataGrid = ({ filterValues, ...props }) => {
  const translate = useTranslate();
  let statusField = filterValues?.status === statusesMap[2] ? [<SupplierStatusField key="SupplierStatusField" source="statusLower" label={translate("konebone.supplier.status")} />] : [];
  return (
    <DatagridResponsive rowClick="show" className={"sticky-action_btn"} {...props}>
      <TextField
        source="name"
        label={translate("konebone.supplier.business_company_name")}
      />
      <TextField
        source="activity"
        label={translate("konebone.supplier.field_of_activity")}
      />
      <TextField
        source="address"
        label={translate("konebone.supplier.address")}
      />
      <TextField
        source="mainContact"
        label={translate("konebone.supplier.main_contact")}
      />
      <TextField
        source="phone"
        label={translate("konebone.supplier.phone_number")}
      />
      <TextField
        source="taxId"
        label={translate("konebone.supplier.tax_id")}
      />
      <TextField
        source="foundation"
        label={translate("konebone.supplier.company_foundation")}
      />

      {statusField}

      <ButtonsComponent />

    </DatagridResponsive >)
}

function ButtonsComponent({ record, ...props }) {
  let btnArray = [];
  if (!record) {
    return <></>;
  }
  
  btnArray = SupplierActionsButtons(record.status, record, "small")?.buttons;

  btnArray.unshift(<SupplierEditButton key="SupplierEditButton" record={record} {...props} />)

  return (
    <div className="flex-container">
      {btnArray}
    </div>
  )
}

export const SupplierList = (props) => {
  const translate = useTranslate();
  const classes = useStyles();
  const { loading, authenticated } = useAuthState();

  if (!authenticated || loading) {
    return null;
  }

  return (
    <List
      title={translate("konebone.supplier.title").toUpperCase()}
      exporter={null}
      {...props}
      actions={<ListActions />}
      empty={<ListActions />}
      bulkActionButtons={false}
      className={classes.listRoot}
      sort={{ field: "name", order: "DESC" }}
    >
      <SupplierDataGrid />
    </List>
  );
};

