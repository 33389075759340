import { cleanSession } from "../authProvider";
import { wrapArrayToMap } from "./Wrapper";

const handleFailure = (error, notify) => {
  if (!error.response) {
    console.error(error);
    return;
  }
  let { status } = error.response;
  if (status === 401 || status === 403) {
    cleanSession();
    window.location.href = "/login";
  }
  if (status === 500) {
    notify(
      error.response.data?.error,
      "warning"
    )
    return;
  }
  notify(
    error.response.data?.message?.message,
    "warning",
    wrapArrayToMap(error.response.data?.message?.parameters)
  );
}

export {handleFailure}