import { useAuthState, useRedirect, useTranslate } from "ra-core";
import React, { useCallback, useState } from "react";
import {
  Create,
  useNotify,
  TopToolbar,
  Confirm,
} from "react-admin";
import {
  Button,
  makeStyles,
  Box,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { cleanSession } from "../../authProvider";
import StepperForm from "./StepperForm";

const useStyles = makeStyles((theme) => ({
  toolbar_root: {
    justifyContent: "space-between",
    "& button": {
      margin: theme.spacing(1),
    },
  },
  stepper_root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: "inline-block",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const ProductCreateActions = ({
  handlePrevious,
  handleNext,
  activeStep,
  isLastStep,
  basePath,
}) => {
  const [openSaveDialog, setOpenSaveDialog] = useState(false);
  const translate = useTranslate();
  const classes = useStyles();
  const redirect = useRedirect();

  const handleSaveClick = () => setOpenSaveDialog(true);

  const handleSaveDialogClose = () => setOpenSaveDialog(false);

  const handleConfirm = () => {
    setOpenSaveDialog(false);
    redirect("list", basePath);
  };

  return (
    <TopToolbar className={classes.toolbar_root}>
      <Button
        label={translate("konebone.products.go_back")}
        startIcon={<KeyboardBackspaceIcon />}
        component={Link}
        to="/products"
      >
        {translate("konebone.products.go_back")}
      </Button>
      <Box>
        <Button
          variant="outlined"
          color="secondary"
          label={translate("konebone.products.previous")}
          onClick={handlePrevious}
          disabled={activeStep === 0}
        >
          {translate("konebone.products.previous")}
        </Button>
        {isLastStep() ? (
          <Button
            onClick={handleSaveClick}
            color="secondary"
            variant="contained"
            label={translate("ra.action.save")}
          >
            {translate("ra.action.save")}
          </Button>
        ) : (
          <Button
            color="secondary"
            variant="contained"
            onClick={handleNext}
            label={translate("konebone.products.next")}
          >
            {translate("konebone.products.next")}
          </Button>
        )}
      </Box>
      <Confirm
        isOpen={openSaveDialog}
        title=""
        content={translate("confirm_messages.products.save")}
        onConfirm={handleConfirm}
        onClose={handleSaveDialogClose}
      />
    </TopToolbar>
  );
};

const AddProductsTitle = ({ record }) => {
  const translate = useTranslate();
  return (
    <span>{translate("konebone.products.add_product").toUpperCase()}</span>
  );
};

const ProductsCreate = (props) => {
  const [activeStep, setActiveStep] = useState(0);
  const [preActiveStep, setPreActiveStep] = useState(0);

  const notify = useNotify();
  const translate = useTranslate();
  const { loading, authenticated } = useAuthState();

  const steps = [
    translate("konebone.products.general_info"),
    translate("konebone.products.price_lines"),
  ];

  const totalSteps = steps.length;

  const isLastStep = useCallback(() => {
    return activeStep === totalSteps - 1;
  }, [activeStep]);

  const handleFailure = (error) => {
    if (!error.response) {
      console.error(error);
      return;
    }
    let { status } = error.response;
    if (status === 401 || status === 403) {
      cleanSession();
      window.location.href = "/login";
    }
    if (status === 500) {
      notify(
        error.response.data?.error,
        "warning"
      )
      return;
    }
    notify(
      error.response.data?.message?.message,
      "warning",
      wrapArrayToMap(error.response.data?.message?.parameters)
    );
  };

  const handleStep = (step) => () => {
    setPreActiveStep(step);
  };

  const handleToStep = useCallback((step) => {
    setActiveStep(step);
    setPreActiveStep(step);
  });

  const handlePrevious = (e) => {
    if (preActiveStep === 0) {
      return;
    }
    setPreActiveStep(preActiveStep - 1);
  };

  const handleNext = (e) => {
    setPreActiveStep(preActiveStep + 1);
  };

  if (!authenticated || loading) {
    return null;
  }

  return (
    <Create
      title={<AddProductsTitle />}
      onFailure={handleFailure}
      actions={
        <ProductCreateActions
          activeStep={activeStep}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
          isLastStep={isLastStep}
        />
      }
      {...props}
    >
      <StepperForm
        steps={steps}
        activeStep={preActiveStep}
        handleToStep={handleToStep}
        handleStep={handleStep}
        isLastStep={isLastStep}
      />
    </Create>
  );
};

export { ProductsCreate };
