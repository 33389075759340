import { useState } from 'react';
import {
  useTranslate,
  useNotify,
  TextInput,
} from "react-admin";
import {
  Button,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { ReactComponent as DateIcon } from "../../svg/date-icon.svg";
import { KeyboardDatePicker } from '@material-ui/pickers';
import dataProviderForCredit from '../../providers/DataProviderForCredit';
import { wrapArrayToMap } from '../../utils/Wrapper';
import { cleanSession } from '../../authProvider';

const useStyles = makeStyles((theme) => ({
  capitalize: {
    textTransform: "capitalize"
  },
  root: {
    "& * > button": {
      textTransform: "capitalize",
    }
  },
  validate: {
    '& fieldset' : {
      borderColor: 'red',
    },
    '& p' : {
      color: 'red',
    }
  }
}));

const requiredFields = ["extendedTerm", 'date', 'limit'];

const CreditCreate = ({record, open, closeSendDialog, data}) => {
  const [error, setError] = useState(false);
  const [limit, setLimit] = useState(null);
  const [extendedTerm, setExtendedTerm] = useState(null);
  const [date, setDate] = useState(null)
  const notify = useNotify();
  const classes = useStyles();
  const translate = useTranslate();

  const handleChangeCreditLimit = (e) => {
    if(e.target.value || e.target.value > 0) {
      setError({ ...error, [e.target.id]: false });
     }
    setLimit(e.target.value)
  }

  const handlePayment = (e) => {
    if(e.target.value || e.target.value > 0){
      setError({ ...error, [e.target.id]: false });
     }
     setExtendedTerm(e.target.value)
  }

  const changeDate = (date) => {
    if (date) {
      setError(false);
     }
    setDate( date );
  }

  const validator = (e) => {
    if (requiredFields.includes(e.target.id) && !e.target.value.length || e.target.value <= 0) {
      setError({ ...error, [e.target.id]: true });
    }
  };

  const handleClose = (e) => {
    e.stopPropagation();
    closeSendDialog();
  };


  const send = async () => {  
    const getYear = date?.getFullYear();
    const month = `0${date?.getMonth()+1}`.slice(-2);
    const day = date?.getDate() < 10 ? `0${date?.getDate()}` : date?.getDate();
  
    
    if(limit < 0) {
      setError({...error, limit: true})
      return
    }
    if(extendedTerm < 0) {
      setError({...error, extendedTerm: true})
      return
    }

    if(!date || !limit || !extendedTerm) {
      setError({...error, extendedTerm: !extendedTerm, limit: !limit,  date: !date})
      return
    }

    try {
      await dataProviderForCredit.openCredit("customer", {
        customerId: record.id,
        creditLineRequestDto: {
          expireDate: `${getYear}-${month}-${day}`,   
          limit: +limit,
          term: +extendedTerm}
      })
      closeSendDialog();
    } catch (error) {
      if (!error.response) {
        console.error(error);
        return;
      }
      let { status } = error.response;
      if (status === 401 || status === 403) {
        cleanSession();
        window.location.href = "/login";
      }
      if (status === 500) {
        notify(
          error.response.data?.error,
          "warning"
        )
        return;
      }
      notify(
        error.response.data?.message?.message,
        "warning",
        wrapArrayToMap(error.response.data?.message?.parameters)
      );
    };
  }
  

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className={classes.capitalize}>
          {translate("konebone.customer.enable_credit_line")}
        </DialogTitle>
        <DialogContent>
          <TextInput
            type='number'
            fullWidth
            required
            source="limit"
            label={translate("konebone.customer.credit_limit")}
            variant="outlined"
            value={limit}
            className={`${error?.limit && classes.validate}`}
            onChange={(event) => handleChangeCreditLimit(event)}
            onBlur={validator}
            helperText={error?.limit && translate("ra.validation.required")}
          />
         <TextInput
          type='number'
          fullWidth
          required
          source="extendedTerm"
          label= {translate("konebone.customer.extended_payment_term")}
          variant="outlined"
          value={extendedTerm}
          className={`${error?.extendedTerm && classes.validate}`}
          onChange={(event) => handlePayment(event)}
          onBlur={validator}
          helperText={error?.extendedTerm && translate("ra.validation.required")}
        />
          <KeyboardDatePicker
            fullWidth
            id="date"
            clearable
            className={`${error?.date && classes.validate}`}
            format={"MMM dd, yyyy"}
            inputVariant="outlined"
            value={!date ? null : date}
            placeholder={translate("konebone.customer.expiration_date")}
            onChange={date => changeDate(date)}
            autoOk="false"
            keyboardIcon={<DateIcon />}
            error={error?.date}
            helperText={error?.date && translate("ra.validation.required")}
            disablePast={true}
            InputProps={{ readOnly: true }}
            margin="none"   
            onBlur={validator}           
          />
      </DialogContent>
      <DialogActions className={classes.root}>
        <Button onClick={handleClose} variant="outlined">
          {translate("ra.action.cancel")}
        </Button>
        <Button 
          onClick={send} 
          variant="contained" 
          color="secondary">
          {translate("konebone.customer.connect")}
        </Button>
      </DialogActions>
    </Dialog>
    )
};

export default CreditCreate
