const englishMessages = {
  ra: {
    action: {
      add_filter: "Add filter",
      add: "Add",
      back: "Go Back",
      bulk_actions: "1 item selected |||| %{smart_count} items selected",
      cancel: "Cancel",
      clear_input_value: "Clear value",
      clone: "Clone",
      confirm: "Confirm",
      create: "Create",
      create_item: "Create %{item}",
      delete: "Delete",
      edit: "Edit",
      export: "Export",
      list: "List",
      refresh: "Refresh",
      remove_filter: "Remove this filter",
      remove: "Remove",
      save: "Save",
      search: "Search",
      show: "Show",
      sort: "Sort",
      undo: "Undo",
      unselect: "Unselect",
      expand: "Expand",
      close: "Close",
      open_menu: "Open menu",
      close_menu: "Close menu",
    },
    boolean: {
      true: "Yes",
      false: "No",
      null: " ",
    },
    page: {
      create: "Create %{name}",
      dashboard: "Dashboard",
      edit: "%{name} #%{id}",
      error: "Something went wrong",
      list: "%{name}",
      loading: "Loading",
      not_found: "Not Found",
      show: "%{name} #%{id}",
      empty: "No %{name} yet.",
      invite: "Do you want to add one?",
    },
    input: {
      file: {
        upload_several: "Drop some files to upload, or click to select one.",
        upload_single: "Drop a file to upload, or click to select it.",
      },
      image: {
        upload_several: "Drop some pictures to upload, or click to select one.",
        upload_single: "Drop a picture to upload, or click to select it.",
      },
      references: {
        all_missing: "Unable to find references data.",
        many_missing:
          "At least one of the associated references no longer appears to be available.",
        single_missing:
          "Associated reference no longer appears to be available.",
      },
      password: {
        toggle_visible: "Hide password",
        toggle_hidden: "Show password",
      },
    },
    message: {
      about: "About",
      are_you_sure: "Are you sure?",
      bulk_delete_content:
        "Are you sure you want to delete this %{name}? |||| Are you sure you want to delete these %{smart_count} items?",
      bulk_delete_title: "Delete %{name} |||| Delete %{smart_count} %{name}",
      delete_content: "Are you sure you want to delete this item?",
      delete_title: "Delete %{name} #%{id}",
      details: "Details",
      error: "A client error occurred and your request couldn't be completed.",
      invalid_form: "The form is not valid. Please check for errors",
      loading: "The page is loading, just a moment please",
      no: "No",
      not_found: "Either you typed a wrong URL, or you followed a bad link.",
      yes: "Yes",
      unsaved_changes:
        "Some of your changes weren't saved. Are you sure you want to ignore them?",
    },
    navigation: {
      no_results: "No results found",
      no_more_results:
        "The page number %{page} is out of boundaries. Try the previous page.",
      page_out_of_boundaries: "Page number %{page} out of boundaries",
      page_out_from_end: "Cannot go after last page",
      page_out_from_begin: "Cannot go before page 1",
      page_range_info: "%{offsetBegin}-%{offsetEnd} of %{total}",
      page_rows_per_page: "Rows per page:",
      next: "Next",
      prev: "Prev",
      skip_nav: "Skip to content",
    },
    sort: {
      sort_by: "Sort by %{field} %{order}",
      ASC: "ascending",
      DESC: "descending",
    },
    auth: {
      auth_check_error: "Please login to continue",
      user_menu: "Profile",
      username: "Username",
      password: "Password",
      sign_in: "Sign in",
      can_not_login: "Can't log in?",
      back_sign_in: "Back to Sign in",
      forgot_password: "Forgot Password?",
      forgot_text: "Enter your email address. You will receive an email with a link to reset your password.",
      reset_password: "Reset Password",
      link_sent: "The recovery link was sent",
      recover_text_half: "An email has been sent to",
      recover_text: ". Please follow the link in the letter to be able to create a new password.",
      got_it: "Ok, got it",
      sign_in_error: "Authentication failed, please retry",
      logout: "Logout",
      set_new_password: "Set new password",
      confirm_password: "Confirm new password",
      new_password: "New password",
    },
    notification: {
      updated: "Element has been updated.",
      created: "Element has been created.",
      deleted: "Element has been deleted.",
      bad_item: "Incorrect element",
      item_doesnt_exist: "Element does not exist",
      http_error: "Server communication error",
      data_provider_error: "dataProvider error. Check the console for details.",
      i18n_error: "Cannot load the translations for the specified language",
      canceled: "Action cancelled",
      logged_out: "Your session has ended, please reconnect.",
      general_info_saved: "Product has been created",
      general_info_updated: "Product has been updated",
    },
    validation: {
      numberAmount: 'this field support only digits format',
      link: "must be link",
      required: "Required",
      minLength: "Must be %{min} characters at least",
      maxLength: "Must be %{max} characters or less",
      minValue: "Must be at least %{min}",
      maxValue: "Must be %{max} or less",
      number: "Must be a number",
      email: "Must be a valid email",
      phone: "Must be a valid phone (in format 05 xxxx xxxx)",
      oneOf: "Must be one of: %{options}",
      regex: "Must match a specific format (regexp): %{pattern}",
      password_mismatch: "The password does not match confirmation password",
      between: "Must be between %{min} and %{max}",
      integer: "This value must be an integer and be greater than 0",
      tax_id: "The tax ID must contain a 9\u002Ddigit numeric value",
      cost: "Must be a number",
      amount_error_text: "The amount should be between 0 and ",
    },
    tree: {
      add_child_node: "Add child node",
      add_node_after: "Insert node after",
      add_node_before: "Insert node before",
      close: "Close",
      expand: "Expand",
    },
  },
  konebone: {
    sign_in: {
      email: "Email Address",
      reset_error: "Invalid email",
      login_error: "Invalid email or password",
      password_size: "Password size must be between 6 and 64",
    },
    upload: {
      menu_item: "Import Catalog",
      title: "Import Catalog",
      dropzone_text: "Drag the file here or click to select",
      success: "Success",
      errors: "Errors in uploaded file!",
      rejected_with_errors: "Rejected with errors:",
      rejected_with_defects: "Rejected with defects:",
      total_record_count: "Total record count: ",
      uploaded_record_count: "Uploaded record count: ",
      rejected_record_count: "Rejected record count: ",
      read_csv_error: "Reading csv error with exception message: %{arg_0}",
      read: {
        csv: {
          not_null_value_error:
            "Values of %{arg_1} in record %{arg_0} must be present",
          not_parsable_value_error:
            "Values of %{arg_1} in record %{arg_0} must have appropriate %{arg_2} valid formats",
          required_header_name_error:
            "csv file headers do not contain required headers %{arg_1}",
          input_valid_price_date_error:
            "One or more values of %{arg_1} must be valid values",
          product_package_relation_error: "Product with name %{arg_1} already has package with type %{arg_0}",
        },
      },
      product: {
        image: {
          connection_timeout: "Connection timeout %{arg_2} when upload %{arg_1}",
          response_status_is_not_ok: "Response status %{arg_2} when upload %{arg_1}",
          unknown_error: "Unknown error when upload product with id %{arg_1}",
          unsupported_image_format: "Unsupported image %{arg_1} format %{arg_2}. Must be %{arg_3}",
          unknown_content_type: "Unknown content type when upload %{arg_1}",
          bigger_than_max_size: "The image %{arg_1} size %{arg_3} bigger than max size %{arg_2}",
          content_length_unknown: "Unknown content length %{arg_2}  when upload %{arg_1}",
        },
      },
      image: {
        get_image_from_url_failed: "Error when get image from url"
      },
    },
    admins: {
      menu_item: "Admins",
      title: "Admins",
      id: "Id",
      login: "Login",
      first_name: "First name",
      last_name: "Last name",
      email: "Email",
      phone: "Phone",
      confirmation_code: "Confirmation code",
      password: "Password",
      confirm_password: "Confirm password",
      roles: "Roles",
      logo_url: "Logo URL",
      logo_url_size: "Must be between 1 and 512",
      created_by: "Created by",
      created_at: "Created at",
      edited_by: "Edited by",
      edited_at: "Edited at",
    },
    manufacturer: {
      menu_item: "Manufacturers",
      title: "Manufacturers",
      id: "Id",
      name: "Name",
      description: "Description",
    },
    manufacturing_country: {
      menu_item: "Manufacturing Countries",
      title: "Manufacturing Countries",
      id: "Id",
      name: "Name",
    },
    measurement: {
      menu_item: "Measurements Types",
      title: "Measurements Types",
      id: "Id",
      name: "Name",
    },
    products: {
      export: 'Export',
      menu_item: "Catalog",
      title: "Catalog",
      add_product: "Add product",
      import: "Import",
      search: "Search by Konebone Product Name, Catalog Number or Bar Code",
      next: "Next",
      previous: "Previous",
      general_info: "General Information",
      alternative_product: "Alternative Products",
      manufacturer: "manufacturer",
      specification: "specification",
      upload: "Upload",
      add_manufacturer: "Add Manufacturer",
      add_manufacturer_country: "Add Manufacturer Country",
      add_package_type: "Add Package type",
      add_measurement_type: "Add Measurement Type",
      show_deactivated: "Show deactivated",
      add_new_price_line: "Add new price line",
      edit_price_line: "Edit price line",
      price_lines: "Price Lines",
      deactivate_product: "Deactivate product",
      activate_product: "Activate product",
      go_back: "Back to catalog",
      status: "Status",
      create_on: "Created on %{arg_0} by %{arg_1}",
      last_update_on: "Last update on",
      catalog: {
        category: "Category",
        technical_specification: "Technical specification",
        product_number: "Product number",
        catalog_number: "Catalog number",
        konebone_product_name: "Konebone product name",
        product_name: "Product name",
        bar_code: "Bar code",
        product_model: "Product model",
        type_of_measurement: "Type of measurement",
        package_type: "Package type",
        units_in_package: "Units in package",
        suppliers: "Suppliers",
        manufacturer_name: "Manufacturer name",
        manufacturer_description: "Manufacturer description",
        manufacturer_country: "Manufacturer country",
        comments: "Comments",
        last_update_date: "Last update date",
        consumption_quantity: "Consumption quantity",
        consumptio_units: "Consumption units",
        shelf_life: "Shelf life, months",
        link_video: "Link to video instruction & tips",
        price_for: "Price for",
        product_photo: "Product photo",
        attach_file: "Attach File",
        add_link: "Add link",
        url: "Url",
        measurement_type: "Measurement Type",
        quantity_on_pallet: "Quantity on pallet",
        kb_price: "Kb price",
        additonal_package_type: `The supplier suggested a package type that was not previously in the system.
        Click on the "+" icon to add a new package type.`,
        additonal_manufacturer_name: `The supplier suggested a manufacturer name that was not previously in the system.
        Click on the "+" icon to add a new manufacturer name.`,
        additonal_measurenment: `The supplier suggested a type of measurement that was not previously in the system.
        Click on the "+" icon to add a new type of measurement.`,
      },
      price_lines_list: {
        supplier_name: "Supplier name",
        supplier_part_number: "Supplier Catalog Number",
        supplier_product_name: "Supplier product name",
        supplier_price: "Supplier price",
        kb_price: "Kb price",
        valid_from: "Valid from",
        valid_to: "Valid to",
        price_per_unit: "Price per unit",
        quantity_on_pallet: "Quantity on pallet",
        min_order_quantity: "Min Order Quantity",
        supplier: "Supplier",
        supplier_product_details: "Supplier product details",
        part_number: "Catalog Number",
        product_name: "Product Name",
        in_stock: "In stock",
        price_details: "Price Details",
        add_price: "Add Price",
        edit_price: "Edit Price",
        add_supplier: "Add Supplier",
        supplier_description: "Supplier description",
        delivery_price: "Delivery & Price",
      },
    },
    supplier: {
      error_foundation: "Impossible to save date from future",
      date_time: 'Date And Time',
      user_id: 'User Id',
      admin_id: "Admin Id",
      request: "Request",
      endpoint: "Endpoint",
      request_body: "Request Body",
      manager: "Manager",
      imported: "Imported",
      add_supplier: 'Add Supplier',
      menu_item: "Suppliers",
      title: "Suppliers",
      id: "Id",
      name: "Supplier",
      description: "Description",
      tax_id: "Tax ID",
      city: "City",
      street: "Street",
      unit: "Apt./Unit",
      firstName: "First Name",
      lastName: "Last Name",
      jobTitle: "Job title",
      company_email: "Company Email",
      candidates: "Candidates",
      active: "Active",
      inactive: "Inactive",
      search: "Search by company name",
      decline_candidate: "Decline candidate",
      reason_for_decline: "Reason for decline",
      decline: "Decline",
      block: "Block",
      status: "Status",
      reason_for_block: "Reason for block",
      block_supplier: "Block supplier",
      company: "Company",
      go_back: "Back to suppliers",
      create_on: "Created on",
      company_description: "Company Description",
      company_phone_number: "Company phone number",
      approve: "Approve",
      business_company_name: "Business/Company Name",
      has_not_been_imported: "supplier is new and has not been imported before",
      has_been_imported: "supplier has been imported before",
      field_of_activity: "Field Of Activity",
      address: "Address",
      main_contact: "Main Contact",
      phone_number: "Phone Number",
      company_foundation: "Company Foundation",
      company_name: "Company/Business Name",
      approve_candidate: "Approve Candidate",
    },
    supplier_candidates: {
      menu_item: "Supplier Candidates",
      title: "Supplier Candidates",
      id: "Id",
      name: "Supplier Candidates",
      description: "Description",
      taxId: "Tax ID",
      foundation: "Foundation",
      activity: "activity",
      city: "City",
      street: "Street",
      unit: "Unit",
      firstName: "First Name",
      lastName: "Last Name",
      jobTitle: "Job title",
      email: "Email",
      approve: "Approve",
    },
    categories: {
      menu_item: "Categories",
      title: "Categories",
      create_button: "Create root category",
      name: "Name",
      description: "Text for the page",
      image: "Image",
      metaTitle: "Meta title",
      metaDescription: "Meta Description",
    },
    package: {
      menu_item: "Package",
      title: "Package",
      id: "Id",
      name: "Name",
      package_type: "Package Type",
      measurement: "Measurement",
      quantity: "Quantity",
      size: "Size",
      weight: "Weight",
    },
    package_type: {
      menu_item: "Package Types",
      title: "Package Types",
      name: "Name",
      id: "Id",
    },
    customer: {
      access_link: "Access Link",
      link: 'Link',
      time: "Time",
      second: "second",
      generate_access_link: "Generate access link",
      menu_item: "Customers",
      title: "Customers",
      id: "Id",
      company_name: "Company/Business Name",
      taxId: "Tax ID",
      contractor_number: "Contractor number",
      adress: "Address",
      city: "City",
      email: "Company Email",
      first_name: "First name",
      last_name: "Last name",
      main_contact: "Main contact",
      phone_number: "Phone number",
      jobTitle: "Job title",
      disable: "Enabled",
      candidates: "Candidates",
      active: "Active",
      inactive: "Inactive",
      search: "Search by company name or phone number",
      decline_candidate: "Decline candidate",
      reason_for_decline: "Reason for decline",
      decline: "Decline",
      block: "Block",
      status: "Status",
      info: "Info",
      credit_payment: "Credit payments",
      available_credit: "Available credit",
      credit_limit: "Credit Limit",
      expiration: "Date of credit limit expiration",
      extendid_payment: "Extended payment term",
      outstanding_receivables: "Outstanding Receivables",
      pay_later: "Buy now pay later",
      date_placed: "Date Placed",
      due_date: "Due Date",
      payment_term: "Payment Term",
      order_id: "Order Id",
      order_total_amount: "Order total Amount",
      amount: "Amount",
      comment: "Comment",
      restore: "restore",
      credit_limit_restore: "Credit limit restore",
      enable_credit_line: "Enable Credit Line",
      connect: "Connect",
      extended_payment_term: "Extended payment term",
      expiration_date: "Date of Credit Limit Expiration",
      reason_for_block: "Reason for block",
      block_customer: "Block customer",
      company: "Company",
      go_back: "Back to customers",
      create_on: "Created on",
      company_description: "Company Description",
      edit: "Edit",
      street: "Street",
      company_phone_number: "Company phone number",
      unit: "Apt./Unit"
    },
    customer_type: {
      menu_item: "Customer Types",
      title: "Customer Types",
      name: "Name",
      id: "Id",
    },
    manager: {
      firstName: "First Name",
      lastName: "Last Name",
      jobTitle: "Job title",
      contact_email: "Contact Email",
      phone_number: "Phone Number",
      action: 'Action',
      title: "Managers",
      name: "Name",
      full_name: "Full Name",
      email: "Contact Email",
      job_title: "Job title",
      phone: "Phone Number",
      phone_confirmed: "Phone Confirmed",
      created_at: "Created At",
      active: "Status",
      add_manager: "Add Manager",
      actions: "Actions",
      user_information: "User information",
      first_name: "First name",
      last_name: "Last name",
      reason_for_block: "Reason for block",
      main_contact: "Main Contact",
      user_main_contact: "User is the main contact",
      edit_manager: "Edit Manager",
      block_manager: "Block Manager",
    },
    project: {
      packing_slips: "Packing Slips",
      mark_packing_slip_verified: "Mark packing slip verified",
      eldorado_packing_slip: 'Eldorado packing slip',
      confirmation_message: "Delivery needs confirmation. Please upload the packing slip for this order.",
      menu_item: "Projects",
      title: "Projects",
      project_name: "Project Name",
      contact_phone: "Contact Phone",
      contact_full_name: "Contact Full Name",
      address: "Address",
      city: "City",
      street: "Street",
      district: "District",
      apt: "Apt",
      customer_id: "Customer Id"
    },
    orders: {
      packing_slip_verified: "Packing Slip Verified",
      total_with_difference: 'Total with difference',
      verify: "verify",
      invoice: 'Invoice#',
      invoice_link: "Invoice link",
      invoice_amount: 'Invoice amount',
      order_total: "Order total (paid by customer)",
      packing_slips_message:"Delivery needs confirmation. Please upload the packing slip for this order.",
      paid_order_cancel_message_start: "Please note that this order has already been paid for.",
      paid_order_cancel_message_end: "Don't forget to make a refund for this order.",
      due_date: "Due Date",
      bnpl: "Buy Now Pay Later",
      card: "Debit or credit card",
      packing_slips: "Packing Slips",
      mark_packing_slip_verified: "Mark packing slip verified",
      eldorado_packing_slip: 'Eldorado packing slip',
      packing_slip: 'packing slip',
      packing_slip_error_maxSize: 'Please use a max file size of 10 MB for uploads',
      menu_item: "Orders",
      title: "Orders",
      id: "Order Id",
      date_placed: "Date placed",
      customer_manager: "Customer manager",
      status: "Status",
      all_orders: "All Orders",
      new: "New",
      in_progress: "In progress",
      in_review: "In review",
      awaiting_delivery: "Awating delivery",
      accepted: "Accepted",
      declined: "Declined",
      completed: "Completed",
      delivered: "Delivered",
      canceled: "Canceled",
      choose_suppliers: "Choose the suppliers",
      in_review_suppliers: "In review of suppliers",
      in_review_customer: "In review of customer",
      order_price: "Order price",
      project_city: "Project city",
      search: "Search by Order ID or Company name",
      company_name: "Company name",
      info: "Info",
      suppliers: "Suppliers",
      invoices: "Invoices",
      order: "Order",
      placed_on: "Placed on",
      urgent_order: "Urgent order",
      up_to_4_hours_from_the_order_confirmation: "Up to 4 hours from the order confirmation",
      time_constraints: "Time constraints",
      edit_notify: "Time constraints, project and product is required fields",
      estimated_total: "Estimated total",
      delivery_time: "Specific delivery time",
      project: "Project",
      additional_services: "Additional services",
      comments: "Comments",
      unloading: "Unloading",
      unloading_with_crane: "Unloading with crane",
      order_filling: "Order filling",
      go_back: "Back to orders list",
      more: "More",
      cancel_order: "Cancel order",
      edit_order: "Edit order",
      send_supplier: "Send to supplier(s)",
      type_reason_placeholder: "Type the reason here",
      indicate_reason: "Indicate the reason for closing the order",
      delivery: "Delivery",
      supplier_info: "Supplier Info",
      payment: "Payment",
      save_changes: "Save Changes",
      cancel: "Cancel",
      project_id: "Project",
      specific_delivery_time: "Specific delivery time",
      date: "Date",
      time: "Time",
      select_pickup_date_and_time: "Select pickup date and time",
      comment_for_supplier: "Comment for supplier",
      add_new_project: "Add new project",
      edit_project: "Edit project",
      delete_project: "Delete Project",
      delete: 'Delete',
      change: "change",
      no_project: "No saved project",
      sure_text: 'Are you sure that you want to delete this project?',
      confirmation_text: "After confirmation this action cannot be undone",
      ready_to_get_alternative_product: "Ready to get alternative product",
      add_product: "Add product",
      edit: "Edit",
      add_product_to_order: "Please, add at least 1 product to the order",
      search_by_catalog_number: "Search by catalog number",
      projects: {
        project_name: "Project Name",
        contact_full_name: "Contact Full Name",
        contact_phone: "Contact phone",
        address: "Address",
        street: "Street",
        apt_unit: "Apt/Unit",
        city: "City",
      },
      offers: {
        exclude_offer: "Exclude offer from the group",
        exclude_offer_text_start: "Are you sure you want to exclude",
        exclude_offer_text_end: " offer from the group?",
        remove_offer_group: "Remove the product from the offer group",
        group: 'Group',
        error_time: "Please enter time exceeding previous time",
        supplier_name: "supplier name",
        offer_status: "Offer status",
        rating: "Rating",
        delivery: "Delivery",
        new_products: "New products",
        supplier_price: "Supplier price",
        kb_price: "Konebone price",
        cancel_offer: "Cancel an offer",
        confirm: "Confirm",
        sure_cancel_offer: "Are you sure you want to cancel this offer for the",
        sure_cancel_offer_group_start: "Are you sure you want to remove",
        sure_cancel_offer_group_mid: "from the",
        sure_cancel_offer_group_end: "offer?",
        sure_group_offer_start: "Are you sure you want to group",
        sure_group_offer_end: " offers?",
        no_matches: "No matches were found between the products of these suppliers.",
        matches_products: "The matches between suppliers' products found. Eliminate matches to continue:",
        cancel_offer_message1: "This supplier will no longer be able to continue working on this order.",
        cancel_offer_message2: "It will be canceled for him.",
        time_to_review: "Time to review",
        choose_supplier: "Choose the best supplier",
        number_of_competitors: "Number of competitors",
        confirm_and_send: "Confirm and send",
        send_to_suppliers: "Send to supplier(s)",
        send_to_customer: "Send to customer",
        group_offers: "Group offers",
        send_offer_message: "Are you sure you want to send the RFP order for review to the",
        send_offer_customer_message_start: "Are you sure you want to send offers from ",
        send_offer_customer_message_end: "for customer review?",
      },
    },
    offer: {
      delivery_time_not_specified: "Time constraint",
      offer: "offer",
      customer_project: "Customer project",
      additional_services: "Additional services",
      delivery: "Delivery",
      supplier_info: "Supplier info",
      comments: "Comments",
      in_stock: "In stock",
      out_stock: "Out stock",
      supplier_price: "Supplier price",
      discount: "Price with discount",
      kb_price: "KB price",
      kb_price_discount: "KB price with discount",
      gross_profit: "Gross Profit",
      delivery_price: "Delivery price",
      total: "Total",
      subtotal: "Subtotal",
      vat: "VAT",
      time_constraints: "Time constraints",
      available_at_supplier: "Available at supplier",
      go_back: "Back to offers list",
      go_back_edit: "Back to edit offer",
      add_new_product: "Add new product for order",
      cancel_offer: "Cancel offer",
      edit_offer: "Edit offer",
      new_product: "New product",
      replacement: "Replacement",
      customer_delivery_time: "Customer’s expected delivery time",
      without_delivery: "Without changing delivery",
      specific_delivery_time: "Specific delivery time",
      delivery_by_konebone: "Delivery by KoneBone",
      select_date_time: "Need to select pickup date and time",
      delivery_will_be_handled_by_konebone: "Delivery will be handled by KoneBone",
      by_konebone: "By Konebone",
      by_supplier_with_delay: "By supplier with delay",
      by_supplier_as_customer_expect: "By supplier as expected",
      vehicle: "vehicle",
      with_crane: "with crane",
      without_crane: "without crane",
      unloading: "Unloading",
      edit: "Edit",
      delivery_contact_name: "Delivery Contact Name",
      delivery_contact_phone: "Delivery Contact Phone",
      delivery_cost: "Delivery cost",
      date: "Date",
      time: "Time",
      date_time: "Offers are valid until the date and time",
      edit_type_of_vehicle: "Edit type of vehicle",
      edit_suppliers_pickup_address: "Edit supplier's pickup address",
      save: "Save",
      pick_a_date: "Pick a date",
      pick_a_time: "Pick a time",
      choose_vehicle: "Choose vehicle",
      need_to_specify_address: "Need to specify address",
      pickup_address: "Pickup address",
      add_alternative_product: "Add alternative product",
      alternative_dialog_message: `Use the search bar by Eldorado catalog number
      to add an alternative product instead of`,
      search_alternative: "Search by supplier catalog number",
      product_not_in_catalog: "The product isn’t in the catalog?",
      city: "City",
      apt: "Apt",
      street: "Street",
      products_in_stock: "Products in stock",
      cancel: "Cancel",
      products_out_stock: "Products out stock",
      alternative: "Alternative",
      create_new_product: "Create product",
      info_btn: "The customer isn’t ready to consider an alternative for this order.",
      no_search_text_alternative: "The search request must include your full catalog number. Please clarify your request if you didn't find what you were looking for.",
      no_search_results_for: "No search results for",
      without_vat: "without vat",
      sent_on: "sent on",
    },
    button: {
      submit: "Submit",
    },
  },
  error: {
    project: {
      cant_delete: 'This project already uses for existing order(s)'
    },
    bean: {
      validation: {
        tomorrow_or_later: 'Delivery date has already passed'
      }
    },
    admin: {
      email_exists: "Element with the same email already exists",
    },
    manufacturer: {
      name: {
        exists: "Element with the same name already exists",
      },
      not_found_by_id: "Manufacturer was not found by %{arg_0} %{arg_1}.",
      name_exists: "Element with the same name already exists",
    },
    manufacturing_country: {
      name: {
        exists: "Element with the same name already exists",
      },
      not_found_by_id:
        "Manufacturing country was not found by %{arg_0} %{arg_1}",
    },
    supplier: {
      with_the_same_name_exists:
        "Element with the same name already exists",
      not_found_by_id: "Supplier was not found by %{arg_0} %{arg_1}",
    },
    measurement: {
      with_the_same_title_exists:
        "Element with the same name already exists",
      not_found_by_id: "Measurement was not found by %{arg_1} %{arg_0}.",
    },
    package: {
      with_the_same_fields_exists:
        "Element with the same name already exists",
      not_found_by_id: "Package was not found by %{arg_1} %{arg_0}.",
    },
    package_type: {
      with_the_same_title_exists:
        "Element with the same name already exists",
      not_found_by_id: "Package type was not found by %{arg_0} %{arg_1}",
    },
    email: {
      send: "Email sending error to address %{arg_0} with message: %{arg_1}",
    },
    pageable: {
      page_should_be_more_then_zero: "Page should be more than 0.",
    },
    product: {
      not_found_by_id: "Product was not found by %{arg_0} %{arg_1}.",
      name_exists: "Element with the same name already exists",
      picture: {
        not_found_by_id: "Product was not found by %{arg_0} %{arg_1}.",
      },
      picture_name_exists: "Picture with the same name already exists",
    },
    manufacturing: {
      country_name_exists:
        "Element with the same name already exists",
    },
    product_price: {
      with_the_same_product_supplier_id_exists:
        "Product price with the same supplier exists",
    },
    product_supplier: {
      with_the_same: {
        supplier_pn_and_product_package_id_and_supplier_id_exists:
          "The supplier catalog number or supplier name must be unique for this product price line.",
      },
    },
    manager: {
      phone_already_exist: "Manager with the same phone number already exists",
    },
    while_file_resizing: "Error while file resizing",
    parameter: {
      should_be_more_then_zero: "Parameter should be more then zero"
    },
    category: {
      has_child_categories: "has child categories",
      has_products: "has products",
      count_limit: "Category limit quantity exceeded. The max number of categories equals 1000"
    },
    order: {
      not_found_by_id: "Order was not found by %{arg_0}.",
      already_cancel: "Order are already canceled",
      cancel_not_allowed: "Cancel not allowed",
    },
    offer: {
      incorrect_count: "A selected number of suppliers is greater than 3. Change your selection to continue.",
      not_found_by_id: "Offer was not found by %{arg_0}.",
      cannot_send_order_already_in_progress: "Cannot send order because it is already in progress",
      cancel_not_allowed: "Not allowed to cancel a %{arg_0} offer",
      does_not_contains_products: "The offer must consist of one or more in-stock products",
    },
    delivery: {
      cost_null: "Delivery information is not full"
    }
  },
  confirm_messages: {
    products: {
      back: "Do you really want to exit? All entered information won’t be saved.",
      save: "The Product will be shown in the Catalog immediately after saving. Continue?",
      next_step: "You have unsaved changes. Do you want to leave the page without saving them?",
      activate:
        "Do you really want to activate the Product %{arg}? Product will be shown in the Catalog immediately.",
      deactivate:
        "Do you really want to deactivate the Product %{arg}? Customers will not be able to order the product.",
    },
    price_lines:
      "Do you really want to exit? All entered information won’t be saved.",
    customer: {
      delete: "Do you really want to delete the candidate?",
      unblock: "Do you really want to unblock %{arg_0} customer?",
      approval: "Do you really want to approve the candidate?",
    },
    supplier: {
      delete: "Do you really want to delete the candidate?",
      unblock: "Do you really want to unblock %{arg_0} supplier?",
      approval: "Do you really want to approve the candidate?",
    },
    manager: {
      unblock: "Do you really want to unblock %{arg_0} manager?",
    },
    offer: {
      remove_alternative: "Do you really want to delete the alternative product?"
    }
  },
  package: {
    was_deleted_successfully: "You successfully delete package.",
    type: {
      was_deleted_successfully: "You successfully delete package type.",
    },
  },
  log: {
    google: {
      drive: {
        error_init: "Can not initialise service to work with Google drive.",
        error: {
          resource_not_found: "Resource not found.",
          not_folder: "File with id = %{arg_0} is not a folder.",
          create: "Can not create via API, unsupported type %{arg_0}.",
          folder_content: "Can not read folder content, folder id = %{arg_0}.",
          file_read: "Can not read files.",
          file_create: "Can not create file %{arg_0}.",
          file_incorrect_type: "Incorrect file type %{arg_0}.",
          file_incorrect_name: "Please, provide not empty file name.",
          file_delete: "Can not delete file with id = %{arg_0}.",
          file_update: "Can not update file name with id = %{arg_0}.",
          file_upload: "Can not upload %{arg_0}.",
          file_move: "Can not move file name with id = %{arg_0}.",
          file_download: "Can not download file with id = %{arg_0}.",
          file_download_not_found:
            "Can not download file with id = %{arg_0}, not found.",
          folder_download: "Can not download folder with id = %{arg_0}.",
          file_get: "Can not get file with id = %{arg_0}.",
          tmp: {
            io: "IO error occurred while trying to make a temporary local copy at %{arg_0}.",
          },
          tmp_copy_create: "Can not create temporary copy of %{arg_0}.",
          tmp_copy_delete: "Can not delete temporary copy of %{arg_0}.",
          error_tmp_create: "Can not create temporary file.",
        },
      },
    },
  },
  auth: {
    service: {
      logout: "You are successfully logout.",
      change_password: "You are successfully changed your password.",
      send_new_password: "New password was sent to your email.",
      send_confirmation_code: "Confirmation code was sent.",
      check_confirmation_code: "Confirmation code verified.",
      login_exists: "Login %{arg_0} exists.",
      login_does_not_exist: "Login %{arg_0} does not exist.",
    },
  },
  bean: {
    validation: {
      fail: "Validation fail.",
    },
  },
  exception: {
    admin: {
      not_found_by_id: "Admin with id %{arg_0} was not found",
    },
    category: {
      not_found_by_id: "Category with id %{arg_0} was not found",
      wrong_position:
        "Category has wrong position",
      root_must_be_unique: "There can be only one root category.",
      not_found_by_parent_id:
        "Parent category for category with id %{arg_0} was not found",
      already_exist: "Category with the same name already exist",
      not_deletable: "Category %{arg_1}",
      parent_relationship: "Category can not be the parent itself"
    },
    user: {
      not_found_by_id: "Admin with id %{arg_0} was not found.",
      not_found_by_email: "Admin with email %{arg_0} was not found.",
      not_found_by_login: "Admin with login %{arg_0} was not found.",
    },
    order: {
      not_found_by_id: "Order with id %{arg_0} was not found.",
    },
    api_not_found: "Api command not found",
    manager_not_found: "Manager not found",
    credit_not_enough: 'Insufficient funds on the balance of the available credit limit',

    manager: {
      not: {
        found: "Manager not found"
      }
    },

    supplier_candidate_not_found: "Supplier candidate not found",
    customer_not_found: "Customer not found",
    role_was_not_found: "Role was not found with %{arg_0} = %{arg_1}!",
    repository: {
      empty_result_data_access: "Invalid catalog number",
      integrity_violation:
        "Element is defined for some products and cannot be deleted.",
      invalid_data_access_api_usage:
        "Invalid data access api usage",
      general: "%{arg_0}",
      duplicate_key: "Make sure that each product has only one price line."
    },
    empty_required_fields: "Please, fill the Business/Company Name field before approving the Candidate"
  },
  s3: {
    uploaded: "File %{arg_0} is uploaded with key %{arg_1}.",
    upload_failed: "Upload failed. File %{arg_0}, key %{arg_1}.",
    downloaded: "File with key %{arg_0} downloaded successfully.",
    download_failed: "Download failed. Key %{arg_0}.",
    file_deleted: "File %{arg_0} was deleted.",
    delete_failed: "Delete failed. Key %{arg_0}.",
  },
  product: {
    was_deleted_successfully: "You successfully deleted product.",
    picture: {
      was_deleted_successfully: "You successfully delete product.",
    },
  },
  manufacturer: {
    was_deleted_successfully: "You successfully deleted manufacturer.",
    country: {
      was_deleted_successfully:
        "You successfully deleted manufacturing country.",
    },
  },
  measurement: {
    was_deleted_successfully: "You successfully delete measurement.",
  },
  supplier: {
    was_deleted_successfully: "You successfully delete supplier.",
  },
  repository: {
    general: "exception.repository.general",
    cannot_acquire_lock: "exception.repository.cannot_acquire_lock",
    cannot_serialize_transaction:
      "exception.repository.cannot_serialize_transaction",
    deadlock_loser_data_access:
      "exception.repository.deadlock_loser_data_access",
    cleanup_failure_data_access:
      "exception.repository.cleanup_failure_data_access",
    data_access_resource_failure:
      "exception.repository.data_access_resource_failure",
    duplicate_key: "exception.repository.duplicate_key",
    integrity_violation: "exception.repository.integrity_violation",
    empty_result_data_access: "exception.repository.empty_result_data_access",
    incorrect_result_size_data_access:
      "exception.repository.incorrect_result_size_data_access",
    incorrect_update_semantics_data_access:
      "exception.repository.incorrect_update_semantics_data_access",
    invalid_data_access_api_usage:
      "exception.repository.invalid_data_access_api_usage",
    non_transient_data_access_resource:
      "exception.repository.non_transient_data_access_resource",
    optimistic_locking_failure:
      "exception.repository.optimistic_locking_failure",
    permission_denied_data_access:
      "exception.repository.permission_denied_data_access",
    pessimistic_locking_failure:
      "exception.repository.pessimistic_locking_failure",
    query_timeout: "exception.repository.query_timeout",
    recoverable_data_access: "exception.repository.recoverable_data_access",
    transient_data_access_resource:
      "exception.repository.transient_data_access_resource",
    type_mismatch_data_access: "exception.repository.type_mismatch_data_access",
    uncategorized_data_access: "exception.repository.uncategorized_data_access",
    invalid_data_access_resource_usage:
      "exception.repository.invalid_data_access_resource_usage",
    data_retrieval_failure: "exception.repository.data_retrieval_failure",
    concurrency_failure: "exception.repository.concurrency_failure",
    non_transient_data_access: "exception.repository.non_transient_data_access",
    transient_data_access: "exception.repository.transient_data_access",
  },
  customer: {
    not: {
      found: "Customer with id  %{arg_0} was not found.",
    },
  },
  customer_type: {
    not: {
      found: "Customer type with id  %{arg_0} was not found.",
    },
  },
  upload: {
    read_csv_error: "Reading csv error with exception message: %{arg_0}",
    read: {
      csv: {
        not_null_value_error:
          "Values of %{arg_1} in record %{arg_0} must be present",
        not_parsable_value_error:
          "Values of %{arg_1} in record %{arg_0} must have appropriate %{arg_2} valid formats",
        required_header_name_error:
          "csv file headers do not contain required headers %{arg_0}",
        input_valid_price_date_error:
          "One or more values of %{arg_0} must be valid values",
        product_package_relation_error: "Product with name %{arg_1} already has package with type %{arg_0}",
      },
    },
    image: {
      get_image_from_url_failed: "Error when get image from url"
    },
  },
};

export default englishMessages;
