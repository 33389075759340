import Wrapper from "../utils/Wrapper";
import axios from 'axios';
import { stringify } from "query-string";

const fieldMap = {
  id: "customerId",
  address: "street",
  mainContact: "firstName",
  statusLower: "status",
}

const dataProviderForCustomers = {

  getOne: async (resource, params) => {
    const url = process.env.REACT_APP_API_URL + "/customer/" + params.id;
    const response = await axios.get(url,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      });
    let resultData = Wrapper.wrapCustomer(response.data.data);
    return ({
      data: resultData,
    });
  },

  delete: async (resource, params) => {
    const url = process.env.REACT_APP_API_URL + "/customer/" + params.id;
    const response = await axios.delete(url,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      });

    if (response.status === 200) {
      return { data: { id: params.id } }
    }
  },

  getList: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const { q } = params.filter;
    const query = {
      page: page - 1,
      size: perPage,
      sort: fieldMap[field] ? fieldMap[field] : field,
      search: q ? q : "",
    };
    const filterStatus = params.filter?.status || 'candidates';
    const url = process.env.REACT_APP_API_URL + `/customer/byStatus/${filterStatus}?${stringify(query)},${order}`;
    const response = await axios.get(url,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      });

    if (!response || !response.data || !response.data.data) {
      return ({
        data: [],
        total: 0
      });
    }
    let resultData = response.data.data.content.map(customer => Wrapper.wrapCustomer(customer));
    let count = response.data.data.totalElements;
    return ({
      data: resultData,
      total: count
    });
  },

  create: async (resource, params) => {
    let data = params.data;
    data.clientType = 'WEB'
    let response = await axios.post(process.env.REACT_APP_API_URL + "/customer", data,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json', 'Authorization': getAuthToken() }
      }
    );

    if (response && response.data) {
      let dataRes = response.data;
      dataRes.data.id = dataRes.data.customerId;
      return ({
        data: dataRes.data
      });
    } else {
      return ({
        data: []
      });
    }
  },

  update: async (resource, params) => {
    let data = params.data.data;
    let id = params.id;

    let response = await axios.put(process.env.REACT_APP_API_URL + "/customer/" + id, data,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      }
    );

    if (response && response.data) {
      let dataRes = response.data;
      dataRes.data.id = dataRes.data.customerId;
      params.data.onSuccess();
      return ({
        data: dataRes.data
      });
    } else {
      params.data.onSuccess();
      return ({
        data: []
      });
    }
  },

  getCountByStatus: async () => {
    let response = await axios.get(process.env.REACT_APP_API_URL + "/customer/counts",
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      }
    );
    if (!response || !response.data) {
      return { data: {} };
    }
    return { data: response.data.data }
  },

  getAccessLink: async (resource, params) => {
    const url = process.env.REACT_APP_API_URL + "/customer/" + params.customerId + '/access-link';
    const response = await axios.get(url,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      });

    let resultData = Wrapper.wrapCustomer(response.data.data);

    return ({
      data: resultData.accessLink,
    });
  },


  getHistory: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { order } = params.sort;
    const query = {
      page: page - 1,
      size: perPage,
    };
    let response = await axios.get(process.env.REACT_APP_API_URL + '/customer/' + `${params.customerId}/history?${stringify(query)},${order}`,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      }
    );

    if (!response || !response.data) {
      return ({
        data: [],
        total: 0
      });
    } 
    let resultData = response.data.content.map(customer => Wrapper.wrapHistory(customer));
    let count = response.data.totalElements;
    
    return ({
      data: resultData,
      total: count
    });
  },
  

  approve: async (resource, { id }) => {
    let response = await axios.put(process.env.REACT_APP_API_URL + `/customer/${id}/approve`, {},
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      }
    );


    if (!response || !response.data) {
      return { data: false };
    }

    return { data: true }
  },

  block: async (resource, { id, reason }) => {
    let response = await axios.put(process.env.REACT_APP_API_URL + `/customer/${id}/block?reason=${reason}`, {},
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      }
    );

    if (!response || !response.data) {
      return { data: false };
    }

    return { data: true }
  },

  decline: async (resource, { id, reason }) => {
    let response = await axios.put(process.env.REACT_APP_API_URL + `/customer/${id}/decline?reason=${reason}`, {},
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      }
    );

    if (!response || !response.data) {
      return { data: false };
    }

    return { data: true }
  },

  unblock: async (resource, { id }) => {
    let response = await axios.put(process.env.REACT_APP_API_URL + `/customer/${id}/unblock`, {},
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      }
    );

    if (!response || !response.data) {
      return { data: false };
    }

    return { data: true }
  },
};

export default dataProviderForCustomers;
