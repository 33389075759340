import React, { useState } from "react";
import { useTranslate, useRefresh } from "ra-core";
import { Button, makeStyles, Dialog, DialogTitle, DialogContent, TextField, DialogActions } from "@material-ui/core";
import { useNotify, Confirm } from "react-admin";

import { ReactComponent as ButtonLock } from "../../svg/button-lock.svg";
import { ReactComponent as ButtonUnlock } from "../../svg/button-open.svg";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import { wrapArrayToMap } from "../../utils/Wrapper";
import dataProviderForCustomers from "../../providers/DataProviderForCustomers";
import Validator from "../../utils/Validator";

export default (status, data, sizeBtn) => {
  const statusInfoMap = {
    WAITING_FOR_REVIEW: {
      lable: "Waiting for review",
      lableColor: "#2F80ED",
      buttons: [<CustomerApproveButton key="CustomerApproveButton" record={data} sizeBtn={sizeBtn} />, <CustomerDeclineButton key="CustomerDeclineButton" record={data} sizeBtn={sizeBtn} />],
    },
    UNFINISHED: {
      lable: "Unfinished",
      lableColor: "#686868",
      buttons: [<CustomerApproveButton key="CustomerApproveButton" record={data} sizeBtn={sizeBtn} />, <CustomerDeleteButton key="CustomerDeleteButton" record={data} sizeBtn={sizeBtn} />]
    },
    DECLINED: {
      lable: "Declined",
      lableColor: "#F2994A",
      buttons: [<CustomerApproveButton key="CustomerApproveButton" record={data} sizeBtn={sizeBtn} />]
    },
    BLOCKED: {
      lable: "Blocked",
      lableColor: "#EB5757",
      buttons: [<CustomerUnblockedButton key="CustomerUnblockedButton" record={data} sizeBtn={sizeBtn} />]
    },
    ACTIVE: {
      lable: "Active",
      lableColor: "#219653",
      buttons: [<CustomerBlockedButton key="CustomerBlockedButton" record={data} sizeBtn={sizeBtn} />]
    }
  };
  return statusInfoMap[status];
}
const useStyles = makeStyles((theme) => ({
  approve: {
    border: "1px solid #219653",
    borderRadius: "3px",
    color: "#219653",
  },
  decline: {
    border: "1px solid #E02443",
    borderRadius: "3px",
    color: "#E02443",
  },
  unblock: {
    border: "1px solid #DDDBDA",
    borderRadius: "3px",
    color: "#484848",
  },
  block: {
    border: "1px solid #DDDBDA",
    borderRadius: "3px",
    color: "#484848",
  },
  delete: {
    border: "1px solid #DDDBDA",
    borderRadius: "3px",
    color: "#484848",
  },
  small_btn: {
    width: "30px",
    minWidth: "30px",
    maxWidth: "30px",
    padding: "3px 0",
  },
}));

const buttonsType = {
  approve: {
    bntLable: "Approve",
    icon: <CheckCircleOutlineIcon />
  },
  decline: {
    bntLable: "Decline",
    icon: <ClearIcon />
  },
  unblock: {
    bntLable: "Unblock",
    icon: <ButtonUnlock />
  },
  block: {
    bntLable: "Block",
    icon: <ButtonLock />
  },
  delete: {
    bntLable: "Delete",
    icon: <DeleteOutlineIcon style={{ color: "#AFAFAF" }} />
  }
}

export const CustomerBlockedButton = ({ record, sizeBtn }) => {
  const [dialogOpened, setDialogOpened] = useState(false);
  const [reason, setReason] = useState("");
  const [reasonError, setReasonError] = useState("");
  const [error, setError] = useState(false);
  const notify = useNotify();
  const classes = useStyles();
  const translate = useTranslate();
  const refresh = useRefresh();

  const handleClick = (e) => {
    e.stopPropagation();
    setDialogOpened(true)
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setDialogOpened(false);
  };

  const blocked = async () => {
    if (!reason) {
      setError(true);
      return;
    }

    let isError = false;

    if (Validator.validateReasonBlock(reason)) {
      setReasonError(Validator.validateReasonBlock(reason));
      isError = true;
    }

    if (isError) {
      return;
    }

    try {
      await dataProviderForCustomers.block("customers", {
        id: record.id,
        reason: reason
      });
      setDialogOpened(false);
      refresh();
    } catch (error) {
      if (!error.response) {
        console.error(error);
        return;
      }
      let { status } = error.response;
      if (status === 401 || status === 403) {
        cleanSession();
        window.location.href = "/login";
      }
      if (status === 500) {
        notify(
          error.response.data?.error,
          "warning"
        )
        return;
      }
      notify(
        error.response.data?.message?.message,
        "warning",
        wrapArrayToMap(error.response.data?.message?.parameters)
      );
    }
  };

  const checkRequired = (e) => {
    if (!e.target.value) {
      setError(true);
    }
  }

  const changeReason = (e) => {
    const { value } = e.target
    if (value) {
      setError(false);
    }
    setReasonError("");
    setReason(value);
  }

  return (
    <>
      <div className="listButton">
        <CustomerActionsButtons sizeBtn={sizeBtn} type="block" handleClick={handleClick} />
      </div >
      {dialogOpened && (
        <Dialog
          open={dialogOpened}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" className={classes.capitalize}>
            {translate("konebone.customer.block_customer")}
          </DialogTitle>
          <DialogContent>
            <TextField
              error={error || !!reasonError}
              id="name"
              label={translate(
                "konebone.customer.reason_for_block"
              )}
              fullWidth
              variant="outlined"
              value={reason}
              onChange={changeReason}
              onBlur={(e) => checkRequired(e)}
              helperText={error ?  translate("ra.validation.required") : translate(reasonError, { max: "500" })}
              multiline
              rows="4"
              required
            />
          </DialogContent>
          <DialogActions className={classes.root}>
            <Button onClick={handleClose} variant="outlined">
              {translate("ra.action.cancel")}
            </Button>
            <Button onClick={blocked} variant="contained" color="secondary">
              {translate("konebone.customer.block")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export const CustomerUnblockedButton = ({ record, sizeBtn }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const translate = useTranslate();
  const [open, setOpen] = useState(false);

  const handleClick = (e) => {
    e.stopPropagation();
    setOpen(true)
  };
  const handleDialogClose = (e) => {
    e.stopPropagation();
    setOpen(false)
  };

  const handleConfirm = async (e) => {
    e.stopPropagation();
    try {
      await dataProviderForCustomers.unblock("customers", {
        id: record.id,
      });
      refresh();
    } catch (error) {
      if (!error.response) {
        console.error(error);
        return;
      }
      let { status } = error.response;
      if (status === 401 || status === 403) {
        cleanSession();
        window.location.href = "/login";
      }
      if (status === 500) {
        notify(
          error.response.data?.error,
          "warning"
        )
        return;
      }
      notify(
        error.response.data?.message?.message,
        "warning",
        wrapArrayToMap(error.response.data?.message?.parameters)
      );
    }
  };

  return (
    <>
      <div className="listButton">
        <CustomerActionsButtons sizeBtn={sizeBtn} type="unblock" handleClick={handleClick} />
      </div>
      <Confirm
        isOpen={open}
        title=""
        content={translate("confirm_messages.customer.unblock", { arg_0: record.name })}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

export const CustomerDeclineButton = ({ record, sizeBtn }) => {
  const [dialogOpened, setDialogOpened] = useState(false);
  const [reason, setReason] = useState("");
  const [error, setError] = useState(false);
  const [reasonError, setReasonError] = useState("");
  const notify = useNotify();
  const refresh = useRefresh();
  const classes = useStyles();
  const translate = useTranslate();

  const handleClick = (e) => {
    e.stopPropagation();
    setDialogOpened(true)
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setDialogOpened(false);
  };

  const decline = async () => {
    if (!reason) {
      setError(true);
      return;
    }

    let isError = false;

    if (Validator.validateReasonBlock(reason)) {
      setReasonError(Validator.validateReasonBlock(reason));
      isError = true;
    }

    if (isError) {
      return;
    }

    try {
      await dataProviderForCustomers.decline("customers", {
        id: record.id,
        reason: reason
      });
      setDialogOpened(false);
      refresh();
    } catch (error) {
      if (!error.response) {
        console.error(error);
        return;
      }
      let { status } = error.response;
      if (status === 401 || status === 403) {
        cleanSession();
        window.location.href = "/login";
      }
      if (status === 500) {
        notify(
          error.response.data?.error,
          "warning"
        )
        return;
      }
      notify(
        error.response.data?.message?.message,
        "warning",
        wrapArrayToMap(error.response.data?.message?.parameters)
      );
    }
  };

  const checkRequired = (e) => {
    if (!e.target.value) {
      setError(true);
    }
  }

  const changeReason = (e) => {
    const { value } = e.target
    if (value) {
      setError(false);
    }
    setReasonError("");
    setReason(value);
  }

  return (
    <>
      <div className="listButton">
        <CustomerActionsButtons type="decline" sizeBtn={sizeBtn} handleClick={handleClick} />
      </div>
      {dialogOpened && (
        <Dialog
          open={dialogOpened}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" className={classes.capitalize}>
            {translate("konebone.customer.decline_candidate")}
          </DialogTitle>
          <DialogContent>
            <TextField
              error={error || !!reasonError}
              id="name"
              label={translate(
                "konebone.customer.reason_for_decline"
              )}
              fullWidth
              variant="outlined"
              value={reason}
              onChange={changeReason}
              onBlur={(e) => checkRequired(e)}
              helperText={error ?  translate("ra.validation.required") : translate(reasonError, { max: "500" })}
              multiline
              rows="4"
              required
            />
          </DialogContent>
          <DialogActions className={classes.root}>
            <Button onClick={handleClose} variant="outlined">
              {translate("ra.action.cancel")}
            </Button>
            <Button onClick={decline} variant="contained" color="secondary">
              {translate("konebone.customer.decline")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export const CustomerApproveButton = ({ record, sizeBtn }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const translate = useTranslate();
  const [open, setOpen] = useState(false);

  const handleClick = (e) => {
    e.stopPropagation();
    setOpen(true)
  };
  const handleDialogClose = (e) => {
    e.stopPropagation();
    setOpen(false)
  };

  const handleConfirm = async (e) => {
    e.stopPropagation();
    try {
      await dataProviderForCustomers.approve("customers", {
        id: record.id,
      });
      refresh();
    } catch (error) {
      if (!error.response) {
        console.error(error);
        return;
      }
      let { status } = error.response;
      if (status === 401 || status === 403) {
        cleanSession();
        window.location.href = "/login";
      }
      if (status === 500) {
        notify(
          error.response.data?.error,
          "warning"
        )
        return;
      }
      notify(
        error.response.data?.message?.message,
        "warning",
        wrapArrayToMap(error.response.data?.message?.parameters)
      );
    }
  };

  if (record.status?.toUpperCase() === "BLOCKED") {
    return (<CustomerUnblockedButton record={record} />);
  }

  return (
    <>
      <div className="listButton">
        <CustomerActionsButtons type="approve" sizeBtn={sizeBtn} handleClick={handleClick} />
      </div>
      <Confirm
        isOpen={open}
        title=""
        content={translate("confirm_messages.customer.approval")}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

export const CustomerDeleteButton = ({ record, sizeBtn }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const translate = useTranslate();
  const [open, setOpen] = useState(false);

  const handleClick = (e) => {
    e.stopPropagation();
    setOpen(true)
  };
  const handleDialogClose = (e) => {
    e.stopPropagation();
    setOpen(false)
  };

  const handleConfirm = async (e) => {
    e.stopPropagation();
    try {
      await dataProviderForCustomers.delete("customers", {
        id: record.id,
      });
      setOpen(false);
      refresh();
    } catch (error) {
      if (!error.response) {
        console.error(error);
        return;
      }
      let { status } = error.response;
      if (status === 401 || status === 403) {
        cleanSession();
        window.location.href = "/login";
      }
      if (status === 500) {
        notify(
          error.response.data?.error,
          "warning"
        )
        return;
      }
      notify(
        error.response.data?.message?.message,
        "warning",
        wrapArrayToMap(error.response.data?.message?.parameters)
      );
    }
  };

  return (
    <>
      <div className="listButton">
        <CustomerActionsButtons type="delete" sizeBtn={sizeBtn} handleClick={handleClick} />
      </div>
      <Confirm
        isOpen={open}
        title=""
        content={translate("confirm_messages.customer.delete")}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

function CustomerActionsButtons({ type, sizeBtn = "large", handleClick }) {
  const classes = useStyles();
  const isLargeSize = sizeBtn === "large";
  const btnSize = isLargeSize ? "large" : "small";
  type = type.toLowerCase();

  return (
    <Button
      variant="outlined"
      size={btnSize}
      className={!isLargeSize ? (classes[type] + ' ' + classes.small_btn) : classes[type]}
      endIcon={isLargeSize && buttonsType[type].icon}
      onClick={handleClick}
    >{isLargeSize ? buttonsType[type].bntLable : buttonsType[type].icon}
    </Button>
  )
}