import React, { useState, cloneElement } from "react";
import {
  List,
  EmailField,
  TextField,
  CreateButton,
  TopToolbar,
  sanitizeListRestProps,
  ExportButton,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  EditButton,
  DeleteButton,
  useNotify,
  SelectInput,
} from "react-admin";
import { wrapArrayToMap } from "../utils/Wrapper";
import { useAuthState, useRedirect, useRefresh, useTranslate } from "ra-core";
import Validator from "../utils/Validator";
import axios from "axios";
import DatagridResponsive from "../components/DataGridResponsive";
import { cleanSession } from "../authProvider";

const ListActions = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter, // you can hide ExportButton if exporter = (null || false)
  filterValues,
  permanentFilter,
  hasCreate, // you can hide CreateButton if hasCreate = false
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  permissions,
  ...rest
}) => (
  <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
    {filters &&
      cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button",
      })}
    {permissions === "SUPER_ADMIN" && <CreateButton basePath="/admin" />}
    {exporter && (
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={{ ...filterValues, ...permanentFilter }}
        exporter={exporter}
        maxResults={maxResults}
      />
    )}
  </TopToolbar>
);

ListActions.defaultProps = {
  selectedIds: [],
};

export const AdminCreate = (props) => {
  const [rolesList, setRolesList] = useState([]);
  const [rolesFetch, setRolesFetch] = useState(false);
  const translate = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();
  const { loading, authenticated } = useAuthState();

  const onSuccess = () => {
    notify("ra.notification.created", "success");
    redirect("list", props.basePath);
  };

  const handleFailure = (error) => {
    if (!error.response) {
      console.error(error);
      return;
    }
    let { status } = error.response;
    if (status === 401 || status === 403) {
      cleanSession();
      window.location.href = "/login";
    }
    if (status === 500) {
      notify(
        error.response.data?.error,
        "warning"
      )
      return;
    }
    notify(
      error.response.data?.message?.message,
      "warning",
      wrapArrayToMap(error.response.data?.message?.parameters)
    );
  };

  if (!authenticated || loading) {
    return null;
  }

  if (!rolesFetch) {
    setRolesFetch(true);
    getRoles(setRolesList);
  }

  return rolesList.length > 0 ? (
    <Create {...props} onFailure={handleFailure} onSuccess={onSuccess}>
      <SimpleForm redirect="list" submitOnEnter={false}>
        <TextInput
          source="firstName"
          label={translate("konebone.admins.first_name")}
          validate={Validator.validateName}
          variant="outlined"
        />
        <TextInput
          source="lastName"
          label={translate("konebone.admins.last_name")}
          validate={Validator.validateName}
          variant="outlined"
        />
        <TextInput
          source="email"
          label={translate("konebone.admins.email")}
          validate={Validator.validateEmail}
          variant="outlined"
        />
        <TextInput
          source="phone"
          label={translate("konebone.admins.phone")}
          validate={Validator.validatePhone}
          variant="outlined"
        />
        <TextInput
          type="password"
          source="password"
          label={translate("konebone.admins.password")}
          validate={Validator.validatePassword}
          variant="outlined"
        />
        <TextInput
          type="password"
          source="confirmPassword"
          label={translate("konebone.admins.confirm_password")}
          validate={Validator.validateConfirmPassword}
          variant="outlined"
        />
        <SelectInput
          label={translate("konebone.admins.roles")}
          source="roles"
          validate={Validator.validateRoles}
          choices={rolesList.map((role) => {
            return { id: role, name: role };
          })}
          variant="outlined"
        />
      </SimpleForm>
    </Create>
  ) : (
    <div />
  );
};

export const AdminEdit = (props) => {
  const [rolesList, setRolesList] = useState([]);
  const [rolesFetch, setRolesFetch] = useState(false);
  const translate = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();
  const { loading, authenticated } = useAuthState();

  const handleFailure = (error) => {
    if (!error.response) {
      console.error(error);
      return;
    }
    let { status } = error.response;
    if (status === 401 || status === 403) {
      cleanSession();
      window.location.href = "/login";
    }
    if (status === 500) {
      notify(
        error.response.data?.error,
        "warning"
      )
      return;
    }
    notify(
      error.response.data?.message?.message,
      "warning",
      wrapArrayToMap(error.response.data?.message?.parameters)
    );
  };

  const onSuccess = () => {
    notify("ra.notification.updated", "success");
    redirect("list", props.basePath);
  };

  if (!authenticated || loading) {
    return null;
  }

  if (!rolesFetch) {
    setRolesFetch(true);
    getRoles(setRolesList);
  }

  return rolesList.length > 0 ? (
    <Edit {...props} mutationMode="pessimistic" onFailure={handleFailure} onSuccess={onSuccess}>
      <SimpleForm redirect="list" submitOnEnter={false}>
        <TextInput
          source="firstName"
          label={translate("konebone.admins.first_name")}
          validate={Validator.validateName}
          variant="outlined"
        />
        <TextInput
          source="lastName"
          label={translate("konebone.admins.last_name")}
          validate={Validator.validateName}
          variant="outlined"
        />
        <TextInput
          source="email"
          label={translate("konebone.admins.email")}
          validate={Validator.validateEmail}
          variant="outlined"
        />
        <TextInput
          source="phone"
          label={translate("konebone.admins.phone")}
          validate={Validator.validatePhone}
          variant="outlined"
        />
        <TextInput
          type="password"
          source="password"
          label={translate("konebone.admins.password")}
          validate={Validator.validatePasswordNotRequired}
          variant="outlined"
        />
        <TextInput
          type="password"
          source="confirmPassword"
          label={translate("konebone.admins.confirm_password")}
          validate={Validator.validatePasswordNotRequired}
          variant="outlined"
        />
        <AdminEditRole rolesList={rolesList} />
      </SimpleForm>
    </Edit>
  ) : (
    <div />
  );
};

const AdminDeleteButton = ({ record }) => {
  let canDelete =
    localStorage.getItem("role") &&
    localStorage.getItem("role").includes("SUPER_ADMIN") &&
    localStorage.getItem("login") !== record.email;

  const notify = useNotify();
  const refresh = useRefresh();

  const handleFailure = (error) => {
    if (!error.response) {
      console.error(error);
      return;
    }
    let { status } = error.response;
    if (status === 401 || status === 403) {
      cleanSession();
      window.location.href = "/login";
    }
    if (status === 500) {
      notify(
        error.response.data?.error,
        "warning"
      )
      return;
    }
    notify(
      error.response.data?.message?.message,
      "warning",
      wrapArrayToMap(error.response.data?.message?.parameters)
    );
  };

  const onSuccess = () => {
    notify("ra.notification.deleted", "success");
    refresh();
  };

  return canDelete ? (
    <div className="listButton">
      <DeleteButton
        mutationMode="pessimistic"
        onFailure={handleFailure}
        label={""}
        record={record}
        onSuccess={onSuccess}
      />
    </div>
  ) : (
    <div />
  );
};

const AdminEditRole = ({ record, rolesList }) => {
  const translate = useTranslate();
  let canEditRole = localStorage.getItem("login") !== record.email;

  return canEditRole ? (
    <SelectInput
      label={translate("konebone.admins.roles")}
      source="roles"
      choices={rolesList.map((role) => {
        return { id: role, name: role };
      })}
      record={record}
      validate={Validator.validateRoles}
      variant="outlined"
    />
  ) : (
    <div />
  );
};

const AdminEditButton = ({ record }) => {
  let canEdit =
    localStorage.getItem("role") &&
    localStorage.getItem("role").includes("SUPER_ADMIN");

  return canEdit ? (
    <div className="listButton">
      <EditButton label={""} record={record} />
    </div>
  ) : (
    <div />
  );
};

const AdminTitle = (props) => {
  const translate = useTranslate();
  return <span>{translate("konebone.admins.title").toUpperCase()}</span>;
};

export const AdminsList = (props) => {
  const translate = useTranslate();
  const { loading, authenticated } = useAuthState();

  if (!authenticated || loading) {
    return null;
  }

  return (
    <List
      title={<AdminTitle />}
      {...props}
      actions={<ListActions permissions={props.permissions} />}
      bulkActionButtons={false}
      exporter={null}
      sort={{ field: "id", order: "DESC" }}
    >
      <DatagridResponsive>
        <TextField source="id"
          label={translate("konebone.admins.id")}
          sortable={false}
        />
        <TextField
          source="firstName"
          label={translate("konebone.admins.first_name")}
          sortable={false}
        />
        <TextField
          source="lastName"
          label={translate("konebone.admins.last_name")}
          sortable={false}
        />
        <EmailField source="email"
          label={translate("konebone.admins.email")}
          sortable={false}
        />
        <TextField source="phone"
          label={translate("konebone.admins.phone")}
          sortable={false}
        />
        <TextField
          source="createdBy"
          label={translate("konebone.admins.created_by")}
          sortable={false}
        />
        <TextField
          source="createdAtUnix"
          label={translate("konebone.admins.created_at")}
          sortable={false}
        />
        <TextField
          source="editedBy"
          label={translate("konebone.admins.edited_by")}
          sortable={false}
        />
        <TextField
          source="editedAtUnix"
          label={translate("konebone.admins.edited_at")}
          sortable={false}
        />
        <TextField
          source="rolesString"
          label={translate("konebone.admins.roles")}
          sortable={false}
        />
        <AdminEditButton />
        <AdminDeleteButton />
      </DatagridResponsive>
    </List>
  );
};

function getRoles(setRolesList) {
  axios
    .get(process.env.REACT_APP_API_URL + "/role", {
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (response.data && response.data.data) {
        setRolesList(response.data.data);
      }
    })
    .catch((error) => {
      if (!error.response) {
        console.error(error);
        return;
      }
      let status = error.response.status;
      if (status === 401 || status === 403) {
        cleanSession();
        window.location.href = "/login";
      }
      if (status === 500) {
        notify(
          error.response?.error,
          "warning"
        )
        return;
      }
    });
}
