import React, { useState, useEffect } from "react";
import { useTranslate, useRefresh, useRedirect } from "ra-core";
import {
  Button,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { useNotify, Confirm } from "react-admin";

import { ReactComponent as ButtonLock } from "../../svg/button-lock.svg";
import { ReactComponent as ButtonUnlock } from "../../svg/button-open.svg";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import { wrapArrayToMap } from "../../utils/Wrapper";
import dataProviderForSupplier from "../../providers/DataProviderForSupplier";
import dataProviderForSupplierCandidate from "../../providers/DataProviderForSupplierCandidate";
import Validator from "../../utils/Validator";

export default (status, data, sizeBtn) => {
  const statusInfoMap = {
    WAITING_FOR_REVIEW: {
      lable: "Waiting for review",
      lableColor: "#2F80ED",
      buttons: [<SupplierApproveButton key="SupplierApproveButton" record={data} sizeBtn={sizeBtn} />, <SupplierDeclineButton key="SupplierDeclineButton" record={data} sizeBtn={sizeBtn}  />],
    },
    UNFINISHED: {
      lable: "Unfinished",
      lableColor: "#686868",
      buttons: [<SupplierApproveButton key="SupplierApproveButton" record={data} sizeBtn={sizeBtn}/>, <SupplierDeleteButton key="SupplierDeleteButton" record={data} sizeBtn={sizeBtn} />]
    },
    DECLINED: {
      lable: "Declined",
      lableColor: "#F2994A",
      buttons: [<SupplierApproveButton key="SupplierApproveButton" record={data} sizeBtn={sizeBtn} />]
    },
    BLOCKED: {
      lable: "Blocked",
      lableColor: "#EB5757",
      buttons: [<SupplierUnblockedButton key="SupplierUnblockedButton" record={data} sizeBtn={sizeBtn} />]
    },
    ACTIVE: {
      lable: "Active",
      lableColor: "#219653",
      buttons: [<SupplierBlockedButton key="SupplierBlockedButton" record={data} sizeBtn={sizeBtn} />]
    }
  };
  return statusInfoMap[status];
}

const useStyles = makeStyles((theme) => ({
  approve: {
    border: "1px solid #219653",
    borderRadius: "3px",
    color: "#219653",
  },
  decline: {
    border: "1px solid #E02443",
    borderRadius: "3px",
    color: "#E02443",
  },
  unblock: {
    border: "1px solid #DDDBDA",
    borderRadius: "3px",
    color: "#484848",
  },
  block: {
    border: "1px solid #DDDBDA",
    borderRadius: "3px",
    color: "#484848",
  },
  delete: {
    border: "1px solid #DDDBDA",
    borderRadius: "3px",
    color: "#484848",
  },
  small_btn: {
    width: "30px",
    minWidth: "30px",
    maxWidth: "30px",
    padding: "3px 0",
  },
}));

const buttonsType = {
  approve: {
    bntLable: "Approve",
    icon: <CheckCircleOutlineIcon />
  },
  decline: {
    bntLable: "Decline",
    icon: <ClearIcon />
  },
  unblock: {
    bntLable: "Unblock",
    icon: <ButtonUnlock />
  },
  block: {
    bntLable: "Block",
    icon: <ButtonLock />
  },
  delete: {
    bntLable: "Delete",
    icon: <DeleteOutlineIcon style={{ color: "#AFAFAF" }} />
  }
}

export const SupplierBlockedButton = ({ record, sizeBtn }) => {
  const [dialogOpened, setDialogOpened] = useState(false);
  const [reason, setReason] = useState("");
  const [error, setError] = useState(false);
  const [reasonError, setReasonError] = useState("");
  const notify = useNotify();
  const classes = useStyles();
  const translate = useTranslate();
  const refresh = useRefresh();

  const handleClick = (e) => {
    e.stopPropagation();
    setDialogOpened(true)
  };

  const handleClose = () => {
    setDialogOpened(false);
  };

  const blocked = async () => {
    if (!reason) {
      setError(true);
      return;
    }
    let isError = false;

    if (Validator.validateReasonBlock(reason)) {
      setReasonError(Validator.validateReasonBlock(reason));
      isError = true;
    }

    if (isError) {
      return;
    }

    try {
      await dataProviderForSupplier.block("suppliers", {
        id: record.id,
        reason: reason
      });
      setDialogOpened(false);
      refresh();
    } catch (error) {
      if (!error.response) {
        console.error(error);
        return;
      }
      let { status } = error.response;
      if (status === 401 || status === 403) {
        cleanSession();
        window.location.href = "/login";
      }
      if (status === 500) {
        notify(
          error.response.data?.error,
          "warning"
        );
        return;
      }
      notify(
        error.response.data?.message?.message,
        "warning",
        wrapArrayToMap(error.response?.data?.message?.parameters)
      );
    }
  };

  const checkRequired = (e) => {
    if (!e.target.value) {
      setError(true);
    }
  }

  const changeReason = (e) => {
    const { value } = e.target
    if (value) {
      setError(false);
    }
    setReasonError("");
    setReason(value);
  }

  return (
    <>
      <div className="listButton">
        <SupplierActionsButtons sizeBtn={sizeBtn} type="block" handleClick={handleClick} />
      </div >
      {dialogOpened && (
        <Dialog
          open={dialogOpened}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" className={classes.capitalize}>
            {translate("konebone.supplier.block_supplier")}
          </DialogTitle>
          <DialogContent>
            <TextField
              error={error || !!reasonError}
              id="name"
              label={translate(
                "konebone.supplier.reason_for_block"
              )}
              fullWidth
              variant="outlined"
              value={reason}
              onChange={changeReason}
              onBlur={(e) => checkRequired(e)}
              helperText={error ?  translate("ra.validation.required") : translate(reasonError, { max: "500" })}
              multiline
              rows="4"
              required
            />
          </DialogContent>
          <DialogActions className={classes.root}>
            <Button onClick={handleClose} variant="outlined">
              {translate("ra.action.cancel")}
            </Button>
            <Button onClick={blocked} variant="contained" color="secondary">
              {translate("konebone.supplier.block")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export const SupplierUnblockedButton = ({ record, sizeBtn }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const translate = useTranslate();
  const [open, setOpen] = useState(false);

  const handleClick = (e) => {
    e.stopPropagation();
    setOpen(true)
  };

  const handleDialogClose = (e) => {
    e.stopPropagation();
    setOpen(false);
  };

  const handleConfirm = async (e) => {
    e.stopPropagation();
    try {
      await dataProviderForSupplier.unblock("suppliers", {
        id: record.id,
      });
      setOpen(false);
      refresh();
    } catch (error) {
      if (!error.response) {
        console.error(error);
        return;
      }
      let { status } = error.response;
      if (status === 401 || status === 403) {
        cleanSession();
        window.location.href = "/login";
      }
      if (status === 500) {
        notify(
          error.response.data?.error,
          "warning"
        );
        return;
      }
      notify(
        error.response.data?.message?.message,
        "warning",
        wrapArrayToMap(error.response.data?.message?.parameters)
      );
    }
  };

  return (
    <>
      <div className="listButton">
        <SupplierActionsButtons sizeBtn={sizeBtn} type="unblock" handleClick={handleClick} />
      </div>
      <Confirm
        isOpen={open}
        title=""
        content={translate("confirm_messages.supplier.unblock", { arg_0: record.name })}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

export const SupplierDeclineButton = ({ record, sizeBtn }) => {
  const [dialogOpened, setDialogOpened] = useState(false);
  const [reason, setReason] = useState("");
  const [error, setError] = useState(false);
  const [reasonError, setReasonError] = useState("");
  const notify = useNotify();
  const refresh = useRefresh();
  const classes = useStyles();
  const translate = useTranslate();

  const handleClick = (e) => {
    e.stopPropagation();
    setDialogOpened(true)
  };

  const handleClose = () => {
    setDialogOpened(false);
  };

  const decline = async () => {
    if (!reason) {
      setError(true);
      return;
    }

    let isError = false;

    if (Validator.validateReasonBlock(reason)) {
      setReasonError(Validator.validateReasonBlock(reason));
      isError = true;
    }

    if (isError) {
      return;
    }

    try {
      await dataProviderForSupplierCandidate.decline("suppliers", {
        id: record.id,//editId ? record.id.slice(0,-1) : record.id,
        reason: reason
      });
      setDialogOpened(false);
      refresh();
    } catch (error) {
      if (!error.response) {
        console.error(error);
        return;
      }
      let { status } = error.response;
      if (status === 401 || status === 403) {
        cleanSession();
        window.location.href = "/login";
      }
      if (status === 500) {
        notify(
          error.response.data?.error,
          "warning"
        );
        return;
      }
      notify(
        error.response.data?.message?.message,
        "warning",
        wrapArrayToMap(error.response.data?.message?.parameters)
      );
    }
  };

  const checkRequired = (e) => {
    if (!e.target.value) {
      setError(true);
    }
  }

  const changeReason = (e) => {
    const { value } = e.target
    if (value) {
      setError(false);
    }
    setReasonError("");
    setReason(value);
  }

  return (
    <>
      <div className="listButton">
        <SupplierActionsButtons type="decline" sizeBtn={sizeBtn} handleClick={handleClick} />
      </div>
      {dialogOpened && (
        <Dialog
          open={dialogOpened}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" className={classes.capitalize}>
            {translate("konebone.supplier.decline_candidate")}
          </DialogTitle>
          <DialogContent>
            <TextField
              error={error || !!reasonError}
              id="name"
              label={translate(
                "konebone.supplier.reason_for_decline"
              )}
              fullWidth
              variant="outlined"
              value={reason}
              onChange={changeReason}
              onBlur={(e) => checkRequired(e)}
              helperText={error ? translate("ra.validation.required") : translate(reasonError, { max: "500" })}
              multiline
              rows="4"
              required
            />
          </DialogContent>
          <DialogActions className={classes.root}>
            <Button onClick={handleClose} variant="outlined">
              {translate("ra.action.cancel")}
            </Button>
            <Button onClick={decline} variant="contained" color="secondary">
              {translate("konebone.supplier.decline")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

const createCompaniesNamesOptions = (data) => {
  const result = [<option aria-label="None" value="" key={0} />];
  data.forEach(({ name, id }) => {
    result.push(
      <option key={id} value={id}>
        {name}
      </option>
    );
  });

  return result;
};

export const SupplierApproveButton = ({ record, sizeBtn }) => {
  const [dialogOpened, setDialogOpened] = useState(false);
  const [name, setName] = useState("");
  const [hasBeenImported, setHasBeenImported] = useState(0);
  const [companies, setCompanies] = useState([]);
  const [error, setError] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const classes = useStyles();
  const translate = useTranslate();
  
  useEffect(() => {
    if (!hasBeenImported) {
      return;
    }

    dataProviderForSupplier
      .imported("supplier", {
        pagination: { page: 1, perPage: 1000 },
        sort: { order: "ASC" },
      })
      .then((items) => {
        setCompanies(createCompaniesNamesOptions(items?.data));
      })
      .catch((error) => {
        if (!error.response) {
          console.error(error);
          return;
        }
        let { status } = error.response;
        if (status === 401 || status === 403) {
          cleanSession();
          window.location.href = "/login";
        }
        if (status === 500) {
          notify(
            error.response.data?.error,
            "warning"
          );
          return;
        }
        notify(
          error.response.data?.message?.message,
          "warning",
          wrapArrayToMap(error.response.data?.message?.parameters)
        );
      });
  }, [hasBeenImported]);

  const handleClick = (e) => {
    e.stopPropagation();
    setDialogOpened(true)
  };

  const handleClose = () => {
    setDialogOpened(false);
  };

  const approved = async (e) => {
    if (!name && hasBeenImported) {
      setError(true);
      return;
    }
    e.stopPropagation();
    try {
      await dataProviderForSupplierCandidate.approve("supplier_candidate", {
        id: record.id,//editId ? record.id.slice(0,-1) : record.id,
        supplierId: name
      });
      if(sizeBtn == "small") {
        refresh();
      } else {
        redirect('/supplier?filter=%7B"status"%3A"active"%7D&order=DESC&page=1&perPage=10&sort=name')
      }
    } catch (error) {
      if (!error.response) {
        console.error(error);
        return;
      }
      let { status } = error.response;
      if (status === 401 || status === 403) {
        cleanSession();
        window.location.href = "/login";
      }
      if (status === 500) {
        notify(
          error.response.data?.error,
          "warning"
        );
        return;
      }
      notify(
        error.response.data?.message?.message,
        "warning",
        wrapArrayToMap(error.response.data?.message?.parameters)
      );
    }
  };

  const changeName = (e) => {
    const { value } = e.target
    if (value) {
      setError(false);
    }
    setName(value);
 
  }

  if (record.status?.toUpperCase() === "BLOCKED") {
    return (<SupplierUnblockedButton record={record} />);
  }

  return (
    <>
      <div className="listButton">
        <SupplierActionsButtons type="approve" sizeBtn={sizeBtn} handleClick={handleClick} />
      </div>
      {dialogOpened && (
        <Dialog
          open={dialogOpened}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" className={classes.capitalize}>
            {translate("konebone.supplier.approve_candidate")}
          </DialogTitle>
          <DialogContent>
            <form>
              <FormControl
                className={classes.formControl}
                color="secondary"
                fullWidth
              >
                <RadioGroup name="hasBeenImported" value={hasBeenImported} onChange={(e) => setHasBeenImported(+e.target.value)}>
                  <FormControlLabel value={0} control={<Radio />} label={translate("konebone.supplier.has_not_been_imported")} />
                  <FormControlLabel value={1} control={<Radio />} label={translate("konebone.supplier.has_been_imported")} />
                </RadioGroup>
              </FormControl>
              {!!hasBeenImported && <FormControl
                className={classes.formControl}
                required={!!hasBeenImported}
                error={error}
                variant="outlined"
                fullWidth
              >
                <InputLabel htmlFor="companyName-label">
                  {translate(
                    "konebone.supplier.business_company_name"
                  )}
                </InputLabel>
                <Select
                  native
                  labelWidth={115}
                  name="companyName"
                  id="companyName"
                  labelId="companyName-label"
                  value={name}
                  onChange={changeName}
                >
                  {companies}
                </Select>
                {error && (
                  <FormHelperText>Required</FormHelperText>
                )}
              </FormControl>}
            </form>
          </DialogContent>
          <DialogActions className={classes.root}>
            <Button onClick={handleClose} variant="outlined">
              {translate("ra.action.cancel")}
            </Button>
            <Button onClick={approved} variant="contained" color="secondary">
              {translate("konebone.supplier.approve")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export const SupplierDeleteButton = ({ record, sizeBtn }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const translate = useTranslate();
  const [open, setOpen] = useState(false);

  const handleClick = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleDialogClose = (e) => {
    e.stopPropagation();
    setOpen(false);
  };

  const handleConfirm = async (e) => {
    e.stopPropagation();
    try {
      await dataProviderForSupplierCandidate.delete("supplier_candidate", {
        id: record.id,//editId ? record.id.slice(0,-1) : record.id,
      });
      setOpen(false);
      refresh();
    } catch (error) {
      if (!error.response) {
        console.error(error);
        return;
      }
      let { status } = error.response;
      if (status === 401 || status === 403) {
        cleanSession();
        window.location.href = "/login";
      }
      if (status === 500) {
        notify(
          error.response.data?.error,
          "warning"
        );
        return;
      }
      notify(
        error.response.data?.message?.message,
        "warning",
        wrapArrayToMap(error.response.data?.message?.parameters)
      );
    }
  };

  return (
    <>
      <div className="listButton">
        <SupplierActionsButtons type="delete" sizeBtn={sizeBtn} handleClick={handleClick} />
      </div>
      <Confirm
        isOpen={open}
        title=""
        content={translate("confirm_messages.supplier.delete")}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

function SupplierActionsButtons({ type, sizeBtn = "large", handleClick }) {
  const classes = useStyles();
  const isLargeSize = sizeBtn === "large";
  const btnSize = isLargeSize ? "large" : "small";
  type = type.toLowerCase();

  return (
    <Button
      variant="outlined"
      size={btnSize}
      className={!isLargeSize ? (classes[type] + ' ' + classes.small_btn) : classes[type]}
      endIcon={isLargeSize && buttonsType[type].icon}
      onClick={handleClick}
    >{isLargeSize ? buttonsType[type].bntLable : buttonsType[type].icon}
    </Button>
  )
}
