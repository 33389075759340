import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useTranslate } from 'ra-core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Notification } from "react-admin";
import { useLocation } from "react-router-dom";
import { cleanSession } from '../authProvider';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: "center"
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  forgotLink: {
    justifyContent:"center" 
  }
}));

const Recover = ({ theme }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const location = useLocation();
  const [submitDone, setSubmitDone] = useState(false);
  const [email, setEmail] = useState('')


  const submit = e => {
    e.preventDefault();
    setSubmitDone(true);
    cleanSession();
    window.location.href = "/login";
  };

  useEffect(() => {
    setEmail(location?.state?.email); 
 }, []);

  return (
    <MuiThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {translate('ra.auth.link_sent')}
          </Typography>
          <Typography component="h1" className={classes.text}>
            {translate('ra.auth.recover_text_half')}{" "}{email}
            {translate('ra.auth.recover_text')}
          </Typography>
          <form className={classes.form} noValidate onSubmit={submit}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={submitDone}
            >
              {translate('ra.auth.got_it')}
            </Button>
          </form>
        </div>
      </Container>
      <Notification />
    </MuiThemeProvider>
  );
}

export default Recover;
