import { useAuthState, useRedirect, useTranslate } from "ra-core";
import React, { useEffect, useMemo, useState } from "react";
import {
  useNotify,
  TopToolbar,
  Edit,
  TabbedForm,
  FormTab,
  Confirm,
} from "react-admin";
import { Button, makeStyles, Box, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import ProductGeneralInfo from "./ProductsGeneralInfo";
import ProductsPriceLinesList from "./ProductsPriceLinesList";
import dataProviderForProducts from "../../providers/DataProviderForProducts";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { useLocation } from "react-router-dom";

import { getDateByFormatFull } from "../../utils/DateFormat"

const useStyles = makeStyles((theme) => ({
  toolbar_root: {
    flexDirection: "column",
    justifyContent: "flex-start",
    "& button": {
      margin: theme.spacing(1),
    },
  },
  stepper_root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: "inline-block",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const ProductEditActions = ({ data }) => {
  const [active, setActive] = useState(data?.active);
  const [open, setOpen] = useState(false);

  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();

  useEffect(() => {
    setActive(data?.active);
  }, [data?.active]);

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = () => {
    setOpen(false);
    updateStatus();
  };

  const searchParam = useMemo(() => {
    const search = localStorage.getItem('listSearch');
    localStorage.removeItem('listSearch');
    return search;
  }, [])

  const updateStatus = async (e) => {
    try {
      await dataProviderForProducts.updateStatus("products", {
        data: {
          status: !active,
          productName: `${data?.koneboneProductName || data?.name}`,
        },
      });

      setActive(!active);
    } catch (error) {
      if (!error.response) {
        console.error(error);
        return;
      }
      let { status } = error.response;
      if (status === 401 || status === 403) {
        cleanSession();
        window.location.href = "/login";
      }
      if (status === 500) {
        notify(
          error.response.data?.error,
          "warning"
        )
        return;
      }
      notify(
        error.response.data?.message?.message,
        "warning",
        wrapArrayToMap(error.response.data?.message?.parameters)
      );
    }
  };

  return (
    <TopToolbar className={classes.toolbar_root}>
      <Button
        label={translate("konebone.products.go_back")}
        startIcon={<KeyboardBackspaceIcon />}
        component={Link}
        to={{
          pathname: "/products",
          search: searchParam
        }}
      >
        {translate("konebone.products.go_back")}
      </Button>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Typography variant="h5" gutterBottom>
          {data?.name?.toUpperCase()}
        </Typography>
        <Button
          color="default"
          variant="outlined"
          label={translate("konebone.products.deactivate_product")}
          onClick={handleClick}
        >
          {active
            ? translate("konebone.products.deactivate_product")
            : active === false
              ? translate("konebone.products.activate_product")
              : ""}
        </Button>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        width="100%"
      >
        <Box flex={2} mr="0.8em" display="flex" justifyContent="flex-start">
          <Box marginRight="20px">
            <span style={{ color: "#7A7A7A" }}>{translate("konebone.products.create_on", {
              arg_0: getDateByFormatFull(data?.creationDate) || "-",
              arg_1: `${data?.createdBy?.firstName} ${data?.createdBy?.lastName}`
            })}</span>
          </Box>
          <Box>
            <span style={{ color: "#7A7A7A" }}>{translate("konebone.products.last_update_on")}:</span>
            <span style={{ fontWeight: "700" }}>{data?.lastUpdateDate ? getDateByFormatFull(data.lastUpdateDate) : "-"}</span>
          </Box>
        </Box>
        <Box flex={1} mr="0.2em">
          {translate("konebone.products.status")}:
          <span style={{ color: active ? "green" : "red" }}>
            {active ? "Active" : "Deactivated"}
          </span>
        </Box>
      </Box>
      <Confirm
        isOpen={open}
        title=""
        content={
          active
            ? translate("confirm_messages.products.deactivate", {
              arg: data?.name,
            })
            : translate("confirm_messages.products.activate", {
              arg: data?.name,
            })
        }
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </TopToolbar>
  );
};

const EditProductsTitle = ({ record }) => {
  return <span>{record?.name?.toUpperCase()}</span>;
};


const ProductsEdit = ({ id, ...props }) => {
  const notify = useNotify();
  const location = useLocation();
  const redirect = useRedirect();
  const translate = useTranslate();
  const { loading, authenticated } = useAuthState();
  
  useEffect(() => {
    if (location.pathname.slice(-2) === "/1") {
      return;
    }
    redirect(`${location.pathname}/1`);
  }, []);

  let productId = id.split("-")[0];

  if (productId[0] === "0") {
    productId = Number(productId);
  }

  const handleFailure = (error) => {
    if (!error.response) {
      console.error(error);
      return;
    }
    let { status } = error.response;
    if (status === 401 || status === 403) {
      cleanSession();
      window.location.href = "/login";
    }
    if (status === 500) {
      notify(
        error.response.data?.error,
        "warning"
      )
      return;
    }
    notify(
      error.response.data?.message?.message,
      "warning",
      wrapArrayToMap(error.response.data?.message?.parameters)
    );
  };

  if (!authenticated || loading) {
    return null;
  }

  return (
    <Edit
      title={<EditProductsTitle />}
      onFailure={handleFailure}
      actions={<ProductEditActions />}
      id={productId}
      {...props}
    >
      <TabbedForm
        submitOnEnter={false}
        redirect={"./products"}
        toolbar={null}
      >
        <FormTab label={translate("konebone.products.general_info")}>
          <ProductGeneralInfo environment="edit" />
        </FormTab>
        <FormTab label={translate("konebone.products.price_lines")}>
          <ProductsPriceLinesList />
        </FormTab>
        {/* <FormTab
          label={translate("konebone.products.alternative_product")}
          disabled
        ></FormTab> */}
      </TabbedForm>
    </Edit>
  );
};

export { ProductsEdit };
