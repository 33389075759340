import React, { useEffect, useState, useCallback } from "react";
import { TopToolbar } from "react-admin";
import { useTranslate } from "ra-core";
import { Button, Box, Typography, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import SuppliersActionsButtons from "./SuppliersActionsButtons";

import { getDateByFormatFull } from "../../utils/DateFormat"

const useStyles = makeStyles((theme) => ({
  toolbar_root: {
    flexDirection: "column",
    justifyContent: "flex-start",
    "& button": {
      margin: theme.spacing(1),
    },
  }
}));

export default ({ data: { status, ...restData } = {}, sizeBtn: sizeBtn }) => {
  const [statusInfo, setStatusInfo] = useState(status);
  const translate = useTranslate();
  const classes = useStyles();

  useEffect(() => {
    setStatusInfo(status);
  }, [status]);

  return (
    <TopToolbar className={classes.toolbar_root}>
      <Button
        label={translate("konebone.supplier.go_back")}
        startIcon={<KeyboardBackspaceIcon />}
        component={Link}
        to="/supplier"
      >
        {translate("konebone.supplier.go_back")}
      </Button>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Typography variant="h5" gutterBottom>
          {restData?.name?.toUpperCase()}
        </Typography>
        
        <div className="flex-container">{SuppliersActionsButtons(statusInfo, restData, sizeBtn)?.buttons}</div>

      </Box>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        width="100%"
      >
        <Box flex={1} mr="0.5em">
          <span style={{ color: "#7A7A7A" }}>{translate("konebone.customer.create_on")}:</span>
          <span style={{ fontWeight: "700" }}>{getDateByFormatFull(restData.createdAt)}</span>

        </Box>
        <Box flex={1} mr="0.5em">

          {translate("konebone.supplier.status")}:

          <span style={{ color: SuppliersActionsButtons(statusInfo, restData, sizeBtn)?.lableColor }}>
            {SuppliersActionsButtons(statusInfo, restData, sizeBtn)?.lable}
          </span>
        </Box>
      </Box>
    </TopToolbar>

  );
};