import React from "react";
import UserIcon from "@material-ui/icons/Group";
import Store from "@material-ui/icons/Store";
import CategoryIcon from '@material-ui/icons/Category';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { Manufacturer, Measurement, PackageType } from './utils/Icons';
import PublicIcon from '@material-ui/icons/Public';
import { Admin, Resource } from "react-admin";
import { Route } from "react-router-dom";
import { reducer as tree } from "./raTreeCore";

import { ProductsList, ProductsEdit, ProductsCreate } from "./pages/products";
import { AdminCreate, AdminEdit, AdminsList } from "./pages/Admins";
import { CategoriesList, CategoryCreate, CategoryEdit } from "./pages/Categories";
import { ManufacturerList, ManufacturerCreate, ManufacturerEdit } from "./pages/Manufacturer";
import { ManufacturingCountryList, ManufacturingCountryEdit, ManufacturingCountryCreate } from "./pages/ManufacturingCountry";
import { SupplierCandidateEdit, SupplierCandidateShow, SupplierEdit, SupplierList, SupplierShow } from "./pages/suppliers";
import { MeasurementList, MeasurementEdit, MeasurementCreate } from "./pages/Measurement";
// import { PackageCreate, PackageEdit, PackageList } from "./pages/Packages";
import { PackageTypeCreate, PackageTypeEdit, PackageTypeList } from "./pages/PackageTypes";
import { CustomerEdit, CustomerList, CustomerShow } from "./pages/customers";
import { AlternativeProductCreate, OfferEdit, OfferShow, OrdersList, OrdersShow } from "./pages/orders";
import Login from "./pages/Login";
import MyLayout from "./components/MyLayout";

import theme from "./config/theme";
import authProvider from "./authProvider";
import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "./i18n/en";
import hebrewMessages from "./i18n/he";
import UploadForm from "./pages/UploadForm";
import combineProviders from "./providers/CombineProviders";

import CssBaseline from "@material-ui/core/CssBaseline";
import MyLogoutButton from "./components/MyLogoutButton";
import SupplierCreate from "./pages/suppliers/SupplierCreate";
import { OrderEdit } from "./pages/orders/OrderEdit" 
import ForgotPassword from "./pages/ForgotPassword";
import Recover from "./pages/Recover";
import ResetPassword from "./pages/ResetPassword";

const messages = {
  en: englishMessages,
  he: hebrewMessages,
};

const i18nProvider = polyglotI18nProvider(
  () => messages[process.env.REACT_APP_DIRECTION === "ltr" ? "en" : "he"], 'en', 
  {
    allowMissing: true
  }
);

const App = () => {
  return (
    <>
      <CssBaseline />
      <Admin
        i18nProvider={i18nProvider}
        theme={theme}
        dataProvider={combineProviders}
        authProvider={authProvider}
        layout={MyLayout}
        loginPage={Login}
        logoutButton={MyLogoutButton}
        customReducers={{ tree }}
        customRoutes={[
          <Route key="/recover" path="/recover" component={Recover} noLayout/>,
          <Route key="/reset_password" path="/reset_password" component={ResetPassword} noLayout/>,
          <Route key="/forgot" path="/forgot" component={ForgotPassword} noLayout />,
          <Route key="upload" path="/upload" component={UploadForm} />,
          <Route key="supplier_candidate" exact path="/supplier_candidate/:id" component={SupplierCandidateEdit} />,
          <Route key="supplier_candidate" exact path="/supplier_candidate/:id/show" component={SupplierCandidateShow} />,
          <Route key="offers" exact path="/orders/:orderId/show/offers/:id" component={OfferEdit} />,
          <Route key="offers" exact path="/orders/:orderId/show/offers/:id/show" component={OfferShow} />,
          <Route key="offers" exact path="/orders/:orderId/show/offers/:offerId/product/:id" component={AlternativeProductCreate} />,
        ]}
      >
        <Resource
          options={{ label: "konebone.admins.menu_item" }}
          name="admin"
          icon={AccountCircleIcon}
          list={AdminsList}
          create={AdminCreate}
          edit={AdminEdit}
        />
        <Resource
          options={{ label: "konebone.products.menu_item" }}
          name="products"
          icon={Store}
          list={ProductsList}
          edit={ProductsEdit}
          create={ProductsCreate}
        />
        <Resource
          options={{ label: "konebone.categories.menu_item" }}
          name="categories"
          icon={CategoryIcon}
          list={CategoriesList}
          create={CategoryCreate}
          edit={CategoryEdit}
        />
        <Resource
          options={{ label: "konebone.manufacturer.menu_item" }}
          name="manufacturer"
          icon={Manufacturer}
          list={ManufacturerList}
          create={ManufacturerCreate}
          edit={ManufacturerEdit}
        />
        <Resource
          options={{ label: "konebone.manufacturing_country.menu_item" }}
          name="manufacturing_country"
          icon={PublicIcon}
          list={ManufacturingCountryList}
          create={ManufacturingCountryCreate}
          edit={ManufacturingCountryEdit}
        />
        <Resource
          options={{ label: 'konebone.supplier.menu_item' }}
          name="supplier"
          icon={LocalShippingIcon}
          list={SupplierList}
          show={SupplierShow}
          edit={SupplierEdit}
          create={SupplierCreate}
        />
        <Resource
          options={{ label: 'konebone.measurement.menu_item' }}
          name="measurement"
          icon={Measurement}
          list={MeasurementList}
          create={MeasurementCreate}
          edit={MeasurementEdit}
        />
        {/* <Resource
          options={{ label: 'konebone.package.menu_item' }}
          name="package"
          icon={Package}
          list={PackageList}
          create={PackageCreate}
          edit={PackageEdit}
        /> */}
        <Resource
          options={{ label: 'konebone.package_type.menu_item' }}
          name="package_type"
          icon={PackageType}
          list={PackageTypeList}
          create={PackageTypeCreate}
          edit={PackageTypeEdit}
        />
        <Resource
          options={{ label: 'konebone.customer.menu_item' }}
          name="customer"
          icon={UserIcon}
          list={CustomerList}
          edit={CustomerEdit}
          show={CustomerShow}
        />
        <Resource
          options={{ label: 'konebone.orders.menu_item' }}
          name="orders"
          icon={ListAltIcon}
          list={OrdersList}
          show={OrdersShow}
          edit={OrderEdit}
        />
      </Admin>
    </>
  );
};

export default App;
