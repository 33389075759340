import React, { useState } from "react";
import { useTranslate} from "react-admin";
import {
  Typography,
  Box,
  TextField as TextInput,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  makeStyles,
} from "@material-ui/core";
import Validator from "../utils/Validator";

const PROJECT_OPTION_ADD = "ADD_NEW_PROJECT";
const PROJECT_OPTION_EDIT = "EDIT_PROJECT";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    width: '80%',
  },
}));

const ProjectDialog = ({
  projectOption,
  openDialog,
  setProjectDialogOpen,
  project,
  handleSaveData,
}) => {

  const [requiredFieldErrors, setRequiredFieldsErrors] = useState({});
  const [name, setName] = useState(project?.name || "");
  const [contactFullName, setContactFullName] = useState(project?.contactFullName || "");
  const [contactPhone, setContactPhone] = useState(project?.contactPhone || "");
  const [street, setStreet] = useState(project?.street || "");
  const [apt, setApt] = useState(project?.apt || "");
  const [city, setCity] = useState(project?.city || "");

  const [nameErrors, setNameErrors] = useState("");
  const [contactFullNameErrors, setContactFullNameErrors] = useState("");
  const [contactPhoneErrors, setContactPhoneErrors] = useState("");
  const [streetErrors, setStreetErrors] = useState("");
  const [cityErrors, setCityErrors] = useState("");
  
  const classes = useStyles();
  const translate = useTranslate();

  const requiredFields = ["name", "contactFullName", "contactPhone", "street", "city"];

  // const handleClick = (e) => {
  //   e.stopPropagation();
  //   setProjectDialogOpen(true)
  // };

  const handleClose = (e) => {
    e.stopPropagation();
    setProjectDialogOpen(false);
  };

  const handleSave = (e) => {
    e.stopPropagation();

    const data = {
      name: name?.trim(),
      contactFullName: contactFullName?.trim(),
      contactPhone: contactPhone?.trim(),
      street: street?.trim(),
      city: city?.trim(),
      apt: apt?.trim(),
    };
    let isError = false;

    if (Validator.validateDeliveryContactPhone(contactPhone)) {
      setContactPhoneErrors(Validator.validateDeliveryContactPhone(contactPhone));
      isError = true;
    }

    const newErrors = { ...requiredFieldErrors };

    requiredFields
      .forEach(field => {
        if (!data[field]) {
          newErrors[field] = true;
        }
      });

    const isHaveError = Object.values(newErrors).some((e) => e);

    if (isHaveError) {
      setRequiredFieldsErrors(newErrors);
      return;
    }

    if (isError) {
      return;
    }

    const newProject = {
      name,
      contactFullName,
      contactPhone,
      street,
      city,
      apt,
    }

    handleSaveData(projectOption, newProject);
    setProjectDialogOpen(false);
  };

  //-----------ON CHANGE----------------//
  //------------------------------------//

  const changeProjectName = (e) => {
    if (requiredFieldErrors.name && e.target.value) {
      setRequiredFieldsErrors({ ...requiredFieldErrors, name: false })
    }
    setNameErrors("");
    setName(e.target.value);
  }

  const changeContactFullName = (e) => {
    if (requiredFieldErrors.contactFullName && e.target.value) {
      setRequiredFieldsErrors({ ...requiredFieldErrors, contactFullName: false })
    }
    setContactFullNameErrors("");
    setContactFullName(e.target.value);
  }

  const changeContactPhone = (e) => {
    if (requiredFieldErrors.contactPhone && e.target.value) {
      setRequiredFieldsErrors({ ...requiredFieldErrors, contactPhone: false })
    }
    setContactPhoneErrors("");
    setContactPhone(e.target.value);
  }

  const changeStreet = (e) => {
    if (requiredFieldErrors.street && e.target.value) {
      setRequiredFieldsErrors({ ...requiredFieldErrors, street: false })
    }
    setStreetErrors("");
    setStreet(e.target.value);
  }

  const changeApt = (e) => {
    if (requiredFieldErrors.apt && e.target.value) {
      setRequiredFieldsErrors({ ...requiredFieldErrors, apt: false })
    }
    setApt(e.target.value);
  }

  const changeCity = (e) => {
    if (requiredFieldErrors.city && e.target.value) {
      setRequiredFieldsErrors({ ...requiredFieldErrors, city: false })
    }
    setCityErrors("");
    setCity(e.target.value);
  }
  //------------------------------------//

  const validateRequiredFields = (e) => {
    if (requiredFields.includes(e.target.id) && !e.target.value) {
      setRequiredFieldsErrors({ ...requiredFieldErrors, [e.target.id]: true })
    }
  }
  return (
    <>
      {openDialog && (
        <div className="project-dialog">
          <Dialog
            open={openDialog}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            classes={{ paper: classes.dialogPaper }}
          >
            <DialogTitle id="form-dialog-title" className="dialog-title">
              {projectOption === PROJECT_OPTION_ADD
                ? translate("konebone.orders.add_new_project")
                : translate("konebone.orders.edit_project")}
            </DialogTitle>
            <DialogContent >
              <Box display="flex" flexDirection="column" mt="1em">
                <Box>
                  <Typography variant="body2">{translate("konebone.orders.projects.project_name")}</Typography>
                  <TextInput
                    id="name"
                    label=""
                    placeholder={translate("konebone.orders.projects.project_name")}
                    value={name}
                    onChange={changeProjectName}
                    onBlur={validateRequiredFields}
                    error={requiredFieldErrors.name || !!nameErrors}
                    helperText={requiredFieldErrors.name ? translate("ra.validation.required") : translate(nameErrors, { max: "100" })}
                    fullWidth
                    variant="outlined"
                  />
                </Box>
                <Box display="flex" mt="1em">
                  <Box mr="1em" flex={1}>
                    <Typography variant="body2">{translate("konebone.orders.projects.contact_full_name")}</Typography>
                    <TextInput
                      id="contactFullName"
                      label=""
                      placeholder={translate("konebone.orders.projects.contact_full_name")}
                      value={contactFullName}
                      onChange={changeContactFullName}
                      onBlur={validateRequiredFields}
                      error={requiredFieldErrors.contactFullName || !!contactFullNameErrors}
                      helperText={requiredFieldErrors.contactFullName ? translate("ra.validation.required") : translate(contactFullNameErrors, { max: "200" })}
                      fullWidth
                      variant="outlined"
                    />
                  </Box>
                  <Box flex={1}>
                    <Typography variant="body2">{translate("konebone.orders.projects.contact_phone")}</Typography>
                    <TextInput
                      id="contactPhone"
                      placeholder={translate("konebone.orders.projects.contact_phone")}
                      value={contactPhone}
                      onChange={changeContactPhone}
                      onBlur={validateRequiredFields}
                      error={requiredFieldErrors.contactPhone || !!contactPhoneErrors}
                      helperText={requiredFieldErrors.contactPhone ? translate("ra.validation.required") : translate(contactPhoneErrors, { max: "100" })}
                      fullWidth
                      variant="outlined"
                    />
                  </Box>
                </Box>
                <Box mt="1em">
                  <Typography variant="body2">{translate("konebone.orders.projects.address")}</Typography>
                </Box>
                <Box display="flex">
                  <Box mr="1em" flex={1} >
                    <TextInput
                      id="street"
                      label=""
                      placeholder={translate("konebone.orders.projects.street")}
                      value={street}
                      onChange={changeStreet}
                      onBlur={validateRequiredFields}
                      error={requiredFieldErrors.street || !!streetErrors}
                      helperText={requiredFieldErrors.street ? translate("ra.validation.required") : translate(streetErrors, { max: "100" })}
                      fullWidth
                      variant="outlined"
                    />
                  </Box>
                  <Box flex={1}>
                    <TextInput
                      id="apt"
                      label=""
                      placeholder={translate("konebone.orders.projects.apt_unit")}
                      value={apt}
                      // onBlur={validateRequiredFields}
                      onChange={changeApt}
                      // error={requiredFieldErrors.apt || !!aptErrors}
                      // helperText={requiredFieldErrors.apt ? translate("ra.validation.required") : translate(aptErrors, { max: "100" })}
                      fullWidth
                      variant="outlined"
                    />
                  </Box>
                </Box>
                <Box >
                  <TextInput
                    id="city"
                    label=""
                    placeholder={translate("konebone.offer.city")}
                    value={city}
                    onChange={changeCity}
                    onBlur={validateRequiredFields}
                    error={requiredFieldErrors.city || !!cityErrors}
                    helperText={requiredFieldErrors.city ? translate("ra.validation.required") : translate(cityErrors, { max: "100" })}
                    fullWidth
                    variant="outlined"
                  />
                </Box>
              </Box>
            </DialogContent>
            <DialogActions className={classes.root}>
              <Button onClick={handleClose} variant="outlined">
                {translate("ra.action.cancel")}
              </Button>
              <Button onClick={e => handleSave(e)} variant="contained" color="secondary">
                {translate("konebone.offer.save")}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </>
  );
};

export default ProjectDialog;
