import dataProviderForAdmins from "./DataProviderForAdmins";
import dataProviderForCategories from "./DataProviderForCategories";
import dataProviderForProducts from "./DataProviderForProducts";
import dataProviderForManufacturer from "./DataProviderForManufacturer";
import dataProviderForManufacturingCountry from "./DataProviderForManufacturingCountry";
import dataProviderForSupplier from "./DataProviderForSupplier";
import dataProviderForMeasurement from "./DataProviderForMeasurement"
import dataProviderForPackages from "./DataProviderForPackages";
import dataProviderForPackageTypes from "./DataProviderForPackageTypes";
import dataProviderForCustomers from "./DataProviderForCustomers";
import dataProviderForPriceLines from "./DataProviderForPriceLines";
import dataProviderForPrices from "./DataProviderForPrices";
import dataProviderForManagers from "./DataProviderForManagers";
import dataProviderForProjects from "./DataProviderForProjects";
import dataProviderForSupplierCandidate from "./DataProviderForSupplierCandidate";
import dataProviderForOrders from "./DataProviderForOrders";
import dataProviderForOffers from "./DataProviderForOffers";
import dataProviderForCredit from "./DataProviderForCredit";

const providers = [
  { resource: 'admin', provider: dataProviderForAdmins },
  { resource: 'products', provider: dataProviderForProducts },
  { resource: 'categories', provider: dataProviderForCategories },
  { resource: 'manufacturer', provider: dataProviderForManufacturer },
  { resource: 'manufacturing_country', provider: dataProviderForManufacturingCountry },
  { resource: 'supplier', provider: dataProviderForSupplier },
  { resource: 'measurement', provider: dataProviderForMeasurement },
  { resource: 'package', provider: dataProviderForPackages },
  { resource: 'package_type', provider: dataProviderForPackageTypes },
  { resource: 'customer', provider: dataProviderForCustomers },
  { resource: 'credit', provider: dataProviderForCredit },
  { resource: 'price_lines', provider: dataProviderForPriceLines },
  { resource: 'prices', provider: dataProviderForPrices },
  { resource: 'managers', provider: dataProviderForManagers },
  { resource: 'projects', provider: dataProviderForProjects },
  { resource: 'supplier_candidate', provider: dataProviderForSupplierCandidate },
  { resource: 'orders', provider: dataProviderForOrders },
  { resource: 'offers', provider: dataProviderForOffers },
];

function findProvider(resource) {
  return providers.find(provider => provider.resource.includes(resource)).provider;
}

const combineProviders = {

  getOne: (resource, params) => {
    return findProvider(resource).getOne(resource, params);
  },

  getCredit: (resource, params) => {
    return findProvider(resource).getCredit(resource, params);
  },

  getHistory: (resource, params) => {
    return findProvider(resource).getHistory(resource, params);
  },

  delete: (resource, params) => {
    return findProvider(resource).delete(resource, params);
  },

  getList: (resource, params) => {
    return findProvider(resource).getList(resource, params);
  },
  
  create: (resource, params) => {
    return findProvider(resource).create(resource, params);
  },

  update: (resource, params) => {
    return findProvider(resource).update(resource, params);
  },

  GET_TREE_ROOT_NODES: (resource) => dataProviderForCategories.getRootNodes(resource),

  GET_TREE_CHILDREN_NODES: (resource, params) => dataProviderForCategories.getChildNodes(resource, params), 

  MOVE_NODE: (resource, params) =>  dataProviderForCategories.moveNode(resource, params),
};

export default combineProviders;
