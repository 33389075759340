import React, {
  Component,
  Children,
  cloneElement,
  isValidElement,
  memo,
} from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import classnames from "classnames";
import ListItem from "@material-ui/core/ListItem";
import { withStyles } from "@material-ui/core/styles";
import { withTranslate } from "react-admin";
import {
  crudGetTreeChildrenNodes as crudGetTreeChildrenNodesAction,
  crudMoveNode as crudMoveNodeAction,
  getIsExpanded,
  getIsLoading,
  getChildrenNodes,
  toggleNode as toggleNodeAction,
  getHash,
} from "../raTreeCore";
import { startUndoable as startUndoableAction } from "ra-core";

import { DragSource, DropTarget } from "react-dnd";

import TreeNodeList from "./TreeNodeList";
import TreeNodeIcon from "./TreeNodeIcon";
import getMousePosition from "./getMousePosition";
import getRootElement from "./getRootElement";
import WaitingService from "../services/WaitingService";
import TreeService from "../services/TreeService";

const DELTA = 80;

class TreeNodeView extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (TreeService.getAllNodes()) {
      this.fetchChildren();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !this.props.expanded &&
      (this.props.isOverCurrent ||
        (this.props.isOver && !!this.props.positionSource)) &&
      !this.props.isDragging
    ) {
      this.handleClick();
    }

    if (this.props.hash !== prevProps.hash) {
      this.fetchChildren();
    }
  }

  handleClick = () => {
    this.props.toggleNode({
      resource: this.props.resource,
      nodeId: this.props.record.id,
    });

    // If the node wasn't expanded, the previous line is actually requesting
    // it to expand, so we reload its children to be sure they are up to date
    if (!this.props.expanded) {
      this.fetchChildren();
    }
  };

  fetchChildren = () => {
    const {
      crudGetTreeChildrenNodes,
      parentSource,
      positionSource,
      record,
      resource,
      nodes
    } = this.props;

    // eslint-disable-next-line eqeqeq
    if (record && record.id != undefined && (!TreeService.getAllNodes() ||
      TreeService.getNodesByParentIdCount(record.id) > 0 || nodes?.length > 0)) {
      if (TreeService.getAllNodes()) {
        crudGetTreeChildrenNodes({
          resource,
          parentSource,
          positionSource,
          nodeId: record.id,
        });
      } else {
        WaitingService.pushToQueue({
          fn: crudGetTreeChildrenNodes,
          params: {
            resource,
            parentSource,
            positionSource,
            nodeId: record.id,
          },
        });
      }
    }
  };

  handleBlur = (e) => {
    console.log(e.target);
    e.target.classList.remove(this.props.classes.draggedBelow);
    e.target.classList.remove(this.props.classes.draggedAbove);
  }

  render() {
    const {
      actions,
      basePath,
      canDrag,
      canDrop,
      children,
      className,
      classes,
      connectDragSource,
      connectDropTarget,
      crudGetTreeChildrenNodes,
      crudMoveNode,
      expanded,
      hasCreate,
      hasEdit,
      hasList,
      hasShow,
      isDragging,
      isOver,
      isOverCurrent,
      loading,
      nodeChildren,
      nodes,
      parentSource,
      positionSource,
      record,
      resource,
      startUndoable,
      toggleNode,
      translate,
      undoable,
      ...props
    } = this.props;

    if (!record) {
      return null;
    }
    // The children value for this node may be false (indicating we fetched them but found none)
    // We don't want to show the loading indicator all the time, only on the first fetch
    const showLoading = loading && Array.isArray(nodes) && nodes.length === 0;

    return connectDropTarget(
      connectDragSource(
        <li
          className={classnames({
            [classes.draggedOver]:
              canDrop && (isOverCurrent || (isOver && !!positionSource)),
            // This is a hack to cancel styles added dynamically on hover by our react-dnd target,
            // which are not correctly removed once the dragged item leaves this node
            [classes.resetDraggedOver]: !isOverCurrent,
          })}
          data-position={record[positionSource]}
          {...props}
        >
          <ListItem
            className={classnames(classes.root, className)}
            component="div"
          >
            <div className={classes.container}>
              <TreeNodeIcon
                expanded={expanded}
                loading={showLoading}
                hasChildren={nodes && nodes.length > 0}
                onClick={this.handleClick}
              />
              <div className={classes.content}>
                {Children.map(children, (child) => {
                  return isValidElement(child)
                    ? cloneElement(child, {
                      basePath,
                      record,
                    })
                    : null;
                })}
                {actions && isValidElement(actions)
                  ? cloneElement(actions, {
                    basePath,
                    parentSource,
                    positionSource,
                    record,
                    resource,
                    nodes,
                    ...actions.props,
                  })
                  : null}
              </div>
            </div>
            {expanded ? (
              <TreeNodeList
                basePath={basePath}
                hasCreate={hasCreate}
                hasEdit={hasEdit}
                hasList={hasList}
                hasShow={hasShow}
                nodes={nodes}
                parentSource={parentSource}
                positionSource={positionSource}
                resource={resource}
              >
                {nodeChildren}
              </TreeNodeList>
            ) : null}
          </ListItem>
        </li>
      )
    );
  }
}

const styles = (theme) => ({
  root: {
    display: "inline-block",
    verticalAlign: "middle",
    paddingRight: 0,
  },
  draggedOver: {
    backgroundColor: theme.palette.action.hover,
  },
  resetDraggedOver: {
    "&.draggedAbove": {
      borderTopStyle: "none",
    },
    "&.draggedBelow": {
      borderBottomStyle: "none",
    },
  },
  draggedAbove: {
    borderTopWidth: 1,
    borderTopStyle: "solid",
    borderTopColor: theme.palette.action.active,
  },
  draggedBelow: {
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: theme.palette.action.active,
  },
  container: {
    alignItems: "center",
    display: "flex",
    verticalAlign: "middle",
  },
  content: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-start",
    flex: 1,
  },
});

const mapStateToProps = (state, { record, resource }) => {
  const hasRecord = record && record.id != undefined; // eslint-disable-line eqeqeq

  return {
    expanded: hasRecord ? getIsExpanded(state, resource, record.id) : false,
    loading: hasRecord ? getIsLoading(state, resource, record.id) : false,
    nodes: hasRecord ? getChildrenNodes(state, resource, record.id) : [],
    hash: getHash(state, resource, 'childHash'),
  };
};

// This object contains the react-dnd drop target specification
// See https://react-dnd.github.io/react-dnd/docs/api/drop-target
const nodeTarget = {
  canDrop(props, monitor) {
    const { record: draggedRecord } = monitor.getItem();
    const isJustOverThisOne = monitor.isOver({ shallow: true });
    const canDrop = (props.record.parent_id === draggedRecord.parent_id);
    const isNotDroppingOverItself = props.record.id !== draggedRecord.id;

    return isJustOverThisOne && canDrop && isNotDroppingOverItself;
  },

  // This function is call when a node is dragged over another one
  // It is called for every mouse moves and we use it when nodes are ordered
  // to show lines above or under depending on the mouse position
  hover(props, monitor, component) {
    // If nodes are not ordered we have nothing to do
    if (!props.positionSource) {
      return;
    }

    const isOverCurrent = monitor.isOver({ shallow: true });

    // If the draggged node is over a child of this node, we have nothing to do
    if (!isOverCurrent) {
      return;
    }

    const item = monitor.getItem();
    const canDrop = monitor.canDrop();
    const mousePosition = monitor.getClientOffset();
    const differenceY = monitor.getDifferenceFromInitialOffset().y;
    const { scrollHeight, clientHeight } = document.documentElement;
    const { pageYOffset } = window;

    let domNode = document.elementFromPoint(mousePosition.x, mousePosition.y);
    let droppedPosition = "none";

    if (!domNode) {
      return;
    }

    domNode = getRootElement(domNode);

    if (domNode.nodeName !== "LI") {
      return;
    }

    if (item && canDrop) {
      // Determine rectangle on screen

      const hoverBoundingRect = domNode.getBoundingClientRect();

      droppedPosition = getMousePosition(
        hoverBoundingRect,
        mousePosition,
        props.nodes && props.nodes.length > 0
      );
    }

    domNode.classList.remove(props.classes.draggedBelow);
    domNode.classList.remove(props.classes.draggedAbove);

    const { nextSibling, previousSibling } = domNode;

    const rootNode = getRootElement(domNode);

    rootNode.classList.remove(props.classes.draggedAbove);
    rootNode.classList.remove(props.classes.draggedBelow);

    nextSibling?.classList.remove(props.classes.draggedAbove);
    nextSibling?.classList.remove(props.classes.draggedBelow);

    previousSibling?.classList.remove(props.classes.previousSibling);
    previousSibling?.classList.remove(props.classes.previousSibling);

    if (pageYOffset > DELTA && mousePosition.y < DELTA && differenceY < 0) {
      window.scrollTo(0, pageYOffset - DELTA);
      return;
    }
    if (pageYOffset + clientHeight + DELTA < scrollHeight &&
      mousePosition.y > clientHeight - DELTA &&
      differenceY > 0) {
      window.scrollTo(0, pageYOffset + DELTA);
      return;
    }


    switch (droppedPosition) {
      case "above": {
        domNode.classList.remove(props.classes.draggedBelow);
        if (!domNode.classList.contains(props.classes.draggedAbove)) {
          domNode.classList.add(props.classes.draggedAbove);
        }
        break;
      }
      case "below": {
        domNode.classList.remove(props.classes.draggedAbove);
        if (!domNode.classList.contains(props.classes.draggedBelow)) {
          domNode.classList.add(props.classes.draggedBelow);
        }
        break;
      }
      default:
        domNode.classList.remove(props.classes.draggedAbove);
        domNode.classList.remove(props.classes.draggedBelow);
        break;
    }
  },

  // This function is called when a node is dropped over the current one
  drop(props, monitor, component) {
    if (monitor.didDrop()) {
      return;
    }

    const { record: draggedRecord } = monitor.getItem();

    if (!props.record) {
      return;
    }

    let droppedPosition = "below";

    // Determine mouse position
    const mousePosition = monitor.getClientOffset();

    const dropNode = document.elementFromPoint(
      mousePosition.x,
      mousePosition.y
    );

    if (dropNode) {
      const rootNode = getRootElement(dropNode);
      rootNode.classList.remove(props.classes.draggedAbove);
      rootNode.classList.remove(props.classes.draggedBelow);
      // Determine rectangle on screen
      const hoverBoundingRect = rootNode.getBoundingClientRect();

      droppedPosition = getMousePosition(
        hoverBoundingRect,
        mousePosition
      );
    }

    // // If the item was dropped over the component, its record will be the new parent of the item,
    // // otherwise the parent will be the record's parent
    const nodeParent = props.record[props.parentSource];

    let nodePosition = draggedRecord[props.positionSource];

    if (nodePosition < props.record[props.positionSource]) {
      nodePosition =
        droppedPosition === "above"
          ? props.record[props.positionSource] - 1
          : props.record[props.positionSource];
    } else if (nodePosition > props.record[props.positionSource]) {
      nodePosition =
        droppedPosition === "above"
          ? props.record[props.positionSource]
          : props.record[props.positionSource] + 1;
    }

    const actionPayload = {
      resource: props.resource,
      data: {
        ...draggedRecord,
        [props.parentSource]: nodeParent,
        [props.positionSource]: nodePosition,
      },
      parentSource: props.parentSource,
      positionSource: props.positionSource,
      previousData: draggedRecord,
      basePath: props.basePath,
      refresh: false,
      redirectTo: undefined,
    };

    try {
      props.crudMoveNode(actionPayload)
    } catch (error) {
      console.log(error)
    }
  },
};

const collectDropTarget = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  isOverCurrent: monitor.isOver({ shallow: true }),
  canDrop: monitor.canDrop(),
});

// This object contains the react-dnd drag source specification
// See https://react-dnd.github.io/react-dnd/docs/api/drag-source
const nodeSource = {
  canDrag(props) {
    return props.canDrag ? props.canDrag(props.record) : true;
  },

  isDragging(props, monitor) {
    return monitor.getItem().record.id === props.record.id;
  },

  beginDrag(props, monitor, component) {
    // Returns the node record as the item being dragged
    // It will be returned for every call to monitor.getItem()
    return { record: props.record, component };
  },
};

const collectDragSource = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
});

const TreeNode = compose(
  connect(mapStateToProps, {
    crudGetTreeChildrenNodes: crudGetTreeChildrenNodesAction,
    crudMoveNode: crudMoveNodeAction,
    startUndoable: startUndoableAction,
    toggleNode: toggleNodeAction,
  }),
  withStyles(styles),
  DropTarget("TREE_NODE", nodeTarget, collectDropTarget),
  DragSource("TREE_NODE", nodeSource, collectDragSource),
  withTranslate,
)(TreeNodeView);

TreeNode.defaultProps = {
  undoable: true,
};

export default memo(TreeNode);
