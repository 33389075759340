import { useNotify, useTranslate } from "ra-core";
import React, { useEffect, useState } from "react";
import {
  TextField,
} from "react-admin";
import classNames from "classnames";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableRow,
  TableCell,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import { deliveryColorStatus, deliveryStatus, STATUS, statusColorMap, vehicleWeightTypeValue } from "./enums";
import { ReactComponent as OrderIcon } from "../../svg/order.svg";
import { ReactComponent as InfoIcon } from "../../svg/info-ico.svg";
import AutorenewIcon from '@material-ui/icons/Autorenew';
import NoImage from '../../images/no_image.png';
import { ProductListInOffer, TotalBoxPrices } from "./offers/OfferShowDetails";
import dataProviderForPaid from "../../providers/DataProviderForPaid";
import { ReactComponent as InfoIconPack } from "../../svg/info.svg";
import { handleFailure } from "../../utils/handleFailure";

const useStyles = makeStyles((theme) => ({
  statusField: {
    minWidth: "89px",
    height: "26px",
    borderRadius: "3px",
    display: "table-cell",
    textAlign: "center",
    verticalAlign: "middle",
    textTransform: "capitalize",
  },
  subtitle: {
    textTransform: "uppercase",
    fontWeight: "600",
  },
  orderFilling: {
    width: "100%",
    height: "36px",
    background: "#E7E8EA",
    borderRadius: "5px",
    lineHeight: "36px",
    padding: "0 17px"
  },
  quantity: {
    display: "inline-block",
    lineHeight: "42px",
    textAlign: "center",
    height: "42px",
    width: "100px",
    background: "#E7E8EA",
    borderRadius: "2px"
  },
  delivery_mark: {
    marginLeft: "10px",
    padding: "0 5px",
    background: "#FBE3E7",
  },
  total: {
    display: "flex",
    justifyContent: "space-between",
    margin: "30px 0",
    height: "42px",
    background: "#E7E8EA",
    alignItems: "center",
    borderRadius: "2px",
    padding: "0 16px 0 20px",
    fontSize: "1em",
  },
  refreshButton: {
    height: "26px",
    width: "26px",
    background: "#F7F7F8",
    border: "1px solid #DDDBDA",
    borderRadius: "4px",
    marginLeft: "8px",
    "&:hover": {
      borderRadius: "4px",
    },
  },
  inputField: {
    border: "1px solid #28253D",
    borderRadius: "6px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "12px 12px 12px 15px",
    wordBreak: "break-all",
    gap: '0px 10px',
    background: "#FCE9EC"
  },
  icon: {
    "& > path": {
      fill: 'red'
    },
  },
  avatar: {
    marginTop: 0,
    marginRight: "20px",
    "& > div": {
      padding: "5px 10px",
      height: "80px",
      width: "80px",
      borderRadius: 0,
      border: "1px solid #E5E9EB"
    }
  },
  info: {
    display: "flex",
    alignItems: "center",
    background: "rgba(252, 233, 236, 0.5)",
    borderRadius: "3px",
    columnGap: "12px",
    padding: "14px",
  },
  customerRequestInfo: {
    border: "1px solid #D62C2C;",
    borderRadius: "3px",
    padding: "16px",
    height: "72px",
    marginTop: "19px",
  }
}));

const OrderInfo = ({ record, setPackingSlipButton, getPaidData }) => {
  const [data, setData] = useState('');
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();

  useEffect (()=> {
      if(record.status === STATUS.CANCELED || record.status === STATUS.AWAITING_DELIVERY || record.status === STATUS.DELIVERED || record.status === STATUS.PACKING_SLIP_VERIFIED) {
        dataProviderForPaid.getOne("/paid-order", {orderId: record.id})
          .then((response)=> {
            getPaidData(response.data);
            setData(response.data);
          })
          .catch((error) => {
         handleFailure(error, notify)
      });
      }
  }, [])

  useEffect (()=> {
    if (record.status === STATUS.DELIVERED || record.status === STATUS.PACKING_SLIP_VERIFIED || record.status === STATUS.AWAITING_DELIVERY) {
      setPackingSlipButton(false);
    }
  }, [])
  
  const checkIsPackingSlip = !!record?.offerPackingSlipDtos && record?.offerPackingSlipDtos.filter(
    (item) => item.fileName === null
  );

  const date = record.deliveryDate?.split("-");
  const deliveryDate = date ? `${date?.[2]}/${date?.[1]}/${date?.[0]}` : '';
  const deliveryTime = !record.deliveryTimeRange?.full ?
      `${record.deliveryTimeRange?.start?.slice(0, 5)}-${record.deliveryTimeRange?.end?.slice(0, 5)}` :
      'during the working day';

  return (
    <Box p="1em">
        <Box display="flex" width="100%" mb="2em">
          <Box flex={3} mr="1em">
            <Box>
              <Typography variant="h6">
                {translate("konebone.orders.order")} #{record.id} <OrderIcon />
              </Typography>
              <Typography variant="body2" gutterBottom>
                {translate("konebone.orders.placed_on")} {record.created}
              </Typography>
              <TextField style={{ background: statusColorMap[record.status] }}
                className={classes.statusField} source="statusLower" />
            </Box>
          </Box>
          <Box flex={2}>
            <Box>
              <Typography variant="subtitle2" gutterBottom className={classes.subtitle}>
                {translate("konebone.orders.project")}
              </Typography>
              <Typography variant="body2">{record?.project[0]?.apt ? record.fullProjectInfo : record.projectInfo}</Typography>
              <Typography variant="body2">{record.projectInfo !== '-' ? record.projectContact : ""}</Typography>
            </Box>
          </Box>
          <Box flex={2}>
            <Box>
              <Typography variant="subtitle2" gutterBottom className={classes.subtitle}>
                {translate("konebone.orders.additional_services")}
              </Typography>
              <Typography variant="body2">{record.serviceUnloading ? translate("konebone.orders.unloading") : ""}</Typography>
            </Box>
          </Box>
        </Box>
        <Box display="flex" width="100%" mb="1.5em">
          <Box flex={3} mr="1em">
            <Box>
              <Typography variant="subtitle2" gutterBottom className={classes.subtitle}>
                {translate("konebone.orders.time_constraints")}
              </Typography>
                <>
                  <Typography variant="body2">{translate("konebone.orders.delivery_time")}</Typography>
                  <Typography variant="body2">{deliveryDate}; {deliveryTime}</Typography>
                </>
            </Box>
          </Box>
          <Box flex={2}>
            <Box>
              <Typography variant="subtitle2" gutterBottom className={classes.subtitle}>
                {translate("konebone.orders.company_name")}
              </Typography>
              <Typography variant="body2">{record.customer?.name}</Typography>
              <Typography variant="body2">{record.customer?.firstName} {record.customer?.lastName}, {record.manager?.phone}</Typography>
            </Box>
          </Box>
          <Box flex={2}>
            <Box>
              <Typography variant="subtitle2" gutterBottom className={classes.subtitle}>
                {translate("konebone.orders.comments")}
              </Typography>
              <Typography variant="body2">{record.customerComment || "-"}</Typography>
            </Box>
          </Box>
        </Box>
       <Box mt="1em" style={{ marginTop: "34px" }} />
      {(record.status === STATUS.AWAITING_DELIVERY || record.status === STATUS.DELIVERED || record.status === STATUS.PACKING_SLIP_VERIFIED || record.status === STATUS.CANCELED) && record.paid &&
          <>
            <>
              <div style={{ width: "100%" }}>
                  {(data?.paidOfferInfo?.map((item, index) => {
                    const date = item.deliveryOffer.deliveryDate?.split("-");
                    const deliveryDate = date ? `${date?.[2]}/${date?.[1]}/${date?.[0]}` : '';
                    const deliveryTime = !item.deliveryOffer.deliveryTimeRange?.full ?
                        `${item.deliveryOffer.deliveryTimeRange?.start?.slice(0, 5)}-${item.deliveryOffer.deliveryTimeRange?.end?.slice(0, 5)}` :
                        'during the working day';

                    return (
                    <Box display="flex" width="100%" mb="2em" key={index+1}>
                      <Box flex={3} mr="1em">
                        <Box display="flex" width="100%">
                          <Typography variant="subtitle2" gutterBottom className={classes.subtitle}>
                            {translate("konebone.orders.delivery")} {index+1}
                          </Typography>
                          <Typography variant="subtitle2" className={classNames(classes.statusField, classes.delivery_mark)} style={{ background: deliveryColorStatus[data?.delivery?.deliveryMode]}}>
                            {translate(`konebone.offer.${item?.deliveryOffer?.deliveryMode.toLowerCase()}`)}
                           </Typography>
                        </Box>
                        <Typography variant="body2">{deliveryDate}; {deliveryTime}</Typography>
                        <Typography variant="body2">{item?.deliveryContact}</Typography>
                        {
                          item?.deliveryOffer?.deliveryMode === deliveryStatus.BY_KONEBONE ?
                            <Typography variant="body2">{vehicleWeightTypeValue[item?.deliveryVehicleWeight]} {translate("konebone.offer.vehicle")} <b>{data?.serviceUnloading ? translate("konebone.offer.with_crane") : ""}</b></Typography>
                            : <div></div>
                        }
                      </Box>
                      <Box flex={2}>
                        <Box>
                          <Typography variant="subtitle2" gutterBottom className={classes.subtitle}>
                            {translate("konebone.orders.supplier_info")}
                          </Typography>
                          <Typography variant="body2">{item.supplierInfo}</Typography>
                          <Typography variant="body2">{item.supplierContact}</Typography>
                        </Box>
                      </Box>
                      { index === 0 ?
                        <Box flex={2}>
                          <Box>
                            <Typography variant="subtitle2" gutterBottom className={classes.subtitle}>
                              {translate("konebone.orders.payment")}
                            </Typography>
                            {!data.isCreditPaymentUsed 
                              ? <Box>
                                  <Typography variant="body2">{translate('konebone.orders.card')}</Typography>
                                  <Typography variant="body2">₪ {data.total}</Typography>
                                </Box>
                              :
                              <Box> 
                                  <Typography variant="body2">{translate('konebone.orders.bnpl')}</Typography>
                                  <Typography variant="body2">{translate('konebone.orders.due_date')} - {data.dueDate}</Typography>
                                </Box>
                            }
                          </Box>
                        </Box>
                        : <Box flex={2}></Box>
                      }
                    </Box>
                    )
                  }
               ))}
                {record.customerRequest && (
                    <div className={classes.customerRequestInfo}>
                      <InfoIconPack />
                      <span style={{marginLeft: '15px', color: '#E02443'}}> New product request: </span>
                      <br/>
                      <span style={{marginLeft: '35px'}}> {record.customerRequest} </span>
                    </div>
                )}
              </div>
            </>
            <>
            {record.status === STATUS.CANCELED && record.paid && 
              (
              <Box width="100%" mb="2em" className={classes.inputField}>
                <InfoIcon className={classes.icon}/>
                <TextField label="" source="cancelReason" />
              </Box>
            )}
            {record.status === STATUS.DELIVERED && !!checkIsPackingSlip[0] && (
              <div className={classes.info}>
                <InfoIconPack />
                {translate("konebone.orders.packing_slips_message")}
              </div>
            )}
             {data?.paidOfferInfo?.map((item, index)=> {
                let x =  {
                  inStock: item.products?.filter(p => p?.inStock && !p.alternativeProduct),
                  outStock: item.products?.filter(p => !p?.inStock && !p?.alternativeProduct),
                  alternative: item.products?.filter(p => p?.alternativeProduct)
                   }
                return (
                  <>
                    <ProductListInOffer index={index} status={record?.status}  products={x?.inStock} typeStock= 'inStock' baseHeader={Boolean(data.productInStock?.length)} />
                    <ProductListInOffer index={index} status={record?.status}  products={x?.alternative} typeStock="alternative" baseHeader={Boolean(data.productAlternative?.length) && !Boolean(data.productInStock?.length)} />
                    <ProductListInOffer index={index} status={record?.status}  products={x?.outStock} typeStock="outStock" baseHeader={Boolean(data.productOutStock?.length) && !Boolean(data.productAlternative?.length) && !Boolean(data.productInStock?.length)} />
                  </>
                   )
                })
              }     
            <TotalBoxPrices data={record} record={data} status={record.status}/>
            </>
          </>
      }
      {record.status === STATUS.CANCELED && !record.paid && 
        (
        <Box width="100%" mb="2em" className={classes.inputField}>
          <InfoIcon className={classes.icon}/>
          <TextField label="" source="cancelReason" />
        </Box>
        )}
      {(record.status !== STATUS.AWAITING_DELIVERY && record.status !== STATUS.DELIVERED && record.status !== STATUS.PACKING_SLIP_VERIFIED && (record.status !== STATUS.CANCELED || !data.paid)) &&
          <>
          <Typography variant="subtitle2" className={classes.orderFilling} gutterBottom><b>{translate("konebone.orders.order_filling")}</b> ({record.orderProductResponseDtos?.length})</Typography>
            {record.customerRequest && (
                <div className={classes.customerRequestInfo}>
                  <InfoIconPack />
                  <span style={{marginLeft: '15px', color: '#E02443'}}> New product request: </span>
                  <br/>
                  <span style={{marginLeft: '35px'}}> {record.customerRequest} </span>
                </div>
            )}
            <Table>
              <TableBody>
                {record.orderProductResponseDtos?.map((row) => (
                  <TableRow key={row.catalogNumber}>
                    <TableCell align="left">
                      <ListItem alignItems="flex-start">
                        <ListItemAvatar className={classes.avatar}>
                          <Avatar alt="product picture" src={row.pictureFileName || NoImage} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <p className="code">
                              <span className="product_number">{row.catalogNumber || row.productId}</span>
                              {row.isReadyForAlternatives && <IconButton
                                variant="outlined"
                                className={classes.refreshButton}
                                size="small"
                              >
                                <AutorenewIcon />
                              </IconButton>}</p>
                          }
                          secondary={row.koneboneProductName}
                        />
                      </ListItem>
                    </TableCell>
                    <TableCell><span className={classes.quantity}>х {row.quantity}</span></TableCell>
                    <TableCell align="right">
                      <Typography variant="button" display="block"><b>₪{row.minKpPriceWithQuantity} - ₪{row.maxKpPriceWithQuantity}</b></Typography>
                      <Typography variant="overline" display="block">₪{row.minKbPrice} - ₪{row.maxKbPrice}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell style={{ border: "none" }}></TableCell>
                  <TableCell colSpan={2} style={{ border: "none", paddingRight: 0 }}>
                    {record.status !== STATUS.DELIVERED && record.status !== STATUS.PACKING_SLIP_VERIFIED ?
                      <div className={classes.total}>
                        <span><b>{translate("konebone.orders.estimated_total")}</b> {translate("konebone.offer.without_vat")}</span>
                        <b>{record.orderPrice}</b>
                      </div>
                      : 
                      <TotalBoxPrices data={record} record={data} status={record.status}/>
                    }
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </>
        }
    </Box>
  );
};

export default OrderInfo;
