import React, { useCallback, useEffect, useState } from "react";
import {
  SimpleForm,
} from "react-admin";
import {
  Step,
  Stepper,
  StepButton,
  makeStyles,
} from "@material-ui/core";
import ProductGeneralInfo from "./ProductsGeneralInfo";
import ProductsPriceLinesList from "./ProductsPriceLinesList";

const useStyles = makeStyles((theme) => ({
  stepper_root: {
    width: "100%",
  },
}));

const StepperForm = (props) => {
  const {
    activeStep: newActiveStep,
    steps,
    handleToStep,
    save,
    handleStep,
    isLastStep,
    resource,
    saving,
    basePath,
    initGeneralInfo,
    setNewProductId,
    ...rest
  } = props;
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [generalInfoFormClosed, setGeneralInfoFormClosed] = useState(false);
  const [generalInfo, setGeneralInfo] = useState(initGeneralInfo || {});

  const classes = useStyles();

  useEffect(() => {
    if (newActiveStep !== activeStep) {
      if (newActiveStep === 1 && activeStep === 0) {
        setGeneralInfoFormClosed(true);
        return;
      }
      if (newActiveStep === 0) {
        setGeneralInfoFormClosed(false);
        handleToStep(newActiveStep);
      }
      setActiveStep(newActiveStep);
    }
  }, [newActiveStep, save]);

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const allStepsCompleted = () => {
    return completedSteps() === 2;
  };

  const handleStepError = useCallback(() => {
    setGeneralInfoFormClosed(false);
    handleToStep(activeStep);
  }, []);

  const handleComplete = useCallback((data) => {
    if (data) {
      setGeneralInfo(data);
      if (setNewProductId) {
        setNewProductId(data.id);
      }
    }
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    handleToStep(newActiveStep);
    setActiveStep(newActiveStep);
  }, []);

  return (
    <>
      <Stepper
        className={classes.stepper_root}
        nonLinear
        activeStep={activeStep}
      >
        {steps.map((label, index) => (
          <Step key={label}>
            <StepButton
              onClick={handleStep(index)}
              completed={completed[index]}
            >
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      {activeStep === 0 && (
        <SimpleForm record={generalInfo} toolbar={null}>
          <ProductGeneralInfo
            handleComplete={handleComplete}
            handleStepError={handleStepError}
            closed={generalInfoFormClosed}
            record={generalInfo}
            completed={completed[0]}
            environment={"create"}
          />
        </SimpleForm>
      )}
      {activeStep === 1 && (
        <ProductsPriceLinesList
          initData={initGeneralInfo}
          record={{
            productPackageId: generalInfo.productPackageId,
          }}
        />
      )}
    </>
  );
};

export default StepperForm;