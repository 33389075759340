import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Button,
  makeStyles
} from "@material-ui/core";
import dataProviderForOffers from "../../../providers/DataProviderForOffers";
import { useNotify, useRefresh, useTranslate } from "ra-core";
import { handleFailure } from "../../../utils/handleFailure";

const useStyles = makeStyles((theme) => ({
  statusField: {
    minWidth: "89px",
    height: "26px",
    borderRadius: "3px",
    display: "table-cell",
    textAlign: "center",
    verticalAlign: "middle",
    textTransform: "capitalize",
  },
  small_btn: {
    width: "30px",
    minWidth: "30px",
    maxWidth: "30px",
    padding: "3px 0",
    border: "1px solid #DDDBDA",
    borderRadius: "5px"
  },
}));

export const OfferCancelDialog = ({ record, onClose, open, props  }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const classes = useStyles();
  const translate = useTranslate();

  const cancel = async (e) => {
    e.stopPropagation();
    try {
      await dataProviderForOffers.cancel("offers", {
        id: record.offerId,
      });
      onClose(true);
      refresh();
    } catch (error) {
      handleFailure(error, notify)
    }
  };

  const confirm =  async (e) => {
    e.stopPropagation();
    try {
      await dataProviderForOffers.delete("/offers", {
        offerId: props[0]?.split(' ')[1] || record.offerId,
        productId: props[0]?.split(' ')[0] || ''
      });
      onClose(true);
      refresh();
    } catch (error) {
      handleFailure(error, notify)
    }
  }

  return (
    <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="form-dialog-title"
  >
     {props?.length ?
      <>
      <DialogTitle id="form-dialog-title" className={classes.capitalize}>
        {translate("konebone.orders.offers.remove_offer_group")}
      </DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1" style={{ fontWeight: 600 }} gutterBottom>{translate(
          "konebone.orders.offers.sure_cancel_offer_group_start"
        )} "{props[2]}" </Typography>
        <Typography variant="subtitle2" paragraph>
          {translate("konebone.orders.offers.sure_cancel_offer_group_mid")}
           <span style={{ color: "red" }}> {props[3]} </span>
          {translate("konebone.orders.offers.sure_cancel_offer_group_end")}
        </Typography>
      </DialogContent>
      <DialogActions className={classes.root}>
        <Button onClick={onClose} variant="outlined">
          {translate("ra.action.cancel")}
        </Button>
        <Button onClick={confirm} variant="contained" color="secondary">
          {translate("konebone.orders.offers.confirm")}
        </Button>
      </DialogActions>
    </>
    :
    <>
    <DialogTitle id="form-dialog-title" className={classes.capitalize}>
      {translate("konebone.orders.offers.cancel_offer")}
    </DialogTitle>
    <DialogContent>
      <Typography variant="subtitle1" style={{ fontWeight: 600 }} gutterBottom>{translate(
        "konebone.orders.offers.sure_cancel_offer"
      )} <span style={{ color: "red" }}>{record?.project?.name || record?.supplier?.name}</span>?</Typography>
      <Typography variant="subtitle2" paragraph>{translate(
        "konebone.orders.offers.cancel_offer_message1"
      )}<br />{translate(
        "konebone.orders.offers.cancel_offer_message2"
      )}
      </Typography>
    </DialogContent>
    <DialogActions className={classes.root}>
      <Button onClick={onClose} variant="outlined">
        {translate("ra.action.cancel")}
      </Button>
      <Button onClick={cancel} variant="contained" color="secondary">
        {translate("konebone.orders.offers.confirm")}
      </Button>
    </DialogActions>
  </>
    }
  </Dialog>
  );
};