import React, { useRef, useState, useMemo } from 'react';
import {
  useTranslate,
  useNotify,
} from "react-admin";
import {
  KeyboardDatePicker,
  TimePicker,
} from '@material-ui/pickers';
import {
  Button,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  FormControl,
  InputLabel,
  Select,
  Box
} from "@material-ui/core";
import { ReactComponent as DateIcon } from "../../svg/date-icon.svg";
import dataProviderForOffers from '../../providers/DataProviderForOffers';
import { STATUS } from './enums';
import { handleFailure } from '../../utils/handleFailure';

const useStyles = makeStyles((theme) => ({
  capitalize: {
    textTransform: "capitalize"
  },
  root: {
    "& * > button": {
      textTransform: "capitalize",
    }
  },
  delivery_label_field: {
    margin: '29px 0 9px',
    color: '#0C0C0C',
    "&::after": {
      content: '"\\002A"',
    }, 
  },
  dateValidate: {
    '& fieldset' : {
      borderColor: 'red',
    },
    '& p' : {
      color: 'red',
    }
  }
}));

const requiredFields = ["date", "time"];

const SendToCustomersDialog = ({ groupedOfferId, suppliers, closeSendOffersDialog, id, open }) => {
  const [error, setError] = useState(false);
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const [supplier, setSupplier] = useState("");
  const bestOffer = useRef('');
  const notify = useNotify();
  const classes = useStyles();
  const translate = useTranslate();

  const suppliersValues = Object.values(suppliers);

  const completedSuppliers = useMemo(() => {
    return Object.entries(suppliers).filter(([key, { status }]) => status === STATUS.COMPLETED);
  }, [suppliers]);


  const suppliersChoices = useMemo(() => {
    const isGroup = completedSuppliers.filter(([key, {isPartOfGroup}]) => isPartOfGroup)
    const resultIsGroup = isGroup.map(([key, {name }], index) => { 
        if(index === isGroup.length-1) return name
        else return name + ", "
          });
    const isGroupIds = isGroup.map(item => item[1].id)
    
    const isNotGroup = completedSuppliers.filter(([key, {isPartOfGroup}]) => !isPartOfGroup)
    const isNotGroupResult = isNotGroup.map(([key, { name, id }]) => { 
      return <option value={id} key={key} >{name}</option>
    });

    bestOffer.current = groupedOfferId.map((item) => item.offerId)
    if (isGroupIds.length > 0) {
      setSupplier(isGroupIds);
    }else {
      setSupplier(isNotGroup[0][1].id);
    }
    return <>
            {isGroup.length !== 0 ? <option selected value={isGroupIds}>{resultIsGroup}</option> : ''}
            {isNotGroupResult}
          </>;
  }, [completedSuppliers]);  

    const handleClose = (e) => {
    e.stopPropagation();
    closeSendOffersDialog();
  };

  const validate = (e) => {
    if (requiredFields.includes(e.target.id) && !e.target.value.length) {
      setError({ ...error, [e.target.id]: true });
    }
  };


  const send = async () => {
    const getYear = date?.getFullYear();
    const month = `0${date?.getMonth()+1}`.slice(-2);
    const day = date?.getDate() < 10 ? `0${date?.getDate()}` : date?.getDate();
    const minutes = time?.getMinutes() < 10 ? `0${time?.getMinutes()}` : time?.getMinutes();
    const hour = time?.getHours() < 10 ? `0${time?.getHours()}` : time?.getHours();
    const fullDate = date ? `${getYear}-${month}-${day}`.toString() : '';
    const fullTime = time ? `${hour}:${minutes}:00` : '';

    
    if(!date || !time) {
      setError({ date: !date, time: !time})
      return
    }
    
    const offers = Object.entries(suppliers).filter(([key, { status }]) => status !== STATUS.DEFAULT);
    const defaultSuppliers = Object.entries(suppliers).filter(([key, { status }]) => status === STATUS.DEFAULT);

    try {
      await dataProviderForOffers.sendToCustomer("offers", {
        bestOfferIds: Array.isArray(supplier) ? supplier : [supplier],
        date: fullDate,
        offers: offers.map(([ id ]) => +id),
        orderId: +id,
        suppliers: defaultSuppliers.map(([ id ]) => +(id.split('_')[0])),
        time: fullTime,
      });
      
      closeSendOffersDialog();
    } catch (error) {
     handleFailure(error, notify)
    }
  };


  const changeSuppliers = (e) => {
    bestOffer.current = groupedOfferId.map((item) => item.offerId)
    if(Array.isArray(e.target.value)){
      setSupplier(+e.target.value)
    } else {
      setSupplier([+e.target.value]);
    }
  }

  const changeTime = (time) => {
    if (time) {
      setError(false);
    }
    setTime(time);
  }

  const changeDate = (date) => {
    if (date) {
      setError(false);
     }
    setDate( date );
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" className={classes.capitalize}>
        {translate("konebone.orders.offers.send_to_customer")}
      </DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1" gutterBottom>{translate(
          "konebone.orders.offers.send_offer_customer_message_start"
        )} <span style={{ color: "red" }}>{suppliersValues[0].name}</span>
          {suppliersValues.length > 1 && suppliersValues
            .slice(1).map((el, i) => {
              const lastEl = i === suppliersValues.length - 2;
              return <span style={{ color: "red" }}>{lastEl ? <span style={{ color: "black" }}> and </ span> : <span style={{ color: "black" }}>, </ span>}{el.name}</span>
            })}
          <span> {translate(
            "konebone.orders.offers.send_offer_customer_message_end"
          )}</span>
        </Typography>
        <FormControl
          key="choose_supplier"
          variant="outlined"
          fullWidth
          // error={error}
          // required
        >
          <InputLabel htmlFor="suppliers-label">
            {translate(
              "konebone.orders.offers.choose_supplier"
            )}
          </InputLabel>
          <Select
            native
            labelWidth={195}
            name="choose_supplier"
            id="choose_supplier"
            labelId="suppliers-label"
            value={supplier}
            onChange={changeSuppliers}
          >
           {suppliersChoices}
          </Select>
        </FormControl>
        <Typography variant="body2" className={classes.delivery_label_field}>{translate("konebone.offer.date_time")}</Typography>
          <Box display="flex">
            <Box flex={1} mr="1em">
              <KeyboardDatePicker
                id="date"
                clearable
                className={`${error?.date && classes.dateValidate}`}
                format={"MMM dd, yyyy"}
                inputVariant="outlined"
                value={!date ? null : date}
                placeholder={translate("konebone.offer.pick_a_date")}
                onChange={date => changeDate(date)}
                style={{ width: "100%" }}
                autoOk="false"
                keyboardIcon={<DateIcon />}
                error={error?.date}
                onBlur={validate}
                helperText={error?.date && translate("ra.validation.required")}
                disablePast={true}
                InputProps={{ readOnly: true }}
                margin="none"              
              />
            </Box>
            <Box flex={1}>
              <TimePicker
                id="time"
                placeholder={translate("konebone.offer.pick_a_time")}
                className={`${error?.time && classes.dateValidate}`}
                clearable
                ampm={false}
                style={{ width: "100%" }}
                inputVariant="outlined"
                value={!time ? null : time}
                onChange={time => changeTime(time)}
                // keyboardIcon={<TimeIcon />}
                error={error?.time}
                onBlur={validate}
                helperText={error?.time && translate("ra.validation.required")}
                InputProps={{ readOnly: true }}
                margin="none"
              />
            </Box>
          </Box>
      </DialogContent>
      <DialogActions className={classes.root}>
        <Button onClick={handleClose} variant="outlined">
          {translate("ra.action.cancel")}
        </Button>
        <Button onClick={send} variant="contained" color="secondary">
          {translate("konebone.orders.offers.confirm_and_send")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SendToCustomersDialog
