import { clientType, deviceId } from "./ConnectionParams";
import axios from "axios";

let wasLogout = false;

export function cleanSession() {
  localStorage.removeItem("login");
  localStorage.removeItem("role");
  localStorage.removeItem("auth");
}

export function setUserInfo(user) {
  const { id, fullName, login, roles: role } = user;

  localStorage.setItem("login", login);
  localStorage.setItem("role", role);
  localStorage.setItem(
    "auth",
    JSON.stringify({ id: id, fullName: fullName })
  );
}

export default {
  // called when the user attempts to log in
  login: ({ login, password }) => {
    return axios
      .post(
        process.env.REACT_APP_API_URL + "/login",
        { clientType, deviceId, devicePushToken: null, login, password },
        {
          withCredentials: true,
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        wasLogout = false;
        setUserInfo(response.data.data.user, false);
        
        return Promise.resolve();
      })
      .catch((error) => {
        throw new Error("Network error");
      });
  },
  // called when the user clicks on the logout button
  logout: () => {
    if (wasLogout) {
      return;
    }
    wasLogout = true;
    return axios
      .post(
        process.env.REACT_APP_API_URL + "/logout",
        { deviceId, clientType },
        {
          withCredentials: true,
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          wasLogout = false;
          throw new Error(response.statusText);
        }
      })
      .then(() => {
        cleanSession();
        wasLogout = false;
      })
      .catch((error) => {
        wasLogout = false;
        if (error.status === 401 || error.status === 403) {
          cleanSession();
          window.location.href = "/login";
        }
      });
  },
  // called when the API returns an error
  checkError: (error) => {
    if (
      error &&
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      cleanSession();
      return Promise.reject({ redirectTo: "/login" });
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return localStorage.getItem("login") && localStorage.getItem("role")
      ? Promise.resolve()
      : Promise.reject({ redirectTo: "/login" });
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    const role = localStorage.getItem("role");
    return role ? Promise.resolve(role) : Promise.reject();
  },

  getIdentity: () => {
    try {
      const { id, fullName } = JSON.parse(localStorage.getItem("auth"));
      return Promise.resolve({ id, fullName });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  resetPassword: (data) => {
    return axios
    .post(
      process.env.REACT_APP_API_URL + "/login/reset-password", data,
      {
        withCredentials: true,
        headers: { "Content-Type": "application/json" },
      }
    )
    .then((response) => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }   
      return Promise.resolve(response);
    })
    .catch((error) => {
      throw new Error("Network error");
    });
  },

  newPassword: (data) => {
    return axios
    .post(
      process.env.REACT_APP_API_URL + "/login/save-new-password", data,
      {
        withCredentials: true,
        headers: { "Content-Type": "application/json" },
      }
    )
    .then((response) => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }   
      return Promise.resolve(response);
    })
    .catch((error) => {
      throw new Error("Network error");
    });
  },
};
