import Wrapper from "../utils/Wrapper";
import axios from 'axios';
import { stringify } from "query-string";

const dataProviderForSupplierCandidate = {
  getOne: async (resource, params) => {
    const url = process.env.REACT_APP_API_URL + "/supplier/candidate/" + params.id;
    const response = await axios.get(url,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      });
    let resultData = Wrapper.wrapSupplier(response.data.data);
    return ({
      data: resultData,
    });
  },

  update: async (resource, params) => {
    let data = params.data.data;
    let id = params.id;

    let response = await axios.put(process.env.REACT_APP_API_URL + "/supplier/candidate/" + id, data,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      }
    );

    if (response && response.data) {
      let dataRes = response.data;
      dataRes.data.id = dataRes.data.customerId;
      params.data.onSuccess();
      return ({
        data: dataRes.data
      });
    } else {
      params.data.onSuccess();
      return ({
        data: []
      });
    }
  },
  approve: async (resource, { id, supplierId }) => {
    let response = await axios.put(process.env.REACT_APP_API_URL + `/supplier/candidate/${id}/approve${supplierId ? `/${supplierId}` : ''}`, {},
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      }
    );

    if (!response || !response.data) {
      return { data: false };
    }

    return { data: true }
  },
  decline: async (resource, { id, reason }) => {
    let response = await axios.put(process.env.REACT_APP_API_URL + `/supplier/candidate/${id}/decline?reason=${reason}`, {},
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      }
    );

    if (!response || !response.data) {
      return { data: false };
    }

    return { data: true }
  },
  delete: async (resource, params) => {
    const url = process.env.REACT_APP_API_URL + "/supplier/candidate/" + params.id;
    const response = await axios.delete(url,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      });
    if (response.status === 200) {
      return { data: { id: params.id } }
    }
  },
};
export default dataProviderForSupplierCandidate;
