import Wrapper from "../utils/Wrapper";
import axios from 'axios';
import { stringify } from "query-string";

const dataProviderForManufacturingCountry = {

  delete: async (resource, params) => {
    const url = process.env.REACT_APP_API_URL + "/manufacturing_country/" + params.id;
    const response = await axios.delete(url,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      });
    if (response.status === 200) {
      return { data: { id: params.id } }
    }
  },

  getList: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      'field': field === 'id' ? 'manufacturingCountryId' : field,
      'order': order,
      'page': page,
      'perPage': perPage,
    };
    const url = process.env.REACT_APP_API_URL + '/manufacturing_country?' + stringify(query);
    const response = await axios.get(url,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      });

    if (!response.data || !response.data.data) {
      return ({
        data: [],
        total: 0
      });
    }

    let resultData = response.data.data.manufacturingCountries.map(manufacturer => Wrapper.wrapManufacturingCountries(manufacturer));
    const responseTotalCount = response.data.data.totalElements;

    return ({
      data: resultData,
      total: responseTotalCount
    });
  },

  create: async (resource, params) => {
    let data = params.data;
    data.name = data.name.trim();
    let response = await axios.post(process.env.REACT_APP_API_URL + "/manufacturing_country", data,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      }
    );

    if (response && response.data) {
      let dataRes = response.data;
      dataRes.data.id = dataRes.data.manufacturingCountryId;
      return ({
        data: dataRes.data
      });
    } else {
      return ({
        data: []
      });
    }
  },  

  getOne: async (resource, params) => {
    const url = process.env.REACT_APP_API_URL + "/manufacturing_country/" + params.id;
    const response = await axios.get(url,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      });
    let resultData = Wrapper.wrapManufacturingCountries(response.data.data);
    return ({
      data: resultData,
    });
  },  

  update: async (resource, params) => {
    let data = params.data;
    data.name = data.name.trim();
    let response = await axios.put(process.env.REACT_APP_API_URL + "/manufacturing_country/" + params.data.manufacturingCountryId, data,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      }
    );

    if (response && response.data) {
      let dataRes = response.data;
      dataRes.data.id = dataRes.data.manufacturingCountryId;
      return ({
        data: dataRes.data
      });
    } else {
      return ({
        data: []
      });
    }
  },  

};

export default dataProviderForManufacturingCountry;
