import { useState } from 'react';
import {
  useTranslate,
  useNotify,
  TextInput,
} from "react-admin";
import {
  Button,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem,
} from "@material-ui/core";
import dataProviderForCredit from '../../providers/DataProviderForCredit';
import { handleFailure } from '../../utils/handleFailure';
import Validator from '../../utils/Validator';

const useStyles = makeStyles((theme) => ({
  capitalize: {
    textTransform: "capitalize"
  },
  root: {
    "& * > button": {
      textTransform: "capitalize",
    }
  },
  validate: {
    '& fieldset' : {
      borderColor: 'red',
    },
    '& p' : {
      color: 'red',
    }
  }
}));

const requiredFields = ["amount", 'comment', 'orderId'];

const CreditRestore = ({record, data, closeSendDialog, open}) => {
  const [error, setError] = useState({});
  const [amount, setAmount] = useState(null);
  const [comment, setComment] = useState(null);
  const [orderId, setOrderId] = useState(null)
  const notify = useNotify();
  const classes = useStyles();
  const translate = useTranslate();

  let isError = false;

  const options = data?.payments?.content?.filter((ele, ind)=> 
    ind === data?.payments?.content?.findIndex( elem => elem.order === ele.order))

  const handleClose = (e) => {
    e.stopPropagation();
    closeSendDialog();
  };

  const handleRestoreCredit = (e) => {
    let sum = +e.target.value.replace(',', '')

    if(sum || !(sum > 0 && sum == '.')) {
      setError({ ...error, amountError: false, amount: false});
     }
     if(sum > +data.used || (sum <= 0 && sum == '.')) {
      setError({...error, amountError: true, amount: false})
    }
    setAmount(sum)
  }

  const handleComment = (e) => {
    if(error.comment && e.target.value){
      setError({ ...error.comment, comment: false });
     }
     setComment(e.target.value)
  }

  const changeOrder = (e) => {
    if (error.orderId && e.target.value) {
      setError({ ...error.orderId, orderId: false });
    }
    setOrderId(e.target.value);
  }


  const restore = async () => { 
    const data = {
      amount: +amount,
      comment: comment,
      orderId: orderId,
    }

     const newErrors = { ...error };

     requiredFields.forEach((field) => {
       if (!data[field]) {
         newErrors[field] = true;
       }
     });
 
     const isHaveError = Object.values(newErrors).some((e) => e);

     if (isHaveError) {
      setError(newErrors);
      return;
    }

     if (isError) {
      return;
    }

    try {
      await dataProviderForCredit.restoreCredit("customer", 
      {
        customerId: record.id,
        creditLineRestoreRequestDto: {
          amount: +amount,
          comment: comment,
          orderId: orderId
          }
      });
      closeSendDialog();
    } catch (error) {
      handleFailure(error, notify)
    };
  }
 
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" className={classes.capitalize}>
        {translate("konebone.customer.credit_limit_restore")}
      </DialogTitle>
      <DialogContent>
        <TextInput
          source="amount"
          label={translate('konebone.customer.amount')}
          fullWidth
          variant="outlined"
          validate={Validator.validateNumber}
          value={amount}
          onBlur={error?.amount}
          onChange={handleRestoreCredit}
          error={requiredFields.amount}
          helperText={error?.amount 
            && translate("ra.validation.required") 
            || error?.amountError
            && ` ${translate("ra.validation.amount_error_text")} ${data.used}`}
          className={`${
            (error?.amount || error?.amountError) && 
            classes.validate || requiredFields.amount 
          }`}
        />
        <FormControl
          key="orderId"
          source="orderId"
          variant="outlined"
          fullWidth
          className={`${error?.orderId && classes.validate}`}
          required
        >
        <InputLabel id="orderId">
          {translate(
            "konebone.customer.order_id"
          )}
        </InputLabel>
          <Select
            labelWidth={80}
            name="orderId"
            id="orderId"
            labelId="orderId"
            value={orderId}
            onChange={changeOrder}
          >
          {options?.map((option, index) => (
            <MenuItem
              value={option.order}
              key={option.order}
            >
              {option.order}
            </MenuItem>
          ))}
          </Select>
        {error?.orderId && (
            <FormHelperText>{translate("ra.validation.required")}</FormHelperText>
          )}
        </FormControl>
        <TextInput 
          fullWidth
          source="comment"
          validate={Validator.validateCustomerComment}
          onBlur={error?.comment}
          label={translate("konebone.customer.comment")}
          variant="outlined"
          helperText={error?.comment && translate("ra.validation.required")}
          value={comment}
          error={requiredFields.amount}
          className={`${error?.comment && classes.validate}`}
          onChange={(event) => handleComment(event)}
          >
        </TextInput>
      </DialogContent>
      <DialogActions className={classes.root}>
        <Button onClick={handleClose} variant="outlined">
          {translate("ra.action.cancel")}
        </Button>
        <Button onClick={restore} variant="contained" color="secondary">
          {translate("konebone.customer.restore")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreditRestore
