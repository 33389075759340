import { useState } from 'react';
import {
  useTranslate,
  useNotify,
} from "react-admin";
import {
  Button,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Typography,
} from "@material-ui/core";
import dataProviderForOffers from '../../providers/DataProviderForOffers';
import { handleFailure } from '../../utils/handleFailure';

const useStyles = makeStyles((theme) => ({
  capitalize: {
    textTransform: "capitalize"
  },
  root: {
    "& * > button": {
      textTransform: "capitalize",
    }
  },
}));


const SendToSuppliersDialog = ({ suppliers, closeSendOffersDialog, id, open }) => {
  const [competitors, setCompetitors] = useState(0);
  const notify = useNotify();
  const classes = useStyles();
  const translate = useTranslate();
  const suppliersNames = Object.values(suppliers);

  const handleClose = (e) => {
    e.stopPropagation();
    closeSendOffersDialog();
  };

  const send = async () => {
    try {
      await dataProviderForOffers.send("offers", {
        orderId: +id,
        competitorsQuantity: competitors,
        supplierIds: Object.keys(suppliers).map(id => +(id.split('_')[0])),
      });
      closeSendOffersDialog();
    } catch (error) {
     handleFailure(error, notify)
    }
  };

  const changeCompetitors = (e) => {
    if (e.target.value < 0) {
      return;
    }
    setCompetitors(e.target.value);
  }


  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" className={classes.capitalize}>
        {translate("konebone.orders.offers.send_to_suppliers")}
      </DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1" gutterBottom>{translate(
          "konebone.orders.offers.send_offer_message"
        )} <span style={{ color: "red" }}>{suppliersNames[0].name}</span>
          {suppliersNames.length > 1 && suppliersNames
            .slice(1).map((el, i) => {
              const lastEl = i === suppliersNames.length - 2;
              return <span style={{ color: "red" }}>{lastEl ? <span style={{ color: "black" }}> and </ span> : <span style={{ color: "black" }}>, </ span>}{el.name}</span>
            })}
          <span> ?</span>
        </Typography>
        <TextField
          type="number"
          id="name"
          label={translate(
            "konebone.orders.offers.number_of_competitors"
          )}
          fullWidth
          variant="outlined"
          value={competitors}
          onChange={changeCompetitors}
        />
      </DialogContent>
      <DialogActions className={classes.root}>
        <Button onClick={handleClose} variant="outlined">
          {translate("ra.action.cancel")}
        </Button>
        <Button onClick={send} variant="contained" color="secondary">
          {translate("konebone.orders.offers.confirm_and_send")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SendToSuppliersDialog
