import { useTranslate } from "ra-core";
import React from "react";
import {
  EditButton,
} from "react-admin";
import {
  Typography,
  Box,
  Toolbar,
  TextField,
} from "@material-ui/core";

const SupplierCompanyShow = ({ basePath, resource, record }) => {
  const translate = useTranslate();

  return (
    <form>
      <Toolbar>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Typography variant="h6" gutterBottom>
            {translate("konebone.supplier.company").toUpperCase()}
          </Typography>
          <Box>
            <EditButton
              variant="outlined"
              resource={resource}
              basePath={basePath}
              record={record}
              label={translate("ra.action.edit")}
            />
          </Box>
        </Box>
      </Toolbar>
      <Box p="1em">
        <Box display="flex" width="100%">
          <Box flex={1} mr="1em">
            <TextField
              value={record.name || ""}
              label={translate(
                "konebone.supplier.company_name"
              )}
              fullWidth
              variant="outlined"
              margin="normal"
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              value={record.activity || ""}
              label={translate(
                "konebone.supplier.field_of_activity"
              )}
              fullWidth
              variant="outlined"
              margin="normal"
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              value={record.companyPhone || ""}
              label={translate(
                "konebone.supplier.company_phone_number"
              )}
              fullWidth
              variant="outlined"
              margin="normal"
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              value={record.city || ""}
              label={translate(
                "konebone.supplier.city"
              )}
              fullWidth
              variant="outlined"
              margin="normal"
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
          <Box flex={1}>
            <TextField
              value={record.taxId || ""}
              label={translate(
                "konebone.supplier.tax_id"
              )}
              fullWidth
              variant="outlined"
              margin="normal"
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              value={getDateByFoundationDate(record.foundation) || ""}
              label={translate(
                "konebone.supplier.company_foundation"
              )}
              fullWidth
              variant="outlined"
              margin="normal"
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              value={record.companyEmail || ""}
              label={translate(
                "konebone.supplier.company_email"
              )}
              fullWidth
              variant="outlined"
              margin="normal"
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Box display="flex">
              <Box flex={1} mr="1em">
                <TextField
                  value={record.street || ""}
                  label={translate(
                    "konebone.supplier.street"
                  )}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
              <Box flex={1}>
                <TextField
                  value={record.unit || ""}
                  label={translate(
                    "konebone.supplier.unit"
                  )}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <TextField
          value={record.description || ""}
          label={translate(
            "konebone.supplier.company_description"
          )}
          fullWidth
          multiline
          rows="4"
          variant="outlined"
          margin="normal"
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        {record.status === "BLOCKED" && (<TextField
          value={record.reasonBlock || ""}
          label={translate(
            "konebone.customer.reason_for_block"
          )}
          fullWidth
          multiline
          rows="4"
          variant="outlined"
          margin="normal"
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />)}
        {record.status === "DECLINED" && (<TextField
          value={record.reasonDecline || ""}
          label={translate(
            "konebone.customer.reason_for_decline"
          )}
          fullWidth
          multiline
          rows="4"
          variant="outlined"
          margin="normal"
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />)}
      </Box>
    </form>);
};

function getDateByFoundationDate(date) {

  return new Date(date).toLocaleString('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  }).replaceAll('/', '.');
}

export default SupplierCompanyShow;
