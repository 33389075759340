import * as React from "react";
import {
  List,
  SearchInput,
  TextField,
  Filter,
  SelectInput,
  Datagrid,
  DatagridBody
} from "react-admin";
import {
  InputAdornment,
  makeStyles
} from "@material-ui/core";
import { useAuthState, useTranslate } from "ra-core";
import { ReactComponent as UrgentIcon } from "../../svg/urgent.svg";
import { ReactComponent as FilterIcon } from "../../svg/filter.svg";
import { STATUS, statusColorMap } from "./enums";

const circleColorMap = {
  green: "#2ABA7A",
  red: "#E02443",
  grey: "#E9EAEC",
};

const useStyles = makeStyles((theme) => ({
  table: {
    width: "calc(100% - 32px)",
    minHeight: "0.01%",
    overflowX: "auto",
    margin: "16px auto 0",
    padding: "0 0 6px",
    position: "relative",
    "& thead tr th": {
      textTransform: "capitalize",
      whiteSpace: "nowrap",
      padding: "6px 10px",
    },
    "& thead tr th:first-child, & tbody tr td:first-child": {
      padding: 0,
    },
  },
  filter: {
    marginTop: "15px",

    "& > div": {
      width: "100%",
    },
  },
  textField: {
    marginBottom: 0,
    width: "100%",
    "& > div": {
      maxHeight: "40px",
      width: "100%",
    },
    "& > label": {
      fontSize: "14px",
    },
    "& input": {
      fontSize: "14px",
      textTransform: "capitalize",
    },
  },
  sortField: {
    '& .MuiSelect-select:focus': {
      border: "none",
      backgroundColor: "transparent",
      boxShadow: "none",
    },
    "& .MuiInput-underline:after, .MuiInput-underline:before, .MuiInput-underline:hover:not(.Mui-disabled):before": {
      border: "none",
    },
    "& div div": {
      paddingBottom: "2px",
    },
  },
  capitalize: {
    textTransform: "capitalize",
  },
  statusField: {
    minWidth: "89px",
    height: "26px",
    borderRadius: "3px",
    display: "table-cell",
    textAlign: "center",
    verticalAlign: "middle",
    textTransform: "capitalize",
  },
  circleField: {
    display: "inline-block",
    width: "10px",
    height: "10px",
    borderRadius: "50%"
  }
}));


const OrdersFilter = (props) => {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <Filter {...props} className={classes.filter}>
      {/* <ReferenceInput label="Post" source="post_id" reference="posts">
        <SelectInput optionText="title" />
      </ReferenceInput> */}
      <SelectInput
        source="status"
        label=""
        alwaysOn
        variant="standard"
        color="secondary"
        translateChoice
        allowEmpty={false}
        initialValue="all"
        choices={[
          { id: "all", name: "konebone.orders.all_orders" },
          { id: "new", name: "konebone.orders.new" },
          { id: "in_progress", name: "konebone.orders.in_progress" },
          { id: "in_review", name: "konebone.orders.in_review" },
          { id: "awaiting_delivery", name: "konebone.orders.awaiting_delivery" },
          { id: "delivered", name: "konebone.orders.delivered" },
          { id: "packing_slip_verified", name: "konebone.orders.packing_slip_verified" },
          { id: "canceled", name: "konebone.orders.canceled" },
          { id: "declined", name: "konebone.orders.declined" },
        ]}
        className={classes.sortField}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FilterIcon />
            </InputAdornment>
          ),
        }}
      />
      <SearchInput
        placeholder={translate("konebone.orders.search")}
        source="q"
        className={classes.textField}
        color="secondary"
        variant="outlined"
        alwaysOn
      />
    </Filter>
  );
};

const OrderStatusField = (props) => {
  const classes = useStyles();
  const color = statusColorMap[props.record?.status];
  
  return (
    <TextField
      style={{ background: color }}
      className={classes.statusField}
      {...props}
    />
  )
}


const UrgentOrderField = (props) => {
  if (!props.record?.urgentStatus) {
    return <></>
  }

  return (
    <UrgentIcon />
  )
}

const orderListRowStyle = (record) => ({
  backgroundColor: record?.status !== STATUS.NEW ? '#F9FAFC' : 'white',
});

const OrderDataGrid = (props) => {
  return (
    <div className={props.className}>
      <Datagrid {...props} body={<DatagridBody />} />
    </div>
  )
}

export const OrdersList = (props) => {
  const translate = useTranslate();
  const classes = useStyles();
  const { loading, authenticated } = useAuthState();

  if (!authenticated || loading) {
    return null;
  }

  return (
    <List
      title={translate("konebone.orders.title").toUpperCase()}
      exporter={null}
      {...props}
      filters={<OrdersFilter />}
      empty={<OrdersFilter />}
      actions={<></>}
      bulkActionButtons={false}
      className={classes.listRoot}
      sort={{ field: "created", order: "DESC" }}
    >
      <OrderDataGrid rowClick="show" rowStyle={orderListRowStyle} className={classes.table}>
        <UrgentOrderField />
        <TextField
          source="created"
          label="konebone.orders.date_placed"
          sortable={false}
        />
        <TextField
          source="id"
          label="konebone.orders.id"
          sortable={false}
        />
        <TextField
          source="customer"
          label="konebone.orders.company_name"
          sortable={false}
        />
        <TextField
          source="city"
          label="konebone.orders.project_city"
          sortable={false}
        />
        <OrderStatusField
          source="statusLower"
          label="konebone.orders.status"
          sortable={false}
        />
        <TextField
          source="orderPrice"
          label="konebone.orders.order_price"
          sortable={false}
        />
      </OrderDataGrid>
    </List>
  );
};

