import data from './data';
import nodes from './nodes';
import expanded from './expanded';
import loading from './loading';
import { CRUD_GET_TREE_ROOT_NODES_SUCCESS, CRUD_MOVE_NODE_SUCCESS } from '../actions';

const initialState = { hash: { rootHash: 0, childHash: 0 } };

export default (previousState = initialState, action) => {
    if (!action.meta || !action.meta.resource) {
        return previousState;
    }

    const previousResource = previousState[action.meta.resource] || initialState;
    return {
        ...previousState,
        [action.meta.resource]: {
            data: data(previousResource.data, action),
            nodes: nodes(previousResource.nodes, action),
            expanded: expanded(previousResource.expanded, action),
            loading: loading(previousResource.loading, action),
            hash: getHashes(previousResource.hash, action),
        },
    };
};

const getHashes = (previousState, action) => {
    if (action.type === CRUD_MOVE_NODE_SUCCESS) {
        return { ...previousState, rootHash: (previousState.rootHash || 0) + 1 };
    }

    if (action.type === CRUD_GET_TREE_ROOT_NODES_SUCCESS) {
        return {  ...previousState, childHash: (previousState.childHash || 0) + 1 };
    }

    return previousState;
}