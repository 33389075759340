import React from "react";
import { useTranslate } from "react-admin";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableRow,
  TableCell,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  makeStyles,
  TextField as TextFieldMaterial,
  InputAdornment,
  TableHead,
} from "@material-ui/core";
import { ReactComponent as OfferIcon } from "../../../svg/offer.svg";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import NoImage from "../../../images/no_image.png";
import {
  STATUS,
  deliveryStatus,
  offerStatusColorMap,
  vehicleWeightTypeValue,
  deliveryTimeColor,
} from "../enums";
import currency from "currency.js";
import { parseData } from '../../../utils/DateFormat';

const useStyles = makeStyles((theme) => ({
  statusField: {
    minWidth: "89px",
    height: "26px",
    borderRadius: "3px",
    display: "table-cell",
    textAlign: "center",
    verticalAlign: "middle",
    textTransform: "capitalize",
    fontSize: "1em",
    lineHeight: "26px",
  },
  subtitle: {
    textTransform: "uppercase",
    fontWeight: "600",
    lineHeight: "26px",
  },
  offerFilling: {
    width: "100%",
    height: "36px",
    background: "#E7E8EA",
    borderRadius: "5px",
    lineHeight: "36px",
    padding: "0 17px",
  },
  quantity: {
    display: "inline-block",
    lineHeight: "42px",
    textAlign: "center",
    height: "42px",
    width: "100px",
    background: "#E7E8EA",
    borderRadius: "2px",
  },
  flex_container: {
    display: "flex",
    justifyContent: "end",
  },
  total: {
    margin: "30px 0",
    minHeight: "42px",
    minWidth: "700px",
    background: "#F7F8FA",
    alignItems: "center",
    borderRadius: "2px",
    padding: "10px 16px 18px 20px",
    fontSize: "1em",
    "& > div": {
      margin: "10px",
      "& > div": {
        display: "inline-block",
        minWidth: "30px",
        height: "40px",
        margin: "0",
        lineHeight: "40px",
        "&:nth-child(1)": {
          width: "30%",
        },
        "&:nth-child(2)": {
          width: "40%",
          position: "relative",
          // "& input": {
          //   background: "#fff",
          //   width: "65px",
          //   // padding: "0 45px 0 6px",
          //   height: "40px",
          //   textAlign: "right",
          // },
        },
        "& > div": {
          display: "inline-block",
          margin: "0",
        },
      },
    },
  },
  discount_box: {
    margin: "0px 5px",
    width: "50px",
    height: "20px",
    border: "1px solid #000000",
    borderRadius: "2px",
    textAlign: "center",
    lineHeight: "40px",
  },
  desc: {
    width: "250px",
    maxHeight: "80px",
    margin: "5px 0",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  replaced_box: {
    position: "absolute",
    height: "36px",
    width: "160px",
    background: "#E7E8EA",
    borderRadius: "4px",
    bottom: "-18px",
    lineHeight: "36px",
    margin: "0",
    "& > div": {
      position: "relative",
      padding: "0px 40px",
      lineHeight: "36px",
      "& > p": {
        display: "inline-block",
        position: "absolute",
        width: "36px",
        margin: "0",
        left: "5px",
        top: "6px",
      },
    },
  },
  replaced_box_alternative: {
    background: "#F8E5E8",
  },
  alternative_box: {
    position: "relative",
    border: "none",
  },
  alternative_list: {
    "& > td > li,& > td > span": {
      opacity: "0.6",
    },
  },
  list: {
    "& tr:last-child > td": {
      border: "none",
    },
  },
  new_product: {
    borderColor: "#E02443",
  },
  delivery_mark: {
    marginLeft: "10px",
    padding: "0 5px",
    background: "#FBE3E7",
  },
  answer_label_field: {
    "&::after": {
      content: '"\\002A"',
    },
    marginBottom: "5px",
  },
  avatar: {
    marginTop: 0,
    marginRight: "20px",
    "& > div": {
      padding: "5px 10px",
      height: "80px",
      width: "80px",
      borderRadius: 0,
      border: "1px solid #E5E9EB",
    },
  },
  row_style: {
    "td:last-child": {
      borderBottom: "none",
    },
  },
}));

const ILS = (value) =>
  currency(value, { symbol: "₪ ", decimal: ".", separator: "," });

const OfferDetailHeader = ({ record }) => {
  const translate = useTranslate();
  const classes = useStyles();

  const {
    date,
    time,
    status,
    offerId,
    projectInfo,
    projectContact,
    serviceUnloading,
    supplierInfo,
    supplierContact,
    comment,
    deliveryAddress,
    deliveryVehicleWeight,
    deliveryTimeOther,
    delivery,
    ratingOfferProduct,
    deliveryContact,
    deliveryOffer,
    createdTime,
  } = record;

  const deliveryDateFormat = deliveryOffer.deliveryDate?.split("-");
  const deliveryDateData = deliveryDateFormat ? `${deliveryDateFormat?.[2]}/${deliveryDateFormat?.[1]}/${deliveryDateFormat?.[0]}` : '';
  const deliveryTime = !deliveryOffer.deliveryTimeRange?.full ?
      `${deliveryOffer.deliveryTimeRange?.start?.slice(0, 5)}-${deliveryOffer.deliveryTimeRange?.end?.slice(0, 5)}` :
      'during the working day';

  const checkDeadline = new Date() > new Date(date + " " + time);

  return (
    <>
      <Box display="flex" width="100%" mb="2em">
        <Box flex={3} mr="1em">
          <Box>
            <Typography variant="h6">
              {record?.supplier?.name || "-"}{" "}
              {translate("konebone.offer.offer")}{" "}
              {status === STATUS.COMPLETED || status === STATUS.ACCEPTED
                ? offerId + "#"
                : ""}
              <OfferIcon />
            </Typography>
            <Typography variant="body2" gutterBottom>
              {ratingOfferProduct}
            </Typography>
            <Typography
              style={{ background: offerStatusColorMap[status] }}
              className={classes.statusField}
            >
              {record?.statusLower}
            </Typography>
            {createdTime ? <Typography variant="body2" gutterBottom>
              ({translate("konebone.offer.sent_on")} {''}
              {parseData(createdTime)})
            </Typography> : ''}
          </Box>
        </Box>
        <Box flex={2}>
          <Box>
            <Typography
              variant="subtitle2"
              gutterBottom
              className={classes.subtitle}
            >
              {translate("konebone.offer.customer_project")}
            </Typography>
            <Typography variant="body2">{projectInfo}</Typography>
            <Typography variant="body2">
              {projectInfo !== "-" ? projectContact : ""}
            </Typography>
          </Box>
        </Box>
        <Box flex={2}>
          <Box>
            <Typography
              variant="subtitle2"
              gutterBottom
              className={classes.subtitle}
            >
              {translate("konebone.offer.additional_services")}
            </Typography>
            <Typography variant="body2">
              {record.serviceUnloading
                ? translate("konebone.offer.unloading")
                : "-"}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box display="flex" width="100%" mb="1.5em">
        <Box flex={3} mr="1em">
          <Box>
            {status === STATUS.COMPLETED ||
            status === STATUS.ACCEPTED ||
            status === STATUS.IN_REVIEW ? (
              <>

              {record.delivery.deliveryMode === 'DELIVERY_TIME_NOT_SPECIFIED' ? 
              <Box>
                 <Typography
                    variant="subtitle2"
                    className={classes.subtitle}
                  >
                    {translate(
                      `konebone.offer.${deliveryOffer?.deliveryMode.toLowerCase()}`
                    )}
                  </Typography>
              </Box> :
                <Box display="flex" width="100%">
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    className={classes.subtitle}
                  >
                    {translate("konebone.offer.delivery")}
                  </Typography>
                </Box>
                  }
                <Typography variant="body2">
                  {`${deliveryDateData}; ${deliveryTime}`}
                  {date && time && (
                    <span style={{ color: deliveryTimeColor[checkDeadline] }}>
                      {" "}
                      {`(valid to ${new Date(
                        date
                      )?.toDateString()} at ${time?.slice(0, -3)})`}
                    </span>
                  )}
                </Typography>
                <Typography variant="body2">{deliveryAddress}</Typography>
                <Typography variant="body2">{deliveryContact}</Typography>
                <Typography variant="body2">
                  ₪ {delivery?.deliveryCost || 0}
                </Typography>
                {deliveryOffer.deliveryMode === deliveryStatus.BY_KONEBONE ? (
                  <Typography variant="body2">
                    {vehicleWeightTypeValue[deliveryVehicleWeight]}{" "}
                    {translate("konebone.offer.vehicle")}{" "}
                    <b>
                      {serviceUnloading
                        ? translate("konebone.offer.with_crane")
                        : ""}
                    </b>
                  </Typography>
                ) : (
                  <div></div>
                )}
              </>
            ) : (
              <>
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  className={classes.subtitle}
                >
                  {translate("konebone.offer.time_constraints")}
                </Typography>
                <Typography variant="body2">
                  {translate("konebone.offer.specific_delivery_time")}
                </Typography>
                <Typography variant="body2">
                  {`${deliveryDateData}; ${deliveryTime}`}
                  {date && time && (
                    <span style={{ color: "#E02443" }}>
                      {" "}
                      {`(valid to ${new Date(date)
                        ?.toDateString()
                        .slice(4)} at ${time?.slice(0, -3)})`}
                    </span>
                  )}
                </Typography>
              </>
            )}
          </Box>
        </Box>
        <Box flex={2}>
          <Box>
            <Typography
              variant="subtitle2"
              gutterBottom
              className={classes.subtitle}
            >
              {translate("konebone.offer.supplier_info")}
            </Typography>
            <Typography variant="body2">{supplierInfo}</Typography>
            <Typography variant="body2">
              {supplierInfo !== "-" ? supplierContact : ""}
            </Typography>
          </Box>
        </Box>
        <Box flex={2}>
          <Box>
            <Typography
              variant="subtitle2"
              gutterBottom
              className={classes.subtitle}
            >
              {translate("konebone.offer.comments")}
            </Typography>
            <Typography variant="body2">{comment || "-"}</Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};
const ProductListRow = ({ row, typeInStock, index }) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <>
      <TableRow
        key={row.catalogNumber}
        className={
          row?.alternativeProduct && typeInStock.toLowerCase() === "alternative"
            ? classes.alternative_list
            : ""
        }
      >
        <TableCell align="left" style={{ maxWidth: "940px", width: "940px" }}>
          <ListItem alignItems="flex-start" style={{ height: "90px" }}>
            <ListItemAvatar className={classes.avatar}>
              <>
                <Avatar alt="product picture" src={row.fileName || NoImage} />
                {row?.alternativeProduct && <div className="out_of_stock" style={{ height: "25px" }}>לא במלאי</div>}
                {typeInStock.toLowerCase() === "out stock" && (
                  <div className="out_of_stock" style={{ height: "25px" }}>
                    לא במלאי
                  </div>
                )}
              </>
            </ListItemAvatar>
            <ListItemText
              primary={
                <div>
                  <div className="code">
                    <span className="product_number">
                      {row.catalogNumber || row.productId}
                    </span>
                    <span
                      className="supplier_number"
                      style={{ paddingRight: "0" }}
                    >
                      מק"ט
                    </span>
                    <span className="supplier_number">{row.supplierPn}</span>
                    <p className={classes.desc}>{row.name}</p>
                  </div>
                </div>
              }
            />
          </ListItem>
        </TableCell>
        <TableCell style={{ maxWidth: "370px", width: "370px" }}>
          <span className={classes.quantity}>
            х {row?.quantity?.split(".")[0]}
          </span>
        </TableCell>
        {row.alternativeProduct &&
        typeInStock.toLowerCase() === "alternative" ? (
          <TableCell align="left" className={classes.alternative_box}>
            <div
              className={`${classes.replaced_box} ${
                row.alternativeProduct.isNew
                  ? classes.replaced_box_alternative
                  : ""
              }`}
            >
              <div>
                <p>
                  <AutorenewIcon />
                </p>
                <span>{translate("konebone.offer.replacement")}</span>
              </div>
            </div>
          </TableCell>
        ) : (
          <>
            <TableCell align="left">
              <Typography
                variant="button"
                display="block"
                style={{ fontWeight: "600", whiteSpace: "nowrap" }}
              >
                {ILS(row.supplierPriceTotal).format()}
              </Typography>
              <Typography
                variant="overline"
                display="block"
                style={{ textTransform: "lowercase", whiteSpace: "nowrap" }}
              >{`${ILS(row.supplierPricePerItem).format()}/item`}</Typography>
            </TableCell>
            <TableCell align="left">
              <Typography
                variant="button"
                display="block"
                style={{ fontWeight: "600", whiteSpace: "nowrap" }}
              >
                {ILS(row.kbPriceTotal).format()}
              </Typography>
              <Typography
                variant="overline"
                display="block"
                style={{ textTransform: "lowercase", whiteSpace: "nowrap" }}
              >{`${ILS(row.kbPricePerItem).format()}/item`}</Typography>
            </TableCell>
          </>
        )}
      </TableRow>

      {row?.alternativeProduct && typeInStock.toLowerCase() === "alternative" && (
        <TableRow
          key={row.alternativeProduct.catalogNumber}
          style={{ position: "relative" }}
          className={classes.row_style}
        >
          <TableCell align="left" style={{ maxWidth: "940px", width: "940px" }}>
            <ListItem alignItems="flex-start" style={{ height: "90px" }}>
              <ListItemAvatar className={classes.avatar}>
                <Avatar
                  alt="product picture"
                  src={row.alternativeProduct.fileName || NoImage}
                />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <div>
                    <div className="code">
                      <span className={`product_number ${row.alternativeProduct.isNew ? classes.new_product : ""}`}>
                        {row.alternativeProduct.isNew ? translate("konebone.offer.new_product") : row.alternativeProduct.catalogNumber || row.alternativeProduct.productId}
                      </span>
                      <span className="supplier_number" style={{ paddingRight: "0" }}>ט"קמ</span>
                        {/* <span className="supplier_number">{row.alternativeProduct.catalogNumber}</span> */}
                      <span className="supplier_number">{row.alternativeProduct.supplierPn}</span>
                      <p className={classes.desc}>
                        {row.alternativeProduct.name}
                      </p>
                    </div>
                  </div>
                }
              />
            </ListItem>
          </TableCell>
          <TableCell style={{ maxWidth: "370px", width: "370px" }}>
            <span className={classes.quantity}>
              х{" "}
              {row.alternativeProduct.quantity?.split(".")[0] ||
                row?.quantity?.split(".")[0] ||
                0}
            </span>
          </TableCell>
          <TableCell align="left">
            <Typography
              variant="button"
              display="block"
              style={{ fontWeight: "600", whiteSpace: "nowrap" }}
            >
              {ILS(row.supplierPriceTotal).format()}
            </Typography>
            <Typography
              variant="overline"
              display="block"
              style={{ textTransform: "lowercase", whiteSpace: "nowrap" }}
            >{`${
              ILS(row.supplierPricePerItem).format()
            }/item`}</Typography>
          </TableCell>
          <TableCell align="left">
            <Typography
              variant="button"
              display="block"
              style={{ fontWeight: "600", whiteSpace: "nowrap" }}
            >
              {ILS(row.kbPriceTotal).format()}
            </Typography>
            <Typography
              variant="overline"
              display="block"
              style={{ textTransform: "lowercase", whiteSpace: "nowrap" }}
            >{`${
              ILS(row.kbPricePerItem).format()
            }/item`}</Typography>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
const ProductListInOffer = ({  index, status, products, typeStock, baseHeader }) => {
  const translate = useTranslate();
  const classes = useStyles();

  if (!products.length) {
    return <div></div>;
  }

  const typeInStock = {
    inStock: translate("konebone.offer.in_stock"),
    outStock: translate("konebone.offer.out_stock"),
    alternative: translate("konebone.offer.alternative"),
  };


  return (
    status === STATUS.PACKING_SLIP_VERIFIED || status === STATUS.DELIVERED || status === STATUS.AWAITING_DELIVERY ?
    (<>
      <Box className="table_style">
        <div className="table-responsive">
          <Table>
            <TableHead className={classes.offerFilling} style={{ padding: "0" }}>
              <TableCell>
                <Typography variant="subtitle2">
                  <b>{translate("konebone.orders.delivery")} {index + 1}- {typeInStock[typeStock]}</b> ({products.length})
                </Typography>
              </TableCell>
              <TableCell></TableCell>
              <TableCell style={{ color: "#252C32", opacity: "0.6" }}>
                {baseHeader ? translate("konebone.offer.supplier_price") : ""}
              </TableCell>
              <TableCell style={{ color: "#252C32", opacity: "0.6" }}>
                {baseHeader ? translate("konebone.offer.kb_price") : ""}
              </TableCell>
            </TableHead>
            <TableBody className={classes.list}>
              {products?.map((row) => {
                return (
                  <ProductListRow
                    row={row}
                    key={row.productId}
                    typeInStock={typeInStock[typeStock]}
                  />
                );
              })}
            </TableBody>
          </Table>
        </div>
      </Box>
    </>)
    :
    <Box className="table_style">
      <div className="table-responsive">
        <Table>
          <TableHead className={classes.offerFilling} style={{ padding: "0" }}>
            <TableCell>
              <Typography variant="subtitle2">
                <b>{typeInStock[typeStock]}</b> ({products.length})
              </Typography>
            </TableCell>
            <TableCell></TableCell>
            <TableCell style={{ color: "#252C32", opacity: "0.6" }}>
              {baseHeader ? translate("konebone.offer.supplier_price") : ""}
            </TableCell>
            <TableCell style={{ color: "#252C32", opacity: "0.6" }}>
              {baseHeader ? translate("konebone.offer.kb_price") : ""}
            </TableCell>
          </TableHead>
          <TableBody className={classes.list}>
            {products.map((row) => {
              return (
                <ProductListRow
                  row={row}
                  key={row.productId + 1}
                  typeInStock={typeInStock[typeStock]}
                />
              );
            })}
          </TableBody>
        </Table>
      </div>
    </Box>
  );
};

 

const TotalBoxPrices = ({ data, record, status }) => {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
      <div className={classes.total}>
        <div>
          <div>{translate("konebone.offer.supplier_price")}</div>
          <div></div>
          <div>{ILS(record.supplierPrice).format()}</div>
        </div>
        <div>
          <div>
            <span>{translate("konebone.offer.discount")}</span>
          </div>
          <div>
            <TextFieldMaterial
              className="percent_box"
              value={record.discountPercent}
              placeholder="0.0"
              variant="outlined"
              width="40px"
              disabled
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">&#37;</InputAdornment>
                ),
                readOnly: true,
              }}
            />
          </div>
          <div>{ILS(record.supplierPriceWithDiscount).format()}</div>
        </div>
        <div>
          <div>{translate("konebone.offer.kb_price")}</div>
          <div>
            <TextFieldMaterial
              className="percent_box"
              value={record.kbChargePercent}
              placeholder="0.0"
              variant="outlined"
              width="40px"
              disabled
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">&#37;</InputAdornment>
                ),
                readOnly: true,
              }}
            />
          </div>
          <div>{ILS(record.kbPrice).format()}</div>
        </div>
        <div>
          <div>{translate("konebone.offer.kb_price_discount")}</div>
          <div></div>
          <div>{ILS(record.kbPriceWithDiscount).format()}</div>
        </div>
        <div>
          <div>{translate("konebone.offer.gross_profit")}</div>
          <div></div>
          <div>{ILS(record.profit).format()}</div>
        </div>
        <div>
          <div>{translate("konebone.offer.delivery_price")}</div>
          <div></div>
          <div>{ILS(record.delivery?.deliveryCost).format()}</div>
        </div>
        <div>
          <div>
            <b>{translate("konebone.offer.subtotal")}</b>{" "}
            {translate("konebone.offer.without_vat")}
          </div>
          <div></div>
          <div style={{ fontWeight: "600" }}>
            {ILS(record.subtotal).format()}
          </div>
        </div>
        <div style={{ borderTop: "1px solid #E2E1E1" }}>
          <div>
            <b>{translate("konebone.offer.vat")}</b> (17%)
          </div>
          <div></div>
          <div>{ILS(record.vat).format()}</div>
        </div>
        <div>
          <div>
            <b>{record.status !== STATUS.ACCEPTED ? translate("konebone.offer.total") : 'New total'}</b>
          </div>
          <div></div>
          <div>
            <b>{ILS(record.total).format()}</b>
          </div>
        </div>
        <hr/>
        {(record.status === STATUS.ACCEPTED || record?.orderStatus === STATUS.AWAITING_DELIVERY
        || record?.orderStatus === STATUS.DELIVERED) && (
            <div style={{color: '#E02443'}}>
              <div><b>Paid</b></div>
              <div></div>
              <div><b>{ILS(record.paidTotal).format()}</b></div>
            </div>
        )}
        {data?.status === STATUS.PACKING_SLIP_VERIFIED && (
          <>
            <div style={{ color: "red", borderTop: "1px solid #E2E1E1" }}>
              <div>
                <b>{translate("konebone.orders.total_with_difference")}</b>
              </div>
              <div></div>
              <div>
                <b>{ILS(data?.invoiceOrderResponseDto?.invoiceAmount).format()}</b>
              </div>
            </div>
            <div style={{ color: "red" }}>
              <div>
                <b>{translate("konebone.orders.invoice")}</b>
              </div>
              <div></div>
              <div>
                <b>{data?.invoiceOrderResponseDto?.invoice}</b>
              </div>
            </div>
            <div style={{ color: "red" }}>
              <div>
                <b>{translate("konebone.orders.invoice_link")}</b>
              </div>
              <div></div>
              <div>
                <a style={{ color: "red" }} target={"_blank"} href={data?.invoiceOrderResponseDto?.invoiceLink}>{data?.invoiceOrderResponseDto?.invoiceLink}</a>
              </div>
            </div>
          </>
        )}
      </div>
    </Box>
  );
};

export { OfferDetailHeader, ProductListInOffer, TotalBoxPrices };
