import { 
  Avatar, 
  Button, 
  ListItem, 
  ListItemAvatar, 
  ListItemText, 
  makeStyles, 
  Table, 
  TableBody, 
  TableCell, 
  TableRow, 
  Typography } from "@material-ui/core"
import NoImage from '../../../images/no_image.png';
import currency from "currency.js";
import CloseIcon from '@material-ui/icons/Close';
import { OfferCancelDialog } from "./OfferCancelDialog";
import { Fragment, useState } from "react";


const useStyles = makeStyles((theme) => ({
  quantity: {
    display: "inline-block",
    lineHeight: "42px",
    textAlign: "center",
    height: "42px",
    width: "100px",
    background: "#F7F8FA",
    borderRadius: "2px"
  },
  avatar: {
    marginTop: 0,
    marginRight: "20px",
    "& > div": {
      padding: "5px 10px",
      height: "80px",
      width: "80px",
      borderRadius: 0,
      border: "1px solid #E5E9EB"
    }
  },
  supllierInfo: {
    display: 'flex',
    alignItems: "center",
  }
}));

const ILS = value => currency(value, { symbol: '₪ ', decimal: '.', separator: ',' });

const OfferMatchesProducts = ({isProductsMatches, open}) => {
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const classes = useStyles();


  const openDeleteDialog = (value, id, productName, supplierName) => {
    setSelectedProduct([value, id, productName, supplierName])
    setCancelDialogOpen(true)
  };

  const handleClose = () => {
    // e.stopPropagation();
    setCancelDialogOpen(false);
  };
  

  return (
    <Table>
      <TableBody>
        {isProductsMatches.products?.map((row, i) => (
          <Fragment key={row.product.catalogNumber}>
            <TableRow>
              <TableCell align="left">
                <ListItem alignItems="flex-start">
                  <ListItemAvatar className={classes.avatar}>
                    <Avatar alt="product picture" src={row.product.fileName || NoImage} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <p className="code">
                        <span className="product_number">{row.product.catalogNumber}</span>
                      </p>
                    }
                    secondary={row.product.name}
                  />
                </ListItem>
              </TableCell>
            </TableRow>
            {row.differences.map((dif, i) => {
              return (
                <TableRow key={dif.offerId}>
                    <TableCell >
                      <ListItem className={classes.supllierInfo} >
                        <ListItemText>{dif.supplierName}</ListItemText>
                        <ListItemText><span className={classes.quantity}>{`x ${dif.quantity.split(".")[0]}`}</span></ListItemText>
                        <ListItemText >
                            <Typography variant="button" display="block"><b>{ILS(dif.kbPriceTotal).format()}</b></Typography>
                            <Typography variant="overline" display="block">{`${ILS(dif.kbPricePerItem).format()}/item`}</Typography>
                        </ListItemText>
                        <Button
                          key={i}
                          startIcon={<CloseIcon />}
                          onClick={() => openDeleteDialog(`${row.product.productId} ${dif.offerId}`, i, row.product.name, dif.supplierName)}
                        />
                      </ListItem>
                    </TableCell>
                </TableRow>
              )
            }
            )} 
          </Fragment>
        ))}
          {cancelDialogOpen && <OfferCancelDialog props={selectedProduct} onClose={handleClose} open={open} />}
      </TableBody>
    </Table>
  )
};

export default OfferMatchesProducts;