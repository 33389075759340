import axios from "axios";
import Wrapper from "../utils/Wrapper";
import TreeService from "../services/TreeService";
import { stringify } from "query-string";
import { HttpError } from "ra-core";

const dataProviderForCategories = {
  getList: async (resource, params) => {
    const url = process.env.REACT_APP_API_URL + "/category";
    const response = await axios.get(url, {
      withCredentials: true,
      headers: { accept: "application/json" },
    });
    if (!response.data.data) {
      return {
        data: [],
        total: 0,
      };
    }

    let resultData = response.data.data.map((category) =>
      Wrapper.wrapCategory(category)
    );
    const totalElements = response.data.data.length;
    return {
      data: resultData,
      total: totalElements,
    };
  },

  getRootNodes: async (resource) => {
    const url = process.env.REACT_APP_API_URL + "/category";
    TreeService.resetAllNodes();

    const resultData = await TreeService.getNodesByParentId(url, 0);

    return {
      data: resultData,
    };
  },

  getParentNode: async (resource, { childId }) => {
    const url = process.env.REACT_APP_API_URL + `/category/parent/${childId}`;
    const response = await axios.get(url, {
      withCredentials: true,
      headers: { accept: "application/json" },
    });

    if (!response.data.data) {
      return {
        data: [],
        total: 0,
      };
    }

    let resultData = response.data.data.map((category) =>
      Wrapper.wrapCategory(category)
    );
    const totalElements = response.data.data.length;

    return {
      data: resultData,
      total: totalElements,
    };
  },

  getChildNodes: async (resource, { id }) => {
    const url = process.env.REACT_APP_API_URL + "/category";

    const resultData = await TreeService.getNodesByParentId(url, id);

    return {
      data: resultData,
    };
  },

  getOne: async (resource, { id }) => {
    const url = process.env.REACT_APP_API_URL + `/category/${id}`;
    const response = await axios.get(url, {
      withCredentials: true,
      headers: { accept: "application/json" },
    });

    let resultData = Wrapper.wrapCategory(response.data.data);
    return {
      data: resultData,
    };
  },

  create: async (resource, { data }) => {
    const updateData = {
      metaDescription: data.metaDescription,
      name: data.name,
      metaTitle: data.metaTitle,
      pageText: data.pageText,
      parentCategoryId: data.parent_id,
      position: data.position || 0,
    };

    let bodyFormData;
    bodyFormData = new FormData();
    if (data.image?.rawFile) {
      bodyFormData.append('file', data.image.rawFile, data.image.rawFile.name);
    }
    bodyFormData.append('dto', new Blob([JSON.stringify(updateData)],{
      type: "application/json"
    }));

    let response = await axios.post(
        process.env.REACT_APP_API_URL + '/category',
        bodyFormData,
        {
          withCredentials: true,
          headers: { accept: "application/json", "Content-Type": "application/json" },
        }
    );

    let resultData = Wrapper.wrapCategory(response.data.data);

    return {
      data: resultData,
    };
  },

  update: async (resource, { id, data }) => {
    const updateData = {
      metaDescription: data.metaDescription,
      name: data.name,
      metaTitle: data.metaTitle,
      pageText: data.pageText,
      parentCategoryId: data.parent_id,
      position: data.position || 0,
    };

    let bodyFormData;
    bodyFormData = new FormData();
    if (data.image?.rawFile) {
      bodyFormData.append('file', data.image.rawFile, data.image.rawFile.name);
    }
    bodyFormData.append('dto', new Blob([JSON.stringify(updateData)],{
      type: "application/json"
    }));

    let response = await axios.put(
      process.env.REACT_APP_API_URL + `/category/${id}`,
        bodyFormData,
      {
        withCredentials: true,
        headers: { accept: "application/json", "Content-Type": "application/json" },
      }
    );

    let resultData = Wrapper.wrapCategory(response.data.data);

    return {
      data: resultData,
    };
  },

  delete: async (resource, { id }) => {
    let response = await axios.delete(
      process.env.REACT_APP_API_URL + `/category/${id}`,
      {
        withCredentials: true,
        headers: { accept: "application/json" },
      }
    );

    return {
      data: response.data?.data.result,
    };
  },

  moveNode: async (resource, { data }) => {
    const updateData = {
      position: data.position || 0,
    };

    return new Promise((resolve, reject) => {
      axios.put(
        process.env.REACT_APP_API_URL + `/category/position/${data.id}?` + stringify(updateData), null,
        {
          withCredentials: true,
          headers: {
            accept: "application/json",
          },
        }
      )
        .then((response) => {
          const { status, data, headers } = response.data;
          if (data) {
            let resultData = Wrapper.wrapCategory(data);
            return resolve({ status, data: resultData, headers });
          }

          return resolve({ status, data: null, headers});
        })
        .catch((error) => {
          const { status, data } = error.response;

          if (status < 200 || status >= 300) {
            return reject(
              new HttpError(
                data.message.message,
                status
              )
            );
          }
        });
    });


    // let response = await axios.put(
    //   process.env.REACT_APP_API_URL + `/category/position/${data.id}?` + stringify(updateData), null,
    //   {
    //     withCredentials: true,
    //     headers: {
    //       accept: "application/json",
    //     },
    //   }
    // );

    // let resultData = Wrapper.wrapCategory(response.data.data);

    // return {
    //   data: resultData,
    // };
  },

  removePicture: async (id) => {
    let response = await axios.delete(
      process.env.REACT_APP_API_URL + `/category/image/${id}`,
      {
        withCredentials: true,
        headers: { accept: "application/json" },
      }
    );

    return {
      data: !!response.data?.data.categoryId,
    };
  },
};

export default dataProviderForCategories;
