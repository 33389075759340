import React from "react";
import { TopToolbar } from "react-admin";
import { useTranslate, } from "ra-core";
import { useHistory, useParams } from "react-router-dom";
import {
  Button,
  Box,
  makeStyles,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  toolbar_root: {
    position: "sticky",
    top: "45px",
    zIndex: "200",
    backgroundColor: "#fafafa",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "80px",
    padding: "0px",
    "& *": {
      textTransform: "inherit",
    },
    "& > a": {
      width: "100%",
      justifyContent: "start"
    }
  },
  root: {
    "& > button": {
      marginLeft: theme.spacing(1),
      height: "42px",
    },
  },
  formControl: {
    minWidth: "42px",
    marginTop: "5px",
  }
}));

export default ({ data, handleSave, ...rest }) => {
  const { orderId, id } = useParams();
  const translate = useTranslate();
  const classes = useStyles();
  const history = useHistory();

  const handleClick = (e) => {
    e.stopPropagation();
    history.push(`./${id}/show`);
  };

  return (
    <TopToolbar className={classNames(classes.toolbar_root, "sticky-toolbar")}>
      <Button
        label={translate("konebone.offer.go_back")}
        startIcon={<KeyboardBackspaceIcon />}
        component={Link}
        style={{ height: "42px" }}
        to={`/orders/${orderId}/show/suppliers`}
      >
        {translate("konebone.offer.go_back")}
      </Button>
      <Box
        display="flex"
        justifyContent="end"
        width="100%"
        className={classes.root}
      >
        <Button
          variant="contained"
          onClick={handleSave}
          color="secondary"
        >{translate("konebone.offer.save")}</Button>
        <Button
          variant="outlined"
          onClick={handleClick}
        >{translate("konebone.offer.cancel")}</Button>
      </Box>
    </TopToolbar >
  );
};
