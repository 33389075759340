import React from "react";
import DeleteWithUndoMenuItem from "./DeleteWithUndoMenuItem";
import DeleteWithConfirmMenuItem from "./DeleteWithConfirmMenuItem";
import { Component } from "react";

class DeleteMenuItem extends Component {
  render() {
    const { undoable = true, ...props } = this.props;

    return undoable ? (
      <DeleteWithUndoMenuItem {...props} />
    ) : (
      <DeleteWithConfirmMenuItem {...props} />
    );
  }
}

export default DeleteMenuItem;
