let _isBlocking = false;

const isBlocking = () => {
  return _isBlocking;
};

const setIsBlocking = (value) => {
  _isBlocking = value;
};

export default {
  isBlocking,
  setIsBlocking,
}
