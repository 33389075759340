import React, { useEffect, useState } from "react";
import {
  useNotify,
  useTranslate,
  FormWithRedirect,
  Confirm,
} from "react-admin";
import { Form } from 'react-final-form';
import { useHistory, useLocation } from "react-router-dom";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableRow,
  TableCell,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  makeStyles,
  TextField as TextInput,
  InputAdornment,
  RadioGroup,
  Radio,
  FormControlLabel,
  Checkbox,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Tooltip,
  List,
  Select,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import { KeyboardDatePicker } from '@material-ui/pickers';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import Validator from "../../../utils/Validator";
import AddAlternativeProductDialog from "../AddAlternativeProductDialog";

import { ReactComponent as OfferIcon } from "../../../svg/offer.svg";
import { ReactComponent as EditIcon } from "../../../svg/edit_pen.svg";
import { ReactComponent as PointIconTop } from "../../../svg/point_bottom.svg";
import { ReactComponent as PointIconBottom } from "../../../svg/point_top.svg";
import { ReactComponent as TruckIcon_4 } from "../../../svg/delivery-truck-4.svg";
import { ReactComponent as TruckIcon_8 } from "../../../svg/delivery-truck-8.svg";
import { ReactComponent as TruckIcon_12 } from "../../../svg/delivery-truck-12.svg";
import { ReactComponent as TruckIcon_15 } from "../../../svg/delivery-truck-15.svg";
import { ReactComponent as DateIcon } from "../../../svg/date-icon.svg";
import { ReactComponent as PlusIcon } from "../../../svg/plus.svg";
import { ReactComponent as InfoIcon } from "../../../svg/info-ico.svg";
import { ReactComponent as MinusIcon } from "../../../svg/minus-icon.svg";
import NoImage from '../../../images/no_image.png';
import { deliveryStatus, STATUS, vehicleWeightTypeEnum, vehicleWeightTypeValue } from "../enums";
import currency from "currency.js";
import dataProviderForProducts from "../../../providers/DataProviderForProducts";
import { cleanSession } from "../../../authProvider";
import { wrapArrayToMap } from "../../../utils/Wrapper";
import classNames from "classnames";
import { parseData } from "../../../utils/DateFormat";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import dataProviderForOffers from "../../../providers/DataProviderForOffers";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";

const requiredFields = ["contactName", "contactPhone", "deliveryCost", "date", "time"];
const DEFAULT_QUANTITY = 1;
const OPERATION_ADD = "ADD";
const OPERATION_SUBTRACT = "SUBTRACT";


const useStyles = makeStyles(() => ({
  statusField: {
    minWidth: "89px",
    height: "26px",
    borderRadius: "3px",
    display: "table-cell",
    textAlign: "center",
    verticalAlign: "middle",
    textTransform: "capitalize",
    fontSize: "1em",
    lineHeight: "26px",
  },
  subtitle: {
    textTransform: "uppercase",
    fontWeight: "600",
    lineHeight: "26px",
  },
  offerFilling: {
    width: "100%",
    height: "36px",
    background: "#E7E8EA",
    borderRadius: "5px",
    lineHeight: "36px",
    padding: "0 17px"
  },
  quantity: {
    display: "inline-block",
    lineHeight: "42px",
    textAlign: "center",
    height: "42px",
    width: "100px",
    background: "#E7E8EA",
    borderRadius: "2px"
  },
  flex_container: {
    display: "flex",
    justifyContent: "end",
  },
  total: {
    margin: "30px 0",
    minHeight: "42px",
    minWidth: "650px",
    background: "#E7E8EA",
    alignItems: "center",
    borderRadius: "2px",
    padding: "10px 16px 18px 20px",
    fontSize: "1em",
    "& > div": {
      margin: "10px",
      "& > div": {
        display: "inline-block",
        minWidth: "30px",
        height: "40px",
        margin: "0",
        lineHeight: "40px",
        "&:nth-child(1)": {
          width: "30%",
        },
        "&:nth-child(2)": {
          width: "40%",
          position: "relative",
          // "& input": {
          //   background: "#fff",
          //   width: "65px",
          //   // padding: "0 45px 0 6px",
          //   height: "40px",
          //   textAlign: "right",
          // },
        },
        "& > div": {
          display: "inline-block",
          margin: "0",
        }
      }
    },
  },
  discount_box: {
    margin: "0px 5px",
    width: "50px",
    height: "20px",
    border: "1px solid #000000",
    borderRadius: "2px",
    textAlign: "center",
    lineHeight: "40px",
  },
  delivery_label_field: {
    "&::after": {
      content: '"\\002A"',
    },
    marginBottom: "5px",
  },
  desc: {
    width: "250px",
    maxHeight: "80px",
    margin: "5px 0",
    textOverflow: "ellipsis",
    overflow: "hidden"
  },
  replaced_box: {
    position: "relative",
    background: "#fff",
    borderRadius: "4px",
    top: "50px",
    textAlign: "left",
    lineHeight: "36px",
    "&::first-letter": {
      textTransform: "capitalize"
    },
    "& > div": {
      position: "relative",
      padding: "0px 40px",
      lineHeight: "36px",
      "& > p": {
        display: "inline-block",
        position: "absolute",
        width: "36px",
        margin: "0",
        left: "5px",
        top: "6px",
      }
    }
  },
  replaced_box_alternative: {
    background: "#F8E5E8",
  },
  list: {
    "& tr:last-child > td": {
      border: "none",
    }
  },
  alternative_box: {
    position: "relative",
    minWidth: "160px",
  },
  new_product: {
    borderColor: "#E02443",
  },
  delivery_mark: {
    marginLeft: "10px",
    padding: "0 5px",
    background: "#FBE3E7",
  },
  avatar: {
    marginTop: 0,
    marginRight: "20px",
    "& > div": {
      padding: "5px 10px",
      height: "80px",
      width: "80px",
      borderRadius: 0,
      border: "1px solid #E5E9EB"
    }
  },
  row_style: {
    "td:last-child": {
      borderBottom: "none",
    }
  },
  input_style: {
    marginRight: '40px' 
  }
}));
const ILS = value => currency(value, { symbol: '₪ ', decimal: '.', separator: ',' });

export const EditVehicleButton = ({ record, deliveryVehicleWeight, saveEditVehicleWeight }) => {
  const [dialogOpened, setDialogOpened] = useState(false);
  const [vehicleType, setVehicleType] = useState(deliveryVehicleWeight || "");
  const [required, setRequired] = useState(false);
  const classes = useStyles();
  const translate = useTranslate();

  const handleChangeRadio = (e) => {
    setVehicleType(e.target.value);
  }
  const handleClick = (e) => {
    e.stopPropagation();
    setDialogOpened(true)
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setDialogOpened(false);
  };

  const handleSave = (e) => {
    e.stopPropagation();
    setRequired(false);
    if (vehicleType) {
      saveEditVehicleWeight(vehicleType)
    } else {
      setRequired(true);
      return;
    }
    setDialogOpened(false);
  };

  return (
    <>
      <div className="listButton">
        <Button
          variant="text"
          endIcon={<EditIcon />}
          onClick={handleClick}
        ></Button>
      </div>
      {dialogOpened && (
        <Dialog
          open={dialogOpened}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" className={classes.capitalize}>
            {translate("konebone.offer.edit_type_of_vehicle")}
          </DialogTitle>
          <DialogContent className="truck_boxes">
            <RadioGroup row aria-label="delivery_truck" name="row-radio-buttons-group">

              <FormControlLabel value={vehicleWeightTypeEnum.WEIGHT_4T}
                checked={vehicleType === vehicleWeightTypeEnum.WEIGHT_4T}
                control={<Radio checkedIcon={<TruckIcon_4 />} icon={<TruckIcon_4 />} />}
                onChange={handleChangeRadio}
                label="4ton"
                style={vehicleType === vehicleWeightTypeEnum.WEIGHT_4T ? { border: "1px solid red", borderRadius: "2px" } : { border: "none" }} />

              <FormControlLabel value={vehicleWeightTypeEnum.WEIGHT_8T}
                checked={vehicleType === vehicleWeightTypeEnum.WEIGHT_8T}
                control={<Radio checkedIcon={<TruckIcon_8 />} icon={<TruckIcon_8 />} />}
                onChange={handleChangeRadio}
                label="8ton"
                style={vehicleType === vehicleWeightTypeEnum.WEIGHT_8T ? { border: "1px solid red", borderRadius: "2px" } : { border: "none" }}
              />

              <FormControlLabel value={vehicleWeightTypeEnum.WEIGHT_12T}
                checked={vehicleType === vehicleWeightTypeEnum.WEIGHT_12T}
                control={<Radio checkedIcon={<TruckIcon_12 />} icon={<TruckIcon_12 />} />}
                onChange={handleChangeRadio}
                label="12ton"
                style={vehicleType === vehicleWeightTypeEnum.WEIGHT_12T ? { border: "1px solid red", borderRadius: "2px", boxSizing: "border-box" } : { border: "none" }}
              />

              <FormControlLabel value={vehicleWeightTypeEnum.WEIGHT_15T}
                checked={vehicleType === vehicleWeightTypeEnum.WEIGHT_15T}
                control={<Radio checkedIcon={<TruckIcon_15 />} icon={<TruckIcon_15 />} />}
                onChange={handleChangeRadio}
                label="15ton"
                style={vehicleType === vehicleWeightTypeEnum.WEIGHT_15T ? { border: "1px solid red", borderRadius: "2px" } : { border: "none" }}
              />
            </RadioGroup>
            {required ? <Typography variant="subtitle2" style={{ color: "red" }}>{translate("ra.validation.required")}*</Typography> : ""}
          </DialogContent>
          <DialogActions className={classes.root}>
            <Button onClick={handleClose} variant="outlined">
              {translate("ra.action.cancel")}
            </Button>
            <Button onClick={handleSave} variant="contained" color="secondary">
              {translate("konebone.offer.save")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
export const EditAddressButton = ({ record, saveEditAddress }) => {
  const [apt, setApt] = useState(record?.delivery?.pickupApt || "");
  const [city, setCity] = useState(record?.delivery?.pickupCity || "");
  const [street, setStreet] = useState(record?.delivery?.pickupStreet || "");
  const [dialogOpened, setDialogOpened] = useState(false);

  const [errors, setErrors] = useState({});
  // const [aptError, setAptError] = useState("");
  const [cityError, setCityError] = useState("");
  const [streetError, setStreetError] = useState("");

  const classes = useStyles();
  const translate = useTranslate();

  const requiredFields = ["city", "street"];

  const handleClick = (e) => {
    e.stopPropagation();
    setDialogOpened(true)
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setDialogOpened(false);
  };

  const changeApt = (e) => {
    setApt(e.target.value);
  }

  const changeStreet = (e) => {
    if (errors.street && e.target.value) {
      setErrors({ ...errors, street: false });
    }
    setStreetError("");
    setStreet(e.target.value);
  }

  const changeCity = (e) => {
    if (errors.city && e.target.value) {
      setErrors({ ...errors, city: false });
    }
    setCityError("");
    setCity(e.target.value);
  }

  const handleSave = () => {
    const data = {
      apt: apt?.trim(),
      city: city?.trim(),
      street: street?.trim(),
    };

    let isError = false;

    if (Validator.validateStreet(street)) {
      setStreetError(Validator.validateStreet(street));
      isError = true;
    }

    if (Validator.validateCity(city)) {
      setCityError(Validator.validateCity(city));
      isError = true;
    }
    
    const newErrors = { ...errors };

    requiredFields.forEach(field => {
      if (!data[field]) {
        errors[field] = true;
      }
    })
    const isWrong = Object.values(newErrors).some((error) => error);

    if (isWrong) {
      setErrors(newErrors);
      return;
    }

    if (isError) {
      return;
    }

    saveEditAddress({ apt, city, street });
    setDialogOpened(false);
  }
  return (
    <>
      <div className="listButton">
        <Button
          variant="text"
          endIcon={<EditIcon />}
          onClick={handleClick}
        ></Button>
      </div>
      {dialogOpened && (
        <Dialog
          open={dialogOpened}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" className={classes.capitalize}>
            {translate("konebone.offer.edit_suppliers_pickup_address")}
          </DialogTitle>
          <DialogContent>
            <Box display="flex">
              <Box flex={1} mr="1em">
                <TextInput
                  id="street"
                  label={translate("konebone.offer.street")}
                  value={street}
                  onChange={changeStreet}
                  error={errors.street || !!streetError}
                  helperText={errors.street ? translate("ra.validation.required") : translate(streetError, { max: "100" })}
                  fullWidth
                  variant="outlined"
                  required
                />
              </Box>
              <Box flex={1}>
                <TextInput
                  id="apt"
                  label={translate("konebone.offer.apt")}
                  value={apt}
                  onChange={changeApt}
                  // error={errors.pickupApt || !!aptError}
                  // helperText={errors.apt ? translate("ra.validation.required") : translate(aptError, { max: "10" })}
                  fullWidth
                  variant="outlined"
                />
              </Box>
            </Box>
            <TextInput
              id="city"
              label={translate("konebone.offer.city")}
              value={city}
              onChange={changeCity}
              error={errors.city || !!cityError}
              helperText={errors.city ? translate("ra.validation.required") : translate(cityError, { max: "100" })}
              fullWidth
              variant="outlined"
              required
            />

          </DialogContent>
          <DialogActions className={classes.root}>
            <Button onClick={handleClose} variant="outlined">
              {translate("ra.action.cancel")}
            </Button>
            <Button onClick={handleSave} variant="contained" color="secondary">
              {translate("konebone.offer.save")}
            </Button>
          </DialogActions>
        </Dialog>
      )
      }
    </>
  );
};
const OfferDetailHeader = ({ record }) => {
  const translate = useTranslate();
  const classes = useStyles();

  const {
    offerId,
    supplierInfo,
    supplierContact,
  } = record;

  return (
    <>
      <Box display="flex" width="100%" mb="2em">
        <Box flex={3} mr="1em">
          <Box>
            <Typography variant="h6">
              {translate("konebone.offer.edit")} {record?.supplier?.name || "-"} {translate("konebone.offer.offer")} {"#" + offerId}<OfferIcon />
            </Typography>
          </Box>
        </Box>

      </Box>
      <Box display="flex" width="100%" mb="1.5em">
        <Box flex={2}>
          <Box>
            <Typography variant="subtitle2" gutterBottom className={classes.subtitle}>
              {translate("konebone.offer.supplier_info")}
            </Typography>
            <Typography variant="body2">{supplierInfo}</Typography>
            <Typography variant="body2">{supplierContact}</Typography>
          </Box>
        </Box>
      </Box>
    </>
  )
}

const DeliveryInfo = ({
  record,
  deliveryVehicleWeight,
  saveEditVehicleWeight,
  saveEditAddress,
  pickupStreet,
  pickupCity,
  pickupApt,
  changeUnloading, 
  checked }) => {
  const translate = useTranslate();

  const isHasAddress = !!pickupStreet && !!pickupCity;
  return (
    <div className="delivery_vehicle_box">
      <div><span className="icon"><OfferIcon /></span>{deliveryVehicleWeight ? `${vehicleWeightTypeValue[deliveryVehicleWeight]} vehicle` : <span style={{ color: "red" }}>{translate("konebone.offer.choose_vehicle")}</span>} <EditVehicleButton record={record} deliveryVehicleWeight={deliveryVehicleWeight} saveEditVehicleWeight={saveEditVehicleWeight} /></div>
      <p>
        {record.serviceUnloading
        ? (deliveryVehicleWeight === vehicleWeightTypeEnum.WEIGHT_4T ? `(${translate("konebone.offer.without_crane")})` : `(${translate("konebone.offer.with_crane")})`)
        : `(${translate("konebone.offer.without_crane")})`}
      </p>

      <div>
        <span className="icon">
          <PointIconBottom />
        </span>
        {isHasAddress ? `${pickupStreet} ${pickupApt ? `St. ${pickupApt}` : ""}, ${pickupCity}` : <span style={{ color: "red" }}>{translate("konebone.offer.need_to_specify_address")}</span>}
        <EditAddressButton
          record={record}
          saveEditAddress={saveEditAddress}
        />
      </div>
      <p>
        <span className="icon"><PointIconTop /></span>{record.supplierInfo}
      </p>
      <Checkbox
          size="small"
          className="icon"
          checked={checked}
          onChange={changeUnloading}
        />
      {translate("konebone.orders.unloading_with_crane")}
    </div >
  )
};

const Delivery = ({ record, changesDeliveryInfo, changesUnloadingInfo, errors, setErrors, timeError }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const { deliveryOffer } = record || {};

  const [checked, setChecked] = useState(record.serviceUnloading);
  const [deliveryMode, setDeliveryMode] = useState(deliveryOffer?.deliveryMode)
  const [deliveryContactName, setDeliveryContactName] = useState(deliveryOffer?.contactName || "");
  const [deliveryContactPhone, setDeliveryContactPhone] = useState(deliveryOffer?.contactPhone || "");
  const [deliveryCost, setDeliveryCost] = useState(currency(deliveryOffer?.deliveryCost) || "");

  //Save edit vehicle weight
  const [deliveryVehicleWeight, setDeliveryVehicleWeight] = useState(deliveryOffer?.appropriateVehicleWeight || "");
  //Save edit address
  const [pickupApt, setPickupApt] = useState(deliveryOffer?.pickupApt || "");
  const [pickupCity, setPickupCity] = useState(deliveryOffer?.pickupCity || "");
  const [pickupStreet, setPickupStreet] = useState(deliveryOffer?.pickupStreet || "");

  const [date, setDate] = useState(deliveryOffer?.deliveryDate || null);
  const [time, setTime] = useState(deliveryOffer.deliveryTimeRange ? {
    label : !deliveryOffer?.deliveryTimeRange?.full ?
        `${deliveryOffer?.deliveryTimeRange?.start?.slice(0, 5)}-${deliveryOffer?.deliveryTimeRange?.end?.slice(0, 5)}`
    : 'during the working day',
    id: deliveryOffer?.deliveryTimeRange?.id
  } : "");  const [timeRanges, setTimeRanges] = useState([])

  const orderDate = record.orderDeliveryDate?.split("-");
  const orderDeliveryDate = orderDate ? `${orderDate?.[2]}/${orderDate?.[1]}/${orderDate?.[0]}` : '';
  const orderDeliveryTime = !record.orderDeliveryTimeRange?.full ?
      `${record.orderDeliveryTimeRange?.start?.slice(0, 5)}-${record.orderDeliveryTimeRange?.end?.slice(0, 5)}` :
      'during the working day';

  useEffect(()=> {
    dataProviderForOffers.getTimeRange()
        .then((item)=> {
          setTimeRanges(item.data)
        })
  }, []);

  const [deliveryContactNameError, setDeliveryContactNameError] = useState("");
  const [deliveryContactPhoneError, setDeliveryContactPhoneError] = useState("");
  const [deliveryCostError, setDeliveryCostError] = useState("");
  const [withoutDelivery, setWithoutDelivery] = useState(false);
  let isError = false;

  //-----------Changes---------------//

  const handleChange = (event) => {
    setTime(timeRanges.find(item => item.label === event.target.value));
    changesDeliveryInfo({ time: timeRanges.find(item => item.label === event.target.value).id })
  };

  const changeDate = (date) => {
    if (errors.date && date) {
      setErrors({ ...errors, date: false });
    }
    setDate(date);
    changesDeliveryInfo({ date: date })
  }

  const changeTime = (newTime) => {
    const valueDateTime = new Date(deliveryOffer?.deliveryDate).getDate()
    const valueDate = new Date(date).getDate();
    const deliveryTime = new Date(record?.orderDeliveryTime).getTime();

    if(valueDate === valueDateTime && newTime.getTime() < deliveryTime) {
      timeError.current = true;
    } else {
      timeError.current = false;
    }

    if (errors.time && newTime) {
      setErrors({ ...errors, time: false });
    }

    setTime(newTime);
    changesDeliveryInfo({ time: newTime })
  }

  const changeUnloading = () => {
    setChecked(!checked)
    changesUnloadingInfo(!checked)
  };

  const changeDeliveryContactName = (e) => {
    if (errors.contactName && e.target.value) {
      setErrors({ ...errors, contactName: false });
    }
    setDeliveryContactNameError("");
    if (Validator.validateDeliveryContactName(e.target.value)) {
      setDeliveryContactNameError(Validator.validateDeliveryContactName(e.target.value));
      isError = true;
    }
    setDeliveryContactName(e.target.value);
    changesDeliveryInfo({ contactName: e.target.value })
  }

  const changeDeliveryContactPhone = (e) => {
    if (errors.contactPhone && e.target.value) {
      setErrors({ ...errors, contactPhone: false });
    }
    setDeliveryContactPhoneError("");

    if (Validator.validateDeliveryContactPhone(e.target.value)) {
      setDeliveryContactPhoneError(Validator.validateDeliveryContactPhone(e.target.value));
      isError= true
    }
    setDeliveryContactPhone(e.target.value);
    changesDeliveryInfo({ contactPhone: e.target.value })
  }

  const changeDeliveryCost = (e) => {
    const value = e.target.value;

    let regex = /^\d+\.?\d{0,2}$/;
    if (errors.deliveryCost && value) {
      setErrors({ ...errors, deliveryCost: false });
    }
    setDeliveryCostError("");
    if (Validator.validateDeliveryCost(value)) {
      setDeliveryCostError(Validator.validateDeliveryCost(value));
      isError = true;
    }
    if (value === "") {
      setDeliveryCost(value);
      changesDeliveryInfo({ deliveryCost: value })
      return;
    }
    if (!regex.test(value)) {
      return;
    }
    setDeliveryCost(value);
    changesDeliveryInfo({ deliveryCost: +value })
  }

  //------------------------------------------------//
  //-----------------------------------------------//

  //-----------Save---------------//
  const saveEditAddress = (props) => {
    setPickupCity(props.city);
    setPickupStreet(props.street);
    setPickupApt(props.apt);

    changesDeliveryInfo({ pickupCity: props.city, pickupStreet: props.street, pickupApt: props.apt });
  }

  const saveEditVehicleWeight = (deliveryVehicleType) => {
    if (deliveryVehicleType === "4ton") {
      setUnloading(false);
    }
    setDeliveryVehicleWeight(deliveryVehicleType)
    changesDeliveryInfo({ appropriateVehicleWeight: deliveryVehicleType });
  }

  const handleOnBlurDeliveryCost = (e) => {
    validate(e);
    setDeliveryCost(deliveryCost ? currency(deliveryCost) : "");
  }

  const validate = (e) => {
    if (requiredFields.includes(e.target.id) && !e.target.value.length) {
      setErrors({ ...errors, [e.target.id]: true });
    }
  };

  const handleChangeRadio = (e) => {
    if(e.target.value === "false") {
      setWithoutDelivery(true)
      setDeliveryMode(null);
      changesDeliveryInfo({ deliveryMode: null })
      return
    }

      //Errors
      setErrors({})
      setDeliveryContactNameError("");
      setDeliveryContactPhoneError("");
      setDeliveryCostError("");

      //State
      setDeliveryContactName(record?.deliveryOffer?.contactName || "");
      setDeliveryContactPhone(record?.deliveryOffer?.contactPhone || "");
      setDeliveryCost(record?.deliveryOffer?.deliveryCost || "");
      setDeliveryVehicleWeight(record?.deliveryOffer?.appropriateVehicleWeight || "");
      setPickupApt(record?.deliveryOffer?.pickupApt || "");
      setPickupCity(record?.deliveryOffer?.pickupCity || "");
      setPickupStreet(record?.deliveryOffer?.pickupStreet || "");

      setTime(record?.deliveryOffer?.deliveryTime || null);
      setDate(record?.deliveryOffer?.deliveryTime || null);

      //Change radio
      setWithoutDelivery(false)
      setDeliveryMode(e.target.value);
      changesDeliveryInfo({ deliveryMode: e.target.value });

  }

  return (
    <FormWithRedirect
      record={record}
      render={() => (
        // here starts the custom form layout
        <form>
          <Typography variant="subtitle2" className={classes.offerFilling} gutterBottom><b>{translate("konebone.offer.delivery")}</b></Typography>
          <RadioGroup
            aria-label="gender"
            name="radio-buttons-group"
          >
          {record.status === STATUS.IN_PROGRESS &&
              <div className="delivery_box">
              <FormControlLabel
                control={<Radio />}
                label={translate("konebone.offer.without_delivery")}
                checked={withoutDelivery || deliveryMode === deliveryStatus.WITHOUT_DELIVERY}
                value={withoutDelivery}
                onChange={handleChangeRadio} />
            </div>}
            <div className="delivery_box">
              <FormControlLabel
                control={<Radio />}
                label={translate("konebone.offer.customer_delivery_time")}
                checked={deliveryMode === deliveryStatus.BY_SUPPLIER_AS_CUSTOMER_EXPECT}
                value={deliveryStatus.BY_SUPPLIER_AS_CUSTOMER_EXPECT}
                onChange={handleChangeRadio} />
              <Typography variant="body2">{`${orderDeliveryDate}; ${orderDeliveryTime}` || "-"}</Typography>
              {deliveryMode === deliveryStatus.BY_SUPPLIER_AS_CUSTOMER_EXPECT
                ?
                <Box display="flex">
                  <Box style={{ maxWidth: "830px" }}>
                    <Box display="flex">
                      <Box flex={1} mr="1em" mt="1em" mb="1em">
                        <Typography variant="body2" className={classes.delivery_label_field}>{translate("konebone.offer.delivery_contact_name")}</Typography>
                        <TextInput
                          id="contactName"
                          value={deliveryContactName}
                          onChange={changeDeliveryContactName}
                          onBlur={validate}
                          error={errors?.contactName || !!deliveryContactNameError}
                          helperText={errors?.contactName ? translate("ra.validation.required") : translate(deliveryContactNameError, { max: "100" })}
                          fullWidth
                          variant="outlined"
                          placeholder={translate("konebone.offer.delivery_contact_name")}
                          margin="none"
                        />
                      </Box>
                      <Box flex={1} mt="1em" mb="1em">
                        <Typography variant="body2" className={classes.delivery_label_field}>{translate("konebone.offer.delivery_contact_phone")}</Typography>
                        <TextInput
                          id="contactPhone"
                          value={deliveryContactPhone}
                          onChange={changeDeliveryContactPhone}
                          onBlur={validate}
                          error={errors?.contactPhone || !!deliveryContactPhoneError}
                          helperText={errors?.contactPhone ? translate("ra.validation.required") : translate(deliveryContactPhoneError, { max: "100" })}
                          fullWidth
                          variant="outlined"
                          placeholder={translate("konebone.offer.delivery_contact_phone")}
                          margin="none"
                        />
                      </Box>
                    </Box>
                    <Typography variant="body2" className={classes.delivery_label_field}>{translate("konebone.offer.delivery_cost")}</Typography>
                    <TextInput
                      id="deliveryCost"
                      value={deliveryCost}
                      onChange={changeDeliveryCost}
                      onBlur={handleOnBlurDeliveryCost}
                      error={errors?.deliveryCost || !!deliveryCostError}
                      helperText={errors?.deliveryCost ? translate("ra.validation.required") : translate(deliveryCostError, { max: "100" })}
                      fullWidth
                      variant="outlined"
                      placeholder={translate("konebone.offer.delivery_cost")}
                      margin="none"
                    />
                  </Box>
                  <Box>
                    <Checkbox
                      size="small"
                      className="icon"
                      checked={checked}
                      onChange={changeUnloading}
                    />
                    {translate("konebone.orders.unloading_with_crane")}
                  </Box>
                </Box>
                :
                <div></div>
              }
            </div>
            <div className="delivery_box">
              <FormControlLabel
                // value="specific_delivery_time"
                control={<Radio />}
                checked={deliveryMode === deliveryStatus.BY_SUPPLIER_WITH_DELAY}
                label={translate("konebone.offer.specific_delivery_time")}
                value={deliveryStatus.BY_SUPPLIER_WITH_DELAY}
                onChange={handleChangeRadio} />
              <Typography variant="body2">{translate("konebone.offer.select_date_time")}</Typography>
              {deliveryMode === deliveryStatus.BY_SUPPLIER_WITH_DELAY
                ?
                <Box display="flex">
                  <Box style={{ maxWidth: "830px" }}>
                    <Box display="flex">
                      <Box flex={1} mr="1em" mt="1em">
                        <Typography variant="body2" className={classes.delivery_label_field}>{translate("konebone.offer.date")}</Typography>
                        <KeyboardDatePicker
                          id="date"
                          format={"MMM dd, yyyy"}
                          clearable
                          inputVariant="outlined"
                          value={date}
                          placeholder={translate("konebone.offer.pick_a_date")}
                          onChange={date => changeDate(date)}
                          style={{ width: "100%" }}
                          autoOk="false"
                          keyboardIcon={<DateIcon />}
                          onBlur={validate}
                          error={errors?.date}
                          helperText={errors?.date && translate("ra.validation.required")}
                          disablePast={true}
                          InputProps={{ readOnly: true }}
                          margin="none"
                          minDate={deliveryOffer.deliveryTime}
                        />
                      </Box>
                      <Box flex={1} mt="1em">
                        <Typography variant="body2" className={classes.delivery_label_field}>{translate("konebone.offer.time")}</Typography>
                        <Select
                            className={classNames('time-range1', !time?.label && 'placeholder-time')}
                            variant='standard'
                            placeholder={translate("konebone.offer.pick_a_time")}
                            IconComponent = {AccessTimeIcon}
                            labelId="demo-simple-select-label"
                            id="time"
                            value={time ? time.label : null}
                            disableUnderline
                            required
                            onChange={handleChange}
                            displayEmpty
                            renderValue={(value) => (value ? value : translate("konebone.offer.pick_a_time"))}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                              },
                              getContentAnchorEl: null,
                              classes: {
                                paper: "time-range-item-offer",
                              },
                            }}
                        >
                          {timeRanges.map((item) => (
                              <MenuItem value={item.label} key={item.id}>{item.label}</MenuItem>
                          ))}
                        </Select>
                      </Box>
                    </Box>
                    <Box display="flex" mt="1em" mb="1em">
                      <Box flex={1} mr="1em">
                        <Typography variant="body2" className={classes.delivery_label_field}>{translate("konebone.offer.delivery_contact_name")}</Typography>
                        <TextInput
                          id="contactName"
                          value={deliveryContactName}
                          onChange={changeDeliveryContactName}
                          onBlur={validate}
                          error={errors?.contactName || !!deliveryContactNameError}
                          helperText={errors?.contactName ? translate("ra.validation.required") : translate(deliveryContactNameError, { max: "100" })}
                          fullWidth
                          variant="outlined"
                          placeholder={translate("konebone.offer.delivery_contact_name")}
                          margin="none"
                        />
                      </Box>
                      <Box flex={1} >
                        <Typography variant="body2" className={classes.delivery_label_field}>{translate("konebone.offer.delivery_contact_phone")}</Typography>
                        <TextInput
                          id="contactPhone"
                          value={deliveryContactPhone}
                          onChange={changeDeliveryContactPhone}
                          onBlur={validate}
                          error={errors?.contactPhone || !!deliveryContactPhoneError}
                          helperText={errors?.contactPhone ? translate("ra.validation.required") : translate(deliveryContactPhoneError, { max: "100" })}
                          fullWidth
                          variant="outlined"
                          placeholder={translate("konebone.offer.delivery_contact_phone")}
                          margin="none"
                        />
                      </Box>
                    </Box>
                    <Typography variant="body2" className={classes.delivery_label_field}>{translate("konebone.offer.delivery_cost")}</Typography>
                    <TextInput
                      id="deliveryCost"
                      value={deliveryCost}
                      onChange={changeDeliveryCost}
                      onBlur={handleOnBlurDeliveryCost}
                      error={errors?.deliveryCost || !!deliveryCostError}
                      helperText={errors?.deliveryCost ? translate("ra.validation.required") : translate(deliveryCostError, { max: "100" })}
                      fullWidth
                      variant="outlined"
                      required
                      placeholder={translate("konebone.offer.delivery_cost")}
                      margin="none"
                    />               
                  </Box>
                  <Box>
                    <Checkbox
                      size="small"
                      className="icon"
                      checked={checked}
                      onChange={changeUnloading}
                    />
                    {translate("konebone.orders.unloading_with_crane")}
                  </Box>
                </Box>
                :
                <div></div>
              }
            </div>
            <div className="delivery_box">
              <FormControlLabel
                // value="by_konebone"
                control={<Radio />}
                label={translate("konebone.offer.delivery_by_konebone")}
                checked={deliveryMode === deliveryStatus.BY_KONEBONE}
                value={deliveryStatus.BY_KONEBONE}
                onChange={handleChangeRadio} />
              <Typography variant="body2" >{translate("konebone.offer.delivery_will_be_handled_by_konebone")}</Typography>
              {deliveryMode === deliveryStatus.BY_KONEBONE
                ?
                
                <Box display="flex">
                  <Box flex={2} mr="2em">
                    <Box display="flex" mt="1em">
                      <Box flex={1} mr="1em">
                        <Typography variant="body2" className={classes.delivery_label_field}>{translate("konebone.offer.date")}</Typography>
                        <KeyboardDatePicker
                          id="date"
                          clearable
                          format={"MMM dd, yyyy"}
                          inputVariant="outlined"
                          value={date}
                          placeholder={translate("konebone.offer.pick_a_date")}
                          onChange={date => changeDate(date)}
                          style={{ width: "100%" }}
                          autoOk="false"
                          keyboardIcon={<DateIcon />}
                          onBlur={validate}
                          error={errors?.date}
                          helperText={errors?.date && translate("ra.validation.required")}
                          disablePast={true}
                          InputProps={{ readOnly: true }}
                          margin="none"
                          minDate={deliveryOffer.deliveryTime}
                        />
                      </Box>
                      <Box flex={1}>
                        <Typography variant="body2" className={classes.delivery_label_field}>{translate("konebone.offer.time")}</Typography>
                        <Select
                            className={classNames('time-range2', !time?.label && 'placeholder-time')}
                            variant='standard'
                            placeholder={translate("konebone.offer.pick_a_time")}
                            IconComponent = {AccessTimeIcon}
                            labelId="demo-simple-select-label"
                            id="time"
                            value={time ? time.label : null}
                            disableUnderline
                            required
                            onChange={handleChange}
                            displayEmpty
                            renderValue={(value) => (value ? value : translate("konebone.offer.pick_a_time"))}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                              },
                              getContentAnchorEl: null,
                              classes: {
                                paper: "time-range-item-offer",
                              },
                            }}
                        >
                          {timeRanges.map((item) => (
                              <MenuItem value={item.label} key={item.id}>{item.label}</MenuItem>
                          ))}
                        </Select>
                      </Box>
                    </Box>
                    <Box display="flex" mt="1em" mb="1em">
                      <Box flex={1} mr="1em">
                        <Typography variant="body2" className={classes.delivery_label_field}>{translate("konebone.offer.delivery_contact_name")}</Typography>
                        <TextInput
                          id="contactName"
                          value={deliveryContactName}
                          onChange={changeDeliveryContactName}
                          onBlur={validate}
                          error={errors?.contactName || !!deliveryContactNameError}
                          helperText={errors?.contactName ? translate("ra.validation.required") : translate(deliveryContactNameError, { max: "100" })}
                          fullWidth
                          variant="outlined"
                          placeholder={translate("konebone.offer.delivery_contact_name")}
                          margin="none"
                        />
                      </Box>
                      <Box flex={1} >
                        <Typography variant="body2" className={classes.delivery_label_field}>{translate("konebone.offer.delivery_contact_phone")}</Typography>
                        <TextInput
                          id="contactPhone"
                          value={deliveryContactPhone}
                          onChange={changeDeliveryContactPhone}
                          onBlur={validate}
                          error={errors?.contactPhone || !!deliveryContactPhoneError}
                          helperText={errors?.contactPhone ? translate("ra.validation.required") : translate(deliveryContactPhoneError, { max: "100" })}
                          fullWidth
                          variant="outlined"
                          placeholder={translate("konebone.offer.delivery_contact_phone")}
                          margin="none"
                        />
                      </Box>
                    </Box>
                    <Typography variant="body2" className={classes.delivery_label_field}>{translate("konebone.offer.delivery_cost")}</Typography>
                    <TextInput
                      id="deliveryCost"
                      value={deliveryCost}
                      onChange={changeDeliveryCost}
                      onBlur={handleOnBlurDeliveryCost}
                      error={errors?.deliveryCost || !!deliveryCostError}
                      helperText={errors?.deliveryCost ? translate("ra.validation.required") : translate(deliveryCostError, { max: "100" })}
                      fullWidth
                      variant="outlined"
                      placeholder={translate("konebone.offer.delivery_cost")}
                      margin="none"
                    />
                  </Box>
                  <Box flex={1} style={{ alignSelf: "self-end" }}>
                    <DeliveryInfo
                      record={record}
                      changeUnloading={changeUnloading}
                      checked={checked}
                      deliveryVehicleWeight={deliveryVehicleWeight}
                      saveEditVehicleWeight={saveEditVehicleWeight}
                      saveEditAddress={saveEditAddress}
                      pickupStreet={pickupStreet}
                      pickupCity={pickupCity}
                      pickupApt={pickupApt}
                    />
                  </Box>
                </Box>
                :
                <div></div>
              }
            </div>

          </RadioGroup >
        </form >
      )}
    />
  )
}

const ProductQuantityClicker = ({ id, mainQuantity, changeQuantity }) => {
  const handleClick = (operation) => {
    if (OPERATION_ADD === operation) {
      changeQuantity(id, mainQuantity + 1)
    } else {
      mainQuantity > 1
          ? changeQuantity(id, mainQuantity - 1)
          : null
    }
  }

  return (
      <div className="quantity-box__quantity-element">
        <IconButton onClick={() => handleClick(OPERATION_SUBTRACT)}><RemoveIcon /></IconButton>
        <span>{mainQuantity}</span>
        <IconButton onClick={() => handleClick(OPERATION_ADD)}><AddIcon /></IconButton>
      </div>
  )
}


const ProductListRow = ({
  row,
  id,
  handleCheckOne,
  changesProducts,
  handleRemoveAlternative,
  supplierId,
  supplierName,
  handleAddAlternative,
  removeProductFromOrder,
  status,
}) => {
  const classes = useStyles();
  const translate = useTranslate();
  const history = useHistory();
  const location = useLocation();
  const [quantity, setQuantity] = useState(currency(row.quantity).value);
  const [supplierPricePerItem, setSupplierPricePerItem] = useState(currency(row.supplierPricePerItem).value);
  const [isAlternativeBtn, setIsAlternativeBtn] = useState(false);
  const [openDialogAddAltProduct, setOpenDialogAddAltProduct] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  useEffect(() => {
    if (row.supplierPricePerItem && +supplierPricePerItem !== row.supplierPricePerItem.value) {
      setSupplierPricePerItem(row.supplierPricePerItem?.value);
    }
  }, [row.supplierPricePerItem])

  const changeQuantity1 = (id, quantity) => {
    if (quantity < 1) {
      return;
    }
    const newProducts = {...row};
    if(newProducts.quantity.value){
      newProducts.quantity.value = quantity;
    } else newProducts.quantity = quantity;

    setQuantity(quantity);
    changesProducts(id, "quantity", quantity);
    let sum = row.supplierPriceTotal;
    sum *= quantity;
    changesProducts(id, "supplierPriceTotal", sum);
    recalculateKbPriceForProduct(newProducts, quantity);
  };

  const recalculateKbPriceForProduct = (product, quantity) => {
    product.maxKpPriceWithQuantity = product.maxKbPrice * (quantity);
    product.minKpPriceWithQuantity = product.minKbPrice * (quantity);
  };

  const changeQuantity = (e) => {
    const value = e.target.value;
    let regex = /^\d+\.?\d{0,3}$/;

    if (value === "") {
      setQuantity(value);
      return;
    }
    if (!regex.test(value)) {
      return;
    }
    if (value < 1) {
      setQuantity(1);
      changesProducts(id, "quantity", 1);
      return;
    }
  
    if (!row?.alternativeProduct && +value > +row.initialQuantity) {
      setQuantity(row.initialQuantity.split(".")[0]);
      changesProducts(id, "quantity", row.initialQuantity);
      return;
    }
    setQuantity(value);
    changesProducts(id, "quantity", value);
  }

  const changePricePerItem = (e) => {
    const value = e.target.value;
    let regex = /^\d+\.?\d{0,2}$/;
    if (value === "") {
      setSupplierPricePerItem(value);
      return;
    }
    if (!regex.test(value)) {
      return;
    }
    setSupplierPricePerItem(value);
    changesProducts(id, "supplierPricePerItem", value)
  }

  const handleOnBlurPricePerItem = (e) => {
    setSupplierPricePerItem(supplierPricePerItem ? currency(supplierPricePerItem).value : currency(1).value);
  }

  const handleOnBlurQuantity = (e) => {
    setQuantity(quantity ? currency(quantity).value : currency(1).value);
  }

  const clickAlternativeBtnMinus = () => {
    setOpenConfirmDialog(true);
  }

  const handleDialogClose = () => {
    setOpenConfirmDialog(false);
  }

  const handleConfirm = () => {
    handleRemoveAlternative(id);
    setOpenConfirmDialog(false);
  }

  const clickAlternativeBtn = () => {
    setIsAlternativeBtn(!isAlternativeBtn);
    setOpenDialogAddAltProduct(true);
  }

  const closeAlternativeDialog = (newProduct) => {
    setOpenDialogAddAltProduct(false);
    if (newProduct) {
      handleAddAlternative(id, newProduct);
    }
  }

  const createNewProduct = () => {
    history.push(`${location.pathname}/product/${row.productId}`);
  }

  const ProductAvatarInfo = ({ baseLineProduct = true }) => {
    return (
      <>
        {
          baseLineProduct
            ?
            <ListItem alignItems="flex-start" style={{ height: "90px" }} >
              <ListItemAvatar className={classes.avatar}>
                <>
                  <Avatar alt="product picture" src={row.fileName || NoImage} />
                  {row?.alternativeProduct && row.isChecked && <div className="out_of_stock" style={{ height: "25px" }}>לא במלאי</div>}
                  {!row.isChecked && <div className="out_of_stock" style={{ height: "25px" }}>לא במלאי</div>}
                </>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <div style={row.isChecked ? { opacity: "1" } : { opacity: "0.6" }} >
                    <div className="code">
                      <span className="product_number">{row.catalogNumber || row.productId}</span>
                      <span className="supplier_number" style={{ paddingRight: "0" }}>ט"קמ</span>
                      <span className="supplier_number">{row.supplierPn}</span>
                      <p className={classes.desc}>{row.name}</p>
                    </div>
                  </div>
                }
              />
            </ListItem >
            :
            <ListItem alignItems="flex-start" style={{ height: "90px" }}>
              <ListItemAvatar className={classes.avatar} style={{ position: "relative" }} >
                <Avatar alt="product picture" src={row.alternativeProduct.fileName || NoImage} />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <div style={row.isChecked ? { opacity: "1" } : { opacity: "0.6" }}>
                    <div className="code">
                      <span className={`product_number ${row.alternativeProduct.isNew ? classes.new_product : ""}`}>
                        {row.alternativeProduct.isNew ? translate("konebone.offer.new_product") : row.alternativeProduct.catalogNumber || row.alternativeProduct.productId}
                      </span>
                      <span className="supplier_number" style={{ paddingRight: "0" }}>ט"קמ</span>
                      <span className="supplier_number">{row.alternativeProduct.supplierPn}</span>
                      <p className={classes.desc}>{row.alternativeProduct.name}</p>
                    </div>
                  </div>
                }
              />
            </ListItem>
        }
      </>
    )
  }
  return (
    <>
      <TableRow key={row.catalogNumber}>
        <TableCell style={{ position: "relative", minWidth: "40px", paddingLeft: "17px", border: row?.alternativeProduct ? "none" : "" }} align="left">
          {row?.alternativeProduct
            ? <div className="checkbox_products_list"></div>
            : <Checkbox size="small" className="checkbox_products_list" checked={row.isChecked} onChange={(e) => handleCheckOne(id, e.target.checked)} />
          }
        </TableCell>
        <TableCell align="left" style={row?.alternativeProduct && { opacity: "0.6" }}>
          <ProductAvatarInfo />
        </TableCell>
        {
          row?.alternativeProduct ?
            <>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </>
            :
            <>
              <TableCell>
                <Box display="flex" flexDirection="column">
                  <Typography variant="body2" style={(row.isChecked ? { opacity: "1" } : { opacity: "0.6" })}>Quantity</Typography>
                  {status !== STATUS.ACCEPTED ? (
                  <TextInput
                    value={quantity}
                    placeholder="1"
                    disabled={!row.isChecked}
                    onChange={changeQuantity}
                    onBlur={handleOnBlurQuantity}
                    className={classes.input_style}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      endAdornment: <InputAdornment position="end" style={{ color: "rgba(0, 0, 0, 0.54)"}}>of {row?.initialQuantity?.split(".")[0]}/items</InputAdornment>,
                    }}
                  />
                      ) : (
                      <ProductQuantityClicker id={id} mainQuantity={quantity || DEFAULT_QUANTITY} changeQuantity={changeQuantity1} />
                  )}
                </Box>
              </TableCell>
              <TableCell>
                <Box display="flex" flexDirection="column">
                <Typography variant="body2" style={(row.isChecked ? { opacity: "1" } : { opacity: "0.6" })}>Price</Typography>
                  <TextInput
                    value={supplierPricePerItem}
                    placeholder="1"
                    onBlur={handleOnBlurPricePerItem}
                    className={classes.input_style}
                    disabled={!row.isChecked}
                    variant="outlined"
                    onChange={changePricePerItem}
                    fullWidth
                    InputProps={{
                      endAdornment: <InputAdornment position="end" style={{ color: "rgba(0, 0, 0, 0.54)" }}>₪/item</InputAdornment>,
                    }}
                  />
                </Box>
              </TableCell>
              <TableCell>
                <Box display="flex" flexDirection="column">
                <Typography variant="button" display="block" style={(row.isChecked ? { opacity: "1" } : { opacity: "0.6" }, { textTransform: "capitalize" })}>Sum</Typography>
                  <Typography variant="body2" style={{ whiteSpace: "nowrap", opacity: row.isChecked ? "1" : "0.6" }}>{ILS(row.supplierPriceTotal).format()}</Typography>
                </Box>
              </TableCell>
            </>
        }
        {row.alternativeProduct
          ? <TableCell style={{ border: "none" }} className={classes.alternative_box}>
            <div className={classes.replaced_box}>
              <Button
                variant="outlined"
                className="bnt_alternative"
                disabled={!row.isChecked}
                color="secondary"
                startIcon={<MinusIcon />}
                style={(row.isChecked ? { opacity: "1" } : { opacity: "0.6", color: "#E02443", borderColor: "#E02443" })}
                onClick={clickAlternativeBtnMinus}>
                {translate("konebone.offer.alternative")}
              </Button>
            </div>
          </TableCell>
          : <TableCell>
            {row.isReadyForAlternatives
              ?
              <div style={{display: "flex"}}>
                <Button
                  variant="outlined"
                  className="bnt_alternative"
                  disabled={!row.isChecked}
                  color="secondary"
                  startIcon={<PlusIcon />}
                  style={row.isChecked ? { opacity: "1" } : { opacity: "0.6", color: "#E02443", borderColor: "#E02443" }}
                  onClick={clickAlternativeBtn}>
                  {translate("konebone.offer.alternative")}
                </Button>
              </div>
              :
              <div style={{display: "flex"}}>
                <Tooltip
                  title={translate("konebone.offer.info_btn")}
                  placement="top"
                  style={{ background: "#fff" }}
                >
                  <Button
                    variant="contained"
                    disableElevation
                    className="bnt_not_alternative"
                    disabled={!row.isChecked}
                    startIcon={<InfoIcon />}
                    style={(row.isChecked ? { opacity: "1" } : { opacity: "0.6" }, { textTransform: "capitalize" })}>
                    {translate("konebone.offer.alternative")}
                  </Button>
                </Tooltip>
                { !row.originalQuantity &&
                  <Button
                    startIcon={<CloseIcon />}
                    onClick={(e) => removeProductFromOrder(row.productId)}>
                  </Button>
                }
             </div>
            }
          </TableCell>
        }
      </TableRow>
      {
        row?.alternativeProduct &&
        <TableRow key={`${row.konebonePn}_alt`} style={{ position: "relative" }}>
          <TableCell style={{ position: "relative", minWidth: "40px", paddingLeft: "17px" }}>
            <Checkbox size="small" className="checkbox_products_list alternative" disabled checked={row.isChecked} onChange={(e) => handleCheckOne(id, e.target.checked)} />
          </TableCell>
          <TableCell align="left">
            <ProductAvatarInfo baseLineProduct={false} />
          </TableCell>
          {/* //Quantity Price Sum */}
          <>
            <TableCell>
              <Box display="flex" flexDirection="column">
                <Typography variant="body2" style={row.isChecked ? { opacity: "1" } : { opacity: "0.6" }}>Quantity</Typography>
                {status !== STATUS.ACCEPTED ? (
                <TextInput
                  value={quantity}
                  placeholder="1"
                  disabled={!row.isChecked}
                  onChange={changeQuantity}
                  onBlur={handleOnBlurQuantity}
                  className={classes.input_style}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    endAdornment: <InputAdornment position="end" style={{ color: "rgba(0, 0, 0, 0.54)" }}>of {row.initialQuantity.split(".")[0]}/items</InputAdornment>,
                  }}
                />
                ) : (
                    <ProductQuantityClicker id={id} mainQuantity={quantity || DEFAULT_QUANTITY} changeQuantity={changeQuantity1} />
                )}
              </Box>
            </TableCell>
            <TableCell>
              <Box display="flex" flexDirection="column">
                <Typography variant="body2" style={row.isChecked ? { opacity: "1" } : { opacity: "0.6" }}>Price</Typography>
                <TextInput
                  value={supplierPricePerItem}
                  onBlur={handleOnBlurPricePerItem}
                  placeholder="1"
                  disabled={!row.isChecked}
                  variant="outlined"
                  onChange={changePricePerItem}
                  className={classes.input_style}
                  fullWidth
                  InputProps={{
                    endAdornment: <InputAdornment position="end" style={{ color: "rgba(0, 0, 0, 0.54)" }}>₪/item</InputAdornment>,
                  }}
                />
              </Box>
            </TableCell>
            <TableCell>
              <Box display="flex" flexDirection="column">
              <Typography variant="button" display="block" style={(row.isChecked ? { opacity: "1" } : { opacity: "0.6" }, { textTransform: "capitalize" })}>Sum</Typography>
                <Typography variant="body2" style={{ whiteSpace: "nowrap", opacity: row.isChecked ? "1" : "0.6" }}>{ILS(row.supplierPriceTotal).format()}</Typography>
              </Box>
            </TableCell>
          </>
          <TableCell className={classes.alternative_box}>
            {row.alternativeProduct && row.alternativeProduct.isNew
              &&
              <div>
                <Button
                  variant="contained"
                  color="secondary"
                  className="bnt_alternative"
                  disabled={!row.isChecked}
                  style={row.isChecked ? { opacity: "1" } : { opacity: "0.6" }}
                  onClick={createNewProduct}>
                  {translate("konebone.offer.create_new_product")}
                </Button>
              </div>
            }
          </TableCell>
        </TableRow >
      }
      <AddAlternativeProductDialog
        open={openDialogAddAltProduct}
        onClose={closeAlternativeDialog}
        row={row}
        supplierId={supplierId}
        supplierName={supplierName}
      />
      <Confirm
        isOpen={openConfirmDialog}
        title=""
        content={translate("confirm_messages.offer.remove_alternative")}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  )
}

const ProductListInOffer = ({
  offerId, removeProductFromOrder, products, handleCheckOne, changesProducts, handleRemoveAlternative, supplierId, supplierName, handleAddAlternative, status,
}) => {
  const classes = useStyles();

  if (!products?.length) {
    return <div></div>;
  }

  return (
    <div className="table-responsive">
      <Table >
        <TableBody className={classNames(classes.list, "table_products")}>
          {products.map((row, index) => {
            return <ProductListRow
              status={status}
              key={index}
              row={row}
              id={index}
              offerId={offerId}
              handleCheckOne={handleCheckOne}
              changesProducts={changesProducts}
              handleRemoveAlternative={handleRemoveAlternative}
              handleAddAlternative={handleAddAlternative}
              removeProductFromOrder={removeProductFromOrder}
              supplierId={supplierId}
              supplierName={supplierName}
            />
          })}
        </TableBody>
      </Table>
    </div>
  )
};

const FooterOfferEditDetails = ({ record, addProductFromOrder }) => {
  const [filterData, setFilterData] = useState('');
  const [open, setOpen] = useState(false)
  const [products, setProducts] = useState([])
  const [allProducts, setAllProducts] = useState(record.products);
  const translate = useTranslate();
  const notify = useNotify();
  const classes = useStyles();

  const onSubmit = () => {
    setFilterData("")
    setOpen(true)
    handleSearch();
  };


  const handleSearch = () => {
    if(filterData.length < 10) {
      setOpen(false)
      return
    }
    if(filterData.length > 10) {
     dataProviderForProducts.searchOffer("product", {
        offerId: record.offerId,
        search: filterData
      })
      .then((item) => {
        if(item.data.catalogNumber.includes(products.catalogNumber)){
            notify('This catalog number exist', 'warning')
          } else {
            setProducts(item.data)
          }  
        })
      .catch ((error) => {
        if (!error.response) {
          console.error(error);
          return;
        }
        let { status } = error.response;
        if (status === 401 || status === 403) {
          cleanSession();
          window.location.href = "/login";
        }
        if (status === 500) {
          notify(
            error.response.data?.error,
            "warning"
          )
        }
        notify(
          error.response.data?.message?.message,
          "warning",
          wrapArrayToMap(error.response.data?.message?.parameters)
        );
      });
    }
  };

  const handleFilterValue = (e) => {
    e.target?.value ? (setFilterData(e.target.value), setProducts(e.target.value)) : onSubmit("")
  }

  const handleAddProduct = (products) => {
    const index = record.products.find(item => 
     item.catalogNumber === products.catalogNumber
    )

    if (!index) {
      setAllProducts({...allProducts, ...products})
      addProductFromOrder(products) 
    } else {
      notify('This catalog number exist', 'warning')
    }
    
    setProducts("");
    setOpen(false);
    setFilterData("");
  }

  const SearchProduct = ({ products }) => {
    const { catalogNumber, fileName, name,  kbPricePerItem , orderQuantity  } = products;

    if(!orderQuantity) {
      notify("Adding a product is not possible. This product does not exist in the order for this offer.", "warning")
      return (
        <div className={classes.searchResult}>
        <div className="product_not_found">
          <div className="container">
            <div className="product_not_found_box">
              <div className="banner">
              </div>
              <Typography className="header">
                {translate('konebone.offer.no_search_results_for')} &quot;<span className="red">{catalogNumber}</span>&quot;
              </Typography>
              <Typography>{translate('konebone.offer.no_search_text_alternative')}</Typography>
            </div>
          </div>
        </div>
      </div>
      )
    } else {
      return (
      <>
      <ListItem>
        <ListItemAvatar className={classes.avatar}>
          <Avatar alt="product picture" src={fileName || NoImage} />
        </ListItemAvatar>
        <ListItemText
          primary={
            <p className="code">
              <span className="product_number">{catalogNumber}</span>
            </p>
          }
          secondary={
            <div className="search-product">
              <p className="search-product__p-name">{name}</p>
              <div className="search-product__btn">
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => handleAddProduct(products)}
                  label={translate("ra.action.add")}
                >
                  {translate("ra.action.add")}
                </Button>
              </div>
              <div className="search-product__price">{ILS(kbPricePerItem).format()}</div>
            </div>
          }
        />
      </ListItem >
      </>
      )
    }
  }
  return (
    <div className="footer-order-edit">
      <Form onSubmit={onSubmit}>
        {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <TextInput
                helperText={false}
                source="q"
                label={translate("konebone.orders.search_by_catalog_number")}
                color="secondary"
                variant="outlined"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <SearchIcon color="disabled" />
                    </InputAdornment>
                  )
                }}
                value={filterData}
                onChange={(event) => handleFilterValue(event)}
              />
            { open && products && products?.catalogNumber ? (
                  <List>
                    <SearchProduct products={products} />
                  </List>
              ) : open && filterData.length ? (
                <div className={classes.searchResult}>
                  <div className="product_not_found">
                    <div className="container">
                      <div className="product_not_found_box">
                        <div className="banner">
                          <span />
                        </div>
                        <p className="header">
                          {translate('konebone.offer.no_search_results_for')} &quot;<span className="red">{filterData}</span>&quot;
                        </p>
                        <p>{translate('konebone.offer.no_search_text_alternative')}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )
            : null
           }
        </form>)}
        </Form>
      {/* <div className="footer-order-edit__total-price">
        <div>
          {translate("konebone.orders.estimated_total")}
        </div>
        <div className="footer-order-edit__price">
          <Typography variant="button" display="block" style={{ fontWeight: "600" }}>{ILS(kbPricePerItem).format()}</Typography>
        </div>
      </div> */}
    </div>
  )
}


const TotalBoxPrices = ({ discount, changeDiscount, kbPrice, changeKbPrice, totals, record, totalPriceForCustomer }) => {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
      <div className={classes.total}>
        <div>
          <div>{translate("konebone.offer.supplier_price")}</div>
          <div></div>
          <div>{ILS(totals.supplierPrice).format()}</div>
        </div>
        <div>
          <div><span >{translate("konebone.offer.discount")}</span></div>
          <div>
            <TextInput
              className="percent_box"
              value={discount}
              placeholder="0.0"
              variant="outlined"
              width="40px"
              onChange={changeDiscount}
              InputProps={{
                endAdornment: <InputAdornment position="end">&#37;</InputAdornment>,
              }}
            />
          </div>
          <div>{ILS(totals.priceWithDiscount).format()}</div>
        </div>
        <div>
          <div>{translate("konebone.offer.kb_price")}</div>
          <div >
            <TextInput
              className="percent_box"
              placeholder="0.0"
              value={kbPrice}
              variant="outlined"
              width="40px"
              onChange={changeKbPrice}
              InputProps={{
                endAdornment: <InputAdornment position="end">&#37;</InputAdornment>,
              }}
            />
          </div>
          <div>{ILS(totals.kbPriceCalc).format()}</div>
        </div>
        <div>
          <div>{translate("konebone.offer.kb_price_discount")}</div>
          <div></div>
          <div>{ILS(totals.kbPriceWithDiscountCalc).format()}</div>
        </div>
        <div>
          <div>{translate("konebone.offer.gross_profit")}</div>
          <div></div>
          <div>{ILS(totals.grossProfit).format()}</div>
        </div>
        <div>
          <div>{translate("konebone.offer.delivery_price")}</div>
          <div></div>
          <div>{ILS(totals.deliveryCost).format()}</div>
        </div>
        <div>
          <div><b>{translate("konebone.offer.subtotal")}</b> {translate("konebone.offer.without_vat")}</div>
          <div></div>
          <div style={{ fontWeight: "600" }}>{ILS(totals.subtotalWithoutVat).format()}</div>
        </div>
        <hr/>
        <div style={{borderTop: '1px solid #E2E1E1'}} >
          <div><b>{translate( "konebone.offer.vat")}</b> (17%)</div>
          <div></div>
          <div>{ILS(totals.vat).format()}</div>
        </div>
        <div>
          <div><b>{record.status !== STATUS.ACCEPTED ? translate("konebone.offer.total") : 'New total'}</b></div>
          <div></div>
          <div><b>{ILS(totals.totalPriceForCustomer).format()}</b></div>
        </div>
        <hr/>
        {record.status === STATUS.ACCEPTED && (
        <div style={{color: '#E02443'}}>
          <div><b>Paid</b></div>
          <div></div>
          <div><b>{ILS(record.paidTotal).format()}</b></div>
        </div>
        )}
      </div>
    </Box>
  )
}


export { ProductListInOffer, TotalBoxPrices, OfferDetailHeader, FooterOfferEditDetails, Delivery };
