import React from "react";
import {
  useVersion,
} from "react-admin";
import SupplierCreateActionComponent from "./SupplierCreateActionComponent";

const ShowCustomerTitle = ({ record }) => {
  return <span>{record?.name?.toUpperCase()}</span>;
};

const SupplierCreate = (props) => {
  const version = useVersion();

  return (
    <div key={version}>
      <h1><ShowCustomerTitle /></h1>
      <SupplierCreateActionComponent basePath="/supplier" resource="supplier" record={props} />
    </div>
  );
};

export default SupplierCreate;