export const STATUS = {
  NEW: "NEW",
  IN_REVIEW: "IN_REVIEW",
  IN_PROGRESS: "IN_PROGRESS",
  ACCEPTED: "ACCEPTED",
  DECLINED: "DECLINED",
  COMPLETED: "COMPLETED",
  CANCELED: "CANCELED",
  DELIVERED: "DELIVERED",
  WAITING_FOR_PAYMENT: "WAITING_FOR_PAYMENT",
  AWAITING_DELIVERY: "AWAITING_DELIVERY",
  PACKING_SLIP_VERIFIED: "PACKING_SLIP_VERIFIED",
  DEFAULT: "DEFAULT",
  TIME_IS_OUT: "TIME_IS_OUT"
};

export const statusColorMap = {
  NEW: "#FDF6BC",
  IN_REVIEW: "#F0D9FF",
  IN_PROGRESS: "#C9E2D5",
  AWAITING_DELIVERY: "#EDEAFF",
  COMPLETED: "#FFD6DC",
  TIME_IS_OUT: "#FFFFFF",
  DECLINED: "#E9E9E9",
  DELIVERED: "#FFE7C2",
  CANCELED: "#FFD6DC",
  PACKING_SLIP_VERIFIED: "#D7EDFE",
};

export const offerStatuses = {
  DEFAULT: "Default",
  IN_PROGRESS: "In progress",
  COMPLETED: "Completed",
  CANCELED: "Canceled",
  WAITING_FOR_PAYMENT: "Waiting for payment",
  IN_REVIEW: "In review",
  AWAITING_DELIVERY: "Awaiting delivery",
  PACKING_SLIP_VERIFIED: 'Packing slip verify',
  DECLINED: "Declined",
  ACCEPTED: "Accepted",
  DELIVERED: "Delivered",
};

export const offerStatusColorMap = {
  DEFAULT: "#FEF7DB",
  IN_PROGRESS: "#DFF9E0",
  COMPLETED: "#E5F3FE",
  CANCELED: "#FBE3E7",
  WAITING_FOR_PAYMENT: "#FBE3E7",
  AWAITING_DELIVERY: "#EDEAFF",
  ACCEPTED: "#DFF9E0",
  DECLINED: "#FBE3E7",
  IN_REVIEW: "#F4E5FE",
  DELIVERED: "#FFE7C2",
  PACKING_SLIP_VERIFIED:"#D7EDFE", 
};

export const deliveryColorStatus = {
  BY_KONEBONE: "#FBE3E7",
  BY_SUPPLIER_WITH_DELAY: "#F7F8FA",
  BY_SUPPLIER_AS_CUSTOMER_EXPECT: "#F7F8FA",
};

export const deliveryStatus = {
  BY_KONEBONE: "BY_KONEBONE",
  BY_SUPPLIER_WITH_DELAY: "BY_SUPPLIER_WITH_DELAY",
  BY_SUPPLIER_AS_CUSTOMER_EXPECT: "BY_SUPPLIER_AS_CUSTOMER_EXPECT",
  WITHOUT_DELIVERY: "WITHOUT_DELIVERY"
};

export const deliveryRequiredFields = {
  BY_KONEBONE: ["contactName", "contactPhone", "deliveryCost", "deliveryDate", "time", "pickupCity","pickupStreet","appropriateVehicleWeight"],
  BY_SUPPLIER_WITH_DELAY: ["contactName", "contactPhone", "deliveryCost", "deliveryDate", "time"],
  BY_SUPPLIER_AS_CUSTOMER_EXPECT: ["contactName", "contactPhone", "deliveryCost"],
};
export const vehicleWeightTypeEnum = {
  WEIGHT_4T: "WEIGHT_4T",
  WEIGHT_8T: "WEIGHT_8T",
  WEIGHT_12T: "WEIGHT_12T",
  WEIGHT_15T: "WEIGHT_15T",
};
export const vehicleWeightTypeValue = {
  WEIGHT_4T: "4t",
  WEIGHT_8T: "8t",
  WEIGHT_12T: "12t",
  WEIGHT_15T: "15t",
};

export const deliveryTimeColor = {
  true: "#E02443",
  false: "#059202",
};

