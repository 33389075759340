import Wrapper from "../utils/Wrapper";
import axios from 'axios';
import { stringify } from "query-string";

const dataProviderForCredit = {

  turnOnCredit: async (resource, data) => {
    let response = await axios.put(process.env.REACT_APP_API_URL + `/customer/${data.customerId}/credit/turn-on`, {},
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      }
    );

    if (response && response.data) {
      return ({
        data: response.data
      });
    } else {
      return ({
        data: []
      });
    }
  },

  turnOffCredit: async (resource, data) => {
    let response = await axios.put(process.env.REACT_APP_API_URL + `/customer/${data.customerId}/credit/turn-off`, {},
    {
      withCredentials: true,
      headers: { 'accept': 'application/json' }
    },  
  );
  
    if (response && response.data) {
      let dataRes = response.data;
      return ({
        data: dataRes.data
      });
    } else {
      return ({
        data: []
      });
    }
  },

  editCredit: async (resource, data) => {
    let response = await axios.put(process.env.REACT_APP_API_URL + `/customer/${data.customerId}/credit`, data.creditLineRequestDto,
    {
      withCredentials: true,
      headers: { 'accept': 'application/json' }
    },  
  );

    if (response && response.data) {
      let dataRes = response.data;
      return ({
        data: dataRes.data
      });
    } else {
      return ({
        data: []
      });
    }
 },
  

  restoreCredit: async (resource, data) => {
    let response = await axios.post(process.env.REACT_APP_API_URL + `/customer/${data.customerId}/credit/restore`, data.creditLineRestoreRequestDto ,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      }
    );

    if (!response || !response.data) {
      return { data: false };
    }
    return { data: true }
  },

  openCredit: async (resource, data) => {
    let response = await axios.post(process.env.REACT_APP_API_URL + `/customer/${data.customerId}/credit`, data.creditLineRequestDto,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      },  
    );

    if (!response || !response.data) {
      return { data: false };
    }

    return { data: true }
  },


  getCredit: async (recource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const { customerId } = params.filter;

     const query = {
      page: page - 1,
      size: perPage,
      sort: field === 'id' ? 'paymentId' : field
    };

    const url = process.env.REACT_APP_API_URL + `/customer/${customerId}/credit?${stringify(query)},${order}`;
    const response = await axios.get(url,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      });

    if (!response || !response.data || !response.data.data) {
      return { data: {} };
    }

    return { 
      data: response.data.data ,
      total: response.data.size
    }
  }
};

export default dataProviderForCredit;
