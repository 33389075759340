import * as React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Title, useNotify } from "react-admin";
import { useAuthState, useTranslate } from "ra-core";
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import { useState } from "react";
import axios from "axios";
import { DropzoneArea } from "material-ui-dropzone";
import Button from "@material-ui/core/Button";
import { useAuthenticated } from "react-admin";
import { cleanSession } from "../authProvider";
import { wrapArrayToMap } from "../utils/Wrapper";
import { makeStyles } from "@material-ui/core";

const WAITING_MS = 5000;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "rgba(0, 0, 0, 0.12)",
  }
}));

const waitMs = () => (new Promise((res, rej) => {
  setTimeout(() => {
    console.log('resolve');
    res();
  }, WAITING_MS);
}));


export default function UploadForm() {
  useAuthenticated();
  const [file, setFile] = useState([]);
  const [percentInProgress, setPercentInProgress] = useState(false);
  const [percentCompleted, setPercentCompleted] = useState(0);
  const [key, setKey] = useState(0);
  const [errors, setErrors] = useState([]);
  const [deffects, setDefects] = useState([]);
  const [totalRecordsCount, setTotalRecordsCount] = useState(0);
  const [uploadedRecordsCount, setUploadedRecordsCount] = useState(0);
  const [rejectedRecordsCount, setRejectedRecordsCount] = useState(0);
  const notify = useNotify();
  const translate = useTranslate();
  const classes = useStyles();
  const { loading, authenticated } = useAuthState();

  if (!authenticated || loading) {
    return null;
  }

  function LinearProgressWithLabel(props) {
    return (
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", file[0]);
    formData.append("file_expire", "");
    setPercentInProgress(true);
    setTotalRecordsCount(0);
    setUploadedRecordsCount(0);
    setRejectedRecordsCount(0);
    setErrors([]);
    let loading = true;
    try {
      axios
        .post(process.env.REACT_APP_API_URL + "/upload/products", formData, {
          withCredentials: true,
          headers: { "Content-Type": "multipart/form-data" },
        }).catch((error) => {
          loading = false;
          setPercentInProgress(false);
          if (!error.response) {
            console.error(error);
            return;
          }
          let { status } = error.response;
          if (status === 401 || status === 403) {
            cleanSession();
            window.location.href = "/login";
          }
          if (status === 500) {
            notify(
              error.response.data?.error,
              "warning"
            )
          }
          notify(
            error.response.data?.message?.message,
            "warning",
            wrapArrayToMap(error.response.data?.message?.parameters)
          );

        });

      await waitMs();

      while (loading) {
        await waitMs();

        await axios
          .get(process.env.REACT_APP_API_URL + "/upload/products/status", {
            withCredentials: true,
            headers: { 'accept': 'application/json' },
          }).then((response) => {
            if (response.data && response.data.data) {
              const {
                totalRecordsCount,
                currentCount,
                uploadedRecordsCount,
                rejectedRecordsCount,
                rejectedRecordMessages,
                recordsWithDefect
              } = response.data.data;
              if (totalRecordsCount > 0) {
                setPercentCompleted(
                  Math.round((currentCount * 100) / totalRecordsCount)
                );
                setTotalRecordsCount(totalRecordsCount);
                setUploadedRecordsCount(uploadedRecordsCount);
                setRejectedRecordsCount(rejectedRecordsCount);
              }

              if (totalRecordsCount === currentCount && totalRecordsCount > 0) {
                loading = false;
                if (rejectedRecordsCount === 0) {
                  notify("konebone.upload.success");
                } else {
                  notify("konebone.upload.errors");
                }
              }

              if (rejectedRecordMessages && totalRecordsCount > 0) {
                let errorsTranslated = [];
                for (
                  let i = 0;
                  i < rejectedRecordMessages.length;
                  i++
                ) {
                  let err = {};
                  err.csvRecord =
                    rejectedRecordMessages[i].csvRecord;

                  err.msg =
                    "#[" +
                    rejectedRecordMessages[i].csvRecordMessage
                      .parameters[0] +
                    "]: " +
                    translate(
                      "konebone." +
                      rejectedRecordMessages[i]
                        .csvRecordMessage.message,
                      wrapArrayToMap(
                        rejectedRecordMessages[i]
                          .csvRecordMessage.parameters
                      )
                    );
                  errorsTranslated.push(err);
                }
                setErrors(errorsTranslated);
              }

              if (recordsWithDefect && totalRecordsCount > 0) {
                let defectsTranslated = [];
                for (
                  let i = 0;
                  i < recordsWithDefect.length;
                  i++
                ) {
                  let err = {};
                  err.csvRecord =
                    recordsWithDefect[i].csvRecord;

                  err.msg =
                    "#[" +
                    recordsWithDefect[i].csvRecordMessage
                      .parameters[0] +
                    "]: " +
                    translate(
                      "konebone." +
                      recordsWithDefect[i]
                        .csvRecordMessage.message,
                      wrapArrayToMap(
                        recordsWithDefect[i]
                          .csvRecordMessage.parameters
                      )
                    );
                  defectsTranslated.push(err);
                }
                setDefects(defectsTranslated);
              }
            }
          })
          .catch((error) => {
            loading = false;
            setPercentInProgress(false);
            if (!error.response) {
              console.error(error);
              return;
            }
            let { status } = error.response;
            if (status === 401 || status === 403) {
              cleanSession();
              window.location.href = "/login";
            }
            if (status === 500) {
              notify(
                error.response.data?.error,
                "warning"
              )
            }
            notify(
              error.response.data?.message?.message,
              "warning",
              wrapArrayToMap(error.response.data?.message?.parameters)
            );

          });
      };

      e.target.reset();
      setKey(key + 1);
    } catch (error) {
      console.error(error);
    }
    setPercentInProgress(false);
    setPercentCompleted(0);
  };

  let lineKey = 0;

  return (
    <div className="upload_form">
      <Card>
        <Title title={translate("konebone.upload.title").toUpperCase()} />
        <CardContent>
          <form noValidate onSubmit={(e) => handleSubmit(e)}>
            <div className="dropZoneArea">
              <DropzoneArea
                key={key}
                maxFileSize={10000000}
                onChange={(files) => setFile(files)}
                onAlert={(message, variant) => notify(
                  message,
                  variant
                )}
                filesLimit={1}
                dropzoneText={translate("konebone.upload.dropzone_text")}
                classes={percentInProgress ? classes : {}}
                dropzoneProps={{ disabled: percentInProgress }}
                showAlerts={false}
              />
            </div>

            {percentInProgress ? (
              <div className="linearProgress">
                <LinearProgressWithLabel value={percentCompleted} />
              </div>
            ) : (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={file.length === 0}
              >
                {translate("konebone.button.submit")}
              </Button>
            )}
          </form>

          {totalRecordsCount > 0 ? (
            <div className="wrapper_error">
              <p>
                <strong>
                  {translate("konebone.upload.total_record_count")}{" "}
                  {totalRecordsCount}
                </strong>
              </p>
              <p>
                <strong>
                  {translate("konebone.upload.uploaded_record_count")}{" "}
                  {uploadedRecordsCount}
                </strong>
              </p>
              <p>
                <strong>
                  {translate("konebone.upload.rejected_record_count")}{" "}
                  {rejectedRecordsCount}
                </strong>
              </p>
              {errors && errors.length > 0 ? (
                <p>
                  <strong>
                    {translate("konebone.upload.rejected_with_errors")}
                  </strong>
                </p>
              ) : (
                ""
              )}
              <div className="errorList">
                {errors.map((error) => (
                  <div key={"line_" + lineKey++}>
                    <p>{error.csvRecord}</p>
                    <p className="red">{error.msg}</p>
                  </div>
                ))}
              </div>
              {deffects && deffects.length > 0 ? (
                <p>
                  <strong>
                    {translate("konebone.upload.rejected_with_defects")}
                  </strong>
                </p>
              ) : (
                ""
              )}
              <div className="errorList">
                {deffects.map((deffect) => (
                  <div key={"line_" + lineKey++}>
                    <p>{deffect.csvRecord}</p>
                    <p className="red">{deffect.msg}</p>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            ""
          )}
        </CardContent>
      </Card>
    </div>
  );
}
