import React, { useRef, useState } from 'react';
import { TopToolbar, TextInput, DateInput } from 'react-admin';
import {
  FormWithRedirect,
  useNotify,
  useRedirect,
  useTranslate,
} from 'ra-core';
import {
  Button,
  Box,
  Typography,
  makeStyles,
  Paper,
  Toolbar,
} from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Validator from '../../utils/Validator';
import dataProviderForSupplier from '../../providers/DataProviderForSupplier';
import { cleanSession } from '../../authProvider';
import { wrapArrayToMap } from '../../utils/Wrapper';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '14px',
    padding: '0 29px',
  },
  toolbar_root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& button': {
      margin: theme.spacing(1),
    },
  },
  add: {
    minWidth: '200px',
  },
  approve: {
    border: '1px solid #219653',
    borderRadius: '3px',
    color: '#219653',
  },
  cancell: {
    color: '#DDDBDA',
    border: '1px solid #DDDBDA',
    borderRadius: '4px',
  },
  validate: {
    '& fieldset': {
      borderColor: 'red',
    },
    '& p': {
      color: 'red',
    },
  },
}));

const requiredFields = [
  'companyName',
  'foundation',
  'taxId',
  'city',
  'street',
  'unit',
  'managerFirstName',
  'managerLastName',
  'managerPhoneNumber',
  'managerJobTitle',
  'managerEmail',
];

export default ({ basePath }) => {
  const [requiredFieldErrors, setRequiredFieldsErrors] = useState({});
  const [companyPhoneError, setCompanyPhoneError] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [activity, setActivity] = useState('');
  const [companyPhoneNumber, setCompanyPhoneNumber] = useState('');
  const [city, setCity] = useState('');
  const [taxId, setTaxId] = useState('');
  const [foundation, setFoundation] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');
  const [street, setStreet] = useState('');
  const [unit, setUnit] = useState('');
  const [desctiption, setDesctiption] = useState('');
  const [managerFirstName, setManagerFirstName] = useState('');
  const [managerLastName, setManagerLastName] = useState('');
  const [managerPhoneNumber, setManagerPhoneNumber] = useState('');
  const [managerJobTitle, setManagerJobTitle] = useState('');
  const [managerEmail, setManagerEmail] = useState('');
  const foundationError = useRef(false);

  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();
  const redirectTo = useRedirect();

  let isError = false;


/* -------handle changes----------- */
  const handleFirstNameChange = (e) => {
    if (requiredFieldErrors.managerFirstName && e.target.value) {
      setRequiredFieldsErrors({
        ...requiredFieldErrors,
        managerFirstName: false,
      });
    }
    setManagerFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    if (requiredFieldErrors.managerLastName && e.target.value) {
      setRequiredFieldsErrors({
        ...requiredFieldErrors,
        managerLastName: false,
      });
    }
    setManagerLastName(e.target.value);
  };

  const handlePhoneNumber = (e) => {
    if (requiredFieldErrors.managerPhoneNumber && e.target.value) {
      setRequiredFieldsErrors({
        ...requiredFieldErrors,
        managerPhoneNumber: false,
      });
    }
    setManagerPhoneNumber(e.target.value);
  };

  const handleCompanyPhoneNumber = (e) => {
    if(Validator.validateCompanyPhone(e.target.value)) { 
      return setCompanyPhoneError(Validator.validateCompanyPhone(e.target.value));
    } 
    setCompanyPhoneError(false)
    setCompanyPhoneNumber(e.target.value);
  };

  const handleManagerEmail = (e) => {
    if (requiredFieldErrors.managerEmail && e.target.value) {
      setRequiredFieldsErrors({ ...requiredFieldErrors, managerEmail: false });
    }
    setManagerEmail(e.target.value);
  };

  const handleJobTitle = (e) => {
    if (requiredFieldErrors.managerJobTitle && e.target.value) {
      setRequiredFieldsErrors({
        ...requiredFieldErrors,
        managerJobTitle: false,
      });
    }
    setManagerJobTitle(e.target.value);
  };

  const handleCompanyName = (e) => {
    if (requiredFieldErrors.companyName && e.target.value) {
      setRequiredFieldsErrors({ ...requiredFieldErrors, companyName: false });
    }
    setCompanyName(e.target.value);
  };

  const handleStreet = (e) => {
    if (requiredFieldErrors.street && e.target.value) {
      setRequiredFieldsErrors({ ...requiredFieldErrors, street: false });
    }
    setStreet(e.target.value);
  };

  const handleCity = (e) => {
    if (requiredFieldErrors.city && e.target.value) {
      setRequiredFieldsErrors({ ...requiredFieldErrors, city: false });
    }
    setCity(e.target.value);
  };

  const handleTaxId = (e) => {
    if (requiredFieldErrors.taxId && e.target.value) {
      setRequiredFieldsErrors({ ...requiredFieldErrors, taxId: false });
    }
    setTaxId(e.target.value);
  };

  const handleFoundation = (e) => {
    if (new Date(e.target.value) > new Date()) {
      foundationError.current = true
    } else {
      foundationError.current = false;
    }
    if (requiredFieldErrors.foundation && e.target.value) {
      setRequiredFieldsErrors({ ...requiredFieldErrors, foundation: false });
    }
    setFoundation(e.target.value);
  };

  const handleUnit = (e) => {
    if (requiredFieldErrors.unit && e.target.value) {
      setRequiredFieldsErrors({ ...requiredFieldErrors, unit: false });
    }
    setUnit(e.target.value);
  };
//------------------------------------//

  const handleSave = (e) => {
    const data = {
      companyName: companyName?.trim(),
      taxId: taxId?.trim(),
      foundation: foundation?.trim(),
      street: street?.trim(),
      city: city?.trim(),
      unit: unit?.trim(),
      managerFirstName: managerFirstName?.trim(),
      managerLastName: managerLastName?.trim(),
      managerPhoneNumber: managerPhoneNumber?.trim(),
      managerJobTitle: managerJobTitle?.trim(),
      managerEmail: managerEmail?.trim(),
    };

    const newErrors = { ...requiredFieldErrors };

    requiredFields.forEach((field) => {
      if (!data[field]) {
        newErrors[field] = true;
      }
    });

    const isHaveError = Object.values(newErrors).some((e) => e);

    if (isHaveError) {
      setRequiredFieldsErrors(newErrors);
      return;
    }

    if (companyPhoneError) {
      return;
    }

    if(foundationError.current) {
      return 
    }

    dataProviderForSupplier
      .create('/supplier', {
        data: {
          activity: activity ? activity.trim() : null,
          city: city?.trim(),
          companyEmail: companyEmail?.trim(),
          companyName: companyName?.trim(),
          companyPhone: companyPhoneNumber ? companyPhoneNumber.trim() : null,
          desctiption: desctiption ? desctiption.trim() : null,
          district: null,
          foundation: foundation?.trim(),
          manager: {
            email: managerEmail?.trim(),
            firstName: managerFirstName?.trim(),
            jobTitle: managerJobTitle?.trim(),
            lastName: managerLastName?.trim(),
            phone: managerPhoneNumber?.trim(),
          },
          taxId: taxId?.trim(),
          street: street?.trim(),
          unit: unit?.trim(),
        },
      })
      .then((item) => {
          redirectTo(basePath);
      })
      .catch((error) => {
        if (!error.response) {
          console.error(error);
          return;
        }
        let { status } = error.response;
        if (status === 401 || status === 403) {
          cleanSession();
          window.location.href = '/login';
        }
        if (status === 500) {
          notify(error.response.data?.error, 'warning');
        }
        notify(
          error.response.data?.message?.message,
          'warning',
          wrapArrayToMap(error.response.data?.message?.parameters)
        );
      });
  };

  const handleCancel = () => {
    redirectTo(basePath);
  };


  return (
    <>
      <Paper className={classes.root}>
        <TopToolbar className={classes.toolbar_root}>
          <Typography variant="h5" gutterBottom className={classes.add}>
            {translate('konebone.supplier.add_supplier').toUpperCase()}
          </Typography>
          <Box>
            <Button
              variant="outlined"
              className={classes.cancel}
              onClick={handleCancel}
            >
              {'Cancel'}
            </Button>
            <Button
              variant="outlined"
              className={classes.approve}
              endIcon={<CheckCircleOutlineIcon />}
              onClick={handleSave}
            >
              {'Save & Approve'}
            </Button>
          </Box>
        </TopToolbar>
      </Paper>
      <Paper>
        <FormWithRedirect
          // {...props}
          // warnWhenUnsavedChanges
          render={(formProps) => (
            <form>
              <Toolbar>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                >
                  <Typography variant="h6" gutterBottom>
                    {translate('konebone.supplier.company').toUpperCase()}
                  </Typography>
                </Box>
              </Toolbar>
              <Box p="1em">
                <Box display="flex" width="100%">
                  <Box flex={1} mr="1em">
                    <TextInput
                      source="companyName"
                      resource="suppliers"
                      label={translate('konebone.supplier.company_name')}
                      fullWidth
                      variant="outlined"
                      validate={Validator.validateCompanyName}
                      value={companyName}
                      onChange={handleCompanyName}
                      error={requiredFieldErrors.companyName}
                      helperText={
                        requiredFieldErrors.companyName ? 
                        translate('ra.validation.required') : {minHeight: '20px'}
                      }
                      className={`${
                        requiredFieldErrors.companyName && classes.validate
                      }`}
                    />
                    <TextInput
                      label={translate('konebone.supplier.field_of_activity')}
                      fullWidth
                      source="activity"
                      resource="suppliers"
                      variant="outlined"
                      value={activity}
                      onChange={(e) => setActivity(e.target.value)}
                    />
                    <TextInput
                      source="number"
                      resource="suppliers"
                      label={translate(
                        'konebone.supplier.company_phone_number'
                      )}
                      fullWidth
                      variant="outlined"
                      helperText={companyPhoneError ? translate(companyPhoneError) : {minHeight: '20px'}}
                      error={companyPhoneError}
                      value={companyPhoneNumber}
                      onChange={handleCompanyPhoneNumber}
                      className={`${companyPhoneError && classes.validate}`}
                    />
                    <TextInput
                      resource="suppliers"
                      source="city"
                      label={translate('konebone.supplier.city')}
                      fullWidth
                      variant="outlined"
                      validate={Validator.validateUnit}
                      value={city}
                      onChange={handleCity}
                      error={requiredFieldErrors.city}
                      helperText={
                        requiredFieldErrors.city ?
                        translate('ra.validation.required') : {minHeight: '20px'}
                      }
                      className={`${
                        requiredFieldErrors.city && classes.validate
                      }`}
                    />
                  </Box>
                  <Box flex={1}>
                    <TextInput
                      resource="suppliers"
                      source="taxId"
                      label={translate('konebone.supplier.tax_id')}
                      fullWidth
                      variant="outlined"
                      value={taxId}
                      onChange={handleTaxId}
                      validate={Validator.validateTaxId}
                      error={requiredFieldErrors.taxId}
                      helperText={
                        requiredFieldErrors.taxId ?
                        translate('ra.validation.required') : {minHeight: '20px'}
                      }
                      className={`${
                        requiredFieldErrors.taxId && classes.validate
                      }`}
                    />
                    <DateInput
                      resource="suppliers"
                      source="foundation"
                      label={translate('konebone.supplier.company_foundation')}
                      fullWidth
                      variant="outlined"
                      validate={Validator.validateFoundation}
                      value={foundation}
                      onChange={handleFoundation}
                      error={requiredFieldErrors.foundation || foundationError.current}
                      helperText={requiredFieldErrors.foundation ? translate('ra.validation.required')
                        : foundationError.current ? translate("konebone.supplier.error_foundation") : {minHeight: '20px'}}
                      className={`${
                        requiredFieldErrors.foundation || foundationError.current && classes.validate
                      }`}
                    />
                    <TextInput
                      type='email'
                      value={companyEmail}
                      resource="suppliers"
                      source="email"
                      onChange={(e) => setCompanyEmail(e.target.value)}
                      label={translate('konebone.supplier.company_email')}
                      fullWidth
                      variant="outlined"
                      validate={Validator.validateEmailSignIn}
                    />
                    <Box display="flex">
                      <Box flex={1} mr="1em">
                        <TextInput
                          resource="suppliers"
                          source="street"
                          label={translate('konebone.supplier.street')}
                          fullWidth
                          variant="outlined"
                          value={street}
                          onChange={handleStreet}
                          validate={Validator.validateUnit}
                          error={requiredFieldErrors.street}
                          helperText={
                            requiredFieldErrors.street ?
                            translate('ra.validation.required') : {minHeight: '20px'}
                          }
                          className={`${
                            requiredFieldErrors.street && classes.validate
                          }`}
                        />
                      </Box>
                      <Box flex={1}>
                        <TextInput
                          resource="suppliers"
                          source="unit"
                          label={translate('konebone.supplier.unit')}
                          fullWidth
                          variant="outlined"
                          value={unit}
                          onChange={handleUnit}
                          validate={Validator.validateUnit}
                          error={requiredFieldErrors.unit}
                          helperText={
                            requiredFieldErrors.unit ?
                            translate('ra.validation.required') : {minHeight: '20px'}
                          }
                          className={`${
                            requiredFieldErrors.unit && classes.validate
                          }`}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <TextInput
                  source="desctiption"
                  resource="suppliers"
                  value={desctiption}
                  onChange={(e) => setDesctiption(e.target.value)}
                  label={translate('konebone.supplier.company_description')}
                  fullWidth
                  multiline
                  rows="4"
                  variant="outlined"
                />
              </Box>
            </form>
          )}
        />
      </Paper>
      <Paper>
        <FormWithRedirect
          // {...props}
          // warnWhenUnsavedChanges
          render={(formProps) => (
            <form>
              <Toolbar className={classes.toolbar_root}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                >
                  <Typography variant="h6" gutterBottom>
                    {translate('konebone.supplier.manager').toUpperCase()}
                  </Typography>
                </Box>
              </Toolbar>
              <Box p="1em">
                <Box display="flex" width="100%">
                  <Box flex={1} mr="1em">
                    <TextInput
                      label={translate('konebone.manager.firstName')}
                      fullWidth
                      source="managerFirstName"
                      resource="manager"
                      variant="outlined"
                      validate={Validator.validateManager}
                      value={managerFirstName}
                      onChange={handleFirstNameChange}
                      error={requiredFieldErrors.managerFirstName}
                      helperText={
                        requiredFieldErrors.managerFirstName ?
                        translate('ra.validation.required') : {minHeight: '20px'}
                      }
                      className={`${
                        requiredFieldErrors.managerFirstName && classes.validate
                      }`}
                    />
                    <TextInput
                      source="number"
                      resource="manager"
                      label={translate('konebone.manager.phone_number')}
                      fullWidth
                      variant="outlined"
                      validate={Validator.validatePhone}
                      value={managerPhoneNumber}
                      onChange={handlePhoneNumber}
                      error={requiredFieldErrors.managerPhoneNumber}
                      helperText={
                        requiredFieldErrors.managerPhoneNumber ?
                        translate('ra.validation.required') : {minHeight: '20px'}
                      }
                      className={`${
                        requiredFieldErrors.managerPhoneNumber &&
                        classes.validate
                      }`}
                    />
                    <TextInput
                      resource="manager"
                      source="managerEmail"
                      label={translate('konebone.manager.contact_email')}
                      fullWidth
                      variant="outlined"
                      validate={Validator.validateEmail}
                      value={managerEmail}
                      onChange={handleManagerEmail}
                      error={requiredFieldErrors.managerEmail}
                      helperText={
                        requiredFieldErrors.managerEmail ?
                        translate('ra.validation.required') : {minHeight: '20px'}
                      }
                      className={`${
                        requiredFieldErrors.managerEmail && classes.validate
                      }`}
                    />
                  </Box>
                  <Box flex={1}>
                    <TextInput
                      label={translate('konebone.manager.lastName')}
                      fullWidth
                      source="managerLastName"
                      resource="manager"
                      variant="outlined"
                      validate={Validator.validateManager}
                      value={managerLastName}
                      onChange={handleLastNameChange}
                      error={requiredFieldErrors.managerLastName}
                      helperText={
                        requiredFieldErrors.managerLastName ?
                        translate('ra.validation.required') : {minHeight: '20px'}
                      }
                      className={`${
                        requiredFieldErrors.managerLastName && classes.validate
                      }`}
                    />
                    <TextInput
                      resource="title"
                      source="managerJobTitle"
                      label={translate('konebone.manager.jobTitle')}
                      fullWidth
                      variant="outlined"
                      validate={Validator.validateManager}
                      value={managerJobTitle}
                      onChange={handleJobTitle}
                      error={requiredFieldErrors.managerJobTitle}
                      helperText={
                        requiredFieldErrors.managerJobTitle ?
                        translate('ra.validation.required') : {minHeight: '20px'}
                      }
                      className={`${
                        requiredFieldErrors.managerJobTitle && classes.validate
                      }`}
                    />
                  </Box>
                </Box>
              </Box>
            </form>
          )}
        />
      </Paper>
    </>
  );
};
