import {
    CREATE,
    DELETE,
    DELETE_MANY,
    FETCH_END,
    getFetchedAt,
    GET_LIST,
    GET_MANY,
    GET_MANY_REFERENCE,
    GET_ONE,

    UPDATE,
    UPDATE_MANY,
} from 'ra-core';
import {
    GET_TREE_ROOT_NODES,
    GET_TREE_CHILDREN_NODES,
    MOVE_NODE,
    CRUD_MOVE_NODE_SUCCESS,
} from '../actions';
/**
 * Make the fetchedAt property non enumerable
 */
export const hideFetchedAt = (
    records
) => {
    Object.defineProperty(records, 'fetchedAt', {
        enumerable: false,
        configurable: false,
        writable: false,
    });
    return records;
};

/**
 * Add new records to the pool, and remove outdated ones.
 *
 * This is the equivalent of a stale-while-revalidate caching strategy:
 * The cached data is displayed before fetching, and stale data is removed
 * only once fresh data is fetched.
 */
export const addRecords = (
    newRecords = [],
    oldRecords
) => {
    const newRecordsById = {};
    newRecords.forEach(record => (newRecordsById[record.id] = record));

    const newFetchedAt = getFetchedAt(
        newRecords.map(({ id }) => id),
        oldRecords.fetchedAt
    );

    const records = { fetchedAt: newFetchedAt };
    Object.keys(newFetchedAt).forEach(
        id => (records[id] = newRecordsById[id] || oldRecords[id])
    );

    return hideFetchedAt(records);
};

/**
 * Remove records from the pool
 */
const removeRecords = (
    removedRecordIds = [],
    oldRecords
) => {
    const records = Object.entries(oldRecords)
        .filter(([key]) => !removedRecordIds.includes(key))
        .reduce((obj, [key, val]) => ({ ...obj, [key]: val }), {
            fetchedAt: {}, // TypeScript warns later if this is not defined
        });
    records.fetchedAt = Object.entries(oldRecords.fetchedAt)
        .filter(([key]) => !removedRecordIds.includes(key))
        .reduce((obj, [key, val]) => ({ ...obj, [key]: val }), {});

    return hideFetchedAt(records);
};

const initialState = hideFetchedAt({ fetchedAt: {} });

const dataReducer = (
    previousState = initialState,
    { payload, meta, type }
) => {
    if (meta && meta.optimistic) {
        if (meta.fetch === UPDATE_MANY) {
            const updatedRecords = payload.ids.map(id => ({
                ...previousState[id],
                ...payload.data,
            }));
            return addRecords(updatedRecords, previousState);
        }
        if (meta.fetch === DELETE) {
            return removeRecords([payload.id], previousState);
        }
        if (meta.fetch === DELETE_MANY) {
            return removeRecords(payload.ids, previousState);
        }
    }
    if (!meta || !meta.fetchResponse || meta.fetchStatus !== FETCH_END) {
        return previousState;
    }

    // if (type === CRUD_MOVE_NODE_SUCCESS) {
    //     console.log("move success");
    //     const updatedRecord = {
    //         ...previousState[payload.id],
    //         ...payload.data,
    //     };

    //     const nextSiblings = meta.positionSource
    //         ? Object.keys(previousState)
    //             .filter(
    //                 id =>
    //                     previousState[id][meta.parentSource] ===
    //                     payload.data[meta.parentSource] &&
    //                     previousState[id][meta.positionSource] >=
    //                     payload.data[meta.positionSource]
    //             )
    //             .map(id => ({
    //                 ...previousState[id],
    //                 [meta.positionSource]:
    //                     previousState[id][meta.positionSource] + 1,
    //             }))
    //         : [];


    //     return addRecords([updatedRecord, ...nextSiblings], previousState);
    // }

    switch (meta.fetchResponse) {
        case GET_TREE_ROOT_NODES:
        case GET_TREE_CHILDREN_NODES:
        case GET_LIST:
        case GET_MANY:
        case GET_MANY_REFERENCE:
            return addRecords(payload.data, previousState);
        // case GET_ONE:
        // case UPDATE:
        // case CREATE:
        //     return addRecords([payload.data], previousState);
        default:
            return previousState;
    }
};

export const getRecord = (state, id) => state[id];

export default dataReducer;
