import * as React from "react";
import { useState, useEffect } from "react";
import {
  List,
  TextField,
  TopToolbar,
  ExportButton,
  EditButton,
  TextInput,
  useNotify,
} from "react-admin";
import { Form } from 'react-final-form';
import {
  Paper,
  Tab,
  Tabs,
  makeStyles,
  InputAdornment,
  Box,
} from "@material-ui/core";
import { useAuthState, useListContext, useTranslate } from "ra-core";
import SearchIcon from '@material-ui/icons/Search';
import DatagridResponsive from "../../components/DataGridResponsive";
import { cleanSession } from "../../authProvider";
import dataProviderForCustomers from "../../providers/DataProviderForCustomers";
import CustomerActionsButtons from "./CustomerActionsButtons";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "stretch",
    "& > *": {
      marginLeft: theme.spacing(1),
    },
  },
  listRoot: {
    "& form": {
      width: "100%",
    },
    "& form > div:first-child": {
      width: "100%",
    },
  },
  textField: {
    marginBottom: 0,
    width: "100%",
    "& > div": {
      maxHeight: "40px",
      width: "100%",
    },
    "& > label": {
      fontSize: "14px",
    },
  },
  approve: {
    border: "1px solid green",
    borderRadius: "3px",
    width: "30px",
    minWidth: "30px",
    maxWidth: "30px",
  },
  decline: {
    border: "1px solid red",
    borderRadius: "3px",
    width: "30px",
    minWidth: "30px",
    maxWidth: "30px",
  },
  capitalize: {
    textTransform: "capitalize",
  },
}));

const statusColorMap = {
  BLOCKED: "#E02443",
  DECLINED: "#F2994A",
  UNFINISHED: "#686868",
}

const ListActions = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter, // you can hide ExportButton if exporter = (null || false)
  filterValues,
  permanentFilter,
  hasCreate, // you can hide CreateButton if hasCreate = false
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Box width="100%">
      <TopToolbar className={className, classes.root}>
        {exporter && (
          <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={{ ...filterValues, ...permanentFilter }}
            exporter={exporter}
            maxResults={maxResults}
          />
        )}
      </TopToolbar>
      <CustomersFilter total={total} />
    </Box>
  )
};

ListActions.defaultProps = {
  selectedIds: [],
  onUnselectItems: () => null,
};

const CustomersFilter = ({ total }) => {
  const [value, setValue] = useState(0);
  const [filterData, setFilterData] = useState(filterValues?.q ? filterValues.q : "");
  const [tabCounts, setTabCounts] = useState({});
  const classes = useStyles();
  const translate = useTranslate();
  const {
    setFilters,
    filterValues,
  } = useListContext();
  
  const statusesMap = ["candidates", "active", "inactive"];
  const tabMap = ["candidate", "active", "inactive"];

  const notify = useNotify();
  useEffect(() => {
    if (filterValues) {
      setFilters({ status: filterValues.status })
      const index = statusesMap.indexOf(filterValues.status);
      index >= 0 && setValue(index)
    }
  }, [])


  useEffect(() => {
    if ((!tabCounts.candidate && total === null) || (total !== undefined && tabCounts[tabMap[value]] !== total)) {
      dataProviderForCustomers.getCountByStatus()
      .then((data) => {
        setTabCounts(data?.data);
      })
        .catch((error) => {
          if (!error.response) {
            console.error(error);
            return;
          }
          let { status } = error.response;
          if (status === 401 || status === 403) {
            cleanSession();
            window.location.href = "/login";
          }
          if (status === 500) {
            notify(
              error.response.data?.error,
              "warning"
            )
            return;
          }
        });
    }
  }, [total]);

  const handleChange = (event, newValue) => {
    setValue(newValue);

    setFilters({ q: filterValues.q, status: statusesMap[newValue] });
  };

  const onSubmit = (values) => {
    setFilters({ status: filterValues.status, q: values.q });
  };

  const handleFilterValue = (e) => {
    !e.target?.value ? onSubmit("") : setFilterData(e.target.value);
  }

  return (
    <div>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box display="flex" alignItems="flex-end" mb={1}>
              {/* Full-text search filter. We don't use <SearchFilter> to force a large form input */}
              <TextInput
                resettable
                helperText={false}
                source="q"
                label={translate("konebone.customer.search")}
                color="secondary"
                variant="outlined"
                className={classes.textField}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <SearchIcon color="disabled" />
                    </InputAdornment>
                  )
                }}
                onChange={(event) => handleFilterValue(event)}
              />
            </Box>
          </form>
        )}
      </Form>
      <Paper position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="secondary"
        >
          <Tab className={classes.capitalize} label={`${translate("konebone.customer.candidates")} (${tabCounts.candidate || 0})`} />
          <Tab className={classes.capitalize} label={`${translate("konebone.customer.active")} (${tabCounts.active || 0})`} />
          <Tab className={classes.capitalize} label={`${translate("konebone.customer.inactive")} (${tabCounts.inactive || 0})`} />
        </Tabs>
      </Paper>
    </div>
  );
};

const CustomerStatusField = (props) => {
  const color = statusColorMap[props.record?.status];

  return (
    <TextField
      style={{ color: color, textTransform: "capitalize" }}
      {...props}
    />
  )
}

const CustomerEditButton = ({ record }) => {
  return (
    <div className="listButton">
      <EditButton label={""} record={record} />
    </div>
  );
};

const CustomerDataGrid = ({ filterValues, ...props }) => {
  const translate = useTranslate();
  let statusField = filterValues?.status === "inactive" ? [<CustomerStatusField key="CustomerStatusField" source="statusLower" label={translate("konebone.customer.status")} />] : [];
  return (
    <DatagridResponsive rowClick="show" className={"sticky-action_btn"} filterValues={filterValues} {...props}>
      <TextField
        source="name"
        label={translate("konebone.customer.company_name")}
      />
      <TextField
        source="address"
        label={translate("konebone.customer.adress")}
      />
      <TextField
        source="mainContact"
        label={translate("konebone.customer.main_contact")}
      />
      <TextField
        source="phone"
        label={translate("konebone.customer.phone_number")}
      />
      <TextField
        source="taxId"
        label={translate("konebone.customer.taxId")}
      />
      <TextField
        source="contractorNumber"
        label={translate("konebone.customer.contractor_number")}
      />

      {statusField}

      <ButtonsComponent />

    </DatagridResponsive >)
}
function ButtonsComponent({ record }) {
  if (!record) {
    return <div></div>
  }
  let btnArray = [];
  btnArray = CustomerActionsButtons(record.status, record, "small")?.buttons;
  btnArray.unshift(<CustomerEditButton key="CustomerEditButton" record={record} />)
  return (
    <div className="flex-container">
      {btnArray}
    </div>
  )
}

export const CustomerList = (props) => {
  const translate = useTranslate();
  const classes = useStyles();
  const { loading, authenticated } = useAuthState();

  if (!authenticated || loading) {
    return null;
  }

  return (
    <List
      title={translate("konebone.customer.title").toUpperCase()}
      exporter={null}
      {...props}
      actions={<ListActions />}
      empty={<ListActions />}
      bulkActionButtons={false}
      className={classes.listRoot}
      sort={{ field: "name", order: "DESC" }}
    >
      <CustomerDataGrid />
    </List>
  );
};

