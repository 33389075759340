import { useEffect, useState } from "react";
import { Typography, Button, makeStyles, Box } from "@material-ui/core";
import dataProviderForOffers from "../../providers/DataProviderForOffers";
import { ReactComponent as OrderIcon } from "../../svg/order.svg";
import { ReactComponent as RemoveIcon } from "../../svg/remove.svg";
import { ReactComponent as DownloadIcon } from "../../svg/download.svg";
import { useNotify, useRefresh, useTranslate } from "ra-core";
import { STATUS } from "./enums";
import { handleFailure } from "../../utils/handleFailure";

const useStyles = makeStyles((theme) => ({
  box: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#F8F9FB",
    border: "1px dashed #9AA4B8",
    borderRadius: "3px",
    padding: "10px 25px 10px 15px",
  },
  emptyBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#FEF4F6",
    border: "1px dashed #9AA4B8",
    borderRadius: "3px",
    color: "#E02443",
    padding: "10px 25px 10px 15px",
  },
  button: {
    background: "#FFFFFF",
    textTransform: "capitalize",
  },
  infoText: {
    display: "flex",
    alignItems: "center",
  },
  infoText_descr: {
    paddingLeft: "10px",
  },
  buttonList: {
    display: "flex",
    alignItems: "center",
    columnGap: "20px",
  },
  actionButton: {
    padding: "0",
    minWidth: "auto",
  },
  error: {
    color: "#e02443",
  }
}));

const PackingSlips = ({ id, setPackingSlipButton, record }) => {
  const [packingSlipsList, setPackingSlipsList] = useState([]);
  const [errorSizeMessage, setErrorSizeMessage] = useState('');
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();

  const getList = () => {
    dataProviderForOffers
      .getPackingSlipsList("offers", {
        id: id,
      })
      .then((items) => {
        setPackingSlipsList(items?.data)
      })
      .catch((error) => {
        handleFailure(error, notify);
      });
  };

  useEffect(() => {
    if (id) {
      getList();
    }
    if (record.status === STATUS.DELIVERED || record.status === STATUS.PACKING_SLIP_VERIFIED) {
      setPackingSlipButton(false);
    }
  }, []);

  const attachPackingSlips = (event, index) => {
    event.preventDefault();
    const file = event.target.files[0];
    if (!file) return;
    const isFile10M = file.size <= 1024 * 1024 * 10;
    if (!isFile10M) {
      setErrorSizeMessage(index);
      return;
    }
    setErrorSizeMessage('');
    const formData = new FormData();
    formData.append("file", file);
    try {
      dataProviderForOffers
        .postPackingSlipsItem("offers", formData, index)
        .then((item) => {
          !!item.data,
          refresh();
        });
    } catch (error) {
      handleFailure(error, notify);
    }
  };

  const handleRemove = (id) => {
    dataProviderForOffers
      .deletePackingSlipsItem("offers", {
        id: id,
      })
      .then(() => {
        getList();
        refresh()
      })
      .catch((error) => {
        handleFailure(error, notify);
      });
  };
  const groupedBySupplier = {};
  packingSlipsList.forEach(item => {
    const supplierName = item.supplierName;
    if (!groupedBySupplier[supplierName]) {
      groupedBySupplier[supplierName] = [];
    }
    groupedBySupplier[supplierName].push(item);
  });

  return (
    <Box p="1em">
      {record.status === STATUS.DELIVERED && Object.entries(groupedBySupplier).map(([supplierName, items]) => (
          <>
            {(items?.[0].fileName || items?.[0].needPackingSlip) &&
            <Typography variant="body2" gutterBottom component="p">
              {supplierName} {translate("konebone.orders.packing_slip")}
            </Typography>
            }
            {items.map((item, index) => (
                <div key={index}>
                  <div style={{
                    color: 'rgba(37, 44, 50, 0.50)',
                    fontSize: '16px',
                    lineHeight: '18.72px'
                  }}>
                    {item.createdAt ? `${item.createdAt}  -> Delivered ` : ''}
                  </div>
                  {!!item.fileName && (
                      <div className={classes.box}>
                        <div className={classes.infoText}>
                          <OrderIcon />{" "}
                          <span className={classes.infoText_descr}>{item.fileName}</span>
                        </div>
                        <div className={classes.buttonList}>
                          <a href={item.fileUrl}>
                            <Button variant="text" className={classes.actionButton}>
                              <DownloadIcon />
                            </Button>
                          </a>
                          {record.status === STATUS.PACKING_SLIP_VERIFIED ?
                              <></> :
                              <Button
                                  variant="text"
                                  className={classes.actionButton}
                                  onClick={() => handleRemove(item.offerPackingSlipId)}
                              >
                                <RemoveIcon />
                              </Button>}
                        </div>
                      </div>
                  )}
                  {item.offerId === errorSizeMessage && <p className={classes.error}>{translate("konebone.orders.packing_slip_error_maxSize")}</p>}
                  <Box mt="1em" style={{ marginTop: "17px" }} />
                </div>
            ))}
            {items?.[0]?.needPackingSlip && (
              <div className={classes.emptyBox} style={{marginBottom: '15px'}}>
                <div className={classes.infoText}>
                  <OrderIcon/>{" "}
                  <span className={classes.infoText_descr}>
                  Need to upload the packing slip
                </span>
                </div>
                <Button
                    variant="outlined"
                    size="small"
                    className={classes.button}
                >
                  Choose file
                  <input
                      type="file"
                      accept=".jpeg, .pdf, .docx"
                      onChange={(e) => attachPackingSlips(e, items[0].offerId)}
                      style={{opacity: 0, position: "absolute", width: "100%"}}
                  />
                </Button>
              </div>
            )}
            <hr style={{marginBottom: '30px', marginTop: '30px'}}/>
          </>
          )
      )}
      {record.status !== STATUS.DELIVERED && Object.entries(groupedBySupplier).map(([supplierName, items]) => (
          <>
            {items?.[0].fileName &&
            <Typography variant="body2" gutterBottom component="p">
              {supplierName} {translate("konebone.orders.packing_slip")}
            </Typography>
            }
            {items.map((item, index) => (
                <div key={index}>
                  {!!item.fileName && (
                      <div className={classes.box}>
                        <div className={classes.infoText}>
                          <OrderIcon />{" "}
                          <span className={classes.infoText_descr}>{item.fileName}</span>
                        </div>
                        <div className={classes.buttonList}>
                          <a href={item.fileUrl}>
                            <Button variant="text" className={classes.actionButton}>
                              <DownloadIcon />
                            </Button>
                          </a>
                        </div>
                      </div>
                  )}
                  {item.offerId === errorSizeMessage && <p className={classes.error}>{translate("konebone.orders.packing_slip_error_maxSize")}</p>}
                  <Box mt="1em" style={{ marginTop: "34px" }} />
                </div>
            ))}
          </>
      ))}
    </Box>
  );
};

export default PackingSlips;
