import axios from 'axios';
import { useAuthState } from 'ra-core';
import React, { useEffect, useState, useRef } from 'react';

const VersionComponent = props => {
  const [version, setVersion] = useState("");
  const mounted = useRef(false);
  const { loading, authenticated } = useAuthState();

  useEffect(() => {
    if (!mounted.current && !loading && authenticated) {
      mounted.current = true;
      axios
        .get(process.env.REACT_APP_API_URL + "/environment", {
          withCredentials: true,
          headers: { 'accept': 'application/json' },
        }).then((response) => {
          if (response.data &&  mounted.current) {
            setVersion(response.data.data.commitTimeStamp);
          }
        })
    }

    return () => {
      mounted.current = false;
    };
  }, [loading, authenticated]);

  if (!authenticated || loading) {
    return null;
  }

  if (!version) {
    return null
  }

  return (
    <footer className="version-footer">
      <p>Build version: {version}</p></footer>
  );
};

export default VersionComponent;