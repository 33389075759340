import React, { useMemo } from 'react';
import ContentCreate from '@material-ui/icons/Create';
import { Link } from 'react-router-dom';
import { linkToRecord } from 'ra-core';
import { Button } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

const EditButtonSaveSearch = (props) => {
  const {
    basePath = '',
    icon = defaultIcon,
    label = 'ra.action.edit',
    record,
    scrollToTop = true,
    ...rest
  } = props;

  const { search } = useLocation();

  const handleClick = (e) => {
    e.stopPropagation();
    localStorage.setItem('listSearch', search);
  }

  return (
    <Button
      component={Link}
      to={useMemo(
        () => ({
          pathname: record
            ? linkToRecord(basePath, record.id)
            : '',
          state: { _scrollToTop: scrollToTop },
        }),
        [basePath, record, scrollToTop]
      )}
      label={label}
      onClick={handleClick}
      {...rest}
    >
      {icon}
    </Button>
  );
};

const defaultIcon = <ContentCreate />;

export default EditButtonSaveSearch;
