import { useAuthState, useTranslate, useVersion } from "ra-core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  useNotify,
  TopToolbar,
  Loading,
} from "react-admin";
import {
  Button,
  makeStyles,
  Box,
  Typography,
  Paper,
} from "@material-ui/core";
import { Link, useHistory, useParams } from "react-router-dom";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { cleanSession } from "../../authProvider";
import StepperForm from "../products/StepperForm";
import dataProviderForOffers from "../../providers/DataProviderForOffers";
import { wrapArrayToMap } from "../../utils/Wrapper";

const useStyles = makeStyles((theme) => ({
  toolbar_root: {
    justifyContent: "space-between",
    "& button": {
      margin: theme.spacing(1),
    },
  },
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: "inline-block",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    textTransform: "uppercase",
    fontWeight: "600",
  },
}));

const ProductCreateActions = ({
  handlePrevious,
  handleNext,
  activeStep,
  isLastStep,
  offerId,
  orderId,
  id,
  data,
  handleSave
}) => {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <TopToolbar className={classes.toolbar_root}>
      <Box display="flex" flexDirection="column" alignItems="flex-start" ml="2em">
        <Button
          label={translate("konebone.offer.go_back_edit")}
          startIcon={<KeyboardBackspaceIcon />}
          component={Link}
          to={`../../${offerId}`}
          style={{textTransform: "initial"}}
        >
          {translate("konebone.offer.go_back_edit")}
        </Button>
        <Typography variant="h6" gutterBottom>
          {translate("konebone.offer.add_new_product").toUpperCase()} <span style={{ color: "red" }}>#{orderId}</span>
        </Typography>
        <Box>
          <Typography variant="subtitle2" gutterBottom className={classes.subtitle}>
            {translate("konebone.offer.supplier_info")}
          </Typography>
          <Typography variant="body2">{data.supplierInfo}</Typography>
          <Typography variant="body2">{data.supplierContact}</Typography>
        </Box>
      </Box>
      <Box>
        <Button
          variant="outlined"
          color="secondary"
          label={translate("konebone.products.previous")}
          onClick={handlePrevious}
          disabled={activeStep === 0}
        >
          {translate("konebone.products.previous")}
        </Button>
        {isLastStep() ? (
          <Button
            onClick={handleSave}
            color="secondary"
            variant="contained"
            label={translate("ra.action.create")}
          >
            {translate("ra.action.create")}
          </Button>
        ) : (
          <Button
            color="secondary"
            variant="contained"
            onClick={handleNext}
            label={translate("konebone.products.next")}
          >
            {translate("konebone.products.next")}
          </Button>
        )}
      </Box>
    </TopToolbar>
  );
};

const AlternativeProductCreate = (props) => {
  const [record, setRecord] = useState({});
  const [supplierInfo, setSupplierInfo] = useState({});
  const [load, setLoad] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [preActiveStep, setPreActiveStep] = useState(0);
  const version = useVersion();
  const { id, offerId, orderId } = useParams();
  const notify = useNotify();
  const translate = useTranslate();
  const history = useHistory();
  const { loading, authenticated } = useAuthState();

  const steps = [
    translate("konebone.products.general_info"),
    translate("konebone.products.price_lines"),
  ];

  const totalSteps = steps.length;

  const mounted = useRef(false);

  const newProductId = useRef(0);

  useEffect(() => {
    if (!mounted.current && id) {
      mounted.current = true;
      setLoad(true);

      dataProviderForOffers.getNewProduct("offer", { offerId , productId: id })
        .then((item) => {
          if (mounted.current) {
            if (item?.data) {
              setRecord(item.data.alternativeProduct);
              setSupplierInfo(item.data.supplier);
            }
            setLoad(false);
          }
        }).catch((error) => {
          setLoad(false);
          if (!error.response) {
            console.error(error);
            return;
          }
          let { status } = error.response;
          if (status === 401 || status === 403) {
            cleanSession();
            window.location.href = "/login";
          }
          if (status === 500) {
            notify(
              error.response.data?.error,
              "warning"
            )
          }
          notify(
            error.response.data?.message?.message,
            "warning",
            wrapArrayToMap(error.response.data?.message?.parameters)
          );
        });
    }
    return () => {
      mounted.current = false;
    };
  }, [version]);

  const isLastStep = useCallback(() => {
    return activeStep === totalSteps - 1;
  }, [activeStep]);

  const handleStep = (step) => () => {
    setPreActiveStep(step);
  };

  const handleToStep = useCallback((step) => {
    setActiveStep(step);
    setPreActiveStep(step);
  });

  const handlePrevious = (e) => {
    if (preActiveStep === 0) {
      return;
    }
    setPreActiveStep(preActiveStep - 1);
  };

  const handleNext = (e) => {
    setPreActiveStep(preActiveStep + 1);
  };

  const setNewProductId = (value) => {
    newProductId.current = value;
  }

  const handleSave = async() => {
    try {
      await dataProviderForOffers.setAlternativeProduct("offers", {
        productId: id,
        offerId: offerId,
        alternativeProductId: newProductId.current,
      });

      history.push(`../../${offerId}`);
    } catch (error) {
      if (!error.response) {
        console.error(error);
        return;
      }
      let { status } = error.response;
      if (status === 401 || status === 403) {
        cleanSession();
        window.location.href = "/login";
      }
      if (status === 500) {
        notify(
          error.response.data?.error,
          "warning"
        );
        return;
      }
      notify(
        error.response.data?.message?.message,
        "warning",
        wrapArrayToMap(error.response?.data?.message?.parameters)
      );
    }
  };

  if (!authenticated || loading) {
    return null;
  }

  if (load) {
    return <Loading />
  }

  return (
    <Paper key={version}>
      <ProductCreateActions
        basePath="/offers" 
        resource="offers" 
        data={supplierInfo} 
        activeStep={activeStep}
        handleNext={handleNext}
        handlePrevious={handlePrevious}
        isLastStep={isLastStep}
        orderId={orderId}
        offerId={offerId}
        id={id}
        handleSave={handleSave}
      />
      <StepperForm
        steps={steps}
        activeStep={preActiveStep}
        handleToStep={handleToStep}
        handleStep={handleStep}
        isLastStep={isLastStep}
        initGeneralInfo={record}
        setNewProductId={setNewProductId}
      />
    </Paper>
  );
};

export { AlternativeProductCreate };
