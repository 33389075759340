import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import { useNotify, useTranslate } from "ra-core";
import { useEffect, useRef, useState } from "react";
import dataProviderForCustomers from "../providers/DataProviderForCustomers";
import dataProviderForSupplier from "../providers/DataProviderForSupplier";
import { handleFailure } from "../utils/handleFailure";
import { AdminActionLoged } from "./AdminActionLoged";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBlock: "30px",
  },
  button: {
    marginTop: "28px",
  },
  link: {
    paddingRight: "30%",
    minWidth: "150px",
  },
}));

const INITIAL_COUNT = 15;
const STATUS = {
  STARTED: "Started",
  STOPPED: "Stopped",
};

const AccessLink = (props) => {
  const [secondsRemaining, setSecondsRemaining] = useState(INITIAL_COUNT);
  const [status, setStatus] = useState(STATUS.STOPPED);
  const [link, setLink] = useState("");
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();
  const { record, resource } = props;


  const secondsToDisplay = secondsRemaining % 60;
  const minutesRemaining = (secondsRemaining - secondsToDisplay) / 60;
  const minutesToDisplay = minutesRemaining % 60;
  const hoursToDisplay = (minutesRemaining - minutesToDisplay) / 60;

  const twoDigits = (num) => String(num).padStart(2, "0");

  const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  };

  const getAccessLink = () => {
    if (resource === 'customer') {
      dataProviderForCustomers
      .getAccessLink("/customer", { customerId: record.id })
      .then((data) => {
        if (data) {
          setSecondsRemaining(INITIAL_COUNT);
          setStatus(STATUS.STARTED);
          setLink(data);
        }
      })
      .catch((error) => {
        handleFailure(error, notify)
      });
    } 
    if (resource === 'supplier') {
      dataProviderForSupplier
      .getAccessLink("/supplier", { supplierId: record.id })
      .then((data) => {
        if (data) {
          setSecondsRemaining(INITIAL_COUNT);
          setStatus(STATUS.STARTED);
          setLink(data);
        }
      })
      .catch((error) => {
       handleFailure(error, notify)
      });
    }
  };

  useInterval(
    () => {
      if (secondsRemaining > 0) {
        setSecondsRemaining(secondsRemaining - 1);
      } else {
        setLink("");
        setStatus(STATUS.STOPPED);
      }
    },
    status === STATUS.STARTED ? 1000 : null
  );

  return (
    <>
      <Box className={classes.root}>
        <Box display="flex" justifyContent="flex-start">
          <Box className={classes.link}>
            <Typography variant="subtitle2">
              {translate("konebone.customer.link")}
            </Typography>
            {link ? (
              <a href={link.data} target={"_blank"}>
                {link.data}
              </a>
            ) : (
              "-"
            )}
          </Box>
          <Box>
            <Typography variant="subtitle2">
              {translate("konebone.customer.time")}
            </Typography>
            <Typography variant="subtitle2">
              {twoDigits(hoursToDisplay)}:{twoDigits(minutesToDisplay)}:
              {twoDigits(secondsToDisplay)}{" "}
              {translate("konebone.customer.second")}
            </Typography>
          </Box>
        </Box>
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          onClick={getAccessLink}
          size="medium"
        >
          {translate("konebone.customer.generate_access_link")}
        </Button>
      </Box>
        <AdminActionLoged props={props} record={record} resource={resource} link={link}/>
    </>
  );
};

export default AccessLink;