import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { MenuItem } from "@material-ui/core";
import { useTranslate } from "ra-core";
import { Link } from "react-router-dom";

const TreeNodeActionsMenu = ({
  basePath,
  children,
  parentSource,
  positionSource,
  record,
  resource,
  nodes,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const translate = useTranslate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="More"
        aria-owns={open ? "long-menu" : null}
        aria-haspopup="true"
        onClick={handleClick}
        {...props}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        {...props}
      >
        <MenuItem
          component={Link}
          onClick={handleClose}
          disabled={record.productsCount > 0}
          to={{
            pathname: `${basePath}/create`,
            state: {
              record: {
                [parentSource]: record.id,
                [positionSource]: nodes ? nodes.length : 0,
              },
            },
          }}
        >
          {translate("ra.tree.add_child_node")}
        </MenuItem>

        <MenuItem
          component={Link}
          onClick={handleClose}
          to={{
            pathname: `${basePath}/create`,
            state: {
              record: {
                [parentSource]: record[parentSource],
                [positionSource]: record[positionSource],
              },
            },
          }}
        >
          {translate("ra.tree.add_node_before")}
        </MenuItem>

        <MenuItem
          component={Link}
          onClick={handleClose}
          to={{
            pathname: `${basePath}/create`,
            state: {
              record: {
                [parentSource]: record[parentSource],
                [positionSource]: record[positionSource] + 1,
              },
            },
          }}
        >
          {translate("ra.tree.add_node_after")}
        </MenuItem>

        {/* <MenuItem
          component={Link}
          onClick={handleClose}
          to={{
            pathname: `${basePath}/${record.id}`,
            state: { record: record },
          }}
        >
          {translate("ra.action.edit")}
        </MenuItem>
        <DeleteMenuItem
          basePath={basePath}
          parentSource={parentSource}
          record={record}
          resource={resource}
          positionSource={positionSource}
          {...props}
        /> */}
      </Menu>
    </div>
  );
};

// const TreeNodeActionsMenuWrapped = React.forwardRef((props, ref) => (
//   <TreeNodeActionsMenu {...props} ref={ref} />
// ));

export default TreeNodeActionsMenu;
