
const calculation = (value, exp) => {
  value = value.toString().split('e');
  value = Math.round(+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
  value = value.toString().split('e');
  return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
}

const pad = (number) => {
  if (number < 10) {
    return '0' + number;
  }
  return number;
};

export { calculation, pad };