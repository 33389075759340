import React, { useState, useEffect } from 'react';
import { Layout, useRedirect } from 'react-admin';
import { useSelector } from 'react-redux';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import MyMenu from "./MyMenu";
import axios from "axios";
import { cleanSession, setUserInfo } from "../authProvider";
import { useLocation, useHistory } from 'react-router-dom';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const MyLayout = props => {
  const open = useSelector(state => state.admin.ui.sidebarOpen);
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const redirect = useRedirect();

  useEffect(() => {
    setLoading(true);

    axios
      .get(process.env.REACT_APP_API_URL + "/login", {
        withCredentials: true,
        headers: { 'accept': 'application/json' },
      }).then((response) => {
        if (!response.data?.data?.user) {
          cleanSession();
          redirect('/login');
          history.replace({
            ...history.location,
            state: { nextPathname: location.pathname },
            search: undefined,
          });
          return;
        }

        setUserInfo(response.data.data.user);
        setLoading(false);
      }).catch((error) => {
        console.error(error);
      })
  }, []);


  if (loading) {
    return null;
  }

  return (
    <StylesProvider jss={jss}>
      <Layout
        {...props} menu={MyMenu} className={`layout${open ? ' open' : ''}`}
      />
    </StylesProvider>
  );
};

export default MyLayout;