import Wrapper from "../utils/Wrapper";

import axios from "axios";
import { stringify } from "query-string";

const dataProviderForPrices = {
  getOne: async (resource, params) => {
    const url = process.env.REACT_APP_API_URL + "/adminside/product";
    const response = await axios.get(url, {
      withCredentials: true,
      headers: { accept: "application/json" },
    });
    let resultData = Wrapper.wrapProduct(response.data.data);
    return {
      data: resultData,
    };
  },

  getList: async (resource, params) => {
    const { productSupplierId } = params.filter;
    if (!productSupplierId) {
      return {
        data: [],
        total: 0,
      }
    }

    const url =
      process.env.REACT_APP_API_URL + `/product_price/${productSupplierId}`;
    const response = await axios.get(url, {
      withCredentials: true,
      headers: { accept: "application/json" },
    });

    if (!response.data.data) {
      return {
        data: [],
        total: 0,
      };
    }

    let resultData = response.data.data.map((product) =>
      Wrapper.wrapPrice(product)
    );
    const totalElements = response.data.data.totalElements;
    return {
      data: resultData,
      total: totalElements,
    };
  },

  create: async (resource, params) => {
    // const data = {
    //   description: params.data.description,
    //   name: params.data.name,
    //   image: params.data.image?.src,
    //   parentCategoryId: params.data.parent_id || defaultParentId,
    //   position: params.data.position || 0,
    // };
    let response = await axios.post(
      process.env.REACT_APP_API_URL + "/product_price",
      params.data,
      {
        withCredentials: true,
        headers: { accept: "application/json" },
      }
    );

    let resultData = Wrapper.wrapPrice(response.data.data);

    return {
      data: resultData,
    };
  },

  update: async (resource, { id, data }) => {
    // const updateData = {
    //   categoryId: id,
    //   description: data.description,
    //   name: data.name,
    //   image: data.image?.src,
    //   parentCategoryId: data.parent_id || 0,
    //   position: data.position || 0,
    // };

    let response = await axios.put(
      process.env.REACT_APP_API_URL + `/product_price/${id}`,
      data,
      {
        withCredentials: true,
        headers: { accept: "application/json" },
      }
    );

    let resultData = Wrapper.wrapPrice(response.data.data);

    return {
      data: resultData,
    };
  },

  delete: async (resource, { id }) => {
    await axios.delete(
      process.env.REACT_APP_API_URL + `/product_price/${id}`,
      {
        withCredentials: true,
        headers: { accept: "application/json" },
      }
    );

    return {
      data: true,
    };
  },
};

export default dataProviderForPrices;
