import React, { useState } from "react";
import {
  TextField,
  TopToolbar,
  DeleteButton,
  useNotify,
  useQuery,
  Loading,
  ListContextProvider,
  Pagination,
} from "react-admin";
import keyBy from "lodash/keyBy";
import { wrapArrayToMap } from "../utils/Wrapper";
import { useRefresh, useTranslate } from "ra-core";
import DatagridResponsive from "./DataGridResponsive";
import { cleanSession } from "../authProvider";
import { makeStyles, Paper, Typography } from "@material-ui/core";
import classnames from "classnames";

const useStyles = makeStyles((theme) => ({
  tolbar_root: {
    justifyContent: "space-between",
    "& > *": {
      margin: theme.spacing(1.5),
    },
  },
  paper_mr: {
    marginTop: "10px",
  }
}));

const ListActions = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter, // you can hide ExportButton if exporter = (null || false)
  filterValues,
  permanentFilter,
  hasCreate, // you can hide CreateButton if hasCreate = false
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  ...rest
}) => {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <TopToolbar className={classnames(className, classes.tolbar_root)}>
      <Typography variant="h6" gutterBottom margin="dense">
        {translate(
          "konebone.project.title"
        ).toUpperCase()}
      </Typography>
    </TopToolbar>
  )
}

ListActions.defaultProps = {
  selectedIds: [],
  onUnselectItems: () => null,
};
const ProjectDeleteButton = ({ record }) => {
  const notify = useNotify();
  const refresh = useRefresh();

  const onSuccess = () => {
    notify("ra.notification.deleted", "success");
    refresh();
  };

  const handleFailure = (error) => {
    if (!error.response) {
      console.error(error);
      return;
    }
    let { status } = error.response;
    if (status === 401 || status === 403) {
      cleanSession();
      window.location.href = "/login";
    }
    if (status === 500) {
      notify(
        error.response.data?.error,
        "warning"
      )
      return;
    }
    notify(
      error.response?.data?.message?.message,
      "warning",
      wrapArrayToMap(error.response?.data?.message?.parameters)
    );
  };
  return (
    <div className="listButton">
      <DeleteButton
        resource="projects"
        mutationMode="pessimistic"
        onFailure={handleFailure}
        label={""}
        record={record}
        onSuccess={onSuccess}
      />
    </div>
  );
};

export const ProjectsList = (props) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [sort, setSort] = useState({ field: 'name', order: 'ASC' })

  const classes = useStyles();
  const translate = useTranslate();
  const { data, total, loading, error } = useQuery({
    type: 'getList',
    resource: 'projects',
    payload: {
      pagination: { page, perPage },
      sort,
      filter: { customerId: props.entityId },
    }
  });

  if (loading) {
    return <Loading />
  }
  if (error) {
    return <p>ERROR: {error}</p>
  }
  return (
    <Paper className={classes.paper_mr}>
      <ListContextProvider
        value={{
          total,
          page,
          perPage,
          setPage,
          setSort
        }}>
        <ListActions />
        <DatagridResponsive className={"sticky-last-column"}
          data={keyBy(data, 'id')}
          ids={data.map(({ id }) => id)}
          currentSort={sort}
          setSort={(field, order) => setSort({ field, order })}>
          <TextField
            source="name"
            label={translate("konebone.project.project_name")}
            sortable={false}
          />
          <TextField
            source="contactFullName"
            label={translate("konebone.project.contact_full_name")}
            sortable={false}
          />
          <TextField
            source="contactPhone"
            label={translate("konebone.project.contact_phone")}
            sortable={false}
          />
          <TextField
            source="address"
            label={translate("konebone.project.address")}
            sortable={false}
          />
          {/* <ProjectDeleteButton /> */}
          <></>
        </DatagridResponsive>
        {data.length ? <Pagination
          page={page}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
        /> : <></>}
      </ListContextProvider>
    </Paper>
  )
};
