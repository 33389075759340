import {
  Avatar,
  Dialog,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Typography,
  TextField,
  DialogContent,
  Box,
  List,
  ListItemSecondaryAction,
  Button
} from "@material-ui/core";
import { useRef, useState } from "react";
import { useNotify, useTranslate } from "ra-core";
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import NoImage from '../../images/no_image.png';
import { Link, useParams } from "react-router-dom";
import dataProviderForProducts from "../../providers/DataProviderForProducts";
import { wrapArrayToMap } from "../../utils/Wrapper";
import { cleanSession } from "../../authProvider";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    width: "26px",
    height: "26px",
    margin: "0",
    padding: "0",
    overflow: "hidden",
    borderRadius: "50%",
    cursor: "pointer",
    position: "absolute",
    top: "0",
    right: "auto",
    left: "-38px",
    bottom: "auto",
    backgroundColor: "white",
    "&:hover": {
      opacity: "0.75",
    },
  },
  link: {
    color: "red",
    textDecoration: "underline",
  },
  avatar: {
    marginTop: 0,
    marginRight: "20px",
    "& > div": {
      padding: "5px 10px",
      height: "80px",
      width: "80px",
      borderRadius: 0,
      border: "1px solid #E5E9EB"
    }
  },
  searchResult: {
    position: "absolute",
    background: "#FFFFFF",
    border: "1px solid #EFEFEF",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.16)",
    overflow: 'auto',
    maxHeight: 320,
  }
}));

const useDialog = makeStyles((theme) => ({
  paper: {
    overflow: "inherit",
  },
}));

const useAction = makeStyles((theme) => ({
  root: {
    paddingRight: "90px",
  },
}));

const AddAlternativeProductDialog = (props) => {
  const [products, setProducts] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const classes = useStyles();
  const dialogClasses = useDialog();
  const action = useAction();
  const translate = useTranslate();
  const notify = useNotify();
  const { id } = useParams();
  const { onClose, row, open, supplierId, supplierName } = props;

  let searchStart = false;

  const searchComponent = useRef(null);
  const dialog = useRef(null);

  const handleClose = () => {
    setSearchValue("");
    onClose();
  };

  const handleSearch = async (e) => {
    const { value } = e.target;
    if (searchStart) {
      return;
    }

    searchStart = true;

    try {
      const response = await dataProviderForProducts.searchAlternative("product", {
        supplierId: supplierId,
        search: value,
      });

      if (response.data) {
        setProducts(response.data);
        setSearchValue(value);
        searchStart = false;
      }
    } catch (error) {
      if (!error.response) {
        console.error(error);
        return;
      }
      let { status } = error.response;
      if (status === 401 || status === 403) {
        cleanSession();
        window.location.href = "/login";
      }
      if (status === 500) {
        notify(
          error.response.data?.error,
          "warning"
        )
      }
      notify(
        error.response.data?.message?.message,
        "warning",
        wrapArrayToMap(error.response.data?.message?.parameters)
      );
    }
  };

  const addAlternative = async (newProduct) => {
    setSearchValue("");
    onClose(newProduct);
  };

  const top = (searchComponent?.current?.getBoundingClientRect()?.bottom || 0) - (dialog?.current?.getBoundingClientRect()?.top || 0);
  const left = (searchComponent?.current?.getBoundingClientRect()?.left || 0) - (dialog?.current?.getBoundingClientRect()?.left || 0);
  const width = searchComponent?.current?.getBoundingClientRect()?.width;

  return (
    <Dialog classes={dialogClasses} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogContent ref={dialog}>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>

        {searchValue ?
          products.length ? (
            <List className={classes.searchResult} style={{ top, left, width, minWidth: width }}>
              {products?.map((row) => (
                <ListItem key={row.productId} classes={action}>
                  <ListItemAvatar className={classes.avatar}>
                    <Avatar alt="product picture" src={row.fileName || NoImage} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <p className="code">
                        <span className="product_number">{row.catalogNumber || row.productId}</span>
                        <span className="supplier_number" style={{ paddingRight: "0" }}>ט"קמ</span>
                        <span className="supplier_number">{row.supplierPn}</span>
                      </p>
                    }
                    secondary={row.koneboneProductName}
                  />
                  <ListItemSecondaryAction>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() => addAlternative(row)}
                      label={translate("ra.action.add")}
                    >
                      {translate("ra.action.add")}
                    </Button>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          )
            : (
              <div className={classes.searchResult} style={{ top, left, width, minWidth: width }}>
                <div className="product_not_found">
                  <div className="container">
                    <div className="product_not_found_box">
                      <div className="banner">
                        <span />
                      </div>

                      <p className="header">
                        {translate('konebone.offer.no_search_results_for')} &quot;<span className="red">{searchValue}</span>&quot;
                      </p>

                      <p>{translate('konebone.offer.no_search_text_alternative')}</p>

                      <p><Link to={(location) => `${location.pathname}/product/${row.productId}`} className={classes.link}>
                        {translate("konebone.offer.product_not_in_catalog")}
                      </Link></p>

                    </div>
                  </div>
                </div>
              </div>
            )
          : null
        }

        <Typography variant="h6" gutterBottom id="simple-dialog-title">{translate("konebone.offer.add_alternative_product")}</Typography>

        <Typography variant="body2" gutterBottom>{translate("konebone.offer.alternative_dialog_message", { arg: supplierName })}</Typography>

        <Box mb="1em" >
          <ListItem >
            <ListItemAvatar className={classes.avatar}>
              <Avatar alt="product picture" src={row.fileName || NoImage} />
            </ListItemAvatar>
            <ListItemText
              primary={
                <p className="code">
                  <span className="product_number">{row.catalogNumber || row.productId}</span>
                  <span className="supplier_number" style={{ paddingRight: "0" }}>ט"קמ</span>
                  <span className="supplier_number">{row.supplierPn}</span>
                </p>
              }
              secondary={row.name}
            />
          </ListItem>
        </Box>

        <Box mb="1em">
          <TextField
            type="search"
            label=""
            placeholder={translate("konebone.offer.search_alternative")}
            color="secondary"
            variant="outlined"
            fullWidth
            onChange={handleSearch}
            value={searchValue}
            ref={searchComponent}
            InputProps={{
              endAdornment: !searchValue && (
                <InputAdornment>
                  <SearchIcon color="disabled" />
                </InputAdornment>
              )
            }}
          />
        </Box>

        <Box mb="1em">
          <Link to={(location) => `${location.pathname}/product/${row.productId}`} className={classes.link}>
            {translate("konebone.offer.product_not_in_catalog")}
          </Link>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default AddAlternativeProductDialog;
