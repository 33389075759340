import React, { useEffect, useRef, useState } from "react";
import {
  ImageField,
  Create,
  ImageInput,
  SimpleForm,
  TextInput,
  DeleteButton,
  EditButton,
  Edit,
  useNotify,
  SaveButton,
} from "react-admin";
import {
  Tree,
  TreeNode,
  TreeList,
  TreeNodeActionsMenu,
} from "../raTreeMaterial";
import { downloadCSV, useAuthState, useRedirect, useRefresh, useTranslate, useVersion } from "ra-core";
import Validator from "../utils/Validator";
import jsonExport from "jsonexport/dist";
import { wrapArrayToMap } from "../utils/Wrapper";
import TreeService from "../services/TreeService";
import { cleanSession } from "../authProvider";
import { Box, Toolbar } from "@material-ui/core";
import dataProviderForCategories from "../providers/DataProviderForCategories";

const exporter = (categories) => {
  const categoriesForExport = categories.map((record) => {
    const { id, name, pageText, metaDescription, metaTitle, image, parent_id } = record; // omit backlinks and author
    return { id, name, pageText, metaDescription, metaTitle, image, parent_id };
  });
  jsonExport(
    categoriesForExport,
    {
      headers: ["id", "name", "pageText", "metaDescription", "metaTitle", "image", "parentCategoryId"], // order fields in the export
    },
    (err, csv) => {
      downloadCSV(csv, "categories"); // download as 'categories.csv` file
    }
  );
};

const CategoryEditButton = ({ record }) => (
  <EditButton basePath="/categories" label="" record={record} />
);

const CategoryDeleteButton = ({ record }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const translate = useTranslate();

  const onSuccess = () => {
    notify("ra.notification.deleted", "success");
    refresh();
  };

  const handleFailure = (error) => {
    if (!error.response) {
      console.error(error);
      return;
    }
    let { status } = error.response;
    if (status === 401 || status === 403) {
      cleanSession();
      window.location.href = "/login";
    }
    if (status === 500) {
      notify(
        error.response.data?.error,
        "warning"
      )
      return;
    }
    const params = wrapArrayToMap(error.response.data?.message?.parameters);

    if (params?.arg_1) {
      params.arg_1 = translate(params.arg_1);
    }

    notify(
      error.response.data?.message?.message,
      "warning",
      params
    );
  };

  return (
    <DeleteButton
      mutationMode="pessimistic"
      onFailure={handleFailure}
      confirmContent={ `The SEO settings may be lost.` +
       `You will not be able to recover this record. Are you sure?`}
      label=""
      record={record}
      onSuccess={onSuccess}
    />
  );
};

const CategoryNameField = (props) => {
  const { record } = props;

  return (
    <div style={{ width: "100%" }}>
      <span>{record.name}</span> <div style={{ width: "50px", display: "inline-block" }}>({record.productsCount})</div>
    </div>
  )
}

export const CategoriesList = (props) => {
  const translate = useTranslate();
  const version = useVersion();

  const updateProps = {
    actions: props.actions,
    aside: props.aside,
    basePath: props.basePath,
    children: props.children,
    classes: props.classes,
    className: props.className,
    exporter: null,
    filter: props.filter,
    hasCreate: props.hasCreate,
    parentSource: "parent_id",
    positionSource: "position",
    resource: props.resource,
    version: version,
    title: translate("konebone.categories.title").toUpperCase(),
    createlabel: "konebone.categories.create_button",
  };

  const { loading, authenticated } = useAuthState();

  useEffect(() => {
    return () => {
      TreeService.resetAllNodes();
    };
  }, [])

  if (!authenticated || loading) {
    return null;
  }

  return (
    <Tree {...updateProps} key={version}>
      <TreeList title={translate("konebone.categories.title").toUpperCase()}>
        <TreeNode actions={<TreeNodeActionsMenu />}>
          <CategoryNameField
            source="name"
            label={translate("konebone.categories.name")}
          />
          <CategoryEditButton />
          <CategoryDeleteButton />
        </TreeNode>
      </TreeList>
    </Tree>
  );
};

export const CategoryCreate = (props) => {
  const translate = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();
  const { loading, authenticated } = useAuthState();
  const inintialValues = useRef(props?.location?.state?.record);

  const handleFailure = (error) => {
    if (!error.response) {
      console.error(error);
      return;
    }
    let { status } = error.response;
    if (status === 401 || status === 403) {
      cleanSession();
      window.location.href = "/login";
    }
    if (status === 500) {
      notify(
        error.response.data?.error,
        "warning"
      )
      return;
    }
    notify(
      error.response.data?.message?.message,
      "warning",
      wrapArrayToMap(error.response.data?.message?.parameters)
    );
  };

  const onSuccess = () => {
    notify("ra.notification.created", "success");
    redirect("list", props.basePath);
  };

  if (!authenticated || loading) {
    return null;
  }

  return (
    <Create {...props} onFailure={handleFailure} onSuccess={onSuccess}>
      <SimpleForm
        redirect={props.basePath}
        submitOnEnter={false}
        initialValues={inintialValues.current}
      >
        <TextInput
          source="name"
          className='category_create_edit'
          label={translate("konebone.categories.name")}
          validate={Validator.validateName}
          variant="outlined"
        />
        <TextInput
            className='category_create_edit'
            source="pageText"
            label={translate("konebone.categories.description")}
            variant="outlined"
            rows="4"
            multiline
        />
        <TextInput
            source="metaTitle"
            className='category_create_edit'
            label={translate("konebone.categories.metaTitle")}
            variant="outlined"
        />
        <TextInput
            source="metaDescription"
            className='category_create_edit'
            label={translate("konebone.categories.metaDescription")}
            variant="outlined"
        />
        <ImageInput
          source="image"
          accept="image/*"
          label={translate("konebone.categories.image")}
        >
          <ImageField
            source="src"
            title={translate("konebone.categories.image")}
          />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};

const CategoryEditToolbar = props => {
  const notify = useNotify();
  const redirect = useRedirect();
  const translate = useTranslate();

  const onSuccess = () => {
    notify("ra.notification.deleted", "success");
    redirect('list', props.basePath);
  };

  const handleFailure = (error) => {
    if (!error.response) {
      console.error(error);
      return;
    }
    let { status } = error.response;
    if (status === 401 || status === 403) {
      cleanSession();
      window.location.href = "/login";
    }
    if (status === 500) {
      notify(
        error.response.data?.error,
        "warning"
      )
      return;
    }
    notify(
      error.response.data?.message?.message,
      "warning",
      wrapArrayToMap(error.response.data?.message?.parameters)
    );
  };

  return (
    <Toolbar >
      <Box display="flex" justifyContent="space-between" width="100%">
        <SaveButton
          disabled={props.pristine}
          saving={props.saving}
          handleSubmitWithRedirect={props.handleSubmitWithRedirect}
        />
        <DeleteButton
          mutationMode="pessimistic"
          onFailure={handleFailure}
          onSuccess={onSuccess}
          confirmContent={ `The SEO settings may be lost.` +
          `You will not be able to recover this record. Are you sure?`}
          label={translate("ra.action.delete")}
          record={props.record}
        />
      </Box>
    </Toolbar>
  )
};

export const CategoryEdit = (props) => {
  const [isNewImage, setIsNewImage] = useState(false);
  const translate = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();
  const { loading, authenticated } = useAuthState();

  const handleFailure = (error) => {
    if (!error.response) {
      console.error(error);
      return;
    }
    let { status } = error.response;
    if (status === 401 || status === 403) {
      cleanSession();
      window.location.href = "/login";
    }
    if (status === 500) {
      notify(
        error.response.data?.error,
        "warning"
      )
      return;
    }
    notify(
      error.response.data?.message?.message,
      "warning",
      wrapArrayToMap(error.response.data?.message?.parameters)
    );
  };

  const onSuccess = () => {
    notify("ra.notification.updated", "success");
    redirect("list", props.basePath);
  };

  const handleChange = () => {
    setIsNewImage(true)
  };

  const handleRemove = () => {
    if (isNewImage) {
      return;
    }

    dataProviderForCategories.removePicture(props.id).catch((e) => {
      handleFailure(e);
    })
  }

  if (!authenticated || loading) {
    return null;
  }

  return (
    <Edit {...props} mutationMode="pessimistic" onFailure={handleFailure} onSuccess={onSuccess}>
      <SimpleForm submitOnEnter={false} redirect={props.basePath} toolbar={<CategoryEditToolbar />}>
        <TextInput
          source="name"
          className='category_create_edit'
          label={translate("konebone.categories.name")}
          validate={Validator.validateNameCategory}
          variant="outlined"
          fullWidth
        />
        <TextInput
          source="pageText"
          className='category_create_edit'
          label={translate("konebone.categories.description")}
          variant="outlined"
          rows="4"
          multiline
        />
        <TextInput
            source="metaTitle"
            className='category_create_edit'
            label={translate("konebone.categories.metaTitle")}
            variant="outlined"
            fullWidth
        />
        <TextInput
            source="metaDescription"
            className='category_create_edit'
            label={translate("konebone.categories.metaDescription")}
            variant="outlined"
            fullWidth
        />
        <ImageInput
          source="image"
          accept="image/*"
          label={translate("konebone.categories.image")}
          onChange={handleChange}
          options={{ onRemove: handleRemove }}
        >
          <ImageField
            source="src"
            title={translate("konebone.categories.image")}
          />
        </ImageInput>
      </SimpleForm>
    </Edit >
  );
};
