import {
  BooleanInput,
  EditButton,
  ListContextProvider,
  Pagination,
  useNotify,
  useRefresh,
  useTranslate
} from "react-admin";
import { Form } from 'react-final-form';
import { makeStyles } from "@material-ui/core/styles";
import { Button, Box, Stack, Paper } from "@material-ui/core";
import CreditInfo from "./CreditInfo";
import {CreditHistory} from "./CreditHistory";
import { useState } from "react";
import CreditCreate from "./CreditCreate";
import { useEffect } from "react";
import dataProviderForCredit from "../../providers/DataProviderForCredit";
import CreditRestore from "./CreditRestore";
import CreditEdit from "./CreditEdit";
import { handleFailure } from "../../utils/handleFailure";

const useStyles = makeStyles((theme) => ({
  button: {
    border: "1px solid #E9E9E9",
    borderRadius: "5px",
    color: "#000000",
    "& > label": {
      fontSize: "14px",
    },
    height: '47px',
    padding: "1em 14px",
  },
  booleanInput: {
    height: '47px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    "& > span": {
      color: 'red'
    }
  },
  creditInfo: {
    border: "1px solid #E9E9E9",
    borderRadius: "2px",
    padding: "1em", 
    marginTop: "2em"
  },
  textField: {
    margin: 0,
    marginRight: "30px",
    width: "100%",
    "& > div": {
      maxHeight: "40px",
      width: "100%",
    },
    "& > label": {
      fontSize: "14px",
    },
  },
  datePayment: {
    padding: '25px 0'
  },
  editButton: {
    height: '40px',
    border: '1px solid #DDDBDA',
    borderRadius: "5px",
    "& > span": {
      color: '#484848',
      "& > svg": {
        color: '#B0ADAB'
      }
    },
  },
  paper_mr: {
    padding: "20px",
    marginTop: "50px",
  },
  no_data: {
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center',
    margin: '50px 0',
  }
}));


const CreditPaymentShow = ({record}) => {
  const [openRestorePopup, setOpenRestorePopup] = useState(false);
  const [openLinePopup, setOpenLinePopup] = useState(false);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [sendDialogClosed, setSendDialogClosed] = useState(false);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [sort, setSort] = useState({ field: 'id', order: 'DESC' });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);

  const translate = useTranslate();
  const classes = useStyles();
  const refresh = useRefresh();
  const notify = useNotify();

    useEffect(() => {
      if (!record.id) {
        return;
      }
      dataProviderForCredit.getCredit('customer', {
        sort: sort,
        pagination: { page, perPage },
        filter: { customerId: record.id },
       })
     .then(({ data }) => {
       if (data) {
        setData(data);
        setTotal(data?.payments?.totalElements);
        setLoading(false);
       }
     })
      .catch((error) => {
        setLoading(false);
        handleFailure(error, notify)
      });
  }, [page])

  const handleChange = async (e) => {
    if(!data.active && !data.limit){
      setOpenLinePopup(e)
    } else if (data.active) {
      try {
        await dataProviderForCredit.turnOffCredit("customer", {customerId: record.id})
        refresh();
      } catch (error) {
        handleFailure(error, notify)
      }
    } else {
      try {
        await dataProviderForCredit.turnOnCredit("customer", {customerId: record.id})
        refresh();
      } catch (error) {
        handleFailure(error, notify)
      }
    }
  }

  const onSubmit = (e) => {
  };

  const openHandleClickRestore = () => {
    setOpenRestorePopup(true)
  }

  const openHandleClickEdit = () => {
    setOpenEditPopup(true)
  }

  const closeSendDialog = () => {
    setSendDialogClosed(false);
    refresh();
  }

return (
  <Form onSubmit={onSubmit}>
    {({ handleSubmit }) => (
      <form onSubmit={handleSubmit} >
        <Box 
          className={classes.button} 
          display='flex' 
          alignItems="center"
          justifyContent="space-between">
            {translate("konebone.customer.pay_later")}
          <BooleanInput
            className={classes.booleanInput}
            color="secondary"
            variant="standard"
            label={false}
            source="openLinePopup"
            onChange={handleChange}
            defaultValue={data.active}
          />
        </Box>
        <Box 
          className={classes.creditInfo}  
          display='flex' 
          alignItems='center'
          justifyContent="space-between">
            <CreditInfo data={data}/>
              {data.active && 
                <EditButton 
                  label={translate("konebone.offer.edit")} 
                  variant="outlined" 
                  className={classes.editButton}
                  onClick={openHandleClickEdit}/>}
              {openEditPopup && 
                <CreditEdit 
                  open={openEditPopup} 
                  record={record} 
                  closeSendDialog={closeSendDialog} 
                  data={data}
                  disabled={!data.active}/>} 
              {openLinePopup &&  
                <CreditCreate 
                  open={openLinePopup} 
                  record={record} 
                  closeSendDialog={closeSendDialog} 
                  data={data}/>}
        </Box>
        <Box display='flex' className={classes.datePayment}>
          <Box>{translate("konebone.customer.expiration")} 
            <div><b>{!data.expireDate ? '-' : data.expireDate}</b></div>
          </Box>
          <Box style={{paddingLeft: '51px'}}>{translate("konebone.customer.extendid_payment")}
            <div><b>{!data.extendedTerm ? '-' : data.extendedTerm}</b></div>
          </Box>
        </Box>
          <Button
            variant="contained"
            color="secondary"
            onClick={openHandleClickRestore}
            size="medium"
            disabled={data.limit === data.available || !data.active} 
          >
            CL restore
            <CreditRestore record={record} closeSendDialog={closeSendDialog} data={data} open={openRestorePopup}/>
          </Button>
            <Paper className={classes.paper_mr} >
              <ListContextProvider
                className={classes.tableResponsive}
                value={{
                  total,
                  page,
                  perPage,
                  setPage,
                  setSort,
                  currentSort: { field: "id", order: "DESC" },
                }}>
              <CreditHistory props={record.id} data={data} currentSort={sort} loading={loading}/>
                {data?.payments?.content?.length ? <Pagination
                  page={page}
                  setPage={setPage}
                  perPage={perPage}
                  setPerPage={setPerPage}
                  total={total}
                /> : <div className={classes.no_data}>no data</div>}
            </ListContextProvider >
          </Paper>
        </form>
      )}
    </Form>
)
};

export default CreditPaymentShow;
