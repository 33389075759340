import * as React from "react";
import {
  List,
  TextField,
  CreateButton,
  TopToolbar,
  sanitizeListRestProps,
  ExportButton,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  EditButton,
  DeleteButton,
  useNotify,
} from "react-admin";
import { wrapArrayToMap } from "../utils/Wrapper";
import { cloneElement } from "react";
import { useAuthState, useRedirect, useRefresh, useTranslate } from "ra-core";
import Validator from "../utils/Validator";
import DatagridResponsive from "../components/DataGridResponsive";
import { cleanSession } from "../authProvider";

const ListActions = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter, // you can hide ExportButton if exporter = (null || false)
  filterValues,
  permanentFilter,
  hasCreate, // you can hide CreateButton if hasCreate = false
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  ...rest
}) => (
  <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
    {filters &&
      cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button",
      })}
    <CreateButton basePath="/measurement" />
    {exporter && (
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={{ ...filterValues, ...permanentFilter }}
        exporter={exporter}
        maxResults={maxResults}
      />
    )}
  </TopToolbar>
);

ListActions.defaultProps = {
  selectedIds: [],
};

export const MeasurementCreate = (props) => {
  const translate = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();
  const { loading, authenticated } = useAuthState();

  const onSuccess = () => {
    notify("ra.notification.created", "success");
    redirect("list", props.basePath);
  };

  const handleFailure = (error) => {
    if (!error.response) {
      console.error(error);
      return;
    }
    let { status } = error.response;
    if (status === 401 || status === 403) {
      cleanSession();
      window.location.href = "/login";
    }
    if (status === 500) {
      notify(
        error.response.data?.error,
        "warning"
      )
      return;
    }
    notify(
      error.response.data?.message?.message,
      "warning",
      wrapArrayToMap(error.response.data?.message?.parameters)
    );
    //   notify(translate(error.response?.data.message.message));
  };

  if (!authenticated || loading) {
    return null;
  }

  return (
    <Create {...props} onFailure={handleFailure} onSuccess={onSuccess}>
      <SimpleForm redirect={props.basePath} submitOnEnter={false}>
        <TextInput
          source="title"
          label={translate("konebone.measurement.name")}
          validate={Validator.validateNameMeasurement}
          variant="outlined"
        />
      </SimpleForm>
    </Create>
  );
};

export const MeasurementEdit = (props) => {
  const translate = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();
  const { loading, authenticated } = useAuthState();

  const onSuccess = () => {
    notify("ra.notification.updated", "success");
    redirect("list", props.basePath);
  };

  const handleFailure = (error) => {
    if (!error.response) {
      console.error(error);
      return;
    }
    let { status } = error.response;
    if (status === 401 || status === 403) {
      cleanSession();
      window.location.href = "/login";
    }
    if (status === 500) {
      notify(
        error.response.data?.error,
        "warning"
      )
      return;
    }
    notify(error.response.data?.message?.message, "warning");
  };

  if (!authenticated || loading) {
    return null;
  }

  return (
    <Edit {...props} mutationMode="pessimistic" onFailure={handleFailure} onSuccess={onSuccess}>
      <SimpleForm redirect="list" submitOnEnter={false}>
        <TextInput
          source="title"
          label={translate("konebone.measurement.name")}
          validate={Validator.validateNameMeasurement}
          variant="outlined"
        />
      </SimpleForm>
    </Edit>
  );
};

const MeasurementDeleteButton = ({ record }) => {
  const notify = useNotify();
  const refresh = useRefresh();

  const onSuccess = () => {
    notify("ra.notification.deleted", "success");
    refresh();
  };

  const handleFailure = (error) => {
    if (!error.response) {
      console.error(error);
      return;
    }
    let { status } = error.response;
    if (status === 401 || status === 403) {
      cleanSession();
      window.location.href = "/login";
    }
    if (status === 500) {
      notify(
        error.response.data?.error,
        "warning"
      )
      return;
    }
    notify(
      error.response.data?.message?.message,
      "warning",
      wrapArrayToMap(error.response.data?.message?.parameters)
    );
    //notify(translate(error.response?.data.message.message));
  };
  return (
    <div className="listButton">
      <DeleteButton
        mutationMode="pessimistic"
        onFailure={handleFailure}
        label={""}
        record={record}
        onSuccess={onSuccess}
      />
    </div>
  );
};

const MeasurementEditButton = ({ record }) => {
  return (
    <div className="listButton">
      <EditButton label={""} record={record} />
    </div>
  );
};

export const MeasurementList = (props) => {
  const translate = useTranslate();
  const { loading, authenticated } = useAuthState();

  if (!authenticated || loading) {
    return null;
  }

  return (
    <List
      title={translate("konebone.measurement.title").toUpperCase()}
      {...props}
      actions={<ListActions />}
      bulkActionButtons={false}
      exporter={null}
      sort={{ field: "id", order: "DESC" }}
    >
      <DatagridResponsive>
        <TextField source="id"
          label={translate("konebone.measurement.id")}
          sortable={false} />
        <TextField
          source="title"
          label={translate("konebone.measurement.name")}
          sortable={false}
        />
        <MeasurementEditButton />
        <MeasurementDeleteButton />
      </DatagridResponsive>
    </List>
  );
};
