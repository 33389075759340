import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Button,
  makeStyles
} from "@material-ui/core";
import dataProviderForOffers from "../../../providers/DataProviderForOffers";
import { useNotify, useTranslate, useVersion } from "ra-core";
import { useEffect,  useRef,  useState } from "react";
import OfferMatchesProducts from "./OfferMatchesProducts";
import { Loading } from "ra-ui-materialui";
import { handleFailure } from "../../../utils/handleFailure";

const useStyles = makeStyles((theme) => ({
  statusField: {
    minWidth: "89px",
    height: "26px",
    borderRadius: "3px",
    display: "table-cell",
    textAlign: "center",
    verticalAlign: "middle",
    textTransform: "capitalize",
  },
  small_btn: {
    width: "30px",
    minWidth: "30px",
    maxWidth: "30px",
    padding: "3px 0",
    border: "1px solid #DDDBDA",
    borderRadius: "5px"
  },
}));

export const OfferGroupDialog = ({suppliers, open, id, closeGroupDialog, disabledGroupButton }) => {
  const [isProductsMatches, setIsProductsMatches] = useState([]);
  const [load, setLoad] = useState(false);
  const version = useVersion();
  const classes = useStyles();
  const notify = useNotify();
  const translate = useTranslate();

  const suppliersValues = Object.values(suppliers);
  const suppliersId = Object.keys(suppliers).map((supplier,i) => Number(supplier));

  const mounted = useRef(false);

  useEffect(()=> {
    if (!mounted.current) {
      mounted.current = true;
      setLoad(true);
      dataProviderForOffers.compareOffer("/offer", {
        offers: suppliersId
      })
      .then((item) => {
        if (mounted.current) {
          if (item?.data) {
            setIsProductsMatches(item.data);
          } else {
            setIsProductsMatches()
          }
          setLoad(false);
        }
      })
      .catch((error) => {
        setLoad(false);
        handleFailure(error, notify)
     });
    }
    return () => {
      mounted.current = false;
    };
  }, [version]);

    if (load) {
      return <Loading />
    };

    const handleGrouping = async (e) => {
      e.stopPropagation();

      try {
        await dataProviderForOffers.groupOffers("/offer", {
        offers: suppliersId
      })
       disabledGroupButton.current = false
       closeGroupDialog(suppliersId);
      } catch (error) {
       handleFailure(error, notify)
      }
    }
  

  return (
    <Dialog
      key={suppliersValues}
      open={open}
      onClose={closeGroupDialog}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" className={classes.capitalize}>
        {translate("konebone.orders.offers.group_offers")}
      </DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1" style={{ fontWeight: 600 }} gutterBottom>
        {translate(
          "konebone.orders.offers.sure_group_offer_start"
        )} 
        <span style={{ color: "red" }}> {suppliersValues[0].name} </span>
        {suppliersValues.length > 1 && suppliersValues
            .slice(1).map((el, i) => {
              const lastEl = i === suppliersValues.length - 2;
              return <span style={{ color: "red" }} key={i}>{lastEl ? <span style={{ color: "black" }}> and </ span> : <span style={{ color: "black" }}>, </ span>}{el.name}</span>
            })}
        {translate(
          "konebone.orders.offers.sure_group_offer_end"
        )}
        </Typography> 
        {isProductsMatches ?
         <>
            <Typography variant="subtitle2" paragraph>
              {translate("konebone.orders.offers.matches_products")}
            </Typography>
            <OfferMatchesProducts suppliers={suppliers} isProductsMatches={isProductsMatches} open={open}/>
         </>
        :
          <Typography variant="subtitle2" paragraph>
            {translate("konebone.orders.offers.no_matches")}
          </Typography> 
        }
      </DialogContent>
      <DialogActions className={classes.root}>
        <Button onClick={closeGroupDialog} variant="outlined">
          {translate("ra.action.cancel")}
        </Button>
        <Button disabled={Boolean(isProductsMatches)} onClick={e => handleGrouping(e)} variant="contained" color="secondary">
          {translate("konebone.orders.offers.group")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};