import { TableCell, TableRow, IconButton, Checkbox } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import classNames from "classnames";
import { useListContext, useRedirect } from "ra-core";
import { Datagrid, DatagridBody } from "ra-ui-materialui";
import React, { useState, useCallback } from "react";
import Text from 'react-texty';
import { DatagridHeader } from "./DatagridHeader";
import { STATUS } from "../pages/orders/enums";

const DatagridResponsiveRow = ({
  record,
  resource,
  id,
  expand,
  children,
  basePath,
  rowClick,
  selectable,
  selectedIds,
  onToggleItem,
  hasBulkActions,
  data
}) => {

  const [open, setOpen] = useState(false);
  const redirect = useRedirect();

  const newResource = record?.entityType && record.entityType === "SUPPLIER_CANDIDATE" ? "supplier_candidate" : resource;
  const newPath = record?.entityType && record.entityType === "SUPPLIER_CANDIDATE" ? "/supplier_candidate" : basePath;

  const handleClick = (e) => {
    if ((["TD", "TR", "SPAN"].includes(e.target?.nodeName) || e.target?.parentElement?.nodeName === "TD") &&
      !["A", "BUTTON", "LABEL"].includes(e.target?.parentElement?.nodeName)) {
      redirect(rowClick, newPath, id);
    }
  }

  const handleSelectGroup = useCallback(
    event => {
      const newDataArr = Object.values(data).filter(({status, isPartOfGroup}) => status === STATUS.COMPLETED && isPartOfGroup)
      const isPartOfGroup = newDataArr?.filter(({offerId}) => offerId == id )

        if(isPartOfGroup?.length) {
          onToggleItem(newDataArr, record, event)
        } else {
          onToggleItem(id, record, event)
        }
      },

    [id, record, data, selectedIds]
  );


  return (
    <>
     <TableRow key={id} onClick={handleClick} style={{borderLeft: `${record?.isPartOfGroup && "6px solid #E02443"}`, borderRight: `${record?.isPartOfGroup && "6px solid #E02443"}`}}>
        {!!expand && (
          <TableCell padding="none" key={`${id}-expand`}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        )}
        {hasBulkActions &&
          (
            <TableCell padding="none" key={`${id}-check`}>
              <Checkbox
                key={`${id}-checkbox`}
                disabled={!selectable && selectedIds.indexOf(String(id)) === -1}
                checked={selectedIds.indexOf(String(id)) > -1}
                onChange={handleSelectGroup}
              />
            </TableCell>
          )}
        {/* data columns based on children */}
        {React.Children.map(children, (field) => (
          <TableCell key={`${id}-${field.props.source}`}>
            {record?.[field.props.source] ? (
              <Text tooltip={String(record[field.props.source])}>
                {React.cloneElement(field, {
                  record,
                  basePath: newPath,
                  resource: newResource,
                })}
              </Text>
            ) : (
              React.cloneElement(field, {
                record,
                basePath: newPath,
                resource: newResource,
              })
            )}
          </TableCell>
        ))}
      </TableRow>
      {open &&
        React.cloneElement(expand, {
          record,
          basePath: newPath,
          resource: newResource,
          children,
        })}
    </>
  );
};

const DatagridResponsiveBody = ({ onToggleItem: onToggleItemProp, ...props }) => {
  const {
    onToggleItem,
    selectedIds,
    data
  } = useListContext(props);

  return (
    <DatagridBody {...props} onToggleItem={onToggleItem}
      row={<DatagridResponsiveRow selectedIds={selectedIds} data={data} isRowSelectable={props.isRowSelectable}/>} />
  )
};

const DatagridResponsive = ({disableSelectAll, ...props}) => (
  <div className={classNames("table-responsive", props.className)}>
    <Datagrid 
      {...props}
      body={<DatagridResponsiveBody />}
      header={<DatagridHeader disableSelectAll={disableSelectAll}/>}
      className="table" />
  </div>
);

export default DatagridResponsive;
