import React from "react";
import { TopToolbar } from "react-admin";
import { useNotify, useRedirect, useTranslate, } from "ra-core";
import { useHistory } from "react-router-dom";
import {
  Button,
  Box,
  makeStyles,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import dataProviderForOrders from '../../providers/DataProviderForOrders';
import { STATUS } from "./enums";
import dataProviderForPaid from "../../providers/DataProviderForPaid";
import { handleFailure } from "../../utils/handleFailure";

const useStyles = makeStyles((theme) => ({
  toolbar_root: {
    position: "sticky",
    top: "45px",
    zIndex: "200",
    backgroundColor: "#fafafa",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "80px",
    padding: "0px",
    "& *": {
      textTransform: "inherit",
    },
    "& > a": {
      width: "100%",
      justifyContent: "start"
    }
  },
  root: {
    "& > button": {
      marginLeft: theme.spacing(1),
      height: "42px",
    },
  },
  formControl: {
    minWidth: "42px",
    marginTop: "5px",
  }
}));

export default ({ record, basePath, getDetails}) => {
  const translate = useTranslate();
  const classes = useStyles();
  const redirect = useRedirect();
  const notify = useNotify();
  const history = useHistory();
  let savedData ={};

  const handleClose = (e) => {
    redirect("list", basePath)
  };

  const handleSave = (savedData) => {
    let data = getDetails();

    if (!data) {
     return notify(translate("konebone.orders.edit_notify"));
    }

    dataProviderForOrders.update("orders/", {
        orderId: record.id,
        data: data
      })
      .then((item) => {
        getOrderContent(item)
        notify("ra.notification.updated", "success");
        history.goBack();
      })
      .catch((error) => {
        handleFailure(error, notify)
      });
  }

  const getOrderContent = (data) => {
    savedData = data;
  }


  return (
    <TopToolbar className={classes.toolbar_root}>
      <Button
        label={translate("konebone.orders.go_back")}
        startIcon={<KeyboardBackspaceIcon />}
        component={Link}
        style={{ height: "42px" }}
        to={`/orders`}
      >
        {translate("konebone.orders.go_back")}
      </Button>
      <Box
        display="flex"
        justifyContent="end"
        width="100%"
        className={classes.root}
      >
        <Button
          variant="contained"
          onClick={handleSave}
          color="secondary"
        >{translate("konebone.orders.save_changes")}</Button>
        <Button
          variant="outlined"
          onClick={handleClose}
        >{translate("konebone.orders.cancel")}</Button>
      </Box>
    </TopToolbar >
  );
};
