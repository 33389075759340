import Wrapper from "../utils/Wrapper";
import axios from 'axios';
import { stringify } from "query-string";

const fieldMap = {
  address: "street",
  mainContact: "firstName",
  statusLower: "status",
}

const dataProviderForSupplier = {
  getOne: async (resource, params) => {
    const url = process.env.REACT_APP_API_URL + "/supplier/" + params.id;
    const response = await axios.get(url,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      });
    let resultData = Wrapper.wrapSupplier(response.data.data);
    return ({
      data: resultData,
    });
  },

  getList: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      page: page - 1,
      size: perPage,
      sort: fieldMap[field] ? fieldMap[field] : field,
    };
    const filterStatus = params.filter?.status || 'candidate';
    const url = process.env.REACT_APP_API_URL + `/supplier/${filterStatus}?${stringify(query)},${order}`;
    const response = await axios.get(url,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      });

    if (!response.data || !response.data.data) {
      return ({
        data: [],
        total: 0
      });
    }

    const data = response.data.data.content ? response.data.data.content : response.data.data

    let resultData = data.map(supplier => Wrapper.wrapSupplier(supplier));
    const responseTotalCount = response.data.data.totalElements || data.length;

    return ({
      data: resultData,
      total: responseTotalCount
    });
  },

  create: async (resource, params) => {
    let data = params.data;
    let response = await axios.post(process.env.REACT_APP_API_URL + "/supplier/create", data,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      }
    );

    return {
      data: response.data?.success,
    };
  },


  update: async (resource, params) => {
    let data = params.data.data;
    let id = params.id;

    let response = await axios.put(process.env.REACT_APP_API_URL + "/supplier/" + id, data,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      }
    );

    if (response && response.data) {
      let dataRes = response.data;
      dataRes.data.id = dataRes.data.customerId;
      params.data.onSuccess();
      return ({
        data: dataRes.data
      });
    } else {
      params.data.onSuccess();
      return ({
        data: []
      });
    }
  },

  getCountByStatus: async () => {
    let response = await axios.get(process.env.REACT_APP_API_URL + "/supplier/counts",
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      }
    );
    if (!response || !response.data) {
      return { data: {} };
    }

    return { data: response.data.data }
  },

  imported:  async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { order } = params.sort;
    const query = {
      page: page - 1,
      size: perPage,
    };

    let response = await axios.get(process.env.REACT_APP_API_URL + `/supplier/imported?${stringify(query)},${order}`,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      }
    );
    if (!response || !response.data) {
      return { data: {} };
    }

    return { data: response.data.data }
  },

  block: async (resource, { id, reason }) => {
    let response = await axios.put(process.env.REACT_APP_API_URL + `/supplier/${id}/block?reason=${reason}`, {},
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      }
    );

    if (!response || !response.data) {
      return { data: false };
    }

    return { data: true }
  },

  getAccessLink: async (resource, params) => {
    const url = process.env.REACT_APP_API_URL + "/supplier/" + params.supplierId + '/access-link';
    const response = await axios.get(url,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      });

    let resultData = Wrapper.wrapCustomer(response.data.data);

    return ({
      data: resultData.accessLink,
    });
  },

  getHistory: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { order } = params.sort;
    const query = {
      page: page - 1,
      size: perPage,
    };
    let response = await axios.get(process.env.REACT_APP_API_URL + '/supplier/' + `${params.supplierId}/history?${stringify(query)},${order}`,
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      }
    );

    if (!response || !response.data) {
      return ({
        data: [],
        total: 0
      });
    }
    let resultData = response.data.content.map(customer => Wrapper.wrapHistory(customer));
    let count = response.data.totalElements;
    
    return ({
      data: resultData,
      total: count
    });
  },


  unblock: async (resource, { id }) => {
    let response = await axios.put(process.env.REACT_APP_API_URL + `/supplier/${id}/unblock`, {},
      {
        withCredentials: true,
        headers: { 'accept': 'application/json' }
      }
    );

    if (!response || !response.data) {
      return { data: false };
    }

    return { data: true }
  },
};
export default dataProviderForSupplier;
