import axios from "axios";
import Wrapper from "../utils/Wrapper";
import WaitingService from "./WaitingService";

let _tree;

const resetAllNodes = () => {
  _tree = undefined;
};

const getAllNodes = () => {
  return _tree;
};

const setAllNodes = (value) => {
  _tree = value;
};

const getAllNodesFromApi = async (url) => {
  const allNodes = getAllNodes();
  if (allNodes && allNodes.size) {
    WaitingService.resolvePromise();
    return allNodes;
  }

  const response = await axios
    .get(url, {
      withCredentials: true,
      headers: { accept: "application/json" },
    }).catch((e) => {
      WaitingService.resolvePromise();
      throw e;
    })

  if (!response.data.data) {
    return;
  }

  setAllNodes(_createTreeStructure(response.data.data));

  WaitingService.resolvePromise();
};

const getNodesByParentId = async (url, parentId) => {
  await getAllNodesFromApi(url);

  const allNodes = getAllNodes();

  if (!allNodes.has(parentId)) {
    return [];
  }

  const result = allNodes.get(parentId).sort((a, b) => a.position - b.position);

  return result;
};

const getNodesByParentIdCount = (parentId) => {
  return _tree.get(parentId)?.length || 0;
};

const createFlatTree = (roots, extendNum) => {
  let result = [];
  roots.forEach((el) => {
    el.extendNum = extendNum;
    let children = []
    if (_tree.has(el.id)) {
      const childrenSort = _tree.get(el.id).sort((a, b) => a.position - b.position);
      children = createFlatTree(childrenSort, extendNum + 1);
    }
    const { length } = children;
    el.childCount = length;
    result.push(el);
    result = [...result, ...children];
  });

  return result;
};

const _createTreeStructure = (data) => {
  const dataObj = new Map();
  dataObj.set(0, []);
  data.forEach((el) => {
    const newEl = Wrapper.wrapCategory(el);
    if (dataObj.has(newEl.parent_id || 0)) {
      dataObj.get(newEl.parent_id || 0).push(newEl);
      return;
    }

    if (newEl.parent_id) {
      dataObj.set(newEl.parent_id, [newEl]);
      return;
    }
  });

  return dataObj;
};

export default {
  getAllNodes,
  resetAllNodes,
  getNodesByParentId,
  getAllNodesFromApi,
  getNodesByParentIdCount,
  createFlatTree,
}
