const getRootElement = (element) => {
  if (element.nodeName === 'LI') {
    return element;
  }

  if (!element.parentElement) {
    return element;
  }

  return getRootElement(element.parentElement);
};

export default getRootElement;
