import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";
import inflection from "inflection";
import { withTranslate } from "ra-core";
import { getHash, getTreeRootNodes } from "./selectors";
import { crudGetTreeRootNodes as crudGetTreeRootNodesAction } from "./actions";

export class TreeController extends Component {
    static propTypes = {
        basePath: PropTypes.string.isRequired,
        children: PropTypes.func.isRequired,
        parentSource: PropTypes.string,
        resource: PropTypes.string.isRequired,
    };

    componentDidMount() {
        this.updateData(this.props);
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.hash !== this.props.hash ||
            prevProps.resource !== this.props.resource
        ) {
            this.updateData(this.props);
        }
    }

    updateData = (props) => {
        props.crudGetTreeRootNodes({
            resource: props.resource,
            parentSource: props.parentSource,
            positionSource: props.positionSource,
        });
    };

    render() {
        const {
            children,
            crudGetTreeRootNodes,
            parentSource,
            resource,
            rootNodes,
            translate,
            ...props
        } = this.props;

        const resourceName = translate(`resources.${resource}.name`, {
            smart_count: 2,
            _: inflection.humanize(inflection.pluralize(resource)),
        });
        const defaultTitle = translate("ra.page.list", {
            name: resourceName,
        });

        return children({
            defaultTitle,
            parentSource,
            nodes: rootNodes,
            resource,
            ...props,
        });
    }
}

const mapStateToProps = (state, { resource }) => ({
    rootNodes: getTreeRootNodes(state, resource),
    loading: state.admin.loading > 0,
    hash: getHash(state, resource, 'rootHash'),
});

const EnhancedTreeController = compose(
    connect(mapStateToProps, {
        crudGetTreeRootNodes: crudGetTreeRootNodesAction,
    }),
    withTranslate
)(TreeController);

EnhancedTreeController.defaultProps = {
    parentSource: "parent_id",
};

export default EnhancedTreeController;
