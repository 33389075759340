import { makeStyles, Paper } from "@material-ui/core";
import {  ListContextProvider, useQuery, useTranslate } from "ra-core";
import keyBy from "lodash/keyBy";
import {  useState } from "react";
import DatagridResponsive from "../components/DataGridResponsive";
import {Loading, Pagination, TextField} from 'react-admin';

const useStyles = makeStyles((theme) => ({
  no_data: {
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center',
    margin: '50px 0',
  },
  paper_mr: {
    marginTop: "10px",
  },
  grid: {
    textTransform: "none",
  }
}));


export const AdminActionLoged = ({  record, resource }) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [sort, setSort] = useState({ field: 'date_time', order: 'ASC' })
  const translate = useTranslate();
  const classes = useStyles();
  
  const { data, total, loading, error } = useQuery({
    type: 'getHistory',
    resource: resource === 'customer' ? 'customer' : 'supplier',
    payload: resource === 'customer' ?{
      pagination: { page, perPage },
      sort,
      customerId: record.id
    } : 
    {pagination: { page, perPage },
      sort,
      supplierId: record.id}
  });


  if (loading) {
    return <Loading />
  }
  if (error) {
    return <p>ERROR: {error}</p>
  }

  return (
    <Paper>
      <ListContextProvider
        value={{
          total,
          page,
          perPage,
          setPage,
          setSort
        }}>
        <DatagridResponsive
          className={classes.grid}
          data={keyBy(data, 'id')}
          ids={data.map(({ id }) => id)}
          currentSort={sort}
          setSort={({id}) => setSort(id)}>
            <TextField
              source="dateTime"
              label={translate("konebone.supplier.date_time")}
              sortable={false}
              />
            <TextField
              source="userId"
              label={translate("konebone.supplier.user_id")}
              sortable={false}
            />
            <TextField
              source="adminId"
              label={translate("konebone.supplier.admin_id")}
              sortable={false}
            />
            <TextField
              source="request"
              label={translate("konebone.supplier.request")}
              sortable={false}
            />
            <TextField
              source="endpoint"
              label={translate("konebone.supplier.endpoint")}
              sortable={false}
            />
            <TextField
              source="requestBody"
              label={translate("konebone.supplier.request_body")}
              sortable={false}
            />
        </DatagridResponsive>
        {data.length ? <Pagination
          page={page}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
        /> : <div className={classes.no_data}>no data</div>}
      </ListContextProvider>
    </Paper>
  );
};